import instance from './axiosInstance'
import { getPathsString } from '../@utils'
import _ from 'lodash'

class VisitorService {
  getVisitorRequests = (apartmentId, params) => {
    return instance.get('/rs/staff/visitor/apartment/' + apartmentId, {
      params: {
        unitId: params.unitId,
        state: params.state,
        type: params.type,
        keyword: params.keyword,
        toDateArrive: params.toDateArrive && params.toDateArrive.toISOString(),
        fromDateArrive: params.fromDateArrive && params.fromDateArrive.toISOString(),
        toDateLeave: params.toDateLeave && params.toDateLeave.toISOString(),
        fromDateLeave: params.fromDateLeave && params.fromDateLeave.toISOString(),
        page: params.page,
        size: params.size
      }
    })
  }

  getVisitorRequestById = visitorId => {
    return instance.get('/rs/visitor/' + visitorId, {})
  }

  getVisitorRequestByPhoneNumber = (apartmentId, phoneNumber) => {
    return instance.get('/rs/staff/visitor/apartment/' + apartmentId + '/phoneNumber/' + phoneNumber + '/', {})
  }
  updateVisitorRequest = values => {
    return instance.put('/rs/staff/visitor/' + values.id, {
      apartmentId: values.apartmentId,
      unitId: values.unitId,
      noteForManager: values.noteForManager,
      type: values.type,
      remark: values.remark,
      idType: values.idType,
      idNumber: values.idNumber,
      visitorName: values.visitorName,
      visitorPhoneNumber: values.visitorPhoneNumber,
      dateLeave: values.dateLeave,
      dateArrive: values.dateArrive,
      companyName: values.companyName,
      expectedImagePath: getPathsString(values.expectedImagePath)
    })
  }

  createVisitorRequest = values => {
    return instance.post('/rs/visitor', {
      apartmentId: values.apartmentId,
      unitId: values.unitId,
      noteForManager: values.noteForManager,
      type: values.type,
      remark: values.remark,
      idType: values.idType,
      idNumber: values.idNumber,
      visitorName: values.visitorName,
      visitorPhoneNumber: values.visitorPhoneNumber,
      dateLeave: values.dateLeave,
      dateArrive: values.dateArrive,
      companyName: values.companyName,
      expectedImagePath: getPathsString(values.expectedImagePath)
    })
  }

  revoke = visitorId => {
    return instance.put('/rs/visitor/revoke/' + visitorId, {})
  }

  arrive = values => {
    return instance.put('/rs/staff/visitor/arrive', {
      visitorRequestId: values.visitorRequestId,
      imagePath: values.imagePath,
      description: values.description
    })
  }

  leave = values => {
    return instance.put('/rs/staff/visitor/leave', {
      visitorRequestId: values.visitorRequestId,
      imagePath: values.imagePath
    })
  }

  v2getVisitorRequests = (apartmentId, params) =>
    instance
      .get('/rs/staff/visitor/apartment/' + apartmentId, {
        params: {
          ..._.omit(params, ['dateArrive', 'dateLeave']),
          ...(params?.dateArrive ? { toDateArrive: params.dateArrive?.[1], fromDateArrive: params.dateArrive?.[0] } : {}),
          ...(params?.dateLeave ? { toDateLeave: params.dateLeave?.[1], fromDateLeave: params.dateLeave?.[0] } : {})
        }
      })
      .then(({ data }) => data)

  v2putVisitor = (id, values) => instance.put('/rs/staff/visitor/' + id, values).then(({ data }) => data)

  v2postVisitor = values => instance.post('/rs/visitor', values).then(({ data }) => data)
}

export default new VisitorService()
