import { PlusOutlined } from '@ant-design/icons'
import history from '@history'
import { lazy } from 'react'

const subscriptionModule = {
  pages: {
    GoodsService: {
      hidden: true,
      path: '/s/goods-service/:id',
      tabLink: '/s/group-services',
      component: lazy(() => import('../../services/detail/GoodServiceDetail')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'register',
            label: 'goodServiceDetail.button.create',
            icon: <PlusOutlined />,
            style: 'primary',
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}])
          },
          {
            key: 'back',
            label: 'common.button.back',
            onClick: () => history.goBack(),
            style: 'outline-primary'
          }
        ]
      })
    },
    GroupService: {
      hidden: true,
      path: '/s/group-services/:id',
      tabLink: '/s/group-services',
      component: lazy(() => import('../../services/detail/GroupGoodServiceDetail')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'register',
            label: 'button.createGoodService',
            icon: <PlusOutlined />,
            style: 'primary',
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}]),
          },
          {
            key: 'back',
            label: 'common.button.back',
            onClick: () => history.goBack(),
            style: 'outline-primary'
          }
        ]
      })
    },
    GoodServiceList: {
      label: 'breadcrumb.groupGoodServiceList',
      path: '/s/group-services',
      component: lazy(() => import('../../services/list/GroupGoodServiceList'))
    },
    SubscriptionList: {
      label: 'breadcrumb.subscriptionList',
      path: '/s/subscriptions',
      component: lazy(() => import('./pages/SubscriptionsList')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'register',
            label: 'common.button.register',
            icon: <PlusOutlined />,
            style: 'primary',
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}]),
          }
        ]
      })
    }
  }
}

export default subscriptionModule
