import residentService from "../../services/residentService";
import jwtAuthService from "../../services/jwtAuthService";
import {error, success} from "./alertActions";
import history from "../../@history";

export const GET_OWNER_UNIT = 'GET_OWNER_UNIT';
export const GET_OWNER_UNIT_SUCCESS = 'GET_OWNER_UNIT_SUCCESS';
export const GET_OWNER_UNIT_FAILURE = 'GET_OWNER_UNIT_FAILURE';

export const GET_RESIDENTS = 'GET_RESIDENTS';
export const GET_RESIDENTS_SUCCESS = 'GET_RESIDENTS_SUCCESS';
export const GET_RESIDENTS_FAILURE = 'GET_RESIDENTS_FAILURE';

export const GET_RESIDENTS_BY_APARTMENT = 'GET_RESIDENTS_BY_APARTMENT';
export const GET_RESIDENTS_SUCCESS_BY_APARTMENT = 'GET_RESIDENTS_SUCCESS_BY_APARTMENT';
export const GET_RESIDENTS_FAILURE_BY_APARTMENT = 'GET_RESIDENTS_FAILURE_BY_APARTMENT';

export const LOCK_RESIDENT = 'LOCK_RESIDENT';
export const LOCK_RESIDENT_SUCCESS = 'LOCK_RESIDENT_SUCCESS';
export const LOCK_RESIDENT_FAILURE = 'LOCK_RESIDENT_FAILURE';

export const UNLOCK_RESIDENT = 'UNLOCK_RESIDENT';
export const UNLOCK_RESIDENT_SUCCESS = 'UNLOCK_RESIDENT_SUCCESS';
export const UNLOCK_RESIDENT_FAILURE = 'UNLOCK_RESIDENT_FAILURE';

export const CREATE_RESIDENT = 'CREATE_RESIDENT';
export const CREATE_RESIDENT_SUCCESS = 'CREATE_RESIDENT_SUCCESS';
export const CREATE_RESIDENT_FAILURE = 'CREATE_RESIDENT_FAILURE';

export const UPDATE_RESIDENT = 'UPDATE_RESIDENT';
export const UPDATE_RESIDENT_SUCCESS = 'UPDATE_RESIDENT_SUCCESS';
export const UPDATE_RESIDENT_FAILURE = 'UPDATE_RESIDENT_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const GET_RESIDENT = 'GET_RESIDENT';
export const GET_RESIDENT_SUCCESS = 'GET_RESIDENT_SUCCESS';
export const GET_RESIDENT_FAILURE = 'GET_RESIDENT_FAILURE';

export const GET_RESIDENT_UNIT = 'GET_RESIDENT_UNIT';
export const GET_RESIDENT_UNIT_SUCCESS = 'GET_RESIDENT_UNIT_SUCCESS';
export const GET_RESIDENT_UNIT_FAILURE = 'GET_RESIDENT_UNIT_FAILURE'

export const GET_RESIDENT_UNITS = 'GET_RESIDENT_UNITS';
export const GET_RESIDENT_UNITS_SUCCESS = 'GET_RESIDENT_UNITS_SUCCESS';
export const GET_RESIDENT_UNITS_FAILURE = 'GET_RESIDENT_UNITS_FAILURE';

export const GET_UNITS_OF_RESIDENT = 'GET_UNITS_OF_RESIDENT';
export const GET_UNITS_OF_RESIDENT_SUCCESS = 'GET_UNITS_OF_RESIDENT_SUCCESS';
export const GET_UNITS_OF_RESIDENT_FAILURE = 'GET_UNITS_OF_RESIDENT_FAILURE';

export const GET_VICEGERENTS = 'GET_VICEGERENTS';
export const GET_VICEGERENTS_SUCCESS = 'GET_VICEGERENTS_SUCCESS';
export const GET_VICEGERENTS_FAILURE = 'GET_VICEGERENTS_FAILURE';

export const CREATE_VICEGERENT = 'CREATE_VICEGERENT';
export const CREATE_VICEGERENT_SUCCESS = 'CREATE_VICEGERENT_SUCCESS';
export const CREATE_VICEGERENT_FAILURE = 'CREATE_VICEGERENT_FAILURE';

export const UPDATE_VICEGERENT = 'UPDATE_VICEGERENT';
export const UPDATE_VICEGERENT_SUCCESS = 'UPDATE_VICEGERENT_SUCCESS';
export const UPDATE_VICEGERENT_FAILURE = 'UPDATE_VICEGERENT_FAILURE';

export const DELETE_VICEGERENT = 'DELETE_VICEGERENT';
export const DELETE_VICEGERENT_SUCCESS = 'DELETE_VICEGERENT_SUCCESS';
export const DELETE_VICEGERENT_FAILURE = 'DELETE_VICEGERENT_FAILURE';

export const SET_DEFAULT_VICEGERENT = 'SET_DEFAULT_VICEGERENT';
export const SET_DEFAULT_VICEGERENT_SUCCESS = 'SET_DEFAULT_VICEGERENT_SUCCESS';
export const SET_DEFAULT_VICEGERENT_FAILURE = 'SET_DEFAULT_VICEGERENT_FAILURE';

export const SET_UNDEFAULT_VICEGERENT = 'SET_UNDEFAULT_VICEGERENT';
export const SET_UNDEFAULT_VICEGERENT_SUCCESS = 'SET_UNDEFAULT_VICEGERENT_SUCCESS';
export const SET_UNDEFAULT_VICEGERENT_FAILURE = 'SET_UNDEFAULT_VICEGERENT_FAILURE';

export const GET_RESIDENT_HISTORY_LOGIN = 'GET_RESIDENT_HISTORY_LOGIN';
export const GET_RESIDENT_HISTORY_LOGIN_SUCCESS = 'GET_RESIDENT_HISTORY_LOGIN_SUCCESS';
export const GET_RESIDENT_HISTORY_LOGIN_FAILURE = 'GET_RESIDENT_HISTORY_LOGIN_FAILURE';

export function getUnitsOfResident(apartmentId, residentId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_UNITS_OF_RESIDENT
            });
            const data = await residentService.getUnitsOfResident(apartmentId, residentId)
            dispatch({
                type: GET_UNITS_OF_RESIDENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_UNITS_OF_RESIDENT_FAILURE
            });
            return err;
        }
    }
}

export function getResidentDetail(id) {
    return dispatch => {
        dispatch({
            type: GET_RESIDENT
        });
        residentService
            .getResidentDetail(id)
            .then(data => {
                return dispatch({
                    type: GET_RESIDENT_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                return dispatch({
                    type: GET_RESIDENT_FAILURE
                });
            });
    };
}


export function createResident(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_RESIDENT
            });
            const data = await jwtAuthService.createResident(values)
            dispatch(success("", "message.created"))
            history.push({
                pathname: "/residents"
            });
            dispatch({
                type: CREATE_RESIDENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_RESIDENT_FAILURE
            });
            return err;
        }
    }
}

export function updateResident(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_RESIDENT
            });
            const data = await residentService.updateResident(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_RESIDENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_RESIDENT_FAILURE
            });
            return err;
        }
    }
}


export function resetPassword(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: RESET_PASSWORD
            });
            const data = await residentService.resetPassword(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: RESET_PASSWORD_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: RESET_PASSWORD_FAILURE
            });
            return err;
        }
    }
}

export function getResidents(apartmentId, status, keyword, page, size, showAll) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_RESIDENTS
            });
            const data = await residentService.getResidents(apartmentId, status, keyword, page, size, showAll)
            dispatch({
                type: GET_RESIDENTS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_RESIDENTS_FAILURE
            });
            return err;
        }
    }
}

export function getResidentsByApartment(apartmentId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_RESIDENTS_BY_APARTMENT
            });
            const data = await residentService.getResidentsByApartment(apartmentId)
            dispatch({
                type: GET_RESIDENTS_SUCCESS_BY_APARTMENT,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_RESIDENTS_FAILURE_BY_APARTMENT
            });
            return err;
        }
    }
}

export function lockResident(residentId) {
    return dispatch => {
        dispatch({
            type: LOCK_RESIDENT
        });
        residentService
            .lockResident(residentId)
            .then(data => {
                dispatch(success("Thông báo!", "Khoá cư dân/khách hàng thành công."))
                return dispatch({
                    type: LOCK_RESIDENT_SUCCESS,
                    payload: residentId
                });
            })
            .catch(e => {
                dispatch(error("Thông báo!", "Khoá cư dân/khách hàng thất bại."))
                return dispatch({
                    type: LOCK_RESIDENT_FAILURE
                });
            });
    };
}

export function unlockResident(residentId) {
    return dispatch => {
        dispatch({
            type: UNLOCK_RESIDENT
        });
        residentService
            .unlockResident(residentId)
            .then(data => {
                dispatch(success("Thông báo!", "Mở khoá cư dân/khách hàng thành công."))
                return dispatch({
                    type: UNLOCK_RESIDENT_SUCCESS,
                    payload: residentId
                });
            })
            .catch(e => {
                dispatch(error("Thông báo!", "Mở khoá cư dân/khách hàng thất bại."))
                return dispatch({
                    type: UNLOCK_RESIDENT_FAILURE
                });
            });
    };
}

export function getResidentUnits(apartmentIds, blockIds, floorIds, unitIds) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_RESIDENT_UNITS
            });
            const data = await residentService.getResidentUnits(apartmentIds, blockIds, floorIds, unitIds)
            dispatch({
                type: GET_RESIDENT_UNITS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            return dispatch({
                type: GET_RESIDENT_UNITS_FAILURE
            });
        }
    }
}

export function getResidentByUnit(unitId) {
    return dispatch => {
        dispatch({
            type: GET_RESIDENT_UNIT
        });
        return residentService
            .getResidentByUnit(unitId)
            .then(data => {
                dispatch({
                    type: GET_RESIDENT_UNIT_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                dispatch({
                    type: GET_RESIDENT_UNIT_FAILURE
                });
            });
    };
}

export function getVicegerents(residentId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_VICEGERENTS
            });
            const data = await residentService.getVicegerents(residentId)
            dispatch({
                type: GET_VICEGERENTS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            return dispatch({
                type: GET_VICEGERENTS_FAILURE
            });
        }
    }
}

export function createVicegerent(residentId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_VICEGERENT
            });
            const data = await residentService.createVicegerent(residentId, values)
            dispatch(success("", "message.created"))
            dispatch(getVicegerents(residentId))
            dispatch({
                type: CREATE_VICEGERENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            return dispatch({
                type: CREATE_VICEGERENT_FAILURE
            });
        }
    }
}

export function updateVicegerent(residentId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_VICEGERENT
            });
            const data = await residentService.updateVicegerent(residentId, values)
            dispatch(success("", "message.updated"))
            dispatch(getVicegerents(residentId))
            dispatch({
                type: UPDATE_VICEGERENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            return dispatch({
                type: UPDATE_VICEGERENT_FAILURE
            });
        }
    }
}

export function deleteVicegerent(residentId, vicegerentId) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_VICEGERENT
            });
            const data = await residentService.deleteVicegerent(residentId, vicegerentId)
            dispatch(success("", "message.deleted"))
            dispatch(getVicegerents(residentId))
            dispatch({
                type: DELETE_VICEGERENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            return dispatch({
                type: DELETE_VICEGERENT_FAILURE
            });
        }
    }
}

export function setDefaultVicegerent(residentId, vicegerentId) {
    return async dispatch => {
        try {
            dispatch({
                type: SET_DEFAULT_VICEGERENT
            });
            const data = await residentService.setDefaultVicegerent(residentId, vicegerentId)
            dispatch(success("", "message.updated"))
            dispatch(getVicegerents(residentId))
            dispatch({
                type: SET_DEFAULT_VICEGERENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            return dispatch({
                type: SET_DEFAULT_VICEGERENT_FAILURE
            });
        }
    }
}

export function setUnDefaultVicegerent(residentId, vicegerentId) {
    return async dispatch => {
        try {
            dispatch({
                type: SET_UNDEFAULT_VICEGERENT
            });
            const data = await residentService.setUnDefaultVicegerent(residentId, vicegerentId)
            dispatch(success("", "message.updated"))
            dispatch(getVicegerents(residentId))
            dispatch({
                type: SET_UNDEFAULT_VICEGERENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            return dispatch({
                type: SET_UNDEFAULT_VICEGERENT_FAILURE
            });
        }
    }
}


export function getResidentHistoryLogin(residentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_RESIDENT_HISTORY_LOGIN
            });
            const data = await residentService.getResidentHistoryLogin(residentId, params)
            dispatch({
                type: GET_RESIDENT_HISTORY_LOGIN_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            return dispatch({
                type: GET_RESIDENT_HISTORY_LOGIN_FAILURE
            });
        }
    }
}




