import PageTabsLayout from 'layout/PageTabsLayout'
import newsModule from './newsModule'

const newRoutes = [
  {
    exact: false,
    path: '/news',
    component: PageTabsLayout,
    routes: [
      newsModule.pages.AllListing,
      newsModule.pages.NewsProhomeListing,
      newsModule.pages.NewsInternalListing,
      newsModule.pages.NewsAdsListing
    ]
  }
]

export default newRoutes
