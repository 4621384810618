import {lazy} from "react";

const ContractList = lazy(() => import("./list/ContractList"));
const ContractDetail = lazy(() => import("./detail/ContractDetail"));

const extContractRoutes = [
    {
        path: "/extContracts/:id",
        component: ContractDetail
    },
    {
        path: "/extContracts",
        component: ContractList
    }
];

export default extContractRoutes;
