import uploadService from '../../services/uploadService'

export const UPLOAD_FILES = 'UPLOAD_FILES'
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS'
export const UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE'

export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE'

export const DOWNLOAD_FILE = 'DOWNLOAD_FILES'
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILES_SUCCESS'
export const DOWNLOAD_FILE_FAILURE = 'DOWNLOAD_FILES_FAILURE'

export const VIEW_FILE = 'VIEW_FILE'
export const VIEW_FILE_SUCCESS = 'VIEW_FILE_SUCCESS'
export const VIEW_FILE_FAILURE = 'VIEW_FILE_FAILURE'

export function uploadPrivateFile(file) {
  return async dispatch => {
    dispatch({
      type: UPLOAD_FILES
    })

    try {
      const data = await uploadService.uploadPrivateFile(file)
      dispatch({
        type: UPLOAD_FILES_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: UPLOAD_FILES_FAILURE
      })
    }
  }
}

export function uploadFiles(files) {
  return async dispatch => {
    dispatch({
      type: UPLOAD_FILES
    })

    try {
      const data = await uploadService.uploadFiles(files)
      dispatch({
        type: UPLOAD_FILES_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: UPLOAD_FILES_FAILURE
      })
    }
  }
}

export function uploadFile(file) {
  return async dispatch => {
    dispatch({
      type: UPLOAD_FILE
    })
    try {
      const data = await uploadService.uploadFile(file)
      dispatch({
        type: UPLOAD_FILE_SUCCESS,
        payload: data
      })
      return data
    } catch (error) {
      dispatch({
        type: UPLOAD_FILE_FAILURE
      })
    }
  }
}

export function downloadPrivateFile(fileName) {
  return async dispatch => {
    dispatch({
      type: DOWNLOAD_FILE
    })

    try {
      const res = await uploadService.downloadPrivateFile(fileName)
      dispatch({
        type: DOWNLOAD_FILE_SUCCESS
      })
      return res.data;
    } catch (error) {
      dispatch({
        type: DOWNLOAD_FILE_FAILURE
      })
    }
  }
}
export function ViewPrivateFile(fileName) {
  return async dispatch => {
    dispatch({
      type: VIEW_FILE
    })

    try {
        const res = await uploadService.downloadPrivateFile(fileName)
      
      dispatch({
        type: VIEW_FILE_SUCCESS,
        url: URL.createObjectURL(new Blob([res.data]))
      })
      return URL.createObjectURL(new Blob([res.data]))
    } catch (error) {
      dispatch({
        type: VIEW_FILE_FAILURE
      })
    }
  }
}
