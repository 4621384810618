import { lazy } from 'react'

const workOrderModule = {
  pages: {
    TaskDetail: {
      getLink: id => `/tasks/${id}`
    },
    MaintainDetail: {
      getLink: id => `/wo/maintenances/${id}`
    },
    FeedbackDetail: {
      getLink: id => `/wo/feedbacks/${id}`
    },
    TaskListing: {
      label: 'breadcrumb.employeeTask',
      path: '/wo/tasks',
      component: lazy(() => import('./pages/TaskList')),
      data: { type: 'TASK' },
      actions: eventEmitter => ({
        actions: [
          {
            key: 'add',
            label: 'common.button.add',
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}]),
            style: 'primary'
          }
        ]
      })
    },
    MaintainListing: {
      label: 'breadcrumb.maintenanceList',
      path: '/wo/maintenances',
      component: lazy(() => import('./pages/MaintainList')),
      data: { type: 'MAINTENANCE' }
    },
    FeedbackListing: {
      label: 'breadcrumb.feedbackList',
      path: '/wo/feedbacks',
      component: lazy(() => import('./pages/MaintainList')),
      data: { type: 'FEEDBACK' }
    }
  }
}

export default workOrderModule
