import instance from './axiosInstance'

class UploadService {
  uploadPrivateFile = file => {
    let formData = new FormData()
    formData.append('file', file[0])
    return instance
      .post('/us/private/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  uploadFiles = files => {
    let formData = new FormData()
    for (const iterator of files) {
      formData.append('files', iterator)
    }
    return instance
      .post('/us/upload-multiple-files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  uploadFile = file => {
    let formData = new FormData()
    formData.append('file', file)

    return instance
      .post('/us/upload-file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  downloadPrivateFile = (fileName) => {
    return instance
      .get("/us/private/download/" + fileName, {
        responseType: "blob"
      })
      .then((response) => response);
  }
}

export default new UploadService()
