import {
    ACTIVE_EMPLOYEE,
    ACTIVE_EMPLOYEE_FAILURE,
    ACTIVE_EMPLOYEE_SUCCESS,
    CREATE_DEPARTMENT,
    CREATE_DEPARTMENT_FAILURE,
    CREATE_DEPARTMENT_SUCCESS,
    CREATE_EMPLOYEE,
    CREATE_EMPLOYEE_FAILURE,
    CREATE_EMPLOYEE_SUCCESS,
    CREATE_PERMISSION,
    CREATE_PERMISSION_FAILURE,
    CREATE_PERMISSION_SUCCESS,
    CREATE_ROLE,
    CREATE_ROLE_FAILURE,
    CREATE_ROLE_SUCCESS,
    DEACTIVE_EMPLOYEE,
    DEACTIVE_EMPLOYEE_FAILURE,
    DEACTIVE_EMPLOYEE_SUCCESS,
    DELETE_PERMISSION,
    DELETE_PERMISSION_FAILURE,
    DELETE_PERMISSION_SUCCESS,
    DELETE_ROLE,
    DELETE_ROLE_FAILURE,
    DELETE_ROLE_SUCCESS,
    GET_DEPARTMENT,
    GET_DEPARTMENT_FAILURE,
    GET_DEPARTMENT_SUCCESS,
    GET_DEPARTMENTS,
    GET_DEPARTMENTS_FAILURE,
    GET_DEPARTMENTS_SUCCESS,
    GET_EMPLOYEE,
    GET_EMPLOYEE_FAILURE,
    GET_EMPLOYEE_OPTIONS,
    GET_EMPLOYEE_OPTIONS_FAILURE,
    GET_EMPLOYEE_OPTIONS_SUCCESS,
    GET_EMPLOYEE_SUCCESS,
    GET_EMPLOYEES,
    GET_EMPLOYEES_FAILURE,
    GET_EMPLOYEES_SUCCESS,
    GET_MY_SCHEDULES,
    GET_MY_SCHEDULES_FAILURE,
    GET_MY_SCHEDULES_SUCCESS,
    GET_ORGANIZATION,
    GET_ORGANIZATION_FAILURE,
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATIONS,
    GET_ORGANIZATIONS_FAILURE,
    GET_ORGANIZATIONS_SUCCESS,
    GET_PERMISSION,
    GET_PERMISSION_FAILURE,
    GET_PERMISSION_SUCCESS,
    GET_PERMISSIONS,
    GET_PERMISSIONS_FAILURE,
    GET_PERMISSIONS_SUCCESS,
    GET_ROLE,
    GET_ROLE_FAILURE,
    GET_ROLE_SUCCESS,
    GET_ROLES,
    GET_ROLES_FAILURE,
    GET_ROLES_SUCCESS,
    UPDATE_DEPARTMENT,
    UPDATE_DEPARTMENT_FAILURE,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_EMPLOYEE,
    UPDATE_EMPLOYEE_FAILURE,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_ORG,
    UPDATE_ORG_FAILURE,
    UPDATE_ORG_SUCCESS,
    UPDATE_PERMISSION,
    UPDATE_PERMISSION_FAILURE,
    UPDATE_PERMISSION_SUCCESS,
    UPDATE_ROLE,
    UPDATE_ROLE_FAILURE,
    UPDATE_ROLE_SUCCESS,
    UPLOAD_FILES,
    UPLOAD_FILES_FAILURE,
    UPLOAD_FILES_SUCCESS
} from "../actions/employeeActions";

const EmployeeReducer = function(state = {}, action) {
    switch (action.type) {
        case GET_EMPLOYEE_OPTIONS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_EMPLOYEE_OPTIONS_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_EMPLOYEE_OPTIONS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case UPLOAD_FILES:
            return {
                ...state,
                loading: true
            };
        case UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case UPLOAD_FILES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_EMPLOYEES: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_EMPLOYEES_SUCCESS: {
            return {
                ...state,
                loading: false,
                employees: action.payload
            };
        }
        case GET_EMPLOYEES_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_ORGANIZATIONS:
            return {
                ...state,
                loading: true
            };
        case GET_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                organizations: action.payload
            };
        case GET_ORGANIZATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_DEPARTMENTS:
            return {
                ...state,
                loading: true
            };
        case GET_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                departments: action.payload
            };
        case GET_DEPARTMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_PERMISSIONS:
            return {
                ...state,
                loading: true
            };
        case GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                permissions: action.payload
            };
        case GET_PERMISSIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_PERMISSION:
            return {
                ...state,
                loading: true
            };
        case GET_PERMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                permission: action.payload
            };
        case GET_PERMISSION_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CREATE_PERMISSION:
            return {
                ...state,
                loading: true
            };
        case CREATE_PERMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                permissions: {
                    ...state.permissions,
                    data: [
                        action.payload,
                        ...state.permissions.data
                    ]
                }
            };
        case CREATE_PERMISSION_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case UPDATE_PERMISSION:
            return {
                ...state,
                loading: true
            };
        case UPDATE_PERMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                permissions: {
                    ...state.permissions,
                    data: state.permissions.data.map(
                        (permission, ind) => permission.id === action.payload.id ?
                            {...permission,
                                name: action.payload.name,
                                slug: action.payload.slug,
                                resource: action.payload.resource,
                                description: action.payload.description
                            }
                            : permission
                    )
                },
            };
        case UPDATE_PERMISSION_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case DELETE_PERMISSION:
            return {
                ...state,
                loading: true
            };
        case DELETE_PERMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                permissions: {
                    ...state.permissions,
                    data: state.permissions.data.filter(x => x.id !== action.payload)

                },
            };
        case DELETE_PERMISSION_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_ROLES:
            return {
                ...state,
                loading: true
            };
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.payload
            };
        case GET_ROLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_ROLE:
            return {
                ...state,
                loading: true
            };
        case GET_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                role: action.payload
            };
        case GET_ROLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CREATE_ROLE:
            return {
                ...state,
                loading: true
            };
        case CREATE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: [
                    action.payload,
                    ...state.roles
                ]
            };
        case CREATE_ROLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case UPDATE_ROLE:
            return {
                ...state,
                loading: true
            };
        case UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: state.roles.map(
                    (role, ind) => role.id === action.payload.id ?
                        {...role,
                            name: action.payload.name,
                            privileges: action.payload.privileges
                        }
                        : role
                )
            };
        case UPDATE_ROLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case DELETE_ROLE:
            return {
                ...state,
                loading: true
            };
        case DELETE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: state.roles.filter(x => x.id !== action.payload)
            };
        case DELETE_ROLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_EMPLOYEE: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                loading: false,
                employee: action.payload
            };
        }
        case GET_EMPLOYEE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        case CREATE_EMPLOYEE: {
            return {
                ...state,
                loading: true,
                error: true
            };
        }
        case CREATE_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                loading: false,
                employee: action.payload,
                error: false
            };
        }
        case CREATE_EMPLOYEE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case UPDATE_EMPLOYEE: {
            return {
                ...state,
                loading: true,
                error: true
            };
        }
        case UPDATE_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                loading: false,
                employee: action.payload,
                error: false
            };
        }
        case UPDATE_EMPLOYEE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case ACTIVE_EMPLOYEE: {
            return {
                ...state,
                loading: true
            };
        }
        case ACTIVE_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                loading: false,
                employees: {
                    ...state.employees,
                    data: state.employees.data.map(
                        (employee, ind) => employee.id === action.payload ?
                            {...employee,
                                isEnabled: true
                            }
                            : employee
                    )
                }
            };
        }
        case ACTIVE_EMPLOYEE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case DEACTIVE_EMPLOYEE: {
            return {
                ...state,
                loading: true
            };
        }
        case DEACTIVE_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                loading: false,
                employees: {
                    ...state.employees,
                    data: state.employees.data.map(
                        (employee, ind) => employee.id === action.payload ?
                            {...employee,
                                isEnabled: false
                            }
                            : employee
                    )
                }
            };
        }
        case DEACTIVE_EMPLOYEE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_ORGANIZATION:
            return {
                ...state,
                loading: true
            };
        case GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                loading: false,
                organization: action.payload
            };
        case GET_ORGANIZATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_DEPARTMENT:
            return {
                ...state,
                loading: true
            };
        case GET_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                department: action.payload
            };
        case GET_DEPARTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case UPDATE_DEPARTMENT:
            return {
                ...state,
                loading: true
            };
        case UPDATE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                departments: state.departments.map(
                    (department, ind) => department.id === action.payload.id ?
                        {...department,
                            name: action.payload.name,
                            roles: action.payload.roles
                        }
                        : department
                )
            };
        case UPDATE_DEPARTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            }
        case CREATE_DEPARTMENT:
            return {
                ...state,
                loading: true
            };
        case CREATE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case CREATE_DEPARTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            }
        case UPDATE_ORG:
            return {
                ...state,
                loading: true
            };
        case UPDATE_ORG_SUCCESS:
            return {
                ...state,
                loading: false,
                organizations: {
                    ...state.organizations,
                    data: state.organizations.data.map(
                        (organization, ind) => organization.id === action.payload.id ?
                            {...organization,
                                name: action.payload.name,
                                beneficiaryName: action.payload.beneficiaryName,
                                beneficiaryBank: action.payload.beneficiaryBank,
                                bankAccountNo: action.payload.bankAccountNo
                            }
                            : organization
                    )
                }
            };
        case UPDATE_ORG_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            }
        case GET_MY_SCHEDULES:
            return {
                ...state,
                loading: true
            };
        case GET_MY_SCHEDULES_SUCCESS:
            return {
                ...state,
                loading: false,
                schedules: action.payload
            };
        case GET_MY_SCHEDULES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        default: {
            return state;
        }
    }
};

export default EmployeeReducer;
