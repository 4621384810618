import React from 'react'
import { Tabs } from 'antd'

/**
 * A React component that renders a card with a tabbed interface for navigating between different sections.
 *
 * @param {Object} props - The component props.
 * @param {Array<{ key: string, label: string }>} props.sections - An array of section objects, each with a unique key and label.
 * @param {string} props.value - The currently selected section key.
 * @param {function(string)} props.setSection - A function to call when the selected section changes.
 * @param {React.ReactNode} [props.prefix] - An optional React node to render in the top-right corner of the card.
 * @returns {React.ReactElement} - A React element representing the UiHeadTabs component.
 */
export default function UiHeadTabs({ sections, value, setSection, prefix, ...props }) {
    return <div className="card">
        <div className="card-body py-0">
            <div className="position-relative">
                <Tabs
                    items={sections} activeKey={value} onChange={setSection}
                    type="line" className="ui-head-tabs" {...props}
                />
                {prefix && <div className="position-absolute d-flex justify-content-end align-items-center" style={{ right: 0, top: 0, height: 46 }}> {prefix} </div>}
            </div>
        </div>
    </div>
}
