import reportService from "../../services/reportService";
import {error} from "./alertActions";
import moment from "moment";
import FileSaver from 'file-saver'
import {reports} from "../../services/reportParamsService";

export const GET_WAREHOUSE_REPORTS = 'GET_WAREHOUSE_REPORTS';
export const GET_WAREHOUSE_REPORTS_SUCCESS = 'GET_WAREHOUSE_REPORTS_SUCCESS';
export const GET_WAREHOUSE_REPORTS_FAILURE = 'GET_WAREHOUSE_REPORTS_FAILURE';

export const EXPORT_WAREHOUSE_REPORT = 'EXPORT_WAREHOUSE_REPORT';
export const EXPORT_WAREHOUSE_REPORT_SUCCESS = 'EXPORT_WAREHOUSE_REPORT_SUCCESS';
export const EXPORT_WAREHOUSE_REPORT_FAILURE = 'EXPORT_WAREHOUSE_REPORT_FAILURE';

export const GET_RESIDENT_INDEX_REPORTS = 'GET_RESIDENT_INDEX_REPORTS';
export const GET_RESIDENT_INDEX_REPORTS_SUCCESS = 'GET_RESIDENT_INDEX_REPORTS_SUCCESS';
export const GET_RESIDENT_INDEX_REPORTS_FAILURE = 'GET_RESIDENT_INDEX_REPORTS_FAILURE';

export const GET_REPORT_PARAMS = "GET_REPORT_PARAMS";
export const GET_REPORT_PARAMS_SUCCESS = "GET_REPORT_PARAMS_SUCCESS";
export const GET_REPORT_PARAMS_FAILURE = "GET_REPORT_PARAMS_FAILURE";


export const PREVIEW_REPORTS = 'PREVIEW_REPORTS';
export const PREVIEW_REPORTS_SUCCESS = 'PREVIEW_REPORTS_SUCCESS';
export const PREVIEW_REPORTS_FAILURE = 'PREVIEW_REPORTS_FAILURE';

export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';


export function getWarehouseReports(params) {
    return async (dispatch) => {
        dispatch({
            type: GET_WAREHOUSE_REPORTS
        });
        try {
            const data = await reportService.getWarehouseReports(params);
            dispatch({
                type:GET_WAREHOUSE_REPORTS_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: GET_WAREHOUSE_REPORTS_FAILURE
            });
        }
    }
}

export function exportWarehouseReports(params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            const res = await reportService.exportWarehouseReports(params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-nhap-xuat-kho-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}

export function exportSummaryWarehouseReports(apartmentId, month) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            if(apartmentId==null){
                dispatch(error("Phải chọn kho", "Bạn vui lòng chọn kho để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            if(month === null){
                dispatch(error("Phải chọn tháng", "Bạn vui lòng chọn tháng để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            const res = await reportService.exportSummaryWarehouseReports(apartmentId, month)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-nhap-xuat-ton-kho-hang-thang-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}


export function exportSummaryWarehouseReportsByQuarter(apartmentId, quarter, year) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            if(apartmentId==null){
                dispatch(error("Phải chọn kho", "Bạn vui lòng chọn kho nhà để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            if(quarter==null){
                dispatch(error("Phải chọn Quý", "Bạn vui lòng chọn Quý để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            const res = await reportService.exportSummaryWarehouseReportsByQuarter(apartmentId, quarter,year)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-nhap-xuat-ton-kho-theo-quy-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response?.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}


export function exportSummaryParkingSubscriptionReport(apartmentId) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            const res = await reportService.exportSummaryParkingSubscriptionReport(apartmentId)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-tong-hop-bai-xe-hang-thang-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}

export function exportParkingSubscriptionRequestReport(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            if(params.residentId==null){
                dispatch(error("Phải chọn Khách hàng", "Bạn vui lòng chọn Khách hàng để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            if(params.requestType==null){
                dispatch(error("Phải chọn Loại xe", "Bạn vui lòng chọn loại xe để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }

            const res = await reportService.exportParkingSubscriptionRequestReport(apartmentId, params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-dang-ky-bai-xe-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}

export function exportSummaryContractReport(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            if(params.quarter==null){
                dispatch(error("Phải chọn Quý", "Bạn vui lòng chọn Quý để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            const res = await reportService.exportSummaryContractReport(apartmentId, params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-hop-dong-cho-thue-theo-quy-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response?.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}

export function exportConstructionOvertimeReport(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            const res = await reportService.exportConstructionOvertimeReport(apartmentId, params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-chi-phi-thi-cong-ngoai-gio-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}

export function exportPCCCOvertimeReport(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            const res = await reportService.exportPCCCOvertimeReport(apartmentId, params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-chi-phi-thi-bom-nuoc-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response?.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}

export function exportWatchmanActivityReport(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            if(params.date == null){
                dispatch(error("Phải chọn Ngày", "Bạn vui lòng chọn ngày để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            const res = await reportService.exportWatchmanActivityReport(apartmentId, params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-hang-ngay-cua-bao-ve-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}

export function exportSanitationReport(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            if(params.date == null){
                dispatch(error("Phải chọn Ngày", "Bạn vui lòng chọn ngày để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            const res = await reportService.exportSanitationReport(apartmentId, params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-kiem-tra-ve-sinh-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}

export function exportChecklist(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            const res = await reportService.exportChecklist(apartmentId, params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "phieu-kiem-tra-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}


export function exportProfitAndLossReport(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            if(params.fromDate == null && params.toDate == null){
                dispatch(error("Phải chọn từ và đến ngày", "Bạn vui lòng chọn 'từ và đến ngày' để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }

            if(params.fromDate == null){
                dispatch(error("Phải chọn từ ngày", "Bạn vui lòng chọn 'từ ngày' để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            if(params.toDate == null){
                dispatch(error("Phải chọn đến ngày", "Bạn vui lòng chọn 'đến ngày' để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            const res = await reportService.exportProfitAndLossReport(apartmentId, params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-doanh-thu-va-thu-chi-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}


export function exportWorkingOvertimeReport(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            if(params.fromDate == null && params.toDate == null){
                dispatch(error("Phải chọn từ và đến ngày", "Bạn vui lòng chọn 'từ và đến ngày' để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            if(params.fromDate == null){
                dispatch(error("Phải chọn từ ngày", "Bạn vui lòng chọn 'từ ngày' để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            if(params.toDate == null){
                dispatch(error("Phải chọn đến ngày", "Bạn vui lòng chọn 'đến ngày' để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }
            const res = await reportService.exportWorkingOvertimeReport(apartmentId, params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-lam-viec-ngoai-gio-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}


export function previewReports(reportId,params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS
            });
            const res = await reportService.exportReports(reportId,params)
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            new Blob([res.data], {type: fileType});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload:[res.data]
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
        }
    }
}


export function getResidentIndexReports(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_RESIDENT_INDEX_REPORTS
            });
            const data = await reportService.getResidentIndexReports(apartmentId, params)
            dispatch({
                type: GET_RESIDENT_INDEX_REPORTS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_RESIDENT_INDEX_REPORTS_FAILURE
            });
        }
    }
}


export function getReportParams(reportCode) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_REPORT_PARAMS
            });
            const data = reports.find(x => x.value === reportCode)
            dispatch({
                type: GET_REPORT_PARAMS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_REPORT_PARAMS_FAILURE
            });
        }
    }
}

export function exportIncomeReport(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            if(isNaN(params.month)){
                dispatch(error("Phải chọn tháng", "Bạn vui lòng chọn tháng báo  để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }

            const res = await reportService.exportIncomeReport(apartmentId, params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-chi-tiet-thu-" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err?.response?.data?.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}

export function exportOutcomeReport(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: PREVIEW_REPORTS,
            });
            if(isNaN(params.month)){
                dispatch(error("Phải chọn tháng", "Bạn vui lòng chọn tháng báo cáo để thực hiện"))
                dispatch({
                    type: PREVIEW_REPORTS_FAILURE
                });
                return null;
            }

            const res = await reportService.exportOutcomeReport(apartmentId, params)
            const data = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            dispatch({
                type: PREVIEW_REPORTS_SUCCESS,
                payload: data
            });
            FileSaver.saveAs(data, "bao-cao-chi-tiet--" + moment(new Date()) + ".xlsx");
            return data;
        } catch (err) {
            dispatch(error("", err?.response?.data?.message))
            dispatch({
                type: PREVIEW_REPORTS_FAILURE
            });
            return err;
        }
    }
}
