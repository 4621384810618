import {lazy} from "react";

const SurveyList = lazy(() => import("./list/SurveyList"));
const SurveyCreator = lazy(() => import("./create/SurveyCreator"));
const SurveyReport = lazy(() => import("./detail/SurveyReport"));

const residentRoutes = [
    {
        path: "/surveys/:id/clone",
        component: SurveyCreator
    },
    {
        path: "/surveys/reports/:id",
        component: SurveyReport
    },
    {
        path: "/surveys/:id",
        component: SurveyCreator
    },
    {
        path: "/surveys",
        component: SurveyList
    }
];

export default residentRoutes;
