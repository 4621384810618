
// const ServiceList = lazy(() => import("./list/GroupGoodServiceList"));
// const GroupGoodServiceDetail = lazy(() => import("./detail/GroupGoodServiceDetail"));
// const GoodServiceDetail = lazy(() => import("./detail/GoodServiceDetail"));

const apartmentRoutes = [
    // {
    //     path: "/group-services/:id",
    //     component: GroupGoodServiceDetail
    // },
    // {
    //     path: "/group-services",
    //     component: ServiceList
    // },
    // {
    //     path: "/goods-service/:id",
    //     component: GoodServiceDetail
    // }
];

export default apartmentRoutes;
