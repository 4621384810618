import {
    APPROVE_ADS,
    APPROVE_ADS_FAILURE,
    APPROVE_ADS_SUCCESS,
    CREATE_ADS,
    CREATE_ADS_FAILURE,
    CREATE_ADS_SUCCESS,
    GET_ADS,
    GET_ADS_FAILURE,
    GET_ADS_LIST,
    GET_ADS_LIST_FAILURE,
    GET_ADS_LIST_SUCCESS,
    GET_ADS_SUCCESS,
    REJECT_ADS,
    REJECT_ADS_FAILURE,
    REJECT_ADS_SUCCESS
} from "../actions/adsActions";

const AdsReducer = function(state = {}, action) {
    switch (action.type) {
        case GET_ADS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_ADS_SUCCESS: {
            return {
                ...state,
                ads: action.payload,
                loading: false
            };
        }
        case GET_ADS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_ADS_LIST: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_ADS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                adsList: action.payload
            };
        }
        case GET_ADS_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case APPROVE_ADS: {
            return {
                ...state,
                loading: true
            };
        }
        case APPROVE_ADS_SUCCESS: {
            return {
                ...state,
                ads: {
                    ...state.ads,
                    state: 'ONLINE'
                },
                loading: false
            };
        }
        case APPROVE_ADS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        case REJECT_ADS: {
            return {
                ...state,
                loading: true
            };
        }
        case REJECT_ADS_SUCCESS: {
            return {
                ...state,
                ads: {
                    ...state.ads,
                    state: 'REJECTED'
                },
                loading: false
            };
        }
        case REJECT_ADS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        case CREATE_ADS: {
            return {
                ...state,
                loading: true
            };
        }
        case CREATE_ADS_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case CREATE_ADS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        default: {
            return state;
        }
    }
};

export default AdsReducer;
