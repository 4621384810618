import {
    CREATE_RESIDENT,
    CREATE_RESIDENT_FAILURE,
    CREATE_RESIDENT_SUCCESS,
    GET_OWNER_UNIT,
    GET_OWNER_UNIT_FAILURE,
    GET_OWNER_UNIT_SUCCESS,
    GET_RESIDENT,
    GET_RESIDENT_FAILURE,
    GET_RESIDENT_SUCCESS,
    GET_RESIDENT_UNIT,
    GET_RESIDENT_UNIT_FAILURE,
    GET_RESIDENT_UNIT_SUCCESS,
    GET_RESIDENT_UNITS,
    GET_RESIDENT_UNITS_FAILURE,
    GET_RESIDENT_UNITS_SUCCESS,
    GET_RESIDENTS,
    GET_RESIDENTS_FAILURE,
    GET_RESIDENTS_SUCCESS,
    GET_UNITS_OF_RESIDENT,
    GET_UNITS_OF_RESIDENT_FAILURE,
    GET_UNITS_OF_RESIDENT_SUCCESS,
    GET_RESIDENTS_BY_APARTMENT,
    GET_RESIDENTS_FAILURE_BY_APARTMENT,
    GET_RESIDENTS_SUCCESS_BY_APARTMENT,
    LOCK_RESIDENT,
    LOCK_RESIDENT_FAILURE,
    LOCK_RESIDENT_SUCCESS,
    UNLOCK_RESIDENT,
    UNLOCK_RESIDENT_FAILURE,
    UNLOCK_RESIDENT_SUCCESS,
    UPDATE_RESIDENT,
    UPDATE_RESIDENT_FAILURE,
    UPDATE_RESIDENT_SUCCESS,
    GET_VICEGERENTS,
    GET_VICEGERENTS_SUCCESS,
    GET_VICEGERENTS_FAILURE,
    GET_RESIDENT_HISTORY_LOGIN, GET_RESIDENT_HISTORY_LOGIN_SUCCESS, GET_RESIDENT_HISTORY_LOGIN_FAILURE
} from "../actions/residentActions";

const ResidentReducer = function(state = {error: false, loading: false}, action) {
    switch (action.type) {
        case GET_OWNER_UNIT:
            return {
                ...state,
                loading: true
            };
        case GET_OWNER_UNIT_SUCCESS:
            return {
                ...state,
                loading: false,
                ownerUnit: action.payload
            };
        case GET_OWNER_UNIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_RESIDENT:
            return {
                ...state,
                loading: true
            };
        case GET_RESIDENT_SUCCESS:
            return {
                ...state,
                loading: false,
                resident: action.payload
            };
        case GET_RESIDENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_RESIDENTS:
            return {
                ...state,
                loading: true
            };
        case GET_RESIDENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                residents: [...action.payload.data],
                totalPages: action.payload.totalPages
            };
        case GET_RESIDENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_RESIDENTS_BY_APARTMENT:
            return {
                ...state,
                loading: true
            };
        case GET_RESIDENTS_SUCCESS_BY_APARTMENT:
            return {
                ...state,
                loading: false,
                residents: [...action.payload],
            };
        case GET_RESIDENTS_FAILURE_BY_APARTMENT:
            return {
                ...state,
                loading: false,
                error: true
            };
        case LOCK_RESIDENT:
            return {
                ...state,
                loading: true
            };
        case LOCK_RESIDENT_SUCCESS:
            return {
                ...state,
                residents: state.residents.map((resident, ind) => resident.id === action.payload ?
                    {...resident, locked: true} : resident
                ),
                loading: false
            };
        case LOCK_RESIDENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case UNLOCK_RESIDENT:
            return {
                ...state,
                loading: true
            };
        case UNLOCK_RESIDENT_SUCCESS:
            return {
                ...state,
                residents: state.residents.map((resident, ind) => resident.id === action.payload ?
                    {...resident, locked: false} : resident
                ),
                loading: false
            };
        case UNLOCK_RESIDENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CREATE_RESIDENT: {
            return {
                ...state,
                loading: true
            };
        }
        case CREATE_RESIDENT_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case CREATE_RESIDENT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_RESIDENT_UNITS:
            return {
                ...state,
                loading: true
            };
        case GET_RESIDENT_UNITS_SUCCESS:
            return {
                ...state,
                loading: false,
                residentUnits: action.payload
            };
        case GET_RESIDENT_UNITS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_RESIDENT_UNIT:
            return {
                ...state,
                loading: true
            };
        case GET_RESIDENT_UNIT_SUCCESS:
            return {
                ...state,
                loading: false,
                residentUnits: action.payload
            };
        case GET_RESIDENT_UNIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };

        case UPDATE_RESIDENT:
            return {
                ...state,
                loading: true
            };
        case UPDATE_RESIDENT_SUCCESS:
            return {
                ...state,
                loading: false,
                resident: action.payload
            };
        case UPDATE_RESIDENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_UNITS_OF_RESIDENT:
            return {
                ...state,
                loading: true
            };
        case GET_UNITS_OF_RESIDENT_SUCCESS:
            console.log(action.payload)
            return {
                ...state,
                loading: false,
                unitsOfResident: action.payload.data
            };
        case GET_UNITS_OF_RESIDENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_VICEGERENTS:
            return {
                ...state,
                loading: true
            };
        case GET_VICEGERENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                vicegerents: action.payload
            };
        case GET_VICEGERENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_RESIDENT_HISTORY_LOGIN:
            return {
                ...state,
                loading: true
            };
        case GET_RESIDENT_HISTORY_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                residentHistoryLogins: action.payload
            };
        case GET_RESIDENT_HISTORY_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state
    }
};

export default ResidentReducer;