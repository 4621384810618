import instance from "./axiosInstance";

class SurveyService {

    getSurveys = () => {
        return instance
            .get("/rs/survey/staff", {})
            .then(({data}) => {
                return data;
            });
    };

    createSurveys = (title, isSignatureRequire, questionGroups, residentIds) => {
        return instance
            .post(  "/rs/survey", {
                title: title,
                isSignatureRequire: isSignatureRequire,
                questionGroups: questionGroups,
                residentIds: residentIds
            })
            .then(({data}) => {
                return data;
            });
    }

    getSurveyReport = (surveyId) => {
        return instance
            .get("/rs/survey/report/" + surveyId, {})
            .then(({data}) => {
                return data;
            });
    }

    getSurvey = (surveyId) => {
        return instance
            .get("/rs/survey/" + surveyId, {})
            .then(({data}) => {
                return data;
            });
    }


}

export default new SurveyService();
