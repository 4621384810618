import {
    CREATE_EXTCONTRACT,
    CREATE_EXTCONTRACT_FAILURE,
    CREATE_EXTCONTRACT_SUCCESS,
    GET_EXTCONTRACT,
    GET_EXTCONTRACT_FAILURE,
    GET_EXTCONTRACT_SUCCESS,
    GET_EXTCONTRACTS,
    GET_EXTCONTRACTS_FAILURE,
    GET_EXTCONTRACTS_SUCCESS,
    UPDATE_EXTCONTRACT,
    UPDATE_EXTCONTRACT_FAILURE,
    UPDATE_EXTCONTRACT_SUCCESS
} from "../actions/externalContractActions";

const ExternalContractReducer = function(state = {}, action) {
    switch (action.type) {
        case GET_EXTCONTRACTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_EXTCONTRACTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                extContracts: action.payload
            };
        }
        case GET_EXTCONTRACTS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_EXTCONTRACT: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_EXTCONTRACT_SUCCESS: {
            return {
                ...state,
                loading: false,
                extContracts: action.payload
            };
        }
        case GET_EXTCONTRACT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        case UPDATE_EXTCONTRACT: {
            return {
                ...state,
                error: true,
                loading: true
            };
        }
        case UPDATE_EXTCONTRACT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                extContracts: action.payload
            };
        }
        case UPDATE_EXTCONTRACT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case CREATE_EXTCONTRACT: {
            return {
                ...state,
                loading: true,
                error: true
            };
        }
        case CREATE_EXTCONTRACT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                extContracts: action.payload
            };
        }
        case CREATE_EXTCONTRACT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        default: {
            return state;
        }
    }
};

export default ExternalContractReducer;
