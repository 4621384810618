import {lazy} from "react";

const UserProfile = lazy(() => import("./profile/UserProfile"));
const EmployeeDetail = lazy(() => import("../employees/detail/EmployeeDetail"));


const employeeRoutes = [
  {
    path: "/employees/profile",
    component: UserProfile
  },
  {
    path: "/employees/:id",
    component: EmployeeDetail
  },
  // {
  //   path: "/employees",
  //   component: EmployeeList
  // }
];

export default employeeRoutes;
