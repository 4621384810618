import { PlusOutlined } from '@ant-design/icons'
import { lazy } from 'react'

const meterModule = {
  pages: {
    ImportMeter: {
      path: '/meters/import',
      component: lazy(() => import('../../monthly-reports/import-meters/ImportMeter'))
    },
    ElectricMeters: {
      label: 'nav.electrics',
      path: '/e/electric-meters',
      component: lazy(() => import('./pages/MeterListing')),
      data: { type: 'ELECTRIC' },
      actions: eventEmitter => ({
        actions: [
          {
            key: 'create',
            label: 'meters.button.create',
            icon: <PlusOutlined />,
            style: 'primary',
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}]),
          }
        ]
      })
    },
    ElectricReport: {
      label: 'nav.electrics-reports',
      path: '/e/monthly-reports/:type',
      tabLink: '/e/monthly-reports/ELECTRIC',
      component: lazy(() => import('../../monthly-reports/monthly-report/list/MonthlyReportList')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'create',
            label: 'meters.button.write',
            icon: <PlusOutlined />,
            style: 'primary',
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}]),
          }
        ]
      })
    },
    WaterMeters: {
      label: 'nav.waters',
      path: '/w/water-meters',
      component: lazy(() => import('./pages/MeterListing')),
      data: { type: 'WATER' },
      actions: eventEmitter => ({
        actions: [
          {
            key: 'create',
            label: 'meters.button.create',
            icon: <PlusOutlined />,
            style: 'primary',
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}]),
          }
        ]
      })
    },
    WaterReport: {
      label: 'nav.waters-reports',
      path: '/w/monthly-reports/:type',
      tabLink: '/w/monthly-reports/WATER',
      component: lazy(() => import('../../monthly-reports/monthly-report/list/MonthlyReportList')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'create',
            label: 'meters.button.write',
            icon: <PlusOutlined />,
            style: 'primary',
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}]),
          }
        ]
      })
    }
  }
}

export default meterModule
