import apartmentService from "../../services/apartmentService";
import {error, success} from "./alertActions";
import {DEFAULT_PARAMS} from "../../constants/Constants";
import {getApartmentId} from "../../@utils";
import moment from "moment";

export const GET_APARTMENTS = 'GET_APARTMENTS';
export const GET_APARTMENTS_SUCCESS = 'GET_APARTMENTS_SUCCESS';
export const GET_APARTMENTS_FAILURE = 'GET_APARTMENTS_FAILURE';

export const IMPORT_METER = 'IMPORT_METER';
export const IMPORT_METER_SUCCESS = 'IMPORT_METER_SUCCESS';
export const IMPORT_METER_FAILURE = 'IMPORT_METER_FAILURE';

export const GET_APARTMENT = 'GET_APARTMENT';
export const GET_APARTMENT_SUCCESS = 'GET_APARTMENT_SUCCESS';
export const GET_APARTMENT_FAILURE = 'GET_APARTMENT_FAILURE';

export const GET_BLOCKS = 'GET_BLOCKS';
export const GET_BLOCKS_SUCCESS = 'GET_BLOCKS_SUCCESS';
export const GET_BLOCKS_FAILURE = 'GET_BLOCKS_FAILURE';

export const GET_FLOORS = 'GET_FLOORS';
export const GET_FLOORS_SUCCESS = 'GET_FLOORS_SUCCESS';
export const GET_FLOORS_FAILURE = 'GET_FLOORS_FAILURE';

export const EDIT_APARTMENT = 'EDIT_APARTMENT'
export const EDIT_APARTMENT_SUCCESS = 'EDIT_APARTMENT_SUCCESS'
export const EDIT_APARTMENT_FAILURE = 'EDIT_APARTMENT_FAILURE'

export const DELETE_APARTMENT = 'DELETE_APARTMENT'
export const DELETE_APARTMENT_SUCCESS = 'DELETE_APARTMENT_SUCCESS'
export const DELETE_APARTMENT_FAILURE = 'DELETE_APARTMENT_FAILURE'

export const UPLOAD_PHOTO_APARTMENT = 'UPLOAD_PHOTO_APARTMENT'
export const UPLOAD_PHOTO_APARTMENT_SUCCESS = 'UPLOAD_PHOTO_APARTMENT_SUCCESS'
export const UPLOAD_PHOTO_APARTMENT_FAILURE = 'UPLOAD_PHOTO_APARTMENT_FAILURE'

export const GET_UNIT = 'GET_UNIT';
export const GET_UNIT_SUCCESS = 'GET_UNIT_SUCCESS';
export const GET_UNIT_FAILURE = 'GET_UNIT_FAILURE';

export const EDIT_UNIT = 'EDIT_UNIT'
export const EDIT_UNIT_SUCCESS = 'EDIT_UNIT_SUCCESS'
export const EDIT_UNIT_FAILURE = 'EDIT_UNIT_FAILURE'

export const CREATE_UNIT = 'CREATE_UNIT'
export const CREATE_UNIT_SUCCESS = 'CREATE_UNIT_SUCCESS'
export const CREATE_UNIT_FAILURE = 'CREATE_UNIT_FAILURE'

export const GET_UNITS = 'GET_UNITS';
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
export const GET_UNITS_FAILURE = 'GET_UNITS_FAILURE';

export const GET_CHECKLIST_TEMPLATES = 'GET_CHECKLIST_TEMPLATES';
export const GET_CHECKLIST_TEMPLATES_SUCCESS = 'GET_CHECKLIST_TEMPLATES_SUCCESS';
export const GET_CHECKLIST_TEMPLATES_FAILURE = 'GET_CHECKLIST_TEMPLATES_FAILURE';

export const GET_INSPECTION_CHECKLISTS = 'GET_INSPECTION_CHECKLISTS';
export const GET_INSPECTION_CHECKLISTS_SUCCESS = 'GET_INSPECTION_CHECKLISTS_SUCCESS';
export const GET_INSPECTION_CHECKLISTS_FAILURE = 'GET_INSPECTION_CHECKLISTS_FAILURE';

export const GET_INSPECTION_CHECKLIST = 'GET_INSPECTION_CHECKLIST';
export const GET_INSPECTION_CHECKLIST_SUCCESS = 'GET_INSPECTION_CHECKLIST_SUCCESS';
export const GET_INSPECTION_CHECKLIST_FAILURE = 'GET_INSPECTION_CHECKLIST_FAILURE';

export const CREATE_CHECKLIST = 'CREATE_CHECKLIST';
export const CREATE_CHECKLIST_SUCCESS = 'CREATE_CHECKLIST_SUCCESS';
export const CREATE_CHECKLIST_FAILURE = 'CREATE_CHECKLIST_FAILURE';

export const VERIFY_CHECKLIST = 'VERIFY_CHECKLIST';
export const VERIFY_CHECKLIST_SUCCESS = 'VERIFY_CHECKLIST_SUCCESS';
export const VERIFY_CHECKLIST_FAILURE = 'VERIFY_CHECKLIST_FAILURE';

export const CREATE_CHECKLIST_TEMPLATE = 'CREATE_INSPECTION_CHECKLIST_TEMPLATE';
export const CREATE_CHECKLIST_TEMPLATE_SUCCESS = 'CREATE_CHECKLIST_TEMPLATE_SUCCESS';
export const CREATE_CHECKLIST_TEMPLATE_FAILURE = 'CREATE_CHECKLIST_TEMPLATE_FAILURE';

export const UPDATE_CHECKLIST_TEMPLATE = 'UPDATE_CHECKLIST_TEMPLATE';
export const UPDATE_CHECKLIST_TEMPLATE_SUCCESS = 'UPDATE_CHECKLIST_TEMPLATE_SUCCESS';
export const UPDATE_CHECKLIST_TEMPLATE_FAILURE = 'UPDATE_CHECKLIST_TEMPLATE_FAILURE';

export const GET_CHECKLIST_TEMPLATE = 'GET_CHECKLIST_TEMPLATE';
export const GET_CHECKLIST_TEMPLATE_SUCCESS = 'GET_CHECKLIST_TEMPLATE_SUCCESS';
export const GET_CHECKLIST_TEMPLATE_FAILURE = 'GET_CHECKLIST_TEMPLATE_FAILURE';

export const DELETE_CHECKLIST_TEMPLATE = 'DELETE_CHECKLIST_TEMPLATE';
export const DELETE_CHECKLIST_TEMPLATE_SUCCESS = 'DELETE_CHECKLIST_TEMPLATE_SUCCESS';
export const DELETE_CHECKLIST_TEMPLATE_FAILURE = 'DELETE_CHECKLIST_TEMPLATE_FAILURE';

export const GET_FLOORS_BY_APARTMENT = 'GET_FLOORS_BY_APARTMENT';
export const GET_FLOORS_BY_APARTMENT_SUCCESS = 'GET_FLOORS_BY_APARTMENT_SUCCESS';
export const GET_FLOORS_BY_APARTMENT_FAILURE = 'GET_FLOORS_BY_APARTMENT_FAILURE';

export const GET_ASSETS = 'GET_ASSETS';
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_FAILURE = 'GET_ASSETS_FAILURE';

export const CREATE_ASSET = 'CREATE_ASSET';
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_FAILURE = 'CREATE_ASSET_FAILURE';

export const UPDATE_ASSET = 'UPDATE_ASSET';
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS';
export const UPDATE_ASSET_FAILURE = 'UPDATE_ASSET_FAILURE';

export const DELETE_ASSET = 'DELETE_ASSET';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_FAILURE = 'DELETE_ASSET_FAILURE';

export const GET_ASSET = 'GET_ASSET';
export const GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS';
export const GET_ASSET_FAILURE = 'GET_ASSET_FAILURE';

export const UPDATE_ASSET_LOCATION = 'UPDATE_ASSET_LOCATION';
export const UPDATE_ASSET_LOCATION_SUCCESS = 'UPDATE_ASSET_LOCATION_SUCCESS';
export const UPDATE_ASSET_LOCATION_FAILURE = 'UPDATE_ASSET_LOCATION_FAILURE';

export const DELETE_UNIT = 'DELETE_UNIT'
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS'
export const DELETE_UNIT_FAILURE = 'DELETE_UNIT_FAILURE'

export const GET_WAREHOUSES = 'GET_WAREHOUSES'
export const GET_WAREHOUSES_SUCCESS = 'GET_WAREHOUSES_SUCCESS'
export const GET_WAREHOUSES_FAILURE = 'GET_WAREHOUSES_FAILURE'

export const GET_WAREHOUSE = 'GET_WAREHOUSE'
export const GET_WAREHOUSE_SUCCESS = 'GET_WAREHOUSE_SUCCESS'
export const GET_WAREHOUSE_FAILURE = 'GET_WAREHOUSE_FAILURE'

export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE'
export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS'
export const CREATE_WAREHOUSE_FAILURE = 'CREATE_WAREHOUSE_FAILURE'

export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE'
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS'
export const UPDATE_WAREHOUSE_FAILURE = 'UPDATE_WAREHOUSE_FAILURE'

export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE'
export const DELETE_WAREHOUSE_SUCCESS = 'DELETE_WAREHOUSE_SUCCESS'
export const DELETE_WAREHOUSE_FAILURE = 'DELETE_WAREHOUSE_FAILURE'

export const GET_CONFIG_GOODS = 'GET_CONFIG_GOODS'
export const GET_CONFIG_GOODS_SUCCESS = 'GET_CONFIG_GOODS_SUCCESS'
export const GET_CONFIG_GOODS_FAILURE = 'GET_CONFIG_GOODS_FAILURE'

export const CREATE_CONFIG_GOOD = 'CREATE_CONFIG_GOOD'
export const CREATE_CONFIG_GOOD_SUCCESS = 'CREATE_CONFIG_GOOD_SUCCESS'
export const CREATE_CONFIG_GOOD_FAILURE = 'CREATE_CONFIG_GOOD_FAILURE'

export const UPDATE_CONFIG_GOOD = 'UPDATE_CONFIG_GOOD'
export const UPDATE_CONFIG_GOOD_SUCCESS = 'UPDATE_CONFIG_GOOD_SUCCESS'
export const UPDATE_CONFIG_GOOD_FAILURE = 'UPDATE_CONFIG_GOOD_FAILURE'

export const DELETE_CONFIG_GOOD = 'DELETE_CONFIG_GOOD'
export const DELETE_CONFIG_GOOD_SUCCESS = 'DELETE_CONFIG_GOOD_SUCCESS'
export const DELETE_CONFIG_GOOD_FAILURE = 'DELETE_CONFIG_GOOD_FAILURE'

export const GET_GOODS_RECEIPTS = 'GET_GOODS_RECEIPTS'
export const GET_GOODS_RECEIPTS_SUCCESS = 'GET_GOODS_RECEIPTS_SUCCESS'
export const GET_GOODS_RECEIPTS_FAILURE = 'GET_GOODS_RECEIPTS_FAILURE'

export const GET_GOODS_RECEIPT = 'GET_GOODS_RECEIPT'
export const GET_GOODS_RECEIPT_SUCCESS = 'GET_GOODS_RECEIPT_SUCCESS'
export const GET_GOODS_RECEIPT_FAILURE = 'GET_GOODS_RECEIPT_FAILURE'

export const CREATE_GOODS_RECEIPT = 'CREATE_GOODS_RECEIPT'
export const CREATE_GOODS_RECEIPT_SUCCESS = 'CREATE_GOODS_RECEIPT_SUCCESS'
export const CREATE_GOODS_RECEIPT_FAILURE = 'CREATE_GOODS_RECEIPT_FAILURE'

export const UPDATE_GOODS_RECEIPT = 'UPDATE_GOODS_RECEIPT'
export const UPDATE_GOODS_RECEIPT_SUCCESS = 'UPDATE_GOODS_RECEIPT_SUCCESS'
export const UPDATE_GOODS_RECEIPT_FAILURE = 'UPDATE_GOODS_RECEIPT_FAILURE'

export const APPROVE_GOODS_RECEIPT = 'APPROVE_GOODS_RECEIPT'
export const APPROVE_GOODS_RECEIPT_SUCCESS = 'APPROVE_GOODS_RECEIPT_SUCCESS'
export const APPROVE_GOODS_RECEIPT_FAILURE = 'APPROVE_GOODS_RECEIPT_FAILURE'

export const REJECT_GOODS_RECEIPT = 'REJECT_GOODS_RECEIPT'
export const REJECT_GOODS_RECEIPT_SUCCESS = 'REJECT_GOODS_RECEIPT_SUCCESS'
export const REJECT_GOODS_RECEIPT_FAILURE = 'REJECT_GOODS_RECEIPT_FAILURE'

export const REJECT_GOODS_EXPORT = 'REJECT_GOODS_EXPORT'
export const REJECT_GOODS_EXPORT_SUCCESS = 'REJECT_GOODS_EXPORT_SUCCESS'
export const REJECT_GOODS_EXPORT_FAILURE = 'REJECT_GOODS_EXPORT_FAILURE'

export const GET_GOODS_EXPORTS = 'GET_GOODS_EXPORTS'
export const GET_GOODS_EXPORTS_SUCCESS = 'GET_GOODS_EXPORTS_SUCCESS'
export const GET_GOODS_EXPORTS_FAILURE = 'GET_GOODS_EXPORTS_FAILURE'

export const GET_GOODS_EXPORT = 'GET_GOODS_EXPORT'
export const GET_GOODS_EXPORT_SUCCESS = 'GET_GOODS_EXPORT_SUCCESS'
export const GET_GOODS_EXPORT_FAILURE = 'GET_GOODS_EXPORT_FAILURE'

export const CREATE_GOODS_EXPORT = 'CREATE_GOODS_EXPORT'
export const CREATE_GOODS_EXPORT_SUCCESS = 'CREATE_GOODS_EXPORT_SUCCESS'
export const CREATE_GOODS_EXPORT_FAILURE = 'CREATE_GOODS_EXPORT_FAILURE'

export const UPDATE_GOODS_EXPORT = 'UPDATE_GOODS_EXPORT'
export const UPDATE_GOODS_EXPORT_SUCCESS = 'UPDATE_GOODS_EXPORT_SUCCESS'
export const UPDATE_GOODS_EXPORT_FAILURE = 'UPDATE_GOODS_EXPORT_FAILURE'

export const APPROVE_GOODS_EXPORT = 'APPROVE_GOODS_EXPORT'
export const APPROVE_GOODS_EXPORT_SUCCESS = 'APPROVE_GOODS_EXPORT_SUCCESS'
export const APPROVE_GOODS_EXPORT_FAILURE = 'APPROVE_GOODS_EXPORT_FAILURE'

export const GET_WAREHOUSE_ITEMS = 'GET_WAREHOUSE_ITEMS'
export const GET_WAREHOUSE_ITEMS_SUCCESS = 'GET_WAREHOUSE_ITEMS_SUCCESS'
export const GET_WAREHOUSE_ITEMS_FAILURE = 'GET_WAREHOUSE_ITEMS_FAILURE'

export const GET_WAREHOUSE_ITEM = 'GET_WAREHOUSE_ITEM'
export const GET_WAREHOUSE_ITEM_SUCCESS = 'GET_WAREHOUSE_ITEM_SUCCESS'
export const GET_WAREHOUSE_ITEM_FAILURE = 'GET_WAREHOUSE_ITEM_FAILURE'

export const GET_ELECTRIC_METERS = 'GET_ELECTRIC_METERS'
export const GET_ELECTRIC_METERS_SUCCESS = 'GET_ELECTRIC_METERS_SUCCESS'
export const GET_ELECTRIC_METERS_FAILURE = 'GET_ELECTRIC_METERS_FAILURE'

export const GET_ELECTRIC_METER = 'GET_ELECTRIC_METER'
export const GET_ELECTRIC_METER_SUCCESS = 'GET_ELECTRIC_METER_SUCCESS'
export const GET_ELECTRIC_METER_FAILURE = 'GET_ELECTRIC_METER_FAILURE'


export const CREATE_ELECTRIC_METER = 'CREATE_ELECTRIC_METER'
export const CREATE_ELECTRIC_METER_SUCCESS = 'CREATE_ELECTRIC_METER_SUCCESS'
export const CREATE_ELECTRIC_METER_FAILURE = 'CREATE_ELECTRIC_METER_FAILURE'

export const UPDATE_ELECTRIC_METER = 'UPDATE_ELECTRIC_METER'
export const UPDATE_ELECTRIC_METER_SUCCESS = 'UPDATE_ELECTRIC_METER_SUCCESS'
export const UPDATE_ELECTRIC_METER_FAILURE = 'UPDATE_ELECTRIC_METER_FAILURE'

export const GET_ELECTRIC_METER_MONTHLY_REPORTS = 'GET_ELECTRIC_METER_MONTHLY_REPORTS'
export const GET_ELECTRIC_METER_MONTHLY_REPORTS_SUCCESS = 'GET_ELECTRIC_METER_MONTHLY_REPORTS_SUCCESS'
export const GET_ELECTRIC_METER_MONTHLY_REPORTS_FAILURE = 'GET_ELECTRIC_METER_MONTHLY_REPORTS_FAILURE'

export const GET_ELECTRIC_METER_MONTHLY_REPORT = 'GET_ELECTRIC_METER_MONTHLY_REPORT'
export const GET_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS = 'GET_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS'
export const GET_ELECTRIC_METER_MONTHLY_REPORT_FAILURE = 'GET_ELECTRIC_METER_MONTHLY_REPORT_FAILURE'


export const CREATE_ELECTRIC_METER_MONTHLY_REPORT = 'CREATE_ELECTRIC_METER_MONTHLY_REPORT'
export const CREATE_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS = 'CREATE_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS'
export const CREATE_ELECTRIC_METER_MONTHLY_REPORT_FAILURE = 'CREATE_ELECTRIC_METER_MONTHLY_REPORT_FAILURE'

export const UPDATE_ELECTRIC_METER_MONTHLY_REPORT = 'UPDATE_ELECTRIC_METER_MONTHLY_REPORT'
export const UPDATE_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS = 'UPDATE_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS'
export const UPDATE_ELECTRIC_METER_MONTHLY_REPORT_FAILURE = 'UPDATE_ELECTRIC_METER_MONTHLY_REPORT_FAILURE'

export const DELETE_ELECTRIC_METER_MONTHLY_REPORT = 'DELETE_ELECTRIC_METER_MONTHLY_REPORT'
export const DELETE_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS = 'DELETE_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS'
export const DELETE_ELECTRIC_METER_MONTHLY_REPORT_FAILURE = 'DELETE_ELECTRIC_METER_MONTHLY_REPORT_FAILURE'

export const GET_CONFIG_ELECTRIC_METERS = 'GET_CONFIG_ELECTRIC_METERS'
export const GET_CONFIG_ELECTRIC_METERS_SUCCESS = 'GET_CONFIG_ELECTRIC_METERS_SUCCESS'
export const GET_CONFIG_ELECTRIC_METERS_FAILURE = 'GET_CONFIG_ELECTRIC_METERS_FAILURE'

export const GET_CONFIG_ELECTRIC_METER = 'GET_CONFIG_ELECTRIC_METER'
export const GET_CONFIG_ELECTRIC_METER_SUCCESS = 'GET_CONFIG_ELECTRIC_METER_SUCCESS'
export const GET_CONFIG_ELECTRIC_METER_FAILURE = 'GET_CONFIG_ELECTRIC_METER_FAILURE'

export const CREATE_CONFIG_ELECTRIC_METER = 'CREATE_CONFIG_ELECTRIC_METER'
export const CREATE_CONFIG_ELECTRIC_METER_SUCCESS = 'CREATE_CONFIG_ELECTRIC_METER_SUCCESS'
export const CREATE_CONFIG_ELECTRIC_METER_FAILURE = 'CREATE_CONFIG_ELECTRIC_METER_FAILURE'

export const UPDATE_CONFIG_ELECTRIC_METER = 'UPDATE_CONFIG_ELECTRIC_METER'
export const UPDATE_CONFIG_ELECTRIC_METER_SUCCESS = 'UPDATE_CONFIG_ELECTRIC_METER_SUCCESS'
export const UPDATE_CONFIG_ELECTRIC_METER_FAILURE = 'UPDATE_CONFIG_ELECTRIC_METER_FAILURE'

export const DELETE_CONFIG_ELECTRIC_METER = 'DELETE_CONFIG_ELECTRIC_METER'
export const DELETE_CONFIG_ELECTRIC_METER_SUCCESS = 'DELETE_CONFIG_ELECTRIC_METER_SUCCESS'
export const DELETE_CONFIG_ELECTRIC_METER_FAILURE = 'DELETE_CONFIG_ELECTRIC_METER_FAILURE'

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

export const GET_CONFIGS_GOODS = 'GET_CONFIGS_GOODS'
export const GET_CONFIGS_GOODS_SUCCESS = 'GET_CONFIGS_GOODS_SUCCESS'
export const GET_CONFIGS_GOODS_FAILURE ='GET_CONFIGS_GOODS_FAILURE'

export const GET_EXPENDITURES = 'GET_EXPENDITURES'
export const GET_EXPENDITURES_SUCCESS = 'GET_EXPENDITURES_SUCCESS'
export const GET_EXPENDITURES_FAILURE = 'GET_EXPENDITURES_FAILURE'

export const GET_EXPENDITURE = 'GET_EXPENDITURE'
export const GET_EXPENDITURE_SUCCESS = 'GET_EXPENDITURE_SUCCESS'
export const GET_EXPENDITURE_FAILURE = 'GET_EXPENDITURE_FAILURE'

export const CREATE_EXPENDITURE = 'CREATE_EXPENDITURE'
export const CREATE_EXPENDITURE_SUCCESS = 'CREATE_EXPENDITURE_SUCCESS'
export const CREATE_EXPENDITURE_FAILURE = 'CREATE_EXPENDITURE_FAILURE'

export const UPDATE_EXPENDITURE = 'UPDATE_EXPENDITURE'
export const UPDATE_EXPENDITURE_SUCCESS = 'UPDATE_EXPENDITURE_SUCCESS'
export const UPDATE_EXPENDITURE_FAILURE = 'UPDATE_EXPENDITURE_FAILURE'

export const GET_ELECTRIC_METERS_BY_UNIT = 'GET_ELECTRIC_METERS_BY_UNIT'
export const GET_ELECTRIC_METERS_BY_UNIT_SUCCESS = 'GET_ELECTRIC_METERS_BY_UNIT_SUCCESS'
export const GET_ELECTRIC_METERS_BY_UNIT_FAILURE = 'GET_ELECTRIC_METERS_BY_UNIT_FAILURE'

export const CREATE_ADJ_CONFIG_UNIT = 'CREATE_ADJ_CONFIG_UNIT'
export const CREATE_ADJ_CONFIG_UNIT_SUCCESS = 'CREATE_ADJ_CONFIG_UNIT_SUCCESS'
export const CREATE_ADJ_CONFIG_UNIT_FAILURE = 'CREATE_ADJ_CONFIG_UNIT_FAILURE'

export const UPDATE_ADJ_CONFIG_UNIT = 'UPDATE_ADJ_CONFIG_UNIT'
export const UPDATE_ADJ_CONFIG_UNIT_SUCCESS = 'UPDATE_ADJ_CONFIG_UNIT_SUCCESS'
export const UPDATE_ADJ_CONFIG_UNIT_FAILURE = 'UPDATE_ADJ_CONFIG_UNIT_FAILURE'

export const DELETE_ADJ_CONFIG_UNIT = 'DELETE_ADJ_CONFIG_UNIT'
export const DELETE_ADJ_CONFIG_UNIT_SUCCESS = 'DELETE_ADJ_CONFIG_UNIT_SUCCESS'
export const DELETE_ADJ_CONFIG_UNIT_FAILURE = 'DELETE_ADJ_CONFIG_UNIT_FAILURE'

export function getApartments(page, size) {
    return dispatch => {
        dispatch({
            type: GET_APARTMENTS
        });
        apartmentService
            .getApartments(page, size)
            .then(data => {
                return dispatch({
                    type: GET_APARTMENTS_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                return dispatch({
                    type: GET_APARTMENTS_FAILURE
                });
            });
    };
}

export function getUnitsByCode(apartmentId, keyword) {
    return async dispatch => {
        try {
            const data = await apartmentService.getUnitsByCode(apartmentId, keyword)
            return data;
        } catch (err) {
            return err;
        }
    }
}

export function getUnitsByApartment(apartmentId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_UNITS
            });
            const data = await apartmentService.getUnitsByApartment(apartmentId)
            dispatch({
                type: GET_UNITS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_UNITS_FAILURE
            });
            return err;
        }
    }
}

export function getApartmentDetail(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_APARTMENT
            });
            const data = await apartmentService.getApartmentDetail(apartmentId, params)
            dispatch({
                type: GET_APARTMENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_APARTMENT_FAILURE
            });
            return err;
        }
    }
}

export function editApartment(apartmentId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: EDIT_APARTMENT
            });
            const data = await apartmentService.editApartment(apartmentId, values)
            dispatch(getApartmentDetail(apartmentId));
            dispatch(success("", "message.updated"))
            dispatch({
                type: EDIT_APARTMENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: EDIT_APARTMENT_FAILURE
            });
            return err;
        }
    }
}

export function createUnit(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_UNIT
            });
            const data = await apartmentService.createUnit(values)
            dispatch(getApartmentDetail(values.apartmentId));
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_UNIT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_UNIT_FAILURE
            });
            return err;
        }
    }
}

export function uploadPhotoApartment(apartmentId, file) {
    return dispatch => {
        dispatch({
            type: UPLOAD_PHOTO_APARTMENT
        });
        apartmentService
            .uploadPhotoApartment(apartmentId, file)
            .then(data => {
                dispatch(success("", "message.updated"))
                return dispatch({
                    type: UPLOAD_PHOTO_APARTMENT_SUCCESS,
                    payload: data
                });
            })
            .catch(err => {
                dispatch(error("", err.response.data.message))
                return dispatch({
                    type: UPLOAD_PHOTO_APARTMENT_FAILURE
                });

            });
    };
}

export function getBlocksByApartment(apartmentId) {
    return async dispatch => {
        dispatch({
            type: GET_BLOCKS
        });
        try {
            const data = await apartmentService.getBlocksByApartment(apartmentId)
            dispatch({
                type: GET_BLOCKS_SUCCESS,
                payload: data
            });
            return data
        } catch (err) {
            dispatch({
                type: GET_BLOCKS_FAILURE
            });
        }
    }
}

export function getFloorsByBlock(apartmentId, blockId) {
    return async dispatch => {
        dispatch({
            type: GET_FLOORS
        });
        try {
            const data = await apartmentService.getFloorsByBlock(apartmentId, blockId)
            dispatch({
                type: GET_FLOORS_SUCCESS,
                payload: data
            });
            return data
        } catch (err) {
            dispatch({
                type: GET_FLOORS_FAILURE
            });
        }
    }
}

export function getUnitDetail(unitId) {
    return dispatch => {
        dispatch({
            type: GET_UNIT
        });
        apartmentService
            .getUnitDetail(unitId)
            .then(data => {
                return dispatch({
                    type: GET_UNIT_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                return dispatch({
                    type: GET_UNIT_FAILURE
                });
            });
    };
}

export function editUnit(unitId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: EDIT_UNIT
            });
            const data = await apartmentService.editUnit(unitId, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: EDIT_UNIT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: EDIT_UNIT_FAILURE
            });
            return err;
        }
    }
}

export function getChecklistTemplates(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_CHECKLIST_TEMPLATES
            });
            const data = await apartmentService.getChecklistTemplates(apartmentId, params)
            dispatch({
                type: GET_CHECKLIST_TEMPLATES_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_CHECKLIST_TEMPLATES_FAILURE
            });
        }
    }
}

export function deleteChecklistTemplate(id) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_CHECKLIST_TEMPLATE
            });
            const data = await apartmentService.deleteChecklistTemplate(id)
            dispatch(success("", "message.deleted"))
            dispatch({
                type: DELETE_CHECKLIST_TEMPLATE_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: DELETE_CHECKLIST_TEMPLATE_FAILURE
            });
        }
    }
}


export function getChecklists(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_INSPECTION_CHECKLISTS
            });
            const data = await apartmentService.getChecklists(apartmentId, params)
            dispatch({
                type: GET_INSPECTION_CHECKLISTS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_INSPECTION_CHECKLISTS_FAILURE
            });
        }
    }
}

export function getChecklist(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_INSPECTION_CHECKLIST
            });
            const data = await apartmentService.getChecklist(id)
            dispatch({
                type: GET_INSPECTION_CHECKLIST_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_INSPECTION_CHECKLIST_FAILURE
            });
        }
    }
}

export function createChecklistTemplate(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_CHECKLIST_TEMPLATE
            });
            const data = await apartmentService.createChecklistTemplate(values)
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_CHECKLIST_TEMPLATE_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_CHECKLIST_TEMPLATE_FAILURE
            });
        }
    }
}

export function getChecklistTemplate(checklistTemplateId) {
    console.log("checklistTemplateId"+checklistTemplateId)
    return async dispatch => {
        try {
            dispatch({
                type: GET_CHECKLIST_TEMPLATE
            });
            const data = await apartmentService.getChecklistTemplate(checklistTemplateId)
            dispatch({
                type: GET_CHECKLIST_TEMPLATE_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            console.log("checklistTemplateId"+err)
            dispatch({
                type: GET_CHECKLIST_TEMPLATE_FAILURE
            });
        }
    }
}


export function updateChecklistTemplate(templateId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_CHECKLIST_TEMPLATE
            });
            const data = await apartmentService.updateChecklistTemplate(templateId, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_CHECKLIST_TEMPLATE_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_CHECKLIST_TEMPLATE_FAILURE
            });
        }
    }
}




export function getFloorsByApartmentId(apartmentId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_FLOORS_BY_APARTMENT
            });
            const data = await apartmentService.getFloorsByApartmentId(apartmentId)
            dispatch({
                type: GET_FLOORS_BY_APARTMENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_FLOORS_BY_APARTMENT_FAILURE
            });
        }
    }
}

export function getAssets(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_ASSETS
            });
            const data = await apartmentService.getAssets(apartmentId, params)
            dispatch({
                type: GET_ASSETS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_ASSETS_FAILURE
            });
        }
    }
}

export function getAssetById(assetId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_ASSET
            });
            const data = await apartmentService.getAssetById(assetId)
            dispatch({
                type: GET_ASSET_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_ASSET_FAILURE
            });
        }
    }
}

export function createAssetById(assetId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_ASSET
            });
            const data = await apartmentService.getAssetById(assetId)
            dispatch({
                type: GET_ASSET_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_ASSET_FAILURE
            });
        }
    }
}

export function createAsset(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_ASSET
            });
            const data = await apartmentService.createAsset(values)
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_ASSET_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_ASSET_FAILURE
            });
        }
    }
}

export function updateAsset(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_ASSET
            });
            const data = await apartmentService.updateAsset(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_ASSET_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_ASSET_FAILURE
            });
        }
    }
}

export function deleteAsset(id) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_ASSET
            });
            const data = await apartmentService.deleteAsset(id)
            dispatch(success("", "message.deleted"))
            dispatch({
                type: DELETE_ASSET_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: DELETE_ASSET_FAILURE
            });
        }
    }
}

export function updateLocation(assetId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_ASSET_LOCATION
            });
            const data = await apartmentService.updateLocation(assetId, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_ASSET_LOCATION_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_ASSET_LOCATION_FAILURE
            });
        }
    }
}

export function deleteUnit(unitId) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_UNIT
            });
            await apartmentService.deleteUnit(unitId)
            dispatch(success("", "message.updated"))
            dispatch({
                type: DELETE_UNIT_SUCCESS
            });
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: DELETE_UNIT_FAILURE
            });
        }
    }
}

export function getWarehouses(apartmentId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_WAREHOUSES
            });
            const data = await apartmentService.getWarehouses(apartmentId)
            dispatch({
                type: GET_WAREHOUSES_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: GET_WAREHOUSES_FAILURE
            });
        }
    }
}

export function getWarehouse(warehouseId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_WAREHOUSE
            });
            const data = await apartmentService.getWarehouse(warehouseId)
            dispatch({
                type: GET_WAREHOUSE_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_WAREHOUSE_FAILURE
            });
            return err;
        }
    }
}

export function createWarehouse(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_WAREHOUSE
            });
            const data = await apartmentService.createWarehouse(values)
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_WAREHOUSE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_WAREHOUSE_FAILURE
            });
        }
    }
}

export function updateWarehouse(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_WAREHOUSE
            });
            const data = await apartmentService.updateWarehouse(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_WAREHOUSE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_WAREHOUSE_FAILURE
            });
        }
    }
}

export function deleteWarehouse(id) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_WAREHOUSE
            });
            const data = await apartmentService.createWarehouse(id)
            dispatch(success("", "message.deleted"))
            dispatch(getWarehouses(getApartmentId()))
            dispatch({
                type: DELETE_WAREHOUSE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: DELETE_WAREHOUSE_FAILURE
            });
        }
    }
}

export function getConfigsGoods(params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_CONFIGS_GOODS
            });
            const data = await apartmentService.getGoods(params)
            dispatch({
                type: GET_CONFIGS_GOODS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_CONFIGS_GOODS_FAILURE
            });
            return err;
        }
    }
}

export function getConfigGoods(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_CONFIG_GOODS
            });
            const data = await apartmentService.getGood(id)
            dispatch({
                type: GET_CONFIG_GOODS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_CONFIG_GOODS_FAILURE
            });
            return err;
        }
    }
}


export function createConfigGoods(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_CONFIG_GOOD
            });
            const data = await apartmentService.createGoods(values)
            dispatch(success("", "message.created"))
            dispatch(getConfigsGoods({}));
            dispatch({
                type: CREATE_CONFIG_GOOD_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_CONFIG_GOOD_FAILURE
            });
        }
    }
}

export function updateConfigGoods(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_CONFIG_GOOD
            });
            const data = await apartmentService.updateGoods(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_CONFIG_GOOD_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_CONFIG_GOOD_FAILURE
            });
        }
    }
}
export function deleteConfigGoods(id) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_CONFIG_GOOD
            });
            const data = await apartmentService.deleteGoods(id)
            dispatch(success("", "message.deleted"))
            dispatch({
                type: DELETE_CONFIG_GOOD_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: DELETE_CONFIG_GOOD_FAILURE
            });
        }
    }
}

export function getGoodsReceipts(warehouseId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_GOODS_RECEIPTS
            });
            const data = await apartmentService.getGoodsReceipts(warehouseId, params)
            dispatch({
                type: GET_GOODS_RECEIPTS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_GOODS_RECEIPTS_FAILURE
            });
        }
    }
}

export function getGoodsReceipt(goodReceiptId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_GOODS_RECEIPT
            });
            const data = await apartmentService.getGoodsReceipt(goodReceiptId)
            dispatch({
                type: GET_GOODS_RECEIPT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_GOODS_RECEIPT_FAILURE
            });
        }
    }
}

export function createGoodsReceipt(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_GOODS_RECEIPT
            });
            const data = await apartmentService.createGoodReceipt(values)
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_GOODS_RECEIPT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_GOODS_RECEIPT_FAILURE
            });
            return err;
        }
    }
}

export function updateGoodsReceipt(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_GOODS_RECEIPT
            });
            const data = await apartmentService.updateGoodReceipt(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_GOODS_RECEIPT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_GOODS_RECEIPT_FAILURE
            });
            return err;
        }
    }
}


export function getGoodsExports(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_GOODS_EXPORTS
            });
            const data = await apartmentService.getGoodsExports(apartmentId, params)
            dispatch({
                type: GET_GOODS_EXPORTS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_GOODS_EXPORTS_FAILURE
            });
        }
    }
}

export function getGoodsExport(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_GOODS_EXPORT
            });
            const data = await apartmentService.getGoodsExport(id)
            dispatch({
                type: GET_GOODS_EXPORT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_GOODS_EXPORT_FAILURE
            });
        }
    }
}

export function createGoodsExport(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_GOODS_EXPORT
            });
            const data = await apartmentService.createGoodsExport(values)
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_GOODS_EXPORT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_GOODS_EXPORT_FAILURE
            });
            return err;
        }
    }
}

export function updateGoodsExport(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_GOODS_EXPORT
            });
            const data = await apartmentService.updateGoodsExport(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_GOODS_EXPORT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_GOODS_EXPORT_FAILURE
            });
            return err;
        }
    }
}

export function approveGoodsExport(id) {
    return async dispatch => {
        try {
            dispatch({
                type: APPROVE_GOODS_EXPORT
            });
            const data = await apartmentService.approveGoodsExport(id)
            dispatch(success("", "message.updated"))
            dispatch(getGoodsExport(id));
            dispatch({
                type: APPROVE_GOODS_EXPORT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: APPROVE_GOODS_EXPORT_FAILURE
            });
            return err;
        }
    }
}

export function rejectGoodsExport(id) {
    return async dispatch => {
        try {
            dispatch({
                type: REJECT_GOODS_EXPORT
            });
            const data = await apartmentService.rejectGoodsExport(id)
            dispatch(success("", "message.updated"))
            dispatch(getGoodsExport(id));
            dispatch({
                type: REJECT_GOODS_EXPORT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: REJECT_GOODS_EXPORT_FAILURE
            });
            return err;
        }
    }
}

export function approveGoodsReceipt(id) {
    return async dispatch => {
        try {
            dispatch({
                type: APPROVE_GOODS_RECEIPT
            });
            const data = await apartmentService.approveGoodsReceipt(id)
            dispatch(success("", "message.updated"))
            dispatch(getGoodsReceipt(id));
            dispatch({
                type: APPROVE_GOODS_RECEIPT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: APPROVE_GOODS_RECEIPT_FAILURE
            });
            return err;
        }
    }
}

export function rejectGoodsReceipt(id) {
    return async dispatch => {
        try {
            dispatch({
                type: REJECT_GOODS_RECEIPT
            });
            const data = await apartmentService.rejectGoodsReceipt(id)
            dispatch(success("", "message.updated"))
            dispatch(getGoodsReceipt(id));
            dispatch({
                type: REJECT_GOODS_RECEIPT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: REJECT_GOODS_RECEIPT_FAILURE
            });
            return err;
        }
    }
}

export function getWarehouseItems(warehouseId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_WAREHOUSE_ITEMS
            });
            const data = await apartmentService.getWarehouseItems(warehouseId, params)
            dispatch({
                type: GET_WAREHOUSE_ITEMS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_WAREHOUSE_ITEMS_FAILURE
            });
            return err;
        }
    }
}

export function getWarehouseItem(warehouseId, goodsId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_WAREHOUSE_ITEM
            });
            const data = await apartmentService.getWarehouseItemByGoodsId(warehouseId, goodsId)
            dispatch({
                type: GET_WAREHOUSE_ITEM_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_WAREHOUSE_ITEM_FAILURE
            });
            return err;
        }
    }
}

export function getMeter(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_ELECTRIC_METER
            });
            const data = await apartmentService.getMeter(id)
            dispatch({
                type: GET_ELECTRIC_METER_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_ELECTRIC_METER_FAILURE
            });
            return err;
        }
    }
}

export function getMeters(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_ELECTRIC_METERS
            });
            const data = await apartmentService.getMeters(apartmentId, params)
            dispatch({
                type: GET_ELECTRIC_METERS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_ELECTRIC_METERS_FAILURE
            });
            return err;
        }
    }
}

export function getElectricMetersByUnit(unitId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_ELECTRIC_METERS_BY_UNIT
            });
            const data = await apartmentService.getElectricMetersByUnit(unitId)
            dispatch({
                type: GET_ELECTRIC_METERS_BY_UNIT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_ELECTRIC_METERS_BY_UNIT_FAILURE
            });
            return err;
        }
    }
}

export function createMeter(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_ELECTRIC_METER
            });
            const data = await apartmentService.createMeter(values)
            dispatch(getMeters(localStorage.getItem("apartment_id"), {...DEFAULT_PARAMS, type: values.type}))
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_ELECTRIC_METER_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_ELECTRIC_METER_FAILURE
            });
            return err;
        }
    }
}

export function updateMeter(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_ELECTRIC_METER
            });
            const data = await apartmentService.updateMeter(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_ELECTRIC_METER_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_ELECTRIC_METER_FAILURE
            });
            return err;
        }
    }
}


export function getMonthlyReports(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_ELECTRIC_METER_MONTHLY_REPORTS
            });
            const data = await apartmentService.getMonthlyReports(apartmentId, params)
            dispatch({
                type: GET_ELECTRIC_METER_MONTHLY_REPORTS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_ELECTRIC_METER_MONTHLY_REPORTS_FAILURE
            });
            return err;
        }
    }
}

export function getMonthlyReport(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_ELECTRIC_METER_MONTHLY_REPORT
            });
            const data = await apartmentService.getMonthlyReport(id)
            dispatch({
                type: GET_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_ELECTRIC_METER_MONTHLY_REPORT_FAILURE
            });
            return err;
        }
    }
}

export function createMonthlyReport(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_ELECTRIC_METER_MONTHLY_REPORT
            });
            const data = await apartmentService.createMonthlyReport(values)
            dispatch(success("", "message.created"))
            dispatch(getMonthlyReports(localStorage.getItem("apartment_id"), {...DEFAULT_PARAMS, type: values.type,  fromDate: moment(new Date()).startOf("month"),
                toDate: moment(new Date()).endOf("month")}))
            dispatch({
                type: CREATE_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_ELECTRIC_METER_MONTHLY_REPORT_FAILURE
            });
            return err;
        }
    }
}


export function updateMonthlyReport(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_ELECTRIC_METER_MONTHLY_REPORT
            });
            const data = await apartmentService.updateMonthlyReport(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_ELECTRIC_METER_MONTHLY_REPORT_FAILURE
            });
            return err;
        }
    }
}

export function deleteMonthlyReport(id, type) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_ELECTRIC_METER_MONTHLY_REPORT
            });
            const data = await apartmentService.deleteMonthlyReport(id)
            dispatch(success("", "message.deleted"))
            dispatch(getMonthlyReports(localStorage.getItem("apartment_id"), {...DEFAULT_PARAMS, type: type}))
            dispatch({
                type: DELETE_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS,
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: DELETE_ELECTRIC_METER_MONTHLY_REPORT_FAILURE
            });
            return err;
        }
    }
}

export function getConfigMeterUnits(unitId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_CONFIG_ELECTRIC_METERS
            });
            const data = await apartmentService.getConfigMeterUnits(unitId)
            dispatch({
                type: GET_CONFIG_ELECTRIC_METERS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_CONFIG_ELECTRIC_METERS_FAILURE
            });
            return err;
        }
    }
}

export function getConfigMeterUnit(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_CONFIG_ELECTRIC_METER
            });
            const data = await apartmentService.getConfigMeterUnit(id)
            dispatch({
                type: GET_CONFIG_ELECTRIC_METER_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_CONFIG_ELECTRIC_METER_FAILURE
            });
            return err;
        }
    }
}

export function createConfigMeterUnit(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_CONFIG_ELECTRIC_METER
            });
            const data = await apartmentService.createConfigMetricUnit(values)
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_CONFIG_ELECTRIC_METER_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_CONFIG_ELECTRIC_METER_FAILURE
            });
            return err;
        }
    }
}

export function createAdjConfigUnit(configId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_ADJ_CONFIG_UNIT,
            });
            const data = await apartmentService.createAdjConfigUnit(configId, values)
            dispatch(success("", "message.created"))
            dispatch(getConfigMeterUnit(configId))
            dispatch({
                type: CREATE_ADJ_CONFIG_UNIT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_ADJ_CONFIG_UNIT_FAILURE
            });
            return err;
        }
    }
}

export function updateAdjConfigUnit(configId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_ADJ_CONFIG_UNIT,
            });
            const data = await apartmentService.updateAdjConfigUnit(configId, values)
            dispatch(success("", "message.updated"))
            dispatch(getConfigMeterUnit(configId))
            dispatch({
                type: UPDATE_ADJ_CONFIG_UNIT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_ADJ_CONFIG_UNIT_FAILURE
            });
            return err;
        }
    }
}


export function deleteAdjConfigUnit(configId, adjId) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_ADJ_CONFIG_UNIT,
            });
            const data = await apartmentService.deleteAdjConfigUnit(configId, adjId)
            dispatch(success("", "message.created"))
            dispatch(getConfigMeterUnit(configId))
            dispatch({
                type: DELETE_ADJ_CONFIG_UNIT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: DELETE_ADJ_CONFIG_UNIT_FAILURE
            });
            return err;
        }
    }
}

export function updateConfigMeterUnit(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_CONFIG_ELECTRIC_METER
            });
            const data = await apartmentService.updateConfigMetricUnit(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_CONFIG_ELECTRIC_METER_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_CONFIG_ELECTRIC_METER_FAILURE
            });
            return err;
        }
    }
}

export function deleteConfigMeterUnit(id) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_CONFIG_ELECTRIC_METER
            });
            const data = await apartmentService.deleteConfigMetricUnit(id)
            dispatch(success("", "message.deleted"))
            dispatch({
                type: DELETE_CONFIG_ELECTRIC_METER_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: DELETE_CONFIG_ELECTRIC_METER_FAILURE
            });
            return err;
        }
    }
}

export function getDashboard(apartmentId) {
    return async dispatch => {
        try {
            const data = await apartmentService.getDashboard(apartmentId)
            dispatch({
                type: GET_DASHBOARD_SUCCESS,
                payload: data
            });

        } catch (err) {
            dispatch(error("Thất bại", "Lấy thất bại."))
            dispatch({
                type: GET_DASHBOARD_FAILURE
            });
        }
    }
}

export function getExpenditures(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_EXPENDITURES
            });
            const data = await apartmentService.getExpenditures(apartmentId, params)
            dispatch({
                type: GET_EXPENDITURES_SUCCESS,
                payload: data
            });

        } catch (err) {
            dispatch({
                type: GET_EXPENDITURES_FAILURE
            });
        }
    }
}

export function getExpenditure(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_EXPENDITURE
            });
            const data = await apartmentService.getExpenditure(id)
            dispatch({
                type: GET_EXPENDITURE_SUCCESS,
                payload: data
            });

        } catch (err) {
            dispatch({
                type: GET_EXPENDITURE_FAILURE
            });
        }
    }
}

export function createExpenditure(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_EXPENDITURE
            });
            const data = await apartmentService.createExpenditure(values)
            dispatch(getExpenditures(localStorage.getItem("apartment_id"), DEFAULT_PARAMS))
            dispatch(success("Thông báo!", "Đã tạo thành công."))
            dispatch({
                type: CREATE_EXPENDITURE_SUCCESS,
                payload: data
            });

        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: CREATE_EXPENDITURE_FAILURE
            });
        }
    }
}

export function updateExpenditure(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_EXPENDITURE
            });
            const data = await apartmentService.updateExpenditure(id, values)
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch({
                type: UPDATE_EXPENDITURE_SUCCESS,
                payload: data
            });

        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: UPDATE_EXPENDITURE_FAILURE
            });
        }
    }
}

export function getGoodsReceiptsByApartment(apartmentId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_GOODS_RECEIPTS
            });
            const data = await apartmentService.getGoodsReceiptsByApartment(apartmentId)
            dispatch({
                type: GET_GOODS_RECEIPTS_SUCCESS,
                payload: data
            });

        } catch (err) {
            dispatch({
                type: GET_GOODS_RECEIPTS_FAILURE
            });
        }
    }
}

export function createChecklist(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_CHECKLIST
            });
            const data = await apartmentService.createChecklist(values)
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_CHECKLIST_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_CHECKLIST_FAILURE
            });
        }
    }
}

export function verifyChecklist(checklistId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: VERIFY_CHECKLIST
            });
            const data = await apartmentService.verifyChecklist(checklistId, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: VERIFY_CHECKLIST_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: VERIFY_CHECKLIST_FAILURE
            });
        }
    }
}

export function importMeter(apartmentId, formData) {
    return async dispatch => {
        try {
            dispatch({
                type: IMPORT_METER
            });
            const data = await apartmentService.importMeter(apartmentId, formData)
            dispatch(success("", "message.imported"))
            dispatch({
                type: IMPORT_METER_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: IMPORT_METER_FAILURE
            });
        }
    }
}