import contractService from "../../services/contractService";
import {error, success} from "./alertActions";

export const GET_CONTRACTS = 'GET_CONTRACTS';
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS';
export const GET_CONTRACTS_FAILURE = 'GET_CONTRACTS_FAILURE';

export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS';
export const GET_CONTRACT_FAILURE = 'GET_CONTRACT_FAILURE';

export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';
export const CREATE_CONTRACT_FAILURE = 'CREATE_CONTRACT_FAILURE';

export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_FAILURE = 'UPDATE_CONTRACT_FAILURE';

export const DELETE_CONTRACT = 'DELETE_CONTRACT';
export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS';
export const DELETE_CONTRACT_FAILURE = 'DELETE_CONTRACT_FAILURE';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAILURE = 'GET_DOCUMENTS_FAILURE';

export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_FAILURE = 'ADD_DOCUMENT_FAILURE';

export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const REMOVE_DOCUMENT_SUCCESS = 'REMOVE_DOCUMENT_SUCCESS';
export const REMOVE_DOCUMENT_FAILURE = 'REMOVE_DOCUMENT_FAILURE';

export function getContracts(apartmentId, params) {
    return dispatch => {
        dispatch({
            type: GET_CONTRACTS
        });
        contractService
            .getContracts(apartmentId, params)
            .then(data => {
                return dispatch({
                    type: GET_CONTRACTS_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                return dispatch({
                    type: GET_CONTRACTS_FAILURE
                });
            });
    };
}

export function getContract(contractId) {
    return dispatch => {
        dispatch({
            type: GET_CONTRACT
        });
        contractService
            .getContractDetail(contractId)
            .then(data => {
                return dispatch({
                    type: GET_CONTRACT_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                return dispatch({
                    type: GET_CONTRACT_FAILURE
                });
            });
    };
}

export function createContract(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_CONTRACT
            });
            const data = await contractService.createContract(values)
            dispatch(success("Thông báo!", "Đã tạo thành công."))
            dispatch({
                type: CREATE_CONTRACT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: CREATE_CONTRACT_FAILURE
            });
        }
    }
}

export function updateContract(contractId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_CONTRACT
            });
            const data = await contractService.updateContract(contractId, values)
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch({
                type: UPDATE_CONTRACT_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: UPDATE_CONTRACT_FAILURE
            });
        }
    }
}

export function deleteContract(contractId) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_CONTRACT
            });
            const data = await contractService.deleteContract(contractId)
            dispatch(success("Thông báo!", "Đã xoá thành công."))
            dispatch({
                type: DELETE_CONTRACT_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: DELETE_CONTRACT_FAILURE
            });
        }
    }
}

export function getDocuments(contractId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_DOCUMENTS
            });
            const data = await contractService.getDocuments(contractId)
            dispatch({
                type: GET_DOCUMENTS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_DOCUMENTS_FAILURE
            });
        }
    }
}

export function addDocument(contractId, documents) {
    return async dispatch => {
        try {
            dispatch({
                type: ADD_DOCUMENT
            });
            dispatch(success("Add!", "Your document has been added."))
            const data = await contractService.addDocument(contractId, documents)
            dispatch({
                type: ADD_DOCUMENT_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("Add!", "Your document hasn't been added."))
            dispatch({
                type: ADD_DOCUMENT_FAILURE
            });
        }
    }
}

export function removeDocument(contractId, documentId) {
    return async dispatch => {
        try {
            dispatch({
                type: REMOVE_DOCUMENT
            });
            const data = await contractService.removeDocument(contractId, documentId)
            dispatch(success("Thông báo!", "Đã xoá thành công."))
            dispatch({
                type: REMOVE_DOCUMENT_SUCCESS,
                payload: documentId
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: REMOVE_DOCUMENT_FAILURE
            });
        }
    }
}

export function getContractByUnit(unitId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_CONTRACT
            });
            const data = await contractService.getContractByUnit(unitId)
            dispatch({
                type: GET_CONTRACT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_CONTRACT_FAILURE
            });
        }
    }
}



