import { lazy } from 'react'

const apartmentModule = {
    pages: {
        ApartmentDetail: {
            label: 'common.general',
            path: '/apartments',
            getLink: () => `/apartments`,
            component: lazy(() => import('./pages/ApartmentDetail')),
            auth: ['ROLE_APARTMENT'],
            actions: (eventEmitter) => ({
                actions: [
                    {
                        key: 'createUnit',
                        label: 'button.createUnit',
                        onClick: () => eventEmitter.emit('layout', ['createUnit', {}]),
                        style: 'shine-secondary'
                    },
                    {
                        key: 'apartmentEdit',
                        label: 'common.button.modify',
                        onClick: () => eventEmitter.emit('layout', ['apartmentEdit', {}]),
                        style: 'shine'
                    }
                ]
            })
        },
        UnitDetail: {
            label: 'common.general',
            path: '/apartments/units/:id',
            getLink: ({ id }) => `/apartments/units/${id}`,
            component: lazy(() => import('./pages/UnitDetail')),
            auth: ['ROLE_APARTMENT'],
            actions: (eventEmitter) => ({
                actions: [
                    {
                        key: 'unitEdit',
                        label: 'common.button.modify',
                        onClick: () => eventEmitter.emit('layout', ['onEditUnit', {}]),
                        style: 'shine'
                    }
                ]
            })
        }
    }
}

export default apartmentModule
