import React from 'react'
import { useIntl } from 'react-intl'
import './ui.css'
import { Dropdown, Tooltip } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { Button } from 'antd';

/**
 * A React component that renders a set of UI actions, such as buttons and dropdown menus.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.size] - The size of the buttons, can be 'sm', 'md', or 'lg'.
 * @param {Object[]} props.actions - An array of action objects, each with a `key`, `label`, `hidden`, `disable`, `onClick`, and `style` property.
 * @param {Object} [props.quickActions] - An object with an `icon` property and a `menu` property, which is an object with an `items` property that is an array of action objects.
 * @param {boolean} [props.labelFormated] - A boolean indicating whether the action labels should be formatted using the `intl.formatMessage` function.
 */
// size: sm, md, lg
// iconActions: {key, label, hidden, disable, onClick }[]
// actions: {key, label, hidden, disabled, onClick, style: primary,outline-primary,shine }[]
// quickActions: {icon, menu: {item: actions[] }...}}
// labelFormated: boolean
export default function UiActions({ size, actions, quickActions, iconActions, labelFormated, ...props }) {
    const intl = useIntl()

    const dropdownProps = quickActions ? {
        ...quickActions,
        menu: {
            ...quickActions.menu,
            items: quickActions.menu.items.map(e => ({
                ...e,
                label: labelFormated === true ? e.label : (e.label ? intl.formatMessage({ id: e.label }) : null),
                children: e.children?.map(child => ({
                    ...child,
                    label: labelFormated === true ? child.label : (child.label ? intl.formatMessage({ id: child.label }) : null),
                }))
            }))
        }
    } : {}

    const antdStyleClass = (style) => ({
        '': 'btn-primary',
        'link': 'btn-link',
        'primary': 'btn-primary',
        'danger': 'btn-danger',
        'secondary': 'btn-secondary',
        'outline-primary': 'btn-outline-primary',
        'outline-danger': 'btn-outline-danger',
        'shine': 'btn-primary btn-hover-shine2',
        'shine-secondary': 'btn-secondary btn-hover-shine2'
    }[style])

    const antdSizeClass = (size) => ({
        '': 'default',
        'sm': 'small',
        'md': 'default',
        'lg': 'large',
        'xs': 'small',
    }[size])

    const antdMapButton = ({ style, size, ...e }) => ({
        className: antdStyleClass(style),
        size: antdSizeClass(size),
        ...e
    })

    return <div {...props}>
        <span className='uiactions'>
            {actions && actions.filter(e => !e.hidden).map(({ key, label, ...e }, i) =>
                <span key={`actions-${key}`} className='mx-1'>
                    <Button {...antdMapButton({ ...e, size })}>
                        {labelFormated === true ? label : intl.formatMessage({ id: label })}
                    </Button>
                </span>
            )}
            {quickActions && <span className='mx-1'>
                <Dropdown {..._.omit(dropdownProps, ['icon', 'style'])}>
                    <Button {...antdMapButton({ ...quickActions, size })} icon={dropdownProps?.icon ?? <MoreOutlined />} />
                </Dropdown>
            </span>}
            {iconActions && iconActions.filter(e => !e.hidden).map(({ key, label, icon, ...e }, i) =>
                <span key={`actions-${key}`} className='mx-1'>
                    <Tooltip title={labelFormated === true ? label : intl.formatMessage({ id: label })}>
                        <Button {...antdMapButton({ ...e, size })} icon={icon ?? <MoreOutlined />} />
                    </Tooltip>
                </span>
            )}
        </span>
    </div>
}


const defaultActions = {
    edit: { key: 'edit', label: 'common.button.edit', style: 'outline-primary', order: 1, hidden: false, disabled: false, onClick: () => { console.log('edit') }, },
    delete: { key: 'delete', label: 'common.button.delete', style: 'danger', order: 2, hidden: false, disabled: false, onClick: () => { }, }
}

UiActions.actions = (options = {}) => {

    const mergedActions = Object.entries(options.actions).reduce((acc, [key, action]) => {
        acc[key] = { ...defaultActions[key], ...action, }
        return acc
    }, {})

    const finalActions = Object.values(mergedActions)
        .filter(action => !action.hidden)
        .sort((a, b) => a.order - b.order)
        .map(({ order, hidden, ...action }) => action)

    return {
        ...options,
        actions: finalActions
    }
}