import {NotificationManager} from "react-notifications";
import {createIntl, createIntlCache} from 'react-intl'

export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const enIntl = createIntl({
    locale: 'en',
    messages: {
        "message.created": "Successfully created...",
        "message.updated": "Saved changed...",
        "message.deleted": "Deleted successfully..."
    }
}, cache)

const viIntl = createIntl({
    locale: 'vi',
    messages: {
        "message.created": "Đã tạo thành công...",
        "message.updated": "Đã lưu thay đổi...",
        "message.deleted": "Đã xoá thành công...",
        "message.imported": "Đã lưu thành công..."
    }
}, cache)

export function success(title, message) {
    return dispatch => {
        NotificationManager.success(
            getIntl().formatMessage({id: message}),
            '',
            2000
        );
        dispatch({
            type: ALERT_SUCCESS
        });
    };
}

export function error(title, message) {
    return dispatch => {
        NotificationManager.error(
            message,
            '',
            2000
        );
        dispatch({
            type: ALERT_ERROR
        });
    };
}

export const getIntl = () => {
    if (localStorage.getItem('language') === 'en') {
        return enIntl;
    }
    return viIntl;
}