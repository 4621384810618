import workOrderService from "../../services/workOrderService";
import {error, success} from "./alertActions";

export const GET_WORK_ORDERS = 'GET_WORK_ORDERS';
export const GET_WORK_ORDERS_SUCCESS = 'GET_WORK_ORDERS_SUCCESS';
export const GET_WORK_ORDERS_FAILURE = 'GET_WORK_ORDERS_FAILURE';

export const GET_WORK_ORDER = 'GET_WORK_ORDER';
export const GET_WORK_ORDER_SUCCESS = 'GET_WORK_ORDER_SUCCESS';
export const GET_WORK_ORDER_FAILURE = 'GET_WORK_ORDER_FAILURE';

export const CHANGE_STATUS = 'CHANGE_STATUS'
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS'
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE'

export const CHANGE_PRIORITY = 'CHANGE_PRIORITY'
export const CHANGE_PRIORITY_SUCCESS = 'CHANGE_PRIORITY_SUCCESS'
export const CHANGE_PRIORITY_FAILURE = 'CHANGE_PRIORITY_FAILURE'

export const ASSIGN_WORK_ORDER = 'ASSIGN_WORK_ORDER';
export const ASSIGN_WORK_ORDER_SUCCESS = 'ASSIGN_WORK_ORDER_SUCCESS';
export const ASSIGN_WORK_ORDER_FAILURE = 'ASSIGN_WORK_ORDER_FAILURE';

export const POST_COMMENT = 'POST_COMMENT';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const POST_COMMENT_FAILURE = 'POST_COMMENT_FAILURE';


export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';

export const CLONE_TASK = 'CLONE_TASK';
export const CLONE_TASK_SUCCESS = 'CLONE_TASK_SUCCESS';
export const CLONE_TASK_FAILURE = 'CLONE_TASK_FAILURE';

export const DELETE_WORK_ORDER = 'DELETE_WORK_ORDER';
export const DELETE_WORK_ORDER_SUCCESS = 'DELETE_WORK_ORDER_SUCCESS';
export const DELETE_WORK_ORDER_FAILURE = 'DELETE_WORK_ORDER_FAILURE';


export const UPLOAD_IMAGE_WORK_ORDER = 'UPLOAD_IMAGE_WORK_ORDER';
export const UPLOAD_IMAGE_WORK_ORDER_SUCCESS = 'UPLOAD_IMAGE_WORK_ORDER_SUCCESS';
export const UPLOAD_IMAGE_WORK_ORDER_FAILURE = 'UPLOAD_IMAGE_WORK_ORDER_FAILURE';

export const UPDATE_IMAGE_WORK_ORDER = 'UPDATE_IMAGE_WORK_ORDER';
export const UPDATE_IMAGE_WORK_ORDER_SUCCESS = 'UPDATE_IMAGE_WORK_ORDER_SUCCESS';
export const UPDATE_IMAGE_WORK_ORDER_FAILURE = 'UPDATE_IMAGE_WORK_ORDER_FAILURE';


export function getWorkOrders(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_WORK_ORDERS
            });
            const data = await workOrderService.getWorkOrders(apartmentId, params)
            dispatch({
                type: GET_WORK_ORDERS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_WORK_ORDERS_FAILURE
            });
        }
    }
}

export function getWorkOrderDetail(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_WORK_ORDER
            });
            const data = await workOrderService.getWorkOrderDetail(id)
            dispatch({
                type: GET_WORK_ORDER_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_WORK_ORDER_FAILURE
            });
        }
    }
}

export function changeStatus(id, status) {
    return async dispatch => {
        try {
            dispatch({
                type: CHANGE_STATUS
            });
            const data = await workOrderService.changeStatus(id, status)
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch(getWorkOrderDetail(id))
            dispatch({
                type: CHANGE_STATUS_SUCCESS,
                payload: {id: id, data: status}
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: CHANGE_STATUS_FAILURE
            });
        }
    }
}

export function changePriority(id, priority) {
    return async dispatch => {
        try {
            dispatch({
                type: CHANGE_PRIORITY
            });
            const data = await workOrderService.changePriority(id, priority)
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch({
                type: CHANGE_PRIORITY_SUCCESS,
                payload: {id: id, data: priority}
            });
            return data;
        } catch (err) {

            dispatch({
                type: CHANGE_PRIORITY_FAILURE
            });
        }
    }
}

export function assignWorkOrder(id, employeeId) {
    return async dispatch => {
        try {
            dispatch({
                type: ASSIGN_WORK_ORDER
            });
            const data = await workOrderService.assignWorkOrder(id, employeeId)
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch({
                type: ASSIGN_WORK_ORDER_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: ASSIGN_WORK_ORDER_FAILURE
            });
        }
    }
}

export function postComment(values) {
    return async dispatch => {
        try {
            dispatch({
                type: POST_COMMENT
            });
            const data = await workOrderService.postComment(values)
            dispatch(success("", "message.created"))
            dispatch({
                type: POST_COMMENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: POST_COMMENT_FAILURE
            });
        }
    }
}

export function createSingleTask(values) {
    console.log("create task=========: "+JSON.stringify(values))
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_TASK
            });
            const data = await workOrderService.createSingleTask(values)
            if (values.assetId !== null) {
                dispatch(getWorkOrders(localStorage.getItem("apartment_id"), {page: 0, size: 10, assetId: values.assetId}))
            }
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_TASK_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_TASK_FAILURE
            });
        }
    }
}


export function createScheduleTask(values) {
    console.log(values);
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_TASK
            });
            const detail = await workOrderService.createScheduleTask(values)
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_TASK_SUCCESS
            });
            return detail;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_TASK_FAILURE
            });
        }
    }
}

export function cloneTask(workOrderId, time) {
    return async dispatch => {
        try {
            dispatch({
                type: CLONE_TASK
            });
            const detail = await workOrderService.cloneTask(workOrderId, time)
            dispatch(success("", "message.created"))
            dispatch({
                type: CLONE_TASK_SUCCESS,
                payload: detail
            });
            return detail;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CLONE_TASK_FAILURE
            });
        }
    }
}


export function deleteLoop(loopId) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_WORK_ORDER
            });
            const data = await workOrderService.deleteLoop(loopId)
            dispatch(success("", "message.deleted"))
            dispatch({
                type: DELETE_WORK_ORDER_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: DELETE_WORK_ORDER_FAILURE
            });
            return err;
        }
    }
}

export function uploadImages(workId,files) {
    return async dispatch => {
        try {
            dispatch({
                type: UPLOAD_IMAGE_WORK_ORDER
            });
            const data = await workOrderService.uploadImage(workId,files)
            dispatch({
                type: UPLOAD_IMAGE_WORK_ORDER_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPLOAD_IMAGE_WORK_ORDER_FAILURE
            });
            return err;
        }
    }
}
export function addImages(workId,files) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_IMAGE_WORK_ORDER
            });
            const data = await workOrderService.updateImage(workId,files)
            dispatch({
                type: UPDATE_IMAGE_WORK_ORDER_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_IMAGE_WORK_ORDER_FAILURE
            });
            return err;
        }
    }
}