import history from "@history.js";
import jwtAuthService from "../../services/jwtAuthService";
import {error} from "./alertActions";

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const GET_ME = "GET_ME"
export const GET_ME_SUCCESS = "GET_ME_SUCCESS"
export const GET_ME_FAILURE = "GET_ME_FAILURE"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED"

export function setUserData(user) {
    return dispatch => {
        dispatch({
            type: SET_USER_DATA,
            data: user
        });
    };
}

export function logoutUser() {
    return dispatch => {
        jwtAuthService.logout();

        history.push({
            pathname: "/session/signin"
        });

        dispatch({
            type: USER_LOGGED_OUT
        });
    };
}

export function me() {
    return async dispatch => {
        try {
            dispatch({
                type: GET_ME
            });
            const data = await jwtAuthService.me()
            dispatch({
                type: GET_ME_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_ME_FAILURE
            });
            return err;
        }
    }
}

export function changePassword(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CHANGE_PASSWORD
            });
            const data = await jwtAuthService.changePassword(values)
            dispatch(logoutUser())
            dispatch({
                type: CHANGE_PASSWORD_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: CHANGE_PASSWORD_FAILED
            });
            return err;
        }
    }
}