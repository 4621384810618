import {error, success} from "./alertActions";
import shopService from "../../services/shopService";

export const GET_SHOPS = 'GET_SHOPS';
export const GET_SHOPS_SUCCESS = 'GET_SHOPS_SUCCESS';
export const GET_SHOPS_FAILURE = 'GET_SHOPS_FAILURE';

export const GET_SHOP = 'GET_SHOP';
export const GET_SHOP_SUCCESS = 'GET_SHOP_SUCCESS';
export const GET_SHOP_FAILURE = 'GET_SHOP_FAILURE';

export const UPDATE_SHOP = 'UPDATE_SHOP';
export const UPDATE_SHOP_SUCCESS = 'UPDATE_SHOP_SUCCESS';
export const UPDATE_SHOP_FAILURE = 'UPDATE_SHOP_FAILURE';

export const LOCK_SHOP = 'LOCK_SHOP';
export const LOCK_SHOP_SUCCESS = 'LOCK_SHOP_SUCCESS';
export const LOCK_SHOP_FAILURE = 'LOCK_SHOP_FAILURE';

export const UNLOCK_SHOP = 'UNLOCK_SHOP';
export const UNLOCK_SHOP_SUCCESS = 'UNLOCK_SHOP_SUCCESS';
export const UNLOCK_SHOP_FAILURE = 'UNLOCK_SHOP_FAILURE';


export function getShops(params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_SHOPS
            });
            const data = await shopService.getShops(params)
            dispatch({
                type: GET_SHOPS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_SHOPS_FAILURE
            });
        }
    }
}

export function getShopById(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_SHOP
            });
            const data = await shopService.getShopById(id)
            dispatch({
                type: GET_SHOP_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_SHOP_FAILURE
            });
        }
    }
}

export function updateShop(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_SHOP
            });
            const data = await shopService.updateShop(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_SHOP_SUCCESS,
                payload: {id: id, data: data}
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_SHOP_FAILURE
            });
        }
    }
}

export function lockShop(id) {
    return async dispatch => {
        try {
            dispatch({
                type: LOCK_SHOP
            });
            const data = await shopService.lockShop(id)
            dispatch(success("", "message.updated"))
            dispatch({
                type: LOCK_SHOP_SUCCESS,
                payload: id
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: LOCK_SHOP_FAILURE
            });
        }
    }
}

export function unlockShop(id) {
    return async dispatch => {
        try {
            dispatch({
                type: UNLOCK_SHOP
            });
            const data = await shopService.unlockShop(id)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UNLOCK_SHOP_SUCCESS,
                payload: id
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UNLOCK_SHOP_FAILURE
            });
        }
    }
}