import {combineReducers} from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import NotificationReducer from "./NotificationReducer";
import ApartmentReducer from "./apartmentReducer";
import ResidentReducer from "./residentReducer";
import WorkOrderReducer from "./workOrderReducer";
import EmployeeReducer from "./employeeReducer";
import SubscriptionReducer from "./subscriptionReducer";
import SurveyReducer from "./surveyReducer"
import ParkingReducer from "./parkingReducer"
import AdsReducer from "./adsReducer";
import VisitorReducer from "./visitorReducer";
import NewReducer from "./newReducer";
import ContractReducer from "./contractReducer";
import ExternalContractReducer from "./externalContractReducer";

import OnboardFormReducer from "./onboardFormReducer";
import OnboardApprovalReducer from "./onboardApprovalReducer";
import ReportReducer from "./reportReducer";
import ConfigReducer from "./configReducer";
import UploadReducer from "./uploadReducer";
import StaffNewsReducer from "./staffNewsReducer";
import ReportParamsReducer from "./reportParamsReducer"
import ShopReducer from "./shopReducer"
import ReceiptPaymentReducer from "./receiptPaymentReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  notification: NotificationReducer,
  apartment: ApartmentReducer,
  resident: ResidentReducer,
  workOrder: WorkOrderReducer,
  employee: EmployeeReducer,
  goodService: SubscriptionReducer,
  survey: SurveyReducer,
  parking: ParkingReducer,
  ads: AdsReducer,
  news: NewReducer,
  staffNews: StaffNewsReducer,
  visitor: VisitorReducer,
  contract: ContractReducer,
  extContracts:ExternalContractReducer,
  formRegistration: OnboardFormReducer,
  approval : OnboardApprovalReducer,
  report: ReportReducer,
  config: ConfigReducer,
  upload: UploadReducer,
  reportParams:ReportParamsReducer,
  shop: ShopReducer,
  receiptPayment: ReceiptPaymentReducer
});

export default RootReducer;
