import { PlusOutlined } from '@ant-design/icons'
import { lazy } from 'react'

const actions = eventEmitter => ({
  actions: [
    {
      key: 'add',
      label: 'news.common.add',
      style: 'primary',
      icon: <PlusOutlined />,
      onClick: () => eventEmitter.emit('layout', ['onCreate'])
    }
  ]
})

const newsModule = {
  pages: {
    AllListing: {
      exact: true,
      label: 'common.all',
      path: '/news',
      component: lazy(() => import('./pages/NewsList')),
      actions
    },
    NewsProhomeListing: {
      label: 'news.field.category.1',
      path: '/news/newsProhome',
      data: { listCategoryId: 1 },
      component: lazy(() => import('./pages/NewsList')),
      actions
    },
    NewsInternalListing: {
      label: 'news.field.category.2',
      path: '/news/newsInternal',
      data: { listCategoryId: 2 },
      component: lazy(() => import('./pages/NewsList')),
      actions
    },
    NewsAdsListing: {
      label: 'news.field.category.3',
      path: '/news/newsAds',
      data: { listCategoryId: 3 },
      component: lazy(() => import('./pages/NewsList')),
      actions
    }
  }
}

export default newsModule
