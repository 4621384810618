import { PlusOutlined } from '@ant-design/icons'
import { lazy } from 'react'

const roleModule = {
  pages: {
    RoleListing: {
      label: 'role.head',
      path: '/r/roles',
      component: lazy(() => import('./pages/RoleListing')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'add',
            label: 'common.button.add',
            style: 'primary',
            icon: <PlusOutlined />,
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}])
          }
        ]
      })
    }
  }
}

export default roleModule
