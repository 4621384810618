import instance from "./axiosInstance";

class ShopService {

    getShops = (params) => {
        return instance
            .get(  "/rs/shop", {
                params: {
                    name: params?.name,
                    page: params?.page,
                    size: params?.size
                }
            })
            .then(({data}) => {
                return data;
            });
    };

    updateShop = (id, data) => {
        return instance
            .put(  "/rs/shop/es/" + id, {
                phoneNumber: data.phoneNumber,
                description: data.description,
                location: data.location
            })
            .then(({data}) => {
                return data;
            });
    };

    lockShop = (id) => {
        return instance
            .put(  "/rs/shop/es/" + id + "/lock", {
            })
            .then(({data}) => {
                return data;
            });
    };

    unlockShop = (id) => {
        return instance
            .put(  "/rs/shop/es/" + id + "/unlock", {
            })
            .then(({data}) => {
                return data;
            });
    };

    getShopById = (id) => {
        return instance
            .get("/rs/shop/" + id)
            .then(({data}) => {
                return data
            })
    }

}

export default new ShopService();
