import {lazy} from "react";

const RoleList = lazy(() => import("./list/RoleList"));

const roleRoutes = [

    {
        path: "/roles",
        component: RoleList
    }
];

export default roleRoutes;
