import axios from 'axios'
import { baseURL } from 'env'

const instance = axios.create({ baseURL: baseURL })

instance.interceptors.request.use(
  config => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem('accessToken')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }
    config.headers['Accept-Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'vi'
    return config
  },
  error => Promise.reject(error)
)

export default instance
