import instance from "./axiosInstance";

class StaffNewsService {

    getStaffNews = (apartmentId, page, size) => {
        return instance
            .get("/es/staff/news/staff/apartment/" + apartmentId, {
                params: {
                    page: page,
                    size: size
                }
            })
            .then(({data}) => {
                return data;
            });
    };

    getStaffNewsById = (id) => {
        return instance
            .get("/es/news/" + id, {})
            .then(({data}) => {
                return data;
            });
    };

    addStaffNews = (params) => {
        return instance
            .post("/es/staff/news", {
                apartmentId: params.apartmentId,
                title: params.title,
                content: params.content,
                imageTitle: params.imageTitle,
                attachFileUrls: params.attachFileUrls
            })
            .then(({data}) => {
                return data;
            })
    };

    editStaffNews = (params) => {
        return instance
            .put("/es/staff/news/" + params.id, {
                apartmentId: params.apartmentId,
                title: params.title,
                content: params.content,
                imageTitle: params.imageTitle,
                attachFileUrls: params.attachFileUrls
            })
            .then(({data}) => {
                return data;
            })
    };
}


export default new StaffNewsService();