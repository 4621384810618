import {lazy} from "react";

const CheckList = lazy(() => import("./checklists/CheckList"))
const ChecklistTemplateList = lazy(() => import("./templates/list/ChecklistTemplateList"));
const ChecklistTemplateDetail = lazy(() => import("./templates/detail/ChecklistTemplateDetail"));

const checklistRoutes = [
    {
        path: "/checklists",
        component: CheckList
    },
    {
        path: "/checklist-templates/:id",
        component: ChecklistTemplateDetail
    },
    {
        path: "/checklist-templates",
        component: ChecklistTemplateList
    }
];

export default checklistRoutes;
