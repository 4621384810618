import {error, success} from "./alertActions";
import configService from "../../services/configService";

export const GET_CONFIG_FEES = 'GET_CONFIG_FEES';
export const GET_CONFIG_FEES_SUCCESS = 'GET_CONFIG_FEES_SUCCESS';
export const GET_CONFIG_FEES_FAILURE = 'GET_CONFIG_FEES_FAILURE';

export const CREATE_CONFIG_FREE = 'CREATE_CONFIG_FREE';
export const CREATE_CONFIG_FREE_SUCCESS = 'CREATE_CONFIG_FREE_SUCCESS';
export const CREATE_CONFIG_FREE_FAILURE = 'CREATE_CONFIG_FREE_FAILURE';

export const UPDATE_CONFIG_FREE = 'UPDATE_CONFIG_FREE';
export const UPDATE_CONFIG_FREE_SUCCESS = 'UPDATE_CONFIG_FREE_SUCCESS';
export const UPDATE_CONFIG_FREE_FAILURE = 'UPDATE_CONFIG_FREE_FAILURE';

export const GET_CONFIG_FEE = 'GET_CONFIG_FEE';
export const GET_CONFIG_FEE_SUCCESS = 'GET_CONFIG_FEE_SUCCESS';
export const GET_CONFIG_FEE_FAILURE = 'GET_CONFIG_FEE_FAILURE';
export const GET_CONFIGURATION_BY_KEY = 'GET_CONFIGURATION_BY_KEY';
export const GET_CONFIGURATION_BY_KEY_SUCCESS = 'GET_CONFIGURATION_BY_KEY_SUCCESS';
export const GET_CONFIGURATION_BY_KEY_FAILURE = 'GET_CONFIGURATION_BY_KEY_FAILURE';

export function getConfigFees(apartmentId) {

    return async (dispatch) => {
        dispatch({
            type: GET_CONFIG_FEES
        });

        try {
            const data = await configService.getConfigFees(apartmentId);
            dispatch({
                type: GET_CONFIG_FEES_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: GET_CONFIG_FEES_FAILURE
            });
        }
    }
}

export function getConfigFee(configId) {
    return async (dispatch) => {
        dispatch({
            type: GET_CONFIG_FEE
        });

        try {
            const data = await configService.getConfigFee(configId);
            dispatch({
                type: GET_CONFIG_FEE_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: GET_CONFIG_FEE_FAILURE
            });
        }
    }
}


export function createConfigFee(values) {
    return async (dispatch) => {
        dispatch({
            type: CREATE_CONFIG_FREE
        });
        try {
            const data = await  configService.createConfigFee(values);
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_CONFIG_FREE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_CONFIG_FREE_FAILURE
            });
        }
    }
}

export function updateConfigFee(configId, values) {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_CONFIG_FREE
        });
        try {
            const data = await  configService.updateConfigFee(configId, values);
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_CONFIG_FREE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_CONFIG_FREE_FAILURE
            });
        }
    }
}

export function getConfigurationByKey(configKey) {
    return async (dispatch) => {
        dispatch({
            type: GET_CONFIGURATION_BY_KEY
        });

        try {
            const data = await configService.getConfigurationByKey(configKey);
            dispatch({
                type: GET_CONFIGURATION_BY_KEY_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: GET_CONFIGURATION_BY_KEY_FAILURE
            });
        }
    }
}

export async function getConfigByKey(configKey) {
    try {
        const config = await configService.getConfigurationByKey(configKey);
        return config;
    } catch (error) {
        console.error(`Error fetching config for key ${configKey}:`, error);
        return null;
    }
}