import {
    CANCEL_RECEIPT_PAYMENT,
    CANCEL_RECEIPT_PAYMENT_FAILURE,
    CANCEL_RECEIPT_PAYMENT_SUCCESS,
    CREATE_RECEIPT_PAYMENT,
    CREATE_RECEIPT_PAYMENT_FAILURE,
    CREATE_RECEIPT_PAYMENT_SUCCESS,
    GET_RECEIPT_PAYMENT_LIST,
    GET_RECEIPT_PAYMENT_LIST_FAILURE,
    GET_RECEIPT_PAYMENT_LIST_SUCCESS,
    UPDATE_RECEIPT_PAYMENT,
    UPDATE_RECEIPT_PAYMENT_FAILURE,
    UPDATE_RECEIPT_PAYMENT_SUCCESS,
    DOWNLOAD_RECEIPT_PAYMENT,
    DOWNLOAD_RECEIPT_PAYMENT_SUCCESS,
    DOWNLOAD_RECEIPT_PAYMENT_FAILURE, CREATE_BILL_RECEIPT, CREATE_BILL_RECEIPT_SUCCESS, CREATE_BILL_RECEIPT_FAILURE
} from "../actions/receiptPaymentActions";

const ReceiptPaymentReducer = function(state = {error: false, loading: false}, action) {
    switch (action.type) {
        case GET_RECEIPT_PAYMENT_LIST:
            return {
                ...state,
                loading: true
            };
        case GET_RECEIPT_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                receiptPaymentList: action.payload
            };
        case GET_RECEIPT_PAYMENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CREATE_RECEIPT_PAYMENT: {
            return {
                ...state,
                loading: true
            };
        }
        case CREATE_RECEIPT_PAYMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                receiptPaymentList: action.payload
            };
        }
        case CREATE_RECEIPT_PAYMENT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case UPDATE_RECEIPT_PAYMENT:
            return {
                ...state,
                loading: true
            };
        case UPDATE_RECEIPT_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                receiptPaymentList: action.payload
            };
        case UPDATE_RECEIPT_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CANCEL_RECEIPT_PAYMENT:
            return {
                ...state,
                loading: true
            };
        case CANCEL_RECEIPT_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                receiptPaymentList: action.payload
            };
        case CANCEL_RECEIPT_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case DOWNLOAD_RECEIPT_PAYMENT:
            return {
                ...state,
                loading: true
            };
        case DOWNLOAD_RECEIPT_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case DOWNLOAD_RECEIPT_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CREATE_BILL_RECEIPT: {
            return {
                ...state,
                loading: true
            };
        }
        case CREATE_BILL_RECEIPT_SUCCESS: {
            return {
                ...state,
                loading: false,
                receiptPaymentList: action.payload
            };
        }
        case CREATE_BILL_RECEIPT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        default:
            return state
    }
};

export default ReceiptPaymentReducer;