import instance from "./axiosInstance";

class ContractService {

    getContracts = (apartmentId, params) => {
        return instance
            .get(  "/cs/contracts/staff/apartment/" + apartmentId, {
                params: {
                    residentId: params.residentId,
                    unitId: params.unitId,
                    page: params.page,
                    size: params.size,
                    keyword: params.keyword
                }
            })
            .then(({data}) => {
                return data;
            });
    }

    createContract = (values) => {
        return instance
            .post("/cs/contracts", {
                name: values.name,
                code: values.code,
                signedDate: values.signedDate && values.signedDate.format("YYYY-MM-DD") ,
                effectiveDate: values.effectiveDate && values.effectiveDate.format("YYYY-MM-DD"),
                expiredDate: values.expiredDate && values.expiredDate.format("YYYY-MM-DD"),
                apartmentId: values.apartmentId,
                residentId: values.residentId,
                unitId: values.unitId,
                content: values.content,
                documents: values.documents,
                feeAdjustments: values.feeAdjustments,
                rentalFee: values.rentalFee,
                fixedFee:values.fixedFee,
                isFixed:values.isFixed,
                serviceFee: values.serviceFee,
                periodMonth: values.periodMonth,
                depositAmount:values.depositAmount,
                type: "REVENUE",
                contractUnitRequest: values.components,
                bikeParkingQuantity: values.bikeParkingQuantity,
                bikeParkingFee: values.bikeParkingFee,
                bikeOvernightParkingFee: values.bikeOvernightParkingFee,
                carParkingQuantity: values.carParkingQuantity,
                carParkingFee: values.carParkingFee,
                carOvernightParkingFee: values.carOvernightParkingFee
            })
            .then(({data}) => {
                return data;
            })
    };

    updateContract = (contractId, values) => {
        return instance
            .put("/cs/contracts/" + contractId, {
                name: values.name,
                code: values.code,
                signedDate: values.signedDate && values.signedDate.format("YYYY-MM-DD") ,
                effectiveDate: values.effectiveDate && values.effectiveDate.format("YYYY-MM-DD"),
                expiredDate: values.expiredDate && values.expiredDate.format("YYYY-MM-DD"),
                apartmentId: values.apartmentId,
                residentId: values.residentId,
                unitId: values.unitId,
                content: values.content,
                documents: values.documents,
                feeAdjustments: values.feeAdjustments,
                fixedFee:values.fixedFee,
                isFixed:values.isFixed,
                rentalFee: values.rentalFee,
                serviceFee: values.serviceFee,
                periodMonth: values.periodMonth,
                depositAmount:values.depositAmount,
                type: "REVENUE",
                contractUnitRequest: values.components,
                bikeParkingQuantity: values.bikeParkingQuantity,
                bikeParkingFee: values.bikeParkingFee,
                bikeOvernightParkingFee: values.bikeOvernightParkingFee,
                carParkingQuantity: values.carParkingQuantity,
                carParkingFee: values.carParkingFee,
                carOvernightParkingFee: values.carOvernightParkingFee
            })
            .then(({data}) => {
                return data;
            })
    };

    deleteContract = (contractId) => {
        return instance
            .delete(  "/cs/contracts/" + contractId, {})
            .then(({data}) => {
                return data;
            });
    }

    getDocuments = (contractId) => {
        return instance
            .get(  "/cs/contracts/" + contractId + "/documents" , {})
            .then(({data}) => {
                return data;
            });
    }

    getContractDetail = (contractId) => {
        return instance
            .get(  "/cs/contracts/" + contractId + "/detail"  , {})
            .then(({data}) => {
                return data;
            });
    }

    addDocument = (contractId, documents) => {
        return instance
            .post(  "/cs/contracts/" + contractId + "/documents" , documents)
            .then(({data}) => {
                return data;
            });
    }

    removeDocument = (contractId, documentId) => {
        return instance
            .delete(  "/cs/contracts/" + contractId + "/documents/" + documentId, {})
            .then(({data}) => {
                return data;
            });
    }

    getContractByUnit = (unitId) => {
        return instance
            .get(  "/cs/contracts/unit/" + unitId, {
            })
            .then(({data}) => {
                return data;
            });
    }
}

export default new ContractService();
