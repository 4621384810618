import {
    GET_REPORT_PARAMS_SUCCESS,
    GET_REPORT_PARAMS_FAILURE,
     GET_REPORT_PARAMS,

} from "../actions/reportActions";


const ReportParamsReducer = function(state = {}, action) {
    switch (action.type) {
        case GET_REPORT_PARAMS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_REPORT_PARAMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                reportParams: action.payload
            };
        }
        case GET_REPORT_PARAMS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        default: {
            return state;
        }
    }
};

export default ReportParamsReducer;
