import PageTabsLayout from 'layout/PageTabsLayout'
import parkingModule from './parkingModule'

const parkingRoutes = [
  {
    exact: false,
    path: '/p',
    component: PageTabsLayout,
    routes: [parkingModule.pages.ParkingLotList, parkingModule.pages.ParkingSubscriptionList]
  }
]

export default parkingRoutes
