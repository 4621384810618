import {lazy} from "react";

const ContractList = lazy(() => import("./list/ContractList"));
const ContractDetail = lazy(() => import("./detail/ContractDetail"));

const contractRoutes = [
    {
        path: "/contracts/:id",
        component: ContractDetail
    },
    {
        path: "/contracts",
        component: ContractList
    }
];

export default contractRoutes;
