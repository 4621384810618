import {
    APPROVE,
    APPROVE_FAILURE,
    APPROVE_SUCCESS,
    GET_BY_ID,
    GET_BY_ID_FAILURE,
    GET_BY_ID_SUCCESS,
    GET_REQUESTS,
    GET_REQUESTS_FAILURE,
    GET_REQUESTS_SUCCESS,
    PUTBACK,
    PUTBACK_FAILURE,
    PUTBACK_SUCCESS,
    REJECT,
    REJECT_FAILURE,
    REJECT_SUCCESS,
    SEND_APPROVAL,
    SEND_APPROVAL_FAILURE,
    SEND_APPROVAL_SUCCESS
} from "../actions/onboardApprovalActions";


const OnboardApprovalReducer = function(state = {}, action) {
    switch (action.type) {
        case GET_REQUESTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_REQUESTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                onboards: action.payload
            };
        }
        case GET_REQUESTS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case APPROVE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case APPROVE_SUCCESS: {
            return {
                ...state,
                loading: false,
                forms: action.payload
            };
        }
        case APPROVE_FAILURE: {
            return {
                ...state,
                loading: false,
                forms: action.payload
            };
        }
        case REJECT: {
            return {
                ...state,
                loading: false,
                forms: action.payload
            };
        }
        case REJECT_SUCCESS: {
            return {
                ...state,
                loading: false,
                forms: action.payload
            };
        }
        case REJECT_FAILURE: {
            return {
                ...state,
                loading: false,
                forms: action.payload
            };
        }
        case GET_BY_ID: {
            return {
                ...state,
                loading: false,
                form: action.payload
            };
        }
        case GET_BY_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                form: action.payload
            };
        }
        case GET_BY_ID_FAILURE: {
            return {
                ...state,
                loading: false,
                form: action.payload
            };
        }
        case PUTBACK: {
            return {
                ...state,
                loading: false,
                forms: action.payload
            };
        }
        case PUTBACK_SUCCESS: {
            return {
                ...state,
                loading: false,
                forms: action.payload
            };
        }
        case PUTBACK_FAILURE: {
            return {
                ...state,
                loading: false,
                forms: action.payload
            };
        }
        case SEND_APPROVAL: {
            return {
                ...state,
                loading: true
            };
        }
        case SEND_APPROVAL_SUCCESS: {
            return {
                ...state,
                loading: false,
                onboards: state.onboards !== undefined && {
                    ...state.onboards,
                    data: state.onboards.data.map(x => x.id === action.payload.id ? {
                            ...x,
                            state: action.payload.status
                        }
                        :
                        x
                    )
                },
                form: state.form !== undefined && {
                    ...state.form,
                    state: action.payload.status
                }
            };
        }
        case SEND_APPROVAL_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }

        default: {
            return state;
        }
    }
};

export default OnboardApprovalReducer;
