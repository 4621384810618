import PageTabsLayout from 'layout/PageTabsLayout'
import workOrderModule from './workOrderModule'

const workOrderRoutes = [
  {
    exact: false,
    path: '/wo/tasks',
    component: PageTabsLayout,
    routes: [workOrderModule.pages.TaskListing]
  },
  {
    exact: false,
    path: '/wo',
    component: PageTabsLayout,
    routes: [workOrderModule.pages.MaintainListing, workOrderModule.pages.FeedbackListing]
  },
]

export default workOrderRoutes
