import receiptPaymentService from "../../services/receiptPaymentService";
import {error, success} from "./alertActions";
import subscriptionService from "../../services/subscriptionService";
import {
    GET_BILL_MONTHLY_SUCCESS
} from "./subscriptionActions";

export const CREATE_RECEIPT_PAYMENT = 'CREATE_RECEIPT_PAYMENT';
export const CREATE_RECEIPT_PAYMENT_SUCCESS = 'CREATE_RECEIPT_PAYMENT_SUCCESS';
export const CREATE_RECEIPT_PAYMENT_FAILURE = 'CREATE_RECEIPT_PAYMENT_FAILURE';

export const UPDATE_RECEIPT_PAYMENT = 'UPDATE_RECEIPT_PAYMENT';
export const UPDATE_RECEIPT_PAYMENT_SUCCESS = 'UPDATE_RECEIPT_PAYMENT_SUCCESS';
export const UPDATE_RECEIPT_PAYMENT_FAILURE = 'UPDATE_RECEIPT_PAYMENT_FAILURE';

export const GET_RECEIPT_PAYMENT = 'GET_RECEIPT_PAYMENT';
export const GET_RECEIPT_PAYMENT_SUCCESS = 'GET_RECEIPT_PAYMENT_SUCCESS';
export const GET_RECEIPT_PAYMENT_FAILURE = 'GET_RECEIPT_PAYMENT_FAILURE';

export const GET_RECEIPT_PAYMENT_LIST = 'GET_RECEIPT_PAYMENT_LIST';
export const GET_RECEIPT_PAYMENT_LIST_SUCCESS = 'GET_RECEIPT_PAYMENT_LIST_SUCCESS';
export const GET_RECEIPT_PAYMENT_LIST_FAILURE = 'GET_RECEIPT_PAYMENT_LIST_FAILURE';
export const CANCEL_RECEIPT_PAYMENT = 'CANCEL_RECEIPT_PAYMENT';
export const CANCEL_RECEIPT_PAYMENT_SUCCESS = 'CANCEL_RECEIPT_PAYMENT_SUCCESS';
export const CANCEL_RECEIPT_PAYMENT_FAILURE = 'CANCEL_RECEIPT_PAYMENT_FAILURE';
export const DOWNLOAD_RECEIPT_PAYMENT = 'DOWNLOAD_RECEIPT_PAYMENT';
export const DOWNLOAD_RECEIPT_PAYMENT_SUCCESS = 'DOWNLOAD_RECEIPT_PAYMENT_SUCCESS';
export const DOWNLOAD_RECEIPT_PAYMENT_FAILURE = 'DOWNLOAD_RECEIPT_PAYMENT_FAILURE';
export const CREATE_BILL_RECEIPT = 'CREATE_BILL_RECEIPT';
export const CREATE_BILL_RECEIPT_SUCCESS = 'CREATE_BILL_RECEIPT_SUCCESS';
export const CREATE_BILL_RECEIPT_FAILURE = 'CREATE_BILL_RECEIPT_FAILURE';
export const CANCEL_BILL_RECEIPT = 'CANCEL_BILL_RECEIPT';
export const CANCEL_BILL_RECEIPT_SUCCESS = 'CANCEL_BILL_RECEIPT_SUCCESS';
export const CANCEL_BILL_RECEIPT_FAILURE = 'CANCEL_BILL_RECEIPT_FAILURE';


export function getReceiptPaymentList(apartmentId, incomeOutcomeGroup, page, size) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_RECEIPT_PAYMENT_LIST
            });
            const data = await receiptPaymentService.getReceiptPaymentList(apartmentId, incomeOutcomeGroup, page, size)
            dispatch({
                type: GET_RECEIPT_PAYMENT_LIST_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_RECEIPT_PAYMENT_LIST_FAILURE
            });
            return err;
        }
    }
}

export function createReceiptPayment(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_RECEIPT_PAYMENT
            });
            const data = await receiptPaymentService.createReceiptPayment(values)
            dispatch(success("", "message.created"));
            // history.push({
            //     pathname: "/receipt-payment"
            // });
            dispatch({
                type: CREATE_RECEIPT_PAYMENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_RECEIPT_PAYMENT_FAILURE
            });
            return err;
        }
    }
}

export function updateReceiptPayment(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_RECEIPT_PAYMENT
            });
            const data = await receiptPaymentService.updateReceiptPayment(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_RECEIPT_PAYMENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: UPDATE_RECEIPT_PAYMENT_FAILURE
            });
            return err;
        }
    }
}

export function cancelReceiptPayment(row) {
    return async dispatch => {
        try {
            dispatch({
                type: CANCEL_RECEIPT_PAYMENT
            });
            const data = await receiptPaymentService.cancelReceiptPayment(row)
            dispatch(success("", "message.updated"))
            dispatch({
                type: CANCEL_RECEIPT_PAYMENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CANCEL_RECEIPT_PAYMENT_FAILURE
            });
            return err;
        }
    }
}

export function downloadReceiptPayment(id) {
    return async dispatch => {
        try {
            dispatch({
                type: DOWNLOAD_RECEIPT_PAYMENT
            });
            const data = await receiptPaymentService.downloadReceiptPayment(id)
            dispatch({
                type: DOWNLOAD_RECEIPT_PAYMENT_SUCCESS,
            });
            let blob = new Blob([data], { type: 'application/pdf' }), url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: DOWNLOAD_RECEIPT_PAYMENT_FAILURE
            });
        }
    }
}

export function createBillReceipt(values, billComponentIds) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_BILL_RECEIPT
            });
            const data = await receiptPaymentService.createBillReceipt(values, billComponentIds)
            dispatch(success("", "message.created"));
            dispatch({
                type: CREATE_BILL_RECEIPT_SUCCESS,
                payload: data
            });

            const dataBill = await subscriptionService.getBillMonthly(values.residentMonthlyBillId)
            dispatch({
                type: GET_BILL_MONTHLY_SUCCESS,
                payload: dataBill
            });

            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_BILL_RECEIPT_FAILURE
            });
            return err;
        }
    }
}

export function cancelBillReceipt(id, residentMonthlyBillId) {
    return async dispatch => {
        try {
            dispatch({
                type: CANCEL_BILL_RECEIPT
            });
            const data = await receiptPaymentService.cancelBillReceipt(id)
            dispatch(success("", "message.updated"))
            dispatch({
                type: CANCEL_BILL_RECEIPT_SUCCESS,
                payload: data
            });

            const dataBill = await subscriptionService.getBillMonthly(residentMonthlyBillId)
            dispatch({
                type: GET_BILL_MONTHLY_SUCCESS,
                payload: dataBill
            });

            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CANCEL_BILL_RECEIPT_FAILURE
            });
            return err;
        }
    }
}
