import {
    LOGIN_ERROR,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    OTP_SEND,
    OTP_SEND_FAILED,
    OTP_SEND_SUCCESS,
    REFRESHING_TOKEN,
    RESET_PASSWORD,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_SUCCESS,
    TOKEN_REFRESHED
} from "../actions/LoginActions";

const initialState = {
    success: true,
    loading: false,
    refreshing: false
};

const LoginReducer = function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case LOGIN_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false
            };
        }
        case LOGIN_ERROR: {
            return {
                success: false,
                loading: false,
                error: action.payload
            };
        }
        case OTP_SEND: {
            return {
                ...state,
                loading: true
            };
        }
        case OTP_SEND_SUCCESS: {
            return {
                ...state,
                loading: false,
                email: action.payload
            };
        }
        case OTP_SEND_FAILED: {
            return {
                ...state,
                success: false,
                loading: false
            };
        }
        case RESET_PASSWORD: {
            return {
                ...state,
                loading: true
            };
        }
        case RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false
            };
        }
        case RESET_PASSWORD_FAILED: {
            return {
                ...state,
                error: true,
                loading: false
            };
        }
        case REFRESHING_TOKEN: {
            return {
                ...state,
                refreshing: true
            };
        }
        case TOKEN_REFRESHED: {
            return {
                ...state,
                refreshing: false
            };
        }
        default: {
            return state;
        }
    }
};

export default LoginReducer;
