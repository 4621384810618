import { PlusOutlined } from '@ant-design/icons'
import { lazy } from 'react'

const departmentModule = {
  pages: {
    DepartmentListing: {
      label: 'breadcrumb.departmentList',
      path: '/d/departments',
      component: lazy(() => import('./pages/DepartmentList')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'create',
            label: 'common.button.create',
            style: 'primary',
            icon: <PlusOutlined />,
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}]),
          }
        ]
      })
    }
  }
}

export default departmentModule
