import {lazy} from "react";

const onboardApprovalList = lazy(() => import("./parking/list/OnboardApprovalList"));
const parkingApprovalDetail = lazy(() => import("./parking/detail/OnboardApprovalDetail"));

const onboardApprovalRoutes = [
    {
        path: "/onboard-approvals/:id",
        component: parkingApprovalDetail
    },
    {
        path: "/onboard-approvals",
        component: onboardApprovalList
    }
];

export default onboardApprovalRoutes;
