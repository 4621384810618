import instance from './axiosInstance'

class EmployeeService {
  getEmployeeOptions = keyword => {
    return instance
      .get('/es/employees/get-employee-options', {
        params: {
          keyword: keyword
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  getMySchedules = (apartmentId, fromDate, toDate) => {
    return instance
      .get('/es/schedules/me', {
        params: {
          all: true,
          apartmentId: apartmentId,
          fromDate: fromDate,
          toDate: toDate
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  updateTaskEmployee = params => {
    return instance.put('/es/tasks', params).then(({ data }) => {
      return data
    })
  }

  uploadFiles = files => {
    let formData = new FormData()
    for (const iterator of files) {
      formData.append('file', iterator)
    }
    return instance
      .post('/us/upload-file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  uploadMultipleFiles = files => {
    let formData = new FormData()
    for (const iterator of files) {
      formData.append('files', iterator)
    }
    return instance
      .post('/us/upload-multiple-files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  getEmployees = (departmentId, keyword, page, size) => {
    return instance
      .get('/es/employees', {
        params: {
          apartmentId: departmentId,
          keyword: keyword,
          page: page,
          size: size
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  getEmployee = employeeId => {
    return instance.get('/es/employees/' + employeeId, {}).then(({ data }) => {
      return data
    })
  }

  createEmployee = values => {
    return instance
      .post('/es/employees', {
        username: values.username,
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        organizationId: values.organizationId,
        departmentId: values.departmentId,
        managerId: values.managerId,
        roles: values.roles,
        isPublic: !!values?.isPublic
      })
      .then(({ data }) => {
        return data
      })
  }

  updateEmployee = (employeeId, values) => {
    return instance
      .put('/es/employees/' + employeeId, {
        fullName: values.fullName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        organizationId: values.organizationId,
        departmentId: values.departmentId,
        managerId: values.managerId,
        roles: values.roles,
        isPublic: !!values?.isPublic
      })
      .then(({ data }) => {
        return data
      })
  }

  removeEmployee = employeeId => {
    return instance.delete('/es/employees/' + employeeId).then(({ data }) => {
      return data
    })
  }

  activeEmployee = employeeId => {
    return instance.put('/es/employees/' + employeeId + '/active', {}).then(({ data }) => {
      return data
    })
  }

  deactiveEmployee = employeeId => {
    return instance.put('/es/employees/' + employeeId + '/deactive', {}).then(({ data }) => {
      return data
    })
  }

  getOrganizations = params => {
    return instance
      .get('/es/organizations', {
        params: {
          page: params.page,
          size: params.size,
          keyword: params.keyword
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  getOrganization = id => {
    return instance.get('/es/organizations/' + id, {}).then(({ data }) => {
      return data
    })
  }

  updateOrganization = (id, values) => {
    return instance
      .put('/es/organizations/' + id, {
        name: values.name,
        beneficiaryName: values.beneficiaryName,
        beneficiaryBank: values.beneficiaryBank,
        bankAccountNo: values.bankAccountNo
      })
      .then(({ data }) => {
        return data
      })
  }

  createOrganization = values => {
    console.log(values)
    return instance
      .post('/es/organizations', {
        name: values.name,
        beneficiaryName: values.beneficiaryName,
        beneficiaryBank: values.beneficiaryBank,
        bankAccountNo: values.bankAccountNo
      })
      .then(({ data }) => {
        return data
      })
  }

  getDepartments = params => {
    return instance.get('/es/departments', { params }).then(({ data }) => {
      return data
    })
  }

  getDepartment = id => {
    return instance.get('/es/departments/' + id, {}).then(({ data }) => {
      return data
    })
  }

  createDepartment = values => {
    return instance
      .post('/es/departments', {
        name: values.name,
        roles: values.roles,
        canReceiveOnboardNoti: values.canReceiveOnboardNoti,
        canViewChecklist: values.canViewChecklist,
        canReceiveContractNoti: values.canReceiveContractNoti,
        canViewWorkOrder: values.canViewWorkOrder,
        canReceiveUnknowRequestNoti: values.canReceiveUnknowRequestNoti
      })
      .then(({ data }) => {
        return data
      })
  }

  editRolesOfDepartment = (id, values) => {
    return instance
      .put('/es/departments/' + id + '/update-roles', {
        roles: values.roles
      })
      .then(({ data }) => {
        return data
      })
  }

  updateDepartment = (id, values) => {
    return instance
      .put('/es/departments/' + id, {
        name: values.name,
        roles: values.roles,
        canReceiveOnboardNoti: values.canReceiveOnboardNoti,
        canViewChecklist: values.canViewChecklist,
        canReceiveContractNoti: values.canReceiveContractNoti,
        canViewWorkOrder: values.canViewWorkOrder,
        canReceiveUnknowRequestNoti: values.canReceiveUnknowRequestNoti
      })
      .then(({ data }) => {
        return data
      })
  }

  getRoles = () => {
    return instance.get('/es/roles', {}).then(({ data }) => {
      return data
    })
  }

  getRoleById = roleId => {
    return instance.get('/es/roles/' + roleId, {}).then(({ data }) => {
      return data
    })
  }

  updateRole = (roleId, values) => {
    return instance
      .put('/es/roles/' + roleId, {
        name: values.name,
        slug: values.slug,
        description: values.description,
        privileges: values.privileges
      })
      .then(({ data }) => {
        return data
      })
  }

  createRole = values => {
    return instance
      .post('/es/roles', {
        name: values.name,
        slug: values.slug,
        description: values.description,
        privileges: values.privileges
      })
      .then(({ data }) => {
        return data
      })
  }

  deleteRole = roleId => {
    return instance.delete('/es/roles/' + roleId).then(({ data }) => {
      return data
    })
  }

  getPermissions = params => {
    return instance
      .get('/es/privileges', {
        params: {
          page: params.page,
          size: params.size
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  getPermissionById = id => {
    return instance.get('/es/privileges/' + id, {}).then(({ data }) => {
      return data
    })
  }

  createPermission = values => {
    return instance
      .post('/es/privileges', {
        name: values.name,
        slug: values.slug,
        resource: values.resource,
        description: values.description
      })
      .then(({ data }) => {
        return data
      })
  }

  updatePermission = (permissionId, values) => {
    return instance
      .put('/es/privileges/' + permissionId, {
        name: values.name,
        slug: values.slug,
        resource: values.resource,
        description: values.description
      })
      .then(({ data }) => {
        return data
      })
  }

  deletePermission = permissionId => {
    return instance.delete('/es/privileges/' + permissionId).then(({ data }) => {
      return data
    })
  }
  /// Roles api
  roles = {
    getRoles: params => instance.get(`/es/roles`, { params }).then(({ data }) => data),
    updateRole: (id, data) =>
      // data : { name, slug, description, privileges }
      instance.put('/es/roles/' + id, data).then(({ data }) => data),
    createRole: data =>
      // data : { name, slug, description, privileges }
      instance.put('/es/roles/', data).then(({ data }) => data),
    deleteRole: id => instance.delete('/es/roles/' + id).then(({ data }) => data)
  }
  /// Employee api
  employee = {
    getEmployees: params => instance.get(`/es/employees`, { params }).then(({ data }) => data),
    createEmployee: params => instance.post('/es/employees', params).then(({ data }) => data),
    updateEmployee: (employeeId, values) => instance.put(`/es/employees/${employeeId}`, values).then(({ data }) => data),
    deleteEmployee: employeeId => instance.delete(`/es/employees/${employeeId}`).then(({ data }) => data),
    activeEmployee: employeeId => instance.put(`/es/employees/${employeeId}/active`).then(({ data }) => data),
    deactiveEmployee: employeeId => instance.put(`/es/employees/${employeeId}/deactive`).then(({ data }) => data)
  }
  /// Department api
  department = {
    getDepartments: params => instance.get(`/es/departments`, { params }).then(({ data }) => data),
    createDepartment: params => instance.post(`/es/departments`, params).then(({ data }) => data),
    updateDepartment: (id, values) => instance.put(`/es/departments/${id}`, values).then(({ data }) => data),
    deleteDepartment: id => instance.delete(`/es/departments/${id}`).then(({ data }) => data)
  }
  /// Organization api
  organization = {
    getOrganizations: params => instance.get(`/es/organizations`, { params }).then(({ data }) => data),
    createOrganization: params => instance.post(`/es/organizations`, params).then(({ data }) => data),
    updateOrganization: (id, values) => instance.put(`/es/organizations/${id}`, values).then(({ data }) => data),
    deleteOrganization: id => instance.delete(`/es/organizations/${id}`).then(({ data }) => data)
  }
}

export default new EmployeeService()
