import PageTabsLayout from 'layout/PageTabsLayout'
import residentModule from './residentModule'

const residentRoutes = [
  residentModule.pages.ResidentNewsDetail,
  residentModule.pages.ResidentDetail,
  {
    exact: false,
    path: '/residents',
    component: PageTabsLayout,
    routes: [residentModule.pages.ResidentListing]
  }
]

export default residentRoutes
