import {
  ADD_STAFF_NEWS,
  ADD_STAFF_NEWS_FAILURE,
  ADD_STAFF_NEWS_SUCCESS,
  EDIT_STAFF_NEWS,
  EDIT_STAFF_NEWS_FAILURE,
  EDIT_STAFF_NEWS_SUCCESS,
  GET_STAFF_NEWS_ID,
  GET_STAFF_NEWS_ID_FAILURE,
  GET_STAFF_NEWS_ID_SUCCESS,
  GET_STAFF_NEWS,
  GET_STAFF_NEWS_FAILURE,
  GET_STAFF_NEWS_SUCCESS
} from "../actions/staffNewsActions";

const StaffNewsReducer = function (state = {}, action) {
    switch (action.type) {
        case GET_STAFF_NEWS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_STAFF_NEWS_SUCCESS: {
            return {
                ...state,
                news: action.payload,
                loading: false
            };
        }
        case GET_STAFF_NEWS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_STAFF_NEWS_ID: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_STAFF_NEWS_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                newData: action.payload
            };
        }
        case GET_STAFF_NEWS_ID_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case ADD_STAFF_NEWS: {
            return {
                ...state,
                loading: true
            };
        }
        case ADD_STAFF_NEWS_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case ADD_STAFF_NEWS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case EDIT_STAFF_NEWS: {
            return {
                ...state,
                loading: true
            };
        }
        case EDIT_STAFF_NEWS_SUCCESS: {
            return {
                ...state,
                loading: false,
                news: state.news
            };
        }
        case EDIT_STAFF_NEWS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        default: {
            return state;
        }
    }
}

export default StaffNewsReducer;