import {
    ARRIVE_VISITOR,
    ARRIVE_VISITOR_FAILURE,
    ARRIVE_VISITOR_SUCCESS,
    GET_VISITOR,
    GET_VISITOR_FAILURE,
    GET_VISITOR_SUCCESS,
    GET_VISITORS,
    GET_VISITORS_SUCCESS,
    LEFT_VISITOR,
    LEFT_VISITOR_FAILURE,
    LEFT_VISITOR_SUCCESS,
    REVOKE_VISITOR,
    REVOKE_VISITOR_FAILURE,
    REVOKE_VISITOR_SUCCESS,
    FIND_VISITOR,
    FIND_VISITOR_SUCCESS,
    FIND_VISITOR_FAILURE

} from "../actions/visitorActions";

const VisitorReducer = function(state = {}, action) {
    switch (action.type) {
        case GET_VISITORS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_VISITORS_SUCCESS: {
            return {
                ...state,
                visitors: action.payload,
                loading: false
            };
        }
        case GET_VISITOR: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_VISITOR_SUCCESS: {
            return {
                ...state,
                loading: false,
                visitor: action.payload
            };
        }
        case GET_VISITOR_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case FIND_VISITOR: {
            return {
                ...state,
                loading: true
            };
        }
        case FIND_VISITOR_SUCCESS: {
            return {
                ...state,
                loading: false,
                visitorPayLoad  : action.payload
            };
        }
        case FIND_VISITOR_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case REVOKE_VISITOR: {
            return {
                ...state,
                loading: true
            };
        }
        case REVOKE_VISITOR_SUCCESS: {
            return {
                ...state,
                loading: false,
                visitor: {
                    ...state.visitor,
                    state: 'REVOKED'
                }
            };
        }
        case REVOKE_VISITOR_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case ARRIVE_VISITOR: {
            return {
                ...state,
                loading: true
            };
        }
        case ARRIVE_VISITOR_SUCCESS: {
            return {
                ...state,
                loading: false,
                visitor: {
                    ...state.visitor,
                    state: 'ARRIVED'
                }
            };
        }
        case ARRIVE_VISITOR_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case LEFT_VISITOR: {
            return {
                ...state,
                loading: true
            };
        }
        case LEFT_VISITOR_SUCCESS: {
            return {
                ...state,
                loading: false,
                visitor: {
                    ...state.visitor,
                    state: 'LEFT'
                }
            };
        }
        case LEFT_VISITOR_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        default: {
            return state;
        }
    }
};

export default VisitorReducer;
