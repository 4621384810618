import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import PageTabsLayout from 'layout/PageTabsLayout'
import { lazy } from 'react'

const configRoutes = [
  {
    exact: false,
    path: '/configs',
    component: PageTabsLayout,
    routes: [
      {
        label: 'config.field.categoryFee.FEE',
        path: '/configs/fee',
        component: lazy(() => import('./pages/ConfigFees')),
        actions: eventEmitter => ({
          actions: [
            {
              key: 'register',
              label: 'common.button.add',
              style: 'primary',
              icon: <PlusOutlined />,
              onClick: () => eventEmitter.emit('layout', ['onCreate', { type: 'fee' }])
            }
          ]
        })
      },
      {
        label: 'config.field.categoryFee.PAYMENT',
        path: '/configs/payment',
        component: lazy(() => import('./pages/ConfigPayment')),
        actions: eventEmitter => ({
          actions: [
            {
              key: 'save',
              label: 'common.button.save',
              style: 'primary',
              icon: <EditOutlined />,
              onClick: () => eventEmitter.emit('layout', ['onSave'])
            }
          ]
        })
      },
    ]
  }
]

export default configRoutes
