import {lazy} from "react";

const AdsList = lazy(() => import("./list/AdsList"));
const AdsDetail = lazy(() => import("./detail/AdsDetail"));

const adsRoutes = [

    {
        path: "/ads/:id",
        component: AdsDetail
    },
    {
        path: "/ads",
        component: AdsList
    }
];

export default adsRoutes;
