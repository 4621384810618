import instance from "./axiosInstance";

class AdsService {

    getAds = (apartmentId, state, page, size, sort, order) => {
        return instance
            .get(  "/rs/staff/ads/apartment/" + apartmentId, {
                params: {
                    state: state,
                    page: page,
                    size: size,
                    sort: sort,
                    order: order
                }
            })
            .then(({data}) => {
                return data;
            });
    };

    createAds = (data) => {
        return instance
            .post(  "/rs/ads/staff", {

            })
            .then(({data}) => {
                return data;
            });
    };

    approve = (adsId) => {
        return instance
            .put(  "/rs/staff/ads/approve/" + adsId, {})
            .then(({data}) => {
                return data;
            });
    }

    reject = (adsId) => {
        return instance
            .put(  "/rs/staff/ads/reject/" + adsId, {})
            .then(({data}) => {
                return data;
            });
    }

    getAdsById = (adsId) => {
        return instance
            .get("/rs/staff/ads/" + adsId)
            .then(({data}) => {
                return data
            })
    }

}

export default new AdsService();
