import {error, success} from "./alertActions";
import onboardFormService from "../../services/onboardFormService";

export const GET_FORMS = 'GET_FORMS';
export const GET_FORMS_SUCCESS = 'GET_FORMS_SUCCESS';
export const GET_FORMS_FAILURE = 'GET_FORMS_FAILURE';

export const REGISTER_ONBOARD = 'REGISTER_ONBOARD';
export const REGISTER_ONBOARD_SUCCESS = 'REGISTER_ONBOARD_SUCCESS';
export const REGISTER_ONBOARD_FAILURE = 'REGISTER_ONBOARD_FAILURE';

export function getFormsByType(groupTemplate) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_FORMS
            });
            const data = await onboardFormService.getForms(groupTemplate)
            dispatch({
                type: GET_FORMS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_FORMS_FAILURE
            });
        }
    }
}

export function registerUser(values) {
    return async dispatch => {
        try {
            dispatch({
                type: REGISTER_ONBOARD
            });
            const data = await onboardFormService.registerUser(values)
            dispatch(success("Thông báo!", "Đã tạo thành công."))
            dispatch({
                type: REGISTER_ONBOARD_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: REGISTER_ONBOARD_FAILURE
            });
        }
    }
}

