export const UNIT_TYPE = [
    { value: '', label: '---' },
    { value: 'FLAT', label: 'Chung cư' },
    { value: 'VILLA', label: 'Biệt thự' },
    { value: 'SHOP_HOUSE', label: 'Shop house' }
]

export const WORK_ORDER_STATUS = [
    { value: 'OPEN', label: 'status.OPEN' },
    { value: 'ACK', label: 'status.ACK' },
    { value: 'IN_PROCESS', label: 'status.IN_PROCESS' },
    { value: 'DONE', label: 'status.DONE' },
    { value: 'CANCEL', label: 'status.CANCEL' }
]

export const WORK_ORDER_PRIORITY = [
    { value: 'LOW', label: 'workOrder.priority.LOW' },
    { value: 'MEDIUM', label: 'workOrder.priority.MEDIUM' },
    { value: 'HIGH', label: 'workOrder.priority.HIGH' },
    { value: 'URGENT', label: 'workOrder.priority.URGENT' }
]

export const WORK_ORDER_STATUS_OPTIONS = [
    { value: '', label: 'Tất cả' },
    { value: 'OPEN', label: 'Tạo mới' },
    { value: 'ACK', label: 'Đã đồng ý' },
    { value: 'IN_PROCESS', label: 'Đang xử lý' },
    { value: 'DONE', label: 'Hoàn thành' },
    { value: 'REOPEN', label: 'Mở lại' },
    { value: 'CANCEL', label: 'Đã huỷ' }
]

export const WORK_ORDER_PRIORITY_OPTIONS = [
    { value: '', label: 'Tất cả' },
    { value: 'LOW', label: 'Thấp' },
    { value: 'MEDIUM', label: 'Bình thường' },
    { value: 'HIGH', label: 'Cao' }
]

export const WORK_ORDER_DUE_DATE = [
    { value: '', label: 'Tất cả' },
    { value: 'today', label: 'Hôm nay' },
    { value: 'yesterday', label: 'Hôm qua' },
    { value: 'this_week', label: 'Tuần này' },
    { value: 'this_month', label: 'Tháng này' }
]

export const GROUP_GOOD_SERVICE_TYPE = [
    { value: 'BBQ', label: 'BBQ' },
    { value: 'GYM', label: 'Gym' },
    { value: 'SWIMMING_POOL', label: 'Hồ bơi' },
    { value: 'PARKING', label: 'Bãi xe' },
    { value: 'BILLER', label: 'Hoá đơn' },
    { value: 'CLEAN_UP', label: 'Vệ sinh' },
    { value: 'HELPER', label: 'Giúp việc' },
    { value: 'OTHERS', label: 'Khác' }
]

export const GOOD_SERVICE_TYPE = [
    { value: 'MONTH', label: 'Tháng' },
    { value: 'YEAR', label: 'Năm' },
    { value: 'WEEK', label: 'Tuần' },
    { value: 'DAY', label: 'Ngày' },
    { value: 'HOUR', label: 'Giờ' },
    { value: 'TIME', label: 'Số lần' }
]

export const ADS_STATE = [
    { value: '', label: 'Tất cả' },
    { value: 'PENDING_REVIEW', label: 'Chờ duyệt' },
    { value: 'ONLINE', label: 'Đã duyệt' },
    { value: 'REMOVED', label: 'Đã xoá' },
    { value: 'REJECTED', label: 'Từ chối' },
    { value: 'PAID', label: 'Đã thanh toán' }
]

export const NOTIFICATION_CATEGORY = {
    ANNOUNCEMENT: 'ANNOUNCEMENT',
    PROMOTION: 'PROMOTION',
    COMMENT: 'COMMENT',
    WORK_ORDER: 'WORK_ORDER',
    SURVEY: 'SURVEY',
    BILL_REMIND: 'BILL_REMIND',
    NEWS: 'NEWS',
    SUBSCRIPTION_REJECT: 'SUBSCRIPTION_REJECT',
    GOODS_EXPORT: 'GOODS_EXPORT',
    GOODS_RECEIPT: 'GOODS_RECEIPT',
    CONTRACT_ADJUST: 'CONTRACT_ADJUST',
    ONBOARD: 'ONBOARD_NEW',
    MESSAGES_SUPPORT: 'MESSAGES_SUPPORT'
}

export const IMAGE_OPTION = {
    buttons: {
        backgroundColor: 'rgba(140, 94, 88, 0.8)',
        iconColor: 'rgba(241, 191, 152, 0.7)'
    },
    settings: {
        transitionSpeed: 1000,
        transitionTimingFunction: 'linear'
    },
    thumbnails: {
        thumbnailsSize: ['120px', '100px'],
        thumbnailsOpacity: 0.4
    },
    caption: {
        captionColor: 'rgba(241, 191, 152, 1)'
    },
    progressBar: {
        size: '4px',
        backgroundColor: 'rgba(255, 237, 225, 1)',
        fillColor: '#AF9AB2'
    }
}

export const ID_TYPE = [
    { value: 'CMND', label: 'CMND' },
    { value: 'CCCD', label: 'CCCD' },
    { value: 'PASSPORT', label: 'PASSPORT' }
]

export const customStyles = {
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999
    }),
    control: (base) => ({
        ...base
    })
}

export const DEFAULT_PARAMS = {
    page: 0,
    size: 50
}

export const DEFAULT_MAX_PARAMS = {
    page: 0,
    size: 1000
}

export const ELECTRIC_METER_TYPE = [
    {
        value: 'WATER',
        label: 'type.WATER',
        units: ['NORMAL', 'SHOP_HOUSE', 'SWIMPOOL', 'CAR_PARKING', 'GYM']
    },
    {
        value: 'ELECTRIC',
        label: 'type.ELECTRIC',
        units: ['SHOP_HOUSE', 'SWIMPOOL', 'CAR_PARKING', 'GYM']
    }
]

export const RESIDENT_UNIT_TYPE = [
    { value: 'OWNER', label: 'residentUnits.type.OWNER' },
    { value: 'TENANT', label: 'residentUnits.type.TENANT' },
    { value: 'RELATIVE_OF_THE_OWNER', label: 'residentUnits.type.RELATIVE_OF_THE_OWNER' },
    { value: 'CO_OWNER', label: 'residentUnits.type.CO_OWNER' },
    { value: 'OTHER', label: 'residentUnits.type.OTHER' }
]

export const PARKING_STATUS = [
    { value: 'ACTIVE', label: 'status.ACTIVE' },
    { value: 'INACTIVE', label: 'status.INACTIVE' }
]

export const INCOME_OUTCOME_TYPE = [
    { value: 'INCOME', label: 'field.incomeOutcomeType.INCOME' },
    { value: 'OUTCOME', label: 'field.incomeOutcomeType.OUTCOME' }
]
export const INCOME_OUTCOME_GROUP = [
    { value: 'VISITING', label: 'receiptPayment.field.incomeOutcomeGroup.VISITING' },
    { value: 'PARTNER', label: 'receiptPayment.field.incomeOutcomeGroup.PARTNER' },
    { value: 'UNIT', label: 'receiptPayment.field.incomeOutcomeGroup.UNIT' },
    { value: 'SERVICE', label: 'receiptPayment.field.incomeOutcomeGroup.SERVICE' },
    { value: 'BILL', label: 'receiptPayment.field.incomeOutcomeGroup.BILL' }
]

export const UNIT_STATUS = [
    { value: 'NOT_YET', label: 'status.NOT_YET', color: '#f5222d', allowFilter: true },
    { value: 'RENTED', label: 'status.RENTED', color: '#52c41a', allowFilter: true },
    { value: 'OWNER', label: 'status.OWNER', color: '#52c41a', allowFilter: true },
    { value: 'BOUGHT', label: 'status.BOUGHT', color: '#52c41a', allowFilter: false }
]

export const UNIT_STATUS_KEY = {
    NOT_YET: { color: '#f5222d' },
    RENTED: { color: '#52c41a' },
    OWNER: { color: '#52c41a' },
    BOUGHT: { color: '#52c41a' }
}

export const BILL_MONTHLY_STATUS = [
    { value: 'PENDING', label: 'bill.field.status.PENDING' },
    { value: 'APPROVED', label: 'bill.field.status.APPROVED' },
    { value: 'LOCKED', label: 'bill.field.status.LOCKED' },
    { value: 'CANCELED', label: 'bill.field.status.CANCELED' }
]

export const CATEGORY_FEES = [
    { value: 'FEE', label: 'config.field.categoryFee.FEE' },
    // { value: 'NOTIFICATION', label: 'config.field.categoryFee.NOTIFICATION' },
    { value: 'BANK', label: 'config.field.categoryFee.BANK', isSecurity: true }
]

export const CONFIG_UNIT_TYPES = [
    { value: 'GENERAL', label: 'config.field.unitType.GENERAL', categoryFee: 'FEE' },
    { value: 'NORMAL', label: 'config.field.unitType.NORMAL', categoryFee: 'FEE' },
    { value: 'OFFICETEL', label: 'config.field.unitType.OFFICETEL', categoryFee: 'FEE' },
    { value: 'SHOP_HOUSE', label: 'config.field.unitType.SHOP_HOUSE', categoryFee: 'FEE' },
    { value: 'TTTM', label: 'config.field.unitType.TTTM', categoryFee: 'FEE' },
    { value: 'SWIMPOOL', label: 'config.field.unitType.SWIMPOOL', categoryFee: 'FEE' },
    { value: 'GYM', label: 'config.field.unitType.GYM', categoryFee: 'FEE' },
    { value: 'CAR_PARKING', label: 'config.field.unitType.CAR_PARKING', categoryFee: 'FEE' }
]

export const UNIT_TYPES = [
    { value: 'NORMAL', label: 'config.field.unitType.NORMAL' },
    { value: 'OFFICETEL', label: 'config.field.unitType.OFFICETEL' },
    { value: 'SHOP_HOUSE', label: 'config.field.unitType.SHOP_HOUSE' },
    { value: 'TTTM', label: 'config.field.unitType.TTTM' },
    { value: 'SWIMPOOL', label: 'config.field.unitType.SWIMPOOL' },
    { value: 'GYM', label: 'config.field.unitType.GYM' },
    { value: 'CAR_PARKING', label: 'config.field.unitType.CAR_PARKING' }
]

export const RECEIPT_PAYMENTS = [
    { value: 'TM', label: 'receiptPayment.paymentType.TM' },
    { value: 'CK', label: 'receiptPayment.paymentType.CK' }
]
export const RECEIPT_PAYMENT_LABELS = {
    TM: 'receiptPayment.paymentType.TM',
    CK: 'receiptPayment.paymentType.CK'
}

export const PARKING_LOT_TYPE = [
    { value: 'TWO_WHEELS', label: 'parkingLot.field.type.TWO_WHEELS', requestTypes: ['DAY_TIME', 'OVERNIGHT', 'GUESS'] },
    { value: 'FOUR_WHEELS', label: 'parkingLot.field.type.FOUR_WHEELS', requestTypes: ['DAY_TIME', 'OVERNIGHT', 'GUESS'] },
    { value: 'ELECTRIC_TWO_WHEELS', label: 'parkingLot.field.type.ELECTRIC_TWO_WHEELS', requestTypes: ['DAY_TIME', 'OVERNIGHT', 'GUESS'] },
    { value: 'ELECTRIC_BICYCLE', label: 'parkingLot.field.type.ELECTRIC_BICYCLE', requestTypes: ['DAY_TIME', 'OVERNIGHT', 'GUESS'] },
    { value: 'CHARGE_ELECTRIC_MOTORBIKE', label: 'parkingLot.field.type.CHARGE_ELECTRIC_MOTORBIKE', requestTypes: ['DAY_TIME'] },
    { value: 'CHARGE_ELECTRIC_BICYCLE', label: 'parkingLot.field.type.CHARGE_ELECTRIC_BICYCLE', requestTypes: ['DAY_TIME'] },
    { value: 'BICYCLE', label: 'parkingLot.field.type.BICYCLE', requestTypes: ['DAY_TIME', 'OVERNIGHT', 'GUESS'] },
    { value: 'ELECTRIC_BICYCLE', label: 'parkingLot.field.type.ELECTRIC_BICYCLE', requestTypes: ['DAY_TIME', 'OVERNIGHT', 'GUESS'] }
]

export const PARKING_REQUEST_TYPE = [
    {
        value: 'DAY_TIME',
        label: 'parkingSubscription.field.requestType.DAY_TIME'
    },
    {
        value: 'OVERNIGHT',
        label: 'parkingSubscription.field.requestType.OVERNIGHT'
    },
    {
        value: 'GUESS',
        label: 'parkingSubscription.field.requestType.GUESS'
    }
]

export const NEWS_CATEGORY = [
    { value: 1, label: 'news.field.category.1' },
    { value: 2, label: 'news.field.category.2' },
    { value: 3, label: 'news.field.category.3' }
]

export const SUBSCRIPTION_STATE = [
    { value: 'PENDING', label: 'services.state.PENDING', isFilter: false },
    { value: 'PENDING_APPROVED', label: 'services.state.PENDING_APPROVED' },
    { value: 'ACTIVE', label: 'services.state.ACTIVE' },
    { value: 'EXPIRE', label: 'services.state.EXPIRE' },
    { value: 'INVALID', label: 'services.state.INVALID' },
    { value: 'REJECTED', label: 'services.state.REJECTED' }
]

export const RECEIPTPAYMENT_STATUS = [
    { value: 'PENDING', label: 'receiptPayment.status.PENDING' },
    { value: 'FINISH', label: 'receiptPayment.status.FINISH' },
    { value: 'CANCEL', label: 'receiptPayment.status.CANCEL' }
]

export const RESIDENT_IS_LOCKED = [
    { value: 'true', label: 'resident.field.isLocked.true' },
    { value: 'false', label: 'resident.field.isLocked.false' }
]

export const VISITOR_TYPE = [
    { value: 'GUEST', label: 'visitor.field.type.GUEST' },
    { value: 'WORKER', label: 'visitor.field.type.WORKER' }
]
export const VISITOR_ID_TYPE = [
    { value: 'CMND', label: 'visitor.field.idType.CMND' },
    { value: 'CCCD', label: 'visitor.field.idType.CCCD' },
    { value: 'PASSPORT', label: 'visitor.field.idType.PASSPORT' },
    { value: 'LICENSE_PLATE', label: 'visitor.field.idType.LICENSE_PLATE' },
    { value: 'EMPLOYEE_ID', label: 'visitor.field.idType.EMPLOYEE_ID' },
    { value: 'OTHER', label: 'visitor.field.idType.OTHER' }
]

export const VISITOR_STATE = [
    { value: 'NEW', label: 'visitor.field.state.NEW' },
    { value: 'ARRIVED', label: 'visitor.field.state.ARRIVED' },
    { value: 'LEFT', label: 'visitor.field.state.LEFT' },
    { value: 'REVOKED', label: 'visitor.field.state.REVOKED' }
]

export const RESIDENT_TYPE = [
    { value: 'INDIVIDUAL', label: 'resident.field.type.INDIVIDUAL' },
    { value: 'COMPANY', label: 'resident.field.type.COMPANY' },
    { value: 'PARTNER', label: 'resident.field.type.PARTNER' }
]

export const RESIDENT_UUID_TYPE = [
    { value: 'natID', label: 'resident.field.uuidType.natID' },
    { value: 'passID', label: 'resident.field.uuidType.passID' },
    { value: 'childID', label: 'resident.field.uuidType.childID' }
]

export const MIME_TYPES = {
    // Text
    TXT: 'text/plain',
    HTML: 'text/html',
    CSS: 'text/css',
    JS: 'text/javascript',

    // Image
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    GIF: 'image/gif',
    SVG: 'image/svg+xml',
    WEBP: 'image/webp',

    // Audio
    MP3: 'audio/mpeg',
    WAV: 'audio/wav',
    OGG: 'audio/ogg',

    // Video
    MP4: 'video/mp4',
    WEBM: 'video/webm',

    // Application
    PDF: 'application/pdf',
    JSON: 'application/json',
    XML: 'application/xml',

    // Microsoft Office
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    PPT: 'application/vnd.ms-powerpoint',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',

    // Compressed files
    ZIP: 'application/zip',
    RAR: 'application/x-rar-compressed',

    // Default binary
    BIN: 'application/octet-stream'
}
