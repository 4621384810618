import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuth } from './authContext'

const ApartmentContext = React.createContext({})

export const ApartmentProvider = ({ children }) => {
    const [loaded, setLoaded] = useState(false)
    const [apartment, setApartment] = useState(null)
    const { user } = useAuth()

    useEffect(() => {
        const apartmentSaved = localStorage.getItem('apartment')
        if (apartmentSaved) {
            setApartment(JSON.parse(apartmentSaved))
        }
        setLoaded(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!user) {
            localStorage.removeItem('apartment')
            localStorage.removeItem('apartment_id')
            localStorage.removeItem('apartment_type')
            setApartment(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const selectApartment = (apartment) => {
        localStorage.setItem('apartment', JSON.stringify(apartment))
        localStorage.setItem('apartment_id', apartment.id)
        localStorage.setItem('apartment_type', 'APARTMENT')
        setApartment(apartment)
    }

    if (!loaded) {
        return <></>
    }

    return <ApartmentContext.Provider value={{ apartment, selectApartment }}>{children}</ApartmentContext.Provider>
}

export const ApartmentGuard = withRouter(({ children }) => {
    const { apartment } = useApartment()

    if (!!apartment) {
        return <>{children}</>
    }

    return <Redirect to="/session/choose-building" />
})

export const useApartment = () => React.useContext(ApartmentContext)

export default ApartmentContext
