import instance from "./axiosInstance";

class OnboardFormService {

    getForms = (groupTemplate) => {
        return instance
            .get(  "/as/templateOnboarding", {
                params: {
                    groupTemplate: groupTemplate
                }
            })
            .then(({data}) => {
                return data;
            });
    }

    registerUser = (values) => {
        return instance
            .post("/as/onboard/createUser", {
                "email": values.email,
                "fullName": values.fullName,
                "phoneNumber": values.phoneNumber,
                "companyName": values.companyName,
                "companyAddress": values.companyAddress,
                "companyRequest": values.companyRequest,
                "companyRequestFloor": values.companyRequestFloor
            })
            .then(({data}) => {
                return data;
            })
    }


}

export default new OnboardFormService();
