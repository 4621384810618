import {lazy} from "react";

const AssetList = lazy(() => import("./list/AssetList"));
const AssetDetail = lazy(() => import("./detail/AssetDetail"));

const assetRoutes = [
    {
        path: "/asset-managements/:id",
        component: AssetDetail
    },
    {
        path: "/asset-managements",
        component: AssetList
    }
];

export default assetRoutes;
