import { PlusOutlined } from '@ant-design/icons'
import { lazy } from 'react'

const employeeModule = {
  pages: {
    EmployeeListing: {
      label: 'breadcrumb.employeeList',
      path: '/d/employees',
      component: lazy(() => import('./pages/EmployeeListing')),
      data: {  },
      actions: eventEmitter => ({
        actions: [
          {
            key: 'create',
            label: 'common.button.create',
            style: 'primary',
            icon: <PlusOutlined />,
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}])
          }
        ]
      })
    }
  }
}

export default employeeModule
