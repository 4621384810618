import React, { useEffect, useState } from 'react'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import { IntlProvider } from 'react-intl'
import VietNam from '../i18n/vi.json'
import English from '../i18n/en.json'
import EnglishAntd from 'antd/es/locale/en_US'
import VietNamAntd from 'antd/es/locale/vi_VN'
import * as yupLocales from 'yup-locales'
import * as yup from 'yup'
import rootRoutes from '../routers/rootRoutes'
import { AuthProvider } from './authContext'
import { ApartmentProvider } from './apartmentContext'

const AppContext = React.createContext({})

// setLocale
const mapLocale = {
    en: 'en',
    'en-US': 'en',
    vi: 'vi',
    'vi-VN': 'vi'
}

const mapMessages = {
    en: {
        intl: { ...EnglishAntd, ...English },
        moment: () => moment.locale('en'),
        yup: () => yup.setLocale(yupLocales.en)
    },
    vi: {
        intl: { ...VietNamAntd, ...VietNam },
        moment: () => moment.locale('vi'),
        yup: () => yup.setLocale(yupLocales.vi)
    }
}

export const AppProvider = ({ children }) => {
    const [locale, setLocale] = useState(localStorage.getItem('language') ?? 'vi')

    function selectLanguage(validLocate) {
        setLocale(validLocate)
        mapMessages[validLocate].moment()
        mapMessages[validLocate].yup()
    }

    useEffect(() => {
        const language = navigator.language
        const curLocale = localStorage.getItem('language') ?? mapLocale[language] ?? 'vi'

        selectLanguage(curLocale)
        !localStorage.getItem('language') && localStorage.setItem('language', curLocale)
    }, [])

    return (
        <AppContext.Provider value={{ routes: rootRoutes, locale, selectLanguage }}>
            <ConfigProvider locale={mapMessages[locale].intl}>
                <IntlProvider messages={mapMessages[locale].intl} locale={locale}>
                    <AuthProvider>
                        <ApartmentProvider>{children}</ApartmentProvider>
                    </AuthProvider>
                </IntlProvider>
            </ConfigProvider>
        </AppContext.Provider>
    )
}

export default AppContext
