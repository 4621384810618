import PageTabsLayout from 'layout/PageTabsLayout'
import { lazy } from 'react'

const Report001 = lazy(() => import('./report001/ReportList'))
const Report002 = lazy(() => import('./report002/ReportList'))
const Report003 = lazy(() => import('./report003/ReportList'))

const reportRoutes = [
  {
    path: '/reports/report001',
    component: Report001
  },
  {
    path: '/reports/report002',
    component: Report002
  },
  {
    path: '/reports/report003',
    component: Report003
  },
  {
    exact: false,
    path: '/reports',
    component: PageTabsLayout,
    routes: [
      {
        label: 'report.generic',
        path: '/reports/generic',
        component: lazy(() => import('./generic/ReportList'))
      },
      {
        label: 'report.finance',
        path: '/reports/finance',
        component: lazy(() => import('./pages/Finance'))
      }
    ]
  }
]

export default reportRoutes
