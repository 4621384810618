import {lazy} from "react";

const WarehouseList = lazy(() => import("./warehouse/list/WarehouseList"));
const GoodsList = lazy(() => import("./config-good/list/GoodsList"));
const GoodsReceiptList = lazy(() => import("./goods-receipt/list/GoodsReceiptList"));
const GoodsReceiptDetail = lazy(() => import("./goods-receipt/detail/GoodsReceiptDetail"));
const WarehouseItemList = lazy(() => import("./item/list/WarehouseItemList"));
const GoodsExportDetail = lazy(() => import("./goods-export/detail/GoodsExportDetail"));
const GoodsExportList = lazy(() => import("./goods-export/list/GoodsExportList"));

const warehouseRoutes = [
    {
        path: "/warehouses/items",
        component: WarehouseItemList
    },
    {
        path: "/warehouses",
        component: WarehouseList
    },
    {
        path: "/config/goods",
        component: GoodsList
    },
    {
        path: "/goods-receipts/:id",
        component: GoodsReceiptDetail
    },
    {
        path: "/goods-receipts",
        component: GoodsReceiptList
    },
    {
        path: "/goods-exports/:id",
        component: GoodsExportDetail
    },
    {
        path: "/goods-exports",
        component: GoodsExportList
    }
];

export default warehouseRoutes;
