import instance from './axiosInstance'

class ReceiptPaymentService {
  getReceiptPaymentList = parameters => {
    return instance
      .get('/ss/income-expense', {
        params: { ...parameters }
      })
      .then(({ data }) => {
        return data
      })
  }

  createReceiptPayment = params => {
    return instance.post('/ss/income-expense', params).then(({ data }) => {
      const _params = {
        apartmentId: data.apartmentId,
        incomeOutcomeGroup: data.incomeOutcomeGroup,
        unitId: params.incomeOutcomeGroup === 'UNIT' ? params.unitId : null,
        page: 0,
        size: 10
      }
      return this.getReceiptPaymentList(_params)
    })
  }

  updateReceiptPayment = (id, params) => {
    return instance.put('/ss/income-expense/' + id, params).then(({ data }) => {
      const _params = {
        apartmentId: data.apartmentId,
        incomeOutcomeGroup: data.incomeOutcomeGroup,
        unitId: params.incomeOutcomeGroup === 'UNIT' ? params.unitId : null,
        page: 0,
        size: 10
      }
      return this.getReceiptPaymentList(_params)
    })
  }

  cancelReceiptPayment = row => {
    return instance.put('/ss/income-expense/cancel/' + row.id).then(({ data }) => {
      const _params = {
        apartmentId: row.apartmentId,
        incomeOutcomeGroup: row.incomeOutcomeGroup,
        page: 0,
        size: 10
      }
      return this.getReceiptPaymentList(_params)
    })
  }

  downloadReceiptPayment = id => {
    return instance
      .get('/ss/income-expense/export/' + id, {
        responseType: 'arraybuffer'
      })
      .then(({ data }) => {
        return data
      })
  }

  approveReceiptPayment = id => instance.put(`/ss/income-expense/bill/update/${id}`).then(({ data }) => data)

  createBillReceipt = (params, monthlyBillId) =>
    instance.post(`/ss/income-expense/monthly-bill/create/${monthlyBillId}`, params).then(({ data }) => data)

  cancelBillReceipt = id => {
    return instance.put('/ss/income-expense/bill/cancel/' + id).then(({ data }) => {
      return data
    })
  }

  // Liệt kê tất cả income_outcome
  getReceiptPayments = params => {
    return instance
      .get('/ss/bill-monthly/income/filter', {
        params: {
          apartmentId: params?.apartmentId,
          incomeOutcomeType: params?.incomeOutcomeType,
          unitId: params?.unitId,
          page: params?.page ?? 0,
          size: params?.size ?? 10
        }
      })
      .then(({ data }) => data)
  }

  getUnitForBill = params => instance.get('/ss/bill-monthly/resident-no-bill', { params }).then(({ data }) => data)
}

export default new ReceiptPaymentService()
