import { PlusOutlined } from '@ant-design/icons'
import { getApartmentId } from '@utils'
import { lazy } from 'react'

const accountingModule = {
  pages: {
    BillDetail: {
      path: '/a/bills/:id',
      getLink: ({ id }) => `/a/bills/${id}`,
      component: lazy(() => import('./bill/pages/BillDetail'))
    },
    BillListing: {
      label: 'nav.reports.bills',
      path: '/a/bills',
      component: lazy(() => import('./bill/pages/BillList')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'createByUnits',
            label: 'bill.button.create',
            icon: <PlusOutlined />,
            style: 'primary',
            onClick: () => eventEmitter.emit('page', ['onCreateBills', {}])
          }
        ]
      })
    },
    FinancialListing: {
      label: 'breadcrumb.expenditures',
      path: '/a/financials',
      component: lazy(() => import('./financial/list/FinancialList')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'register',
            label: 'common.button.create',
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}]),
            style: 'shine'
          }
        ]
      })
    },
    ReceiptPaymentListing: {
      label: 'finance.receipt_payment',
      path: '/a/receipt-payment',
      component: lazy(() => import('./receipt-payment/pages/ReceiptPaymentList')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'create.payment',
            label: 'receiptPayment.button.create.payment',
            style: 'danger',
            icon: <PlusOutlined />,
            onClick: () => eventEmitter.emit('layout', ['onCreate', { incomeOutcomeType: 'OUTCOME', apartmentId: getApartmentId() }])
          },
          {
            key: 'create.receipt',
            label: 'receiptPayment.button.create.receipt',
            style: 'primary',
            icon: <PlusOutlined />,
            onClick: () => eventEmitter.emit('layout', ['onCreate', { incomeOutcomeType: 'INCOME', apartmentId: getApartmentId() }])
          }
        ]
      })
    },
  }
}

export default accountingModule
