import instance from './axiosInstance'
import { hashString } from 'react-hash-string'
import { browserName, deviceType, osName, osVersion } from 'react-device-detect'

const authService = {
    login: (username, password) =>
        instance
            .post('/es/auth/login', {
                username: username,
                password: password,
                deviceInfo: {
                    deviceId: hashString(username),
                    deviceType: deviceType,
                    appVersion: '1.0.0',
                    deviceModel: browserName,
                    language: 'en',
                    os: osName,
                    osVersion: osVersion,
                    notificationToken: ''
                }
            })
            .then(({ data }) => data),
    refreshToken: (refreshToken) => instance.post('/es/auth/refresh', { refreshToken }).then(({ data }) => data),
    sendOtp: (email) => instance.post('/es/employees/password/forgot-password', { email }).then(({ data }) => data),
    // values : {email, otp, password, confirmPassword}
    resetPassword: (values) => instance.post('/es/employees/password/reset', values).then(({ data }) => data),
    // values : {currentPassword, newPassword, confirmNewPassword}
    changePassword: (values) => instance.put('/es/employees/password/change', values).then(({ data }) => data),
    changeAvatar: (profileUrl) => instance.put('/es/employees/avatar', { profileUrl }).then(({ data }) => data),
    me: () => instance.get('/es/employees/me').then(({ data }) => data)
}

export default authService
