import surveyService from "../../services/surveyService";
import {error, success} from "./alertActions";
import history from "../../@history";

export const GET_SURVEYS = 'GET_SURVEYS';
export const GET_SURVEYS_SUCCESS = 'GET_SURVEYS_SUCCESS';
export const GET_SURVEYS_FAILURE = 'GET_SURVEYS_FAILURE';

export const CREATE_SURVEY = 'CREATE_SURVEY';
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS';
export const CREATE_SURVEY_FAILURE = 'CREATE_SURVEY_FAILURE';

export const GET_SURVEY_REPORT = 'GET_SURVEY_REPORT';
export const GET_SURVEY_REPORT_SUCCESS = 'GET_SURVEY_REPORT_SUCCESS';
export const GET_SURVEY_REPORT_FAILURE = 'GET_SURVEY_REPORT_FAILURE';

export const GET_SURVEY = 'GET_SURVEY';
export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const GET_SURVEY_FAILURE = 'GET_SURVEY_FAILURE';

export function getSurveys() {
    return async (dispatch) => {
        dispatch({
            type: GET_SURVEYS
        });
        try {
            const data = await surveyService.getSurveys();
            dispatch({
                type: GET_SURVEYS_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: GET_SURVEYS_FAILURE
            });
        }
    }
}

export function createSurvey({title, isSignatureRequire, questionGroups, residentIds}) {
    return async (dispatch) => {
        dispatch({
            type: CREATE_SURVEY
        });
        try {
            const data = await surveyService.createSurveys(title, isSignatureRequire, questionGroups, residentIds);
            dispatch(success("Thông báo!", "Đã tạo thành công."))
            history.push({pathname: "/surveys"});
            dispatch({
                type: CREATE_SURVEY_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: CREATE_SURVEY_FAILURE
            });
        }
    }
}

export function getSurveyReport(surveyId) {
    return async (dispatch) => {
        dispatch({
            type: GET_SURVEY_REPORT
        });
        try {
            const data = await surveyService.getSurveyReport(surveyId);
            dispatch({
                type: GET_SURVEY_REPORT_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: GET_SURVEY_REPORT_FAILURE
            });
        }
    }
}

export function getSurvey(surveyId) {
    return async (dispatch) => {
        dispatch({
            type: GET_SURVEY_REPORT
        });
        try {
            const data = await surveyService.getSurvey(surveyId);
            dispatch({
                type: GET_SURVEY_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: GET_SURVEY_FAILURE
            });
        }
    }
}