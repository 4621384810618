import instance from "./axiosInstance";

class ExternalContractService {

    getExternalContracts = (params) => {
        return instance
            .get(  "/cs/external-contracts", {
                params: {
                    apartmentId: params.apartmentId,
                    page: params.page,
                    size: params.size,
                    keyword: params.keyword
                }
            })
            .then(({data}) => {
                return data;
            });
    }

    createExternalContract = (values) => {
        return instance
            .post("/cs/external-contracts", {
                 name: values.name,
                 code: values.code,
                 signedDate: values.signedDate && values.signedDate.format("YYYY-MM-DD") ,
                 effectiveDate: values.effectiveDate && values.effectiveDate.format("YYYY-MM-DD"),
                expiredDate: values.expiredDate && values.expiredDate.format("YYYY-MM-DD"),
                 apartmentId: values.apartmentId,
                 vendor: values.vendor,
                 depositAmount: values.depositAmount,
                 content: values.content,
                 documents: values.documents,
                 receiptDate:values.receiptDate,
                 serviceFee: values.serviceFee,
                 periodMonth: values.periodMonth,
                serviceName:values.serviceName,
                location:values.location,
                type: values.type
            })
            .then(({data}) => {
                return data;
            })
    };

    updateExternalContract = (contractId, values) => {
        return instance
            .put("/cs/external-contracts/" + contractId, {
                name: values.name,
                code: values.code,
                signedDate: values.signedDate && values.signedDate.format("YYYY-MM-DD") ,
                effectiveDate: values.effectiveDate && values.effectiveDate.format("YYYY-MM-DD"),
                expiredDate: values.expiredDate && values.expiredDate.format("YYYY-MM-DD"),
                apartmentId: values.apartmentId,
                vendor: values.vendor,
                depositAmount: values.depositAmount,
                content: values.content,
                documents: values.documents,
                receiptDate:values.receiptDate,
                serviceFee: values.serviceFee,
                periodMonth: values.periodMonth,
                serviceName:values.serviceName,
                location:values.location,
                type: values.type
            })
            .then(({data}) => {
                return data;
            })
    };

    deleteExternalContract = (contractId) => {
        return instance
            .delete(  "/cs/external-contracts/" + contractId, {})
            .then(({data}) => {
                return data;
            });
    }

    // getDocuments = (contractId) => {
    //     return instance
    //         .get(  "/cs/contracts/" + contractId + "/documents" , {})
    //         .then(({data}) => {
    //             return data;
    //         });
    // }

    getExternalContractDetail = (contractId) => {
        return instance
            .get(  "/cs/external-contracts/" + contractId   , {})
            .then(({data}) => {
                return data;
            });
    }

    // addDocument = (contractId, documents) => {
    //     return instance
    //         .post(  "/cs/contracts/" + contractId + "/documents" , documents)
    //         .then(({data}) => {
    //             return data;
    //         });
    // }
    //
    // removeDocument = (contractId, documentId) => {
    //     return instance
    //         .delete(  "/cs/contracts/" + contractId + "/documents/" + documentId, {})
    //         .then(({data}) => {
    //             return data;
    //         });
    // }

    // getContractByUnit = (unitId) => {
    //     return instance
    //         .get(  "/cs/contracts/unit/" + unitId, {
    //         })
    //         .then(({data}) => {
    //             return data;
    //         });
    // }
}

export default new ExternalContractService();
