import {
    CREATE_CONTRACT,
    CREATE_CONTRACT_FAILURE,
    CREATE_CONTRACT_SUCCESS,
    GET_CONTRACT,
    GET_CONTRACT_FAILURE,
    GET_CONTRACT_SUCCESS,
    GET_CONTRACTS,
    GET_CONTRACTS_FAILURE,
    GET_CONTRACTS_SUCCESS,
    GET_DOCUMENTS,
    GET_DOCUMENTS_FAILURE,
    GET_DOCUMENTS_SUCCESS,
    REMOVE_DOCUMENT,
    REMOVE_DOCUMENT_FAILURE,
    REMOVE_DOCUMENT_SUCCESS,
    UPDATE_CONTRACT,
    UPDATE_CONTRACT_FAILURE,
    UPDATE_CONTRACT_SUCCESS
} from "../actions/contractActions";

const ContractReducer = function(state = {}, action) {
    switch (action.type) {
        case GET_CONTRACTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_CONTRACTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                contracts: action.payload
            };
        }
        case GET_CONTRACTS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_CONTRACT: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_CONTRACT_SUCCESS: {
            return {
                ...state,
                loading: false,
                contract: action.payload
            };
        }
        case GET_CONTRACT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_DOCUMENTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                documents: action.payload
            };
        }
        case GET_DOCUMENTS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case REMOVE_DOCUMENT: {
            return {
                ...state,
                loading: true
            };
        }
        case REMOVE_DOCUMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                documents: {
                    ...state.documents,
                    data: state.documents.data.filter(x => x.id !== action.payload)
                }
            };
        }
        case REMOVE_DOCUMENT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case UPDATE_CONTRACT: {
            return {
                ...state,
                error: true,
                loading: true
            };
        }
        case UPDATE_CONTRACT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                contract: action.payload
            };
        }
        case UPDATE_CONTRACT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case CREATE_CONTRACT: {
            return {
                ...state,
                loading: true,
                error: true
            };
        }
        case CREATE_CONTRACT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                contract: action.payload
            };
        }
        case CREATE_CONTRACT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        default: {
            return state;
        }
    }
};

export default ContractReducer;
