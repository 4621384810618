import instance from "./axiosInstance";

class OnboardApprovalService {

    getRequests = (apartmentId, params) => {
        return instance
            .get("/as/onboard", {
                params: {
                    apartmentId: apartmentId,
                    companyId: params.companyId,
                    templateOnboardingId: params.templateOnboardingId,
                    keyword: params.keyword,
                    fromDate: params.fromDate,
                    toDate: params.toDate,
                    page: params.page,
                    size: params.size
                }
            })
            .then(({data}) => {
                return data;
            })
    }

    rejectOnboard = (id, reason) => {
        return instance.put("/as/onboard/reject/"+id,{
            reason: reason
        })
    }

    approve = (id, parkingLotId) => {
        return instance
            .put("/as/onboard/approve/"+id,{
                "parkingLotId": parkingLotId
            })
    }

    getRequestById = (id) => {
        return instance
            .get(  "/as/onboard/"+id)
            .then(({data}) => {
                return data;
            }).catch(data=>{
                return data;
            });
    }


}

export default new OnboardApprovalService();
