import {lazy} from "react";
const FilesManagement = lazy(() => import("./list/FilesManagement"));


const filesManagementRouters = [
    {
        path: "/files",
        component: FilesManagement
    }
]

export default filesManagementRouters;
