import {
    GET_SHOP,
    GET_SHOP_FAILURE,
    GET_SHOP_SUCCESS,
    GET_SHOPS,
    GET_SHOPS_FAILURE,
    GET_SHOPS_SUCCESS,
    LOCK_SHOP,
    LOCK_SHOP_FAILURE,
    LOCK_SHOP_SUCCESS,
    UNLOCK_SHOP,
    UNLOCK_SHOP_FAILURE,
    UNLOCK_SHOP_SUCCESS,
    UPDATE_SHOP,
    UPDATE_SHOP_FAILURE,
    UPDATE_SHOP_SUCCESS
} from "../actions/shopActions";

const ShopReducer = function(state = {}, action) {
    switch (action.type) {
        case GET_SHOPS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_SHOPS_SUCCESS: {
            return {
                ...state,
                shops: action.payload,
                loading: false
            };
        }
        case GET_SHOPS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_SHOP: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_SHOP_SUCCESS: {
            return {
                ...state,
                shop: action.payload,
                loading: false
            };
        }
        case GET_SHOP_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case LOCK_SHOP:
            return {
                ...state,
                loading: true
            };
        case LOCK_SHOP_SUCCESS:
            return {
                ...state,
                shops: {
                    ...state.shops,
                    data: state.shops.data.map((shop, ind) => shop.id === action.payload ?
                        {...shop, lock: true} : shop
                    )
                },
                loading: false
            };
        case LOCK_SHOP_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case UNLOCK_SHOP:
            return {
                ...state,
                loading: true
            };
        case UNLOCK_SHOP_SUCCESS:
            return {
                ...state,
                shops: {
                    ...state.shops,
                    data: state.shops.data.map((shop, ind) => shop.id === action.payload ?
                        {...shop, lock: false} : shop
                    )
                },
                loading: false
            };
        case UNLOCK_SHOP_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case UPDATE_SHOP:
            return {
                ...state,
                loading: true
            };
        case UPDATE_SHOP_SUCCESS:
            let data = action.payload.data;
            return {
                ...state,
                shops: {
                    ...state.shops,
                    data: state.shops.data.map((shop, ind) => shop.id === action.payload.id ?
                        {...shop,
                            phoneNumber: data.phoneNumber,
                            location: data.location,
                            description: data.description
                        } : shop
                    )
                },
                loading: false
            };
        case UPDATE_SHOP_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        default: {
            return state;
        }
    }
};

export default ShopReducer;
