import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import history from '@history'
import { Loading } from 'common'
import SimpleReactLightbox from 'simple-react-lightbox'
import { AppProvider } from './context/appContext'
import { RecoilRoot } from 'recoil'
import recoilPersist from 'recoil-persist'
import { Store } from './redux/Store'
// import Auth from './auth/Auth'
import rootRoutes from './routers/rootRoutes'
import { renderRoutes } from 'react-router-config'
import './styles/app/app.scss'

const { RecoilPersist, updateState } = recoilPersist([], {
    key: 'recoil-persist',
    storage: sessionStorage
})

function App() {
    return (
        <RecoilRoot initializeState={updateState}>
            <RecoilPersist />
            <AppProvider>
                <SimpleReactLightbox>
                    <Provider store={Store}>
                        <Suspense fallback={<Loading />}>
                            <Router history={history}>{renderRoutes(rootRoutes)}</Router>
                        </Suspense>
                    </Provider>
                </SimpleReactLightbox>
            </AppProvider>
        </RecoilRoot>
    )
}

export default App
