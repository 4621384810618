import externalContractService from "../../services/extContractService";
import {error, success} from "./alertActions";

export const GET_EXTCONTRACTS = 'GET_EXTCONTRACTS';
export const GET_EXTCONTRACTS_SUCCESS = 'GET_EXTCONTRACTS_SUCCESS';
export const GET_EXTCONTRACTS_FAILURE = 'GET_EXTCONTRACTS_FAILURE';

export const GET_EXTCONTRACT = 'GET_EXTCONTRACT';
export const GET_EXTCONTRACT_SUCCESS = 'GET_EXTCONTRACT_SUCCESS';
export const GET_EXTCONTRACT_FAILURE = 'GET_EXTCONTRACT_FAILURE';

export const CREATE_EXTCONTRACT = 'CREATE_EXTCONTRACT';
export const CREATE_EXTCONTRACT_SUCCESS = 'CREATE_EXTCONTRACT_SUCCESS';
export const CREATE_EXTCONTRACT_FAILURE = 'CREATE_EXTCONTRACT_FAILURE';

export const UPDATE_EXTCONTRACT = 'UPDATE_EXTCONTRACT';
export const UPDATE_EXTCONTRACT_SUCCESS = 'UPDATE_EXTCONTRACT_SUCCESS';
export const UPDATE_EXTCONTRACT_FAILURE = 'UPDATE_EXTCONTRACT_FAILURE';

export const DELETE_EXTCONTRACT = 'DELETE_EXTCONTRACT';
export const DELETE_EXTCONTRACT_SUCCESS = 'DELETE_EXTCONTRACT_SUCCESS';
export const DELETE_EXTCONTRACT_FAILURE = 'DELETE_EXTCONTRACT_FAILURE';


export function getExternalContracts( params) {
    return dispatch => {
        dispatch({
            type: GET_EXTCONTRACTS
        });
        externalContractService
            .getExternalContracts( params)
            .then(data => {
                return dispatch({
                    type: GET_EXTCONTRACTS_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                return dispatch({
                    type: GET_EXTCONTRACTS_FAILURE
                });
            });
    };
}

export function getExternalContract(contractId) {
    return dispatch => {
        dispatch({
            type: GET_EXTCONTRACT
        });
        externalContractService
            .getExternalContractDetail(contractId)
            .then(data => {
                return dispatch({
                    type: GET_EXTCONTRACT_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                return dispatch({
                    type: GET_EXTCONTRACT_FAILURE
                });
            });
    };
}

export function createExternalContract(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_EXTCONTRACT
            });
            const data = await externalContractService.createExternalContract(values)
            dispatch(success("Thông báo!", "Đã tạo thành công."))
            dispatch({
                type: CREATE_EXTCONTRACT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: CREATE_EXTCONTRACT_FAILURE
            });
        }
    }
}

export function updateExternalContract(contractId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_EXTCONTRACT
            });
            const data = await externalContractService.updateExternalContract(contractId, values)
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch({
                type: UPDATE_EXTCONTRACT_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: UPDATE_EXTCONTRACT_FAILURE
            });
        }
    }
}

export function deleteExternalContract(contractId) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_EXTCONTRACT
            });
            const data = await externalContractService.deleteExternalContract(contractId)
            dispatch(success("Thông báo!", "Đã xoá thành công."))
            dispatch({
                type: DELETE_EXTCONTRACT_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: DELETE_EXTCONTRACT_FAILURE
            });
        }
    }
}

// export function getDocuments(contractId) {
//     return async dispatch => {
//         try {
//             dispatch({
//                 type: GET_DOCUMENTS
//             });
//             const data = await contractService.getDocuments(contractId)
//             dispatch({
//                 type: GET_DOCUMENTS_SUCCESS,
//                 payload: data
//             });
//             return data;
//         } catch (err) {
//             dispatch({
//                 type: GET_DOCUMENTS_FAILURE
//             });
//         }
//     }
// }
//
// export function addDocument(contractId, documents) {
//     return async dispatch => {
//         try {
//             dispatch({
//                 type: ADD_DOCUMENT
//             });
//             dispatch(success("Add!", "Your document has been added."))
//             const data = await contractService.addDocument(contractId, documents)
//             dispatch({
//                 type: ADD_DOCUMENT_SUCCESS
//             });
//             return data;
//         } catch (err) {
//             dispatch(error("Add!", "Your document hasn't been added."))
//             dispatch({
//                 type: ADD_DOCUMENT_FAILURE
//             });
//         }
//     }
// }

// export function removeDocument(contractId, documentId) {
//     return async dispatch => {
//         try {
//             dispatch({
//                 type: REMOVE_DOCUMENT
//             });
//             const data = await contractService.removeDocument(contractId, documentId)
//             dispatch(success("Thông báo!", "Đã xoá thành công."))
//             dispatch({
//                 type: REMOVE_DOCUMENT_SUCCESS,
//                 payload: documentId
//             });
//             return data;
//         } catch (err) {
//             dispatch(error("Thông báo!", err.response.data.message))
//             dispatch({
//                 type: REMOVE_DOCUMENT_FAILURE
//             });
//         }
//     }
// }

// export function getContractByUnit(unitId) {
//     return async dispatch => {
//         try {
//             dispatch({
//                 type: GET_EXTCONTRACT
//             });
//             const data = await contractService.getContractByUnit(unitId)
//             dispatch({
//                 type: GET_EXTCONTRACT_SUCCESS,
//                 payload: data
//             });
//             return data;
//         } catch (err) {
//             dispatch({
//                 type: GET_EXTCONTRACT_FAILURE
//             });
//         }
//     }
// }
//
//

