import {
    CREATE_CONFIG_FREE,
    CREATE_CONFIG_FREE_FAILURE,
    CREATE_CONFIG_FREE_SUCCESS,
    GET_CONFIG_FEE,
    GET_CONFIG_FEE_FAILURE,
    GET_CONFIG_FEE_SUCCESS,
    GET_CONFIG_FEES,
    GET_CONFIG_FEES_FAILURE,
    GET_CONFIG_FEES_SUCCESS,
    GET_CONFIGURATION_BY_KEY,
    GET_CONFIGURATION_BY_KEY_FAILURE,
    GET_CONFIGURATION_BY_KEY_SUCCESS,
    UPDATE_CONFIG_FREE,
    UPDATE_CONFIG_FREE_FAILURE,
    UPDATE_CONFIG_FREE_SUCCESS
} from "../actions/configActions";

const ConfigReducer = function(state = {}, action) {
    switch (action.type) {
        case CREATE_CONFIG_FREE: {
            return {
                ...state,
                loading: true
            };
        }
        case CREATE_CONFIG_FREE_SUCCESS: {
            return {
                ...state,
                loading: false,
                configFees: {
                    ...state.configFees,
                    data: [
                        action.payload,
                        ...state.configFees.data
                    ]
                }
            };
        }
        case CREATE_CONFIG_FREE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_CONFIG_FEES: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_CONFIG_FEES_SUCCESS: {
            return {
                ...state,
                loading: false,
                configFees: action.payload
            };
        }
        case GET_CONFIG_FEES_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case UPDATE_CONFIG_FREE: {
            return {
                ...state,
                loading: true
            };
        }
        case UPDATE_CONFIG_FREE_SUCCESS: {
            return {
                ...state,
                loading: false,
                configFees: {
                    ...state.configFees,
                    data: state.configFees.data.map(x => x.id === action.payload.id
                        ?
                        {...x, serviceName: action.payload.serviceName,
                            level: action.payload.level,
                            coefficient: action.payload.coefficient,
                            serviceFee: action.payload.serviceFee}
                        : x
                    )
                }
            };
        }
        case UPDATE_CONFIG_FREE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_CONFIG_FEE: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_CONFIG_FEE_SUCCESS: {
            return {
                ...state,
                loading: false,
                configFee: action.payload
            };
        }
        case GET_CONFIG_FEE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_CONFIGURATION_BY_KEY: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_CONFIGURATION_BY_KEY_SUCCESS: {
            return {
                ...state,
                loading: false,
                configurationByKey: action.payload
            };
        }
        case GET_CONFIGURATION_BY_KEY_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        default: {
            return state;
        }
    }
};

export default ConfigReducer;
