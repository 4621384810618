import PageTabsLayout from 'layout/PageTabsLayout'
import subscriptionModule from './subscriptionModules'

const subscriptionRoutes = [
  {
    exact: false,
    path: '/s',
    component: PageTabsLayout,
    routes: [subscriptionModule.pages.GoodsService, subscriptionModule.pages.GroupService, subscriptionModule.pages.GoodServiceList, subscriptionModule.pages.SubscriptionList]
  }
]

export default subscriptionRoutes
