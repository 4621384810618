import {
    APPROVE_BILL,
    APPROVE_BILL_FAILURE,
    APPROVE_BILL_SUCCESS,
    CREATE_BILL,
    CREATE_BILL_FAILURE,
    CREATE_BILL_SUCCESS,
    CREATE_GOOD_SERVICE,
    CREATE_GOOD_SERVICE_FAILURE,
    CREATE_GOOD_SERVICE_SUCCESS,
    CREATE_GROUP_GOODS_SERVICE,
    CREATE_GROUP_GOODS_SERVICE_FAILURE,
    CREATE_GROUP_GOODS_SERVICE_SUCCESS,
    CREATE_RESOURCE_SERVICE,
    CREATE_RESOURCE_SERVICE_FAILURE,
    CREATE_RESOURCE_SERVICE_SUCCESS,
    DOWNLOAD_BILL_MONTHLY,
    DOWNLOAD_BILL_MONTHLY_FAILURE,
    DOWNLOAD_BILL_MONTHLY_SUCCESS,
    GET_BILL_MONTHLY, GET_BILL_MONTHLY_FAILURE,
    GET_BILL_MONTHLY_SUCCESS,
    GET_BILLS_MONTHLY,
    GET_BILLS_MONTHLY_FAILURE,
    GET_BILLS_MONTHLY_SUCCESS,
    GET_GOOD_SERVICE,
    GET_GOOD_SERVICE_FAILURE,
    GET_GOOD_SERVICE_SUCCESS, GET_GOOD_SERVICES, GET_GOOD_SERVICES_FAILURE, GET_GOOD_SERVICES_SUCCESS,
    GET_GROUP_GOOD_SERVICE,
    GET_GROUP_GOOD_SERVICE_FAILURE,
    GET_GROUP_GOOD_SERVICE_SUCCESS,
    GET_GROUP_GOODS_SERVICE,
    GET_GROUP_GOODS_SERVICE_FAILURE,
    GET_GROUP_GOODS_SERVICE_SUCCESS,
    GET_RESOURCE_SERVICES,
    GET_RESOURCE_SERVICES_FAILURE,
    GET_RESOURCE_SERVICES_SUCCESS,
    GET_SUBSCRIPTIONS,
    GET_SUBSCRIPTIONS_FAILURE,
    GET_SUBSCRIPTIONS_SUCCESS,
    PAID_BILL,
    PAID_BILL_FAILURE,
    PAID_BILL_SUCCESS,
    REMIND_BILL,
    REMIND_BILL_FAILURE,
    REMIND_BILL_SUCCESS,
    UPDATE_GOOD_SERVICE,
    UPDATE_GOOD_SERVICE_FAILURE,
    UPDATE_GOOD_SERVICE_SUCCESS,
    UPDATE_GROUP_GOODS_SERVICE,
    UPDATE_GROUP_GOODS_SERVICE_FAILURE,
    UPDATE_GROUP_GOODS_SERVICE_SUCCESS
} from "../actions/subscriptionActions";

const SubscriptionReducer = function(state = {}, action) {
    switch (action.type) {
        case GET_GROUP_GOODS_SERVICE:
            return {
                ...state,
                loading: true
            };
        case GET_GROUP_GOODS_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                groupGoodsService: action.payload
            };
        case GET_GROUP_GOODS_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CREATE_GROUP_GOODS_SERVICE:
            return {
                ...state,
                loading: true
            };
        case CREATE_GROUP_GOODS_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                groupGoodsService: [
                    ...state.groupGoodsService,
                    action.payload
                ]
            };
        case CREATE_GROUP_GOODS_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case UPDATE_GROUP_GOODS_SERVICE:
            return {
                ...state,
                loading: true
            };
        case UPDATE_GROUP_GOODS_SERVICE_SUCCESS:
            let data = action.payload;
            return {
                ...state,
                loading: false,
                groupGoodsService: state.groupGoodsService.map((group, ind) => group.id === data.id ?
                    {...group, galleries: data.galleries, imageUrl: data.imageUrl, description: data.description, displayAmount: data.displayAmount}
                    : group
                )
            };
        case UPDATE_GROUP_GOODS_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_GROUP_GOOD_SERVICE:
            return {
                ...state,
                loading: true
            };
        case GET_GROUP_GOOD_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                groupGoodService: action.payload
            };
        case GET_GROUP_GOOD_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CREATE_GOOD_SERVICE:
            return {
                ...state,
                loading: true
            };
        case CREATE_GOOD_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                groupGoodService: {
                    ...state.groupGoodService,
                    goodsServices: [
                        ...state.groupGoodService.goodsServices,
                        action.payload
                    ]
                }
            };
        case CREATE_GOOD_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case UPDATE_GOOD_SERVICE:
            return {
                ...state,
                loading: true
            };
        case UPDATE_GOOD_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                groupGoodService: {
                    ...state.groupGoodService,
                    goodsServices: state.groupGoodService.goodsServices.map(service => service.id === action.payload.id ?
                        {...service,
                            imageUrl: action.payload.imageUrl,
                            description: action.payload.description,
                            fee: action.payload.fee,
                            name: action.payload.name
                        } : service
                    )
                }
            };
        case UPDATE_GOOD_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_GOOD_SERVICE:
            return {
                ...state,
                loading: true
            };
        case GET_GOOD_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                goodService: action.payload
            };
        case GET_GOOD_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_RESOURCE_SERVICES:
            return {
                ...state,
                loading: true
            };
        case GET_RESOURCE_SERVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                resourceServices: action.payload.data
            };
        case GET_RESOURCE_SERVICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CREATE_RESOURCE_SERVICE:
            return {
                ...state,
                loading: true
            };
        case CREATE_RESOURCE_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                resourceServices: [
                    ...state.resourceServices,
                    action.payload
                ]
            };
        case CREATE_RESOURCE_SERVICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_SUBSCRIPTIONS:
            return {
                ...state,
                loading: true
            };
        case GET_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                subscriptions: action.payload
            };
        case GET_SUBSCRIPTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_BILLS_MONTHLY:
            return {
                ...state,
                loading: true
            };
        case GET_BILLS_MONTHLY_SUCCESS:
            return {
                ...state,
                loading: false,
                bills: action.payload
            };
        case GET_BILLS_MONTHLY_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CREATE_BILL:
            return {
                ...state,
                loading: true
            };
        case CREATE_BILL_SUCCESS:
            return {
                ...state,
                loading: false,
                billLog: action.payload
            };
        case CREATE_BILL_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case DOWNLOAD_BILL_MONTHLY:
            return {
                ...state,
                loading: true
            };
        case DOWNLOAD_BILL_MONTHLY_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case DOWNLOAD_BILL_MONTHLY_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_BILL_MONTHLY:
            return {
                ...state,
                loading: true
            };
        case GET_BILL_MONTHLY_SUCCESS:
            return {
                ...state,
                loading: false,
                bill: action.payload
            };
        case GET_BILL_MONTHLY_FAILURE:
             return {
                 ...state,
                 loading: false
             };
        case APPROVE_BILL:
            return {
                ...state,
                loading: true,

            };
        case APPROVE_BILL_SUCCESS:
            return {
                ...state,
                loading: false,
                bill: {
                    ...state.bill,
                    monthlyBills: state.bill.monthlyBills.map(bill => bill.id === action.payload ?
                        {...bill,
                            status: "APPROVED"
                        } : bill
                    )
                }
            };
        case APPROVE_BILL_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case PAID_BILL:
            return {
                ...state,
                loading: true,

            };
        case PAID_BILL_SUCCESS:
            return {
                ...state,
                loading: false,
                bill: {
                    ...state.bill,
                    monthlyBills: state.bill.monthlyBills.map(bill => bill.id === action.payload.billId ?
                        {...bill,
                            status: action.payload.bill.status,
                            paidAmount: action.payload.bill.paidAmount
                        } : bill
                    )
                }
            };
        case PAID_BILL_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case REMIND_BILL:
            return {
                ...state,
                loading: true,

            };
        case REMIND_BILL_SUCCESS:
            return {
                ...state,
                loading: false,
                bills: {
                    ...state.bills,
                    data: state.bills.data.map(bill => bill.id === action.payload ?
                        {...bill,
                            remindTimes: bill.remindTimes + 1
                        } : bill
                    )
                }
            };
        case REMIND_BILL_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_GOOD_SERVICES:
            return {
                ...state,
                loading: true,

            };
        case GET_GOOD_SERVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                goodServices: action.payload
            };
        case GET_GOOD_SERVICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state
    }
};

export default SubscriptionReducer;