import visitorService from "../../services/visitorService";
import {error, success} from "./alertActions";

export const GET_VISITORS = 'GET_VISITORS';
export const GET_VISITORS_SUCCESS = 'GET_VISITORS_SUCCESS';
export const GET_VISITORS_FAILURE = 'GET_VISITORS_FAILURE';

export const GET_VISITOR = 'GET_VISITOR';
export const GET_VISITOR_SUCCESS = 'GET_VISITOR_SUCCESS';
export const GET_VISITOR_FAILURE = 'GET_VISITOR_FAILURE';

export const FIND_VISITOR = 'FIND_VISITOR';
export const FIND_VISITOR_SUCCESS = 'FIND_VISITOR_SUCCESS';
export const FIND_VISITOR_FAILURE = 'FIND_VISITOR_FAILURE';
export const EDIT_VISITOR = 'EDIT_VISITOR';
export const EDIT_VISITOR_SUCCESS = 'EDIT_VISITOR_SUCCESS';
export const EDIT_VISITOR_FAILURE = 'GET_VISITOR_FAILURE';

export const REVOKE_VISITOR = 'REVOKE_VISITOR';
export const REVOKE_VISITOR_SUCCESS = 'REVOKE_VISITOR_SUCCESS';
export const REVOKE_VISITOR_FAILURE = 'REVOKE_VISITOR_FAILURE';

export const ARRIVE_VISITOR = 'ARRIVE_VISITOR';
export const ARRIVE_VISITOR_SUCCESS = 'ARRIVE_VISITOR_SUCCESS';
export const ARRIVE_VISITOR_FAILURE = 'ARRIVE_VISITOR_FAILURE';

export const LEFT_VISITOR = 'LEFT_VISITOR';
export const LEFT_VISITOR_SUCCESS = 'LEFT_VISITOR_SUCCESS';
export const LEFT_VISITOR_FAILURE = 'LEFT_VISITOR_FAILURE';

export const CREATE_VISITOR = 'CREATE_VISITOR';
export const CREATE_VISITOR_SUCCESS = 'CREATE_VISITOR_SUCCESS';
export const CREATE_VISITOR_FAILURE = 'CREATE_VISITOR_FAILURE';

export function getVisitorRequests(apartmentId, params) {

    return async (dispatch) => {
        dispatch({
            type: GET_VISITORS
        });

        try {
            const response = await visitorService.getVisitorRequests(apartmentId, params);
            const data = await response.data;
            dispatch({
                type: GET_VISITORS_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: GET_VISITORS_FAILURE
            });
        }
    }
}

export function getVisitorRequestById(visitorId) {
    return async (dispatch) => {
        dispatch({
            type: GET_VISITOR
        });

        try {
            const response = await visitorService.getVisitorRequestById(visitorId);
            const data = await response.data;
            dispatch({
                type: GET_VISITOR_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: GET_VISITOR_FAILURE
            });
        }
    }
}
export function getVisitorRequestByPhoneNumber(apartmentId, phoneNumber) {
    return async (dispatch) => {
        dispatch({
            type: FIND_VISITOR
        });

        try {
            const response = await visitorService.getVisitorRequestByPhoneNumber(apartmentId, phoneNumber);
            const data = await response.data;
            dispatch({
                type: FIND_VISITOR_SUCCESS,
                payload: data
            });
            return data;
        } catch (error) {
            dispatch({
                type: FIND_VISITOR_FAILURE
            });
        }
    }
}

export function revoke(visitorId) {
    return async (dispatch) => {
        dispatch({
            type: REVOKE_VISITOR
        });
        try {
            await visitorService.revoke(visitorId);
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch({
                type: REVOKE_VISITOR_SUCCESS
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: REVOKE_VISITOR_FAILURE
            });
        }
    }
}

export function leave(values) {
    return async (dispatch) => {
        dispatch({
            type: LEFT_VISITOR
        });
        try {
            await visitorService.leave(values);
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch(getVisitorRequestById(values.visitorRequestId))
            dispatch({
                type: LEFT_VISITOR_SUCCESS
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: REVOKE_VISITOR_FAILURE
            });
        }
    }
}

export function arrive(values) {
    return async (dispatch) => {
        dispatch({
            type: ARRIVE_VISITOR
        });
        try {
            await visitorService.arrive(values);
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch(getVisitorRequestById(values.visitorRequestId))
            dispatch({
                type: ARRIVE_VISITOR_SUCCESS
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: ARRIVE_VISITOR_FAILURE
            });
        }
    }
}

export function createVisitorRequest(values) {
    return async (dispatch) => {
        dispatch({
            type: CREATE_VISITOR
        });
        try {
            console.log(values);
            const data = await visitorService.createVisitorRequest(values);
            dispatch(success("Thông báo!", "Đã tạo thành công."))
            dispatch(getVisitorRequests(localStorage.getItem("apartment_id"), {}))
            dispatch({
                type: CREATE_VISITOR_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: CREATE_VISITOR_FAILURE
            });
        }
    }
}
export function updateVisitorRequest(values) {
    return async (dispatch) => {
        dispatch({
            type: EDIT_VISITOR
        });
        try {
            console.log(values);
            const data = await visitorService.updateVisitorRequest(values);
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch(getVisitorRequestById(values.id));
            dispatch({
                type: EDIT_VISITOR_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: EDIT_VISITOR_FAILURE
            });
        }
    }
}