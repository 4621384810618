import {lazy} from "react";

const PermissionList = lazy(() => import("./list/PermissionList"));

const permissionRoutes = [

    {
        path: "/permissions",
        component: PermissionList
    }
];

export default permissionRoutes;
