import instance from './axiosInstance'

class ConfigService {
  createConfigFee = values => {
    return instance
      .post('/ss/apartment-fee-config', {
        apartmentId: values.apartmentId,
        serviceName: values.serviceName,
        level: values.level,
        coefficient: values.coefficient,
        serviceFee: values.serviceFee,
        taxRate: values.taxRate
      })
      .then(({ data }) => {
        return data
      })
  }

  updateConfigFee = (configId, values) => {
    return instance
      .put('/ss/apartment-fee-config/' + configId, {
        serviceName: values.serviceName,
        level: values.level,
        coefficient: values.coefficient,
        serviceFee: values.serviceFee,
        taxRate: values.taxRate
      })
      .then(({ data }) => {
        return data
      })
  }

  getConfigFee = configId => {
    return instance.get('/ss/apartment-fee-config/' + configId, {}).then(({ data }) => {
      return data
    })
  }

  getConfigurationByKey = configKey => {
    return instance
      .get('/conf/configuration/v1/displayConfig/getConfigByKey', {
        params: { configKey: configKey }
      })
      .then(({ data }) => {
        return data
      })
  }

  // Những API sau có dùng
  // 06/09/2024

  getConfigFees = apartmentId => instance.get('/ss/apartment-fee-config/apartment/' + apartmentId, {}).then(({ data }) => data)

  
}

export default new ConfigService()
