import jwtAuthService from "../../services/jwtAuthService";
import {logoutUser, setUserData} from "./UserActions";
import history from "@history.js";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";

export const OTP_SEND = "OTP_SEND";
export const OTP_SEND_SUCCESS = "OTP_SEND_SUCCESS";
export const OTP_SEND_FAILED = "OTP_SEND_FAILED";

export const REFRESHING_TOKEN = "REFRESHING_TOKEN";
export const TOKEN_REFRESHED = "TOKEN_REFRESHED";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED"

export function login({username, password}) {
    return async dispatch => {
        try {
            dispatch({
                type: LOGIN_LOADING
            });
            const data = await jwtAuthService.login(username, password)
            dispatch(setUserData(data));
            history.push({pathname: "/session/choose-building"});
            dispatch({
                type: LOGIN_SUCCESS
            });
            return data;
        } catch (err) {
            dispatch({
                type: LOGIN_ERROR,
                payload: err.response.data
            });
            return err;
        }
    }
}

export function sendOtp(email) {
    return async dispatch => {
        try {
            dispatch({
                type: OTP_SEND
            });
            const data = await jwtAuthService.sendOtp(email)
            history.push({pathname: "/session/change-password"});
            dispatch({
                type: OTP_SEND_SUCCESS,
                payload: email
            });
            return data;
        } catch (err) {
            dispatch({
                type: OTP_SEND_FAILED
            });
        }
    }
}

export function resetPassword(payload) {
    return async dispatch => {
        try {
            dispatch({
                type: RESET_PASSWORD
            });
            const data = await jwtAuthService.resetPassword(payload)
            history.push({pathname: "/session/signin"});
            dispatch({
                type: RESET_PASSWORD_SUCCESS,
            });
            return data;
        } catch (err) {
            dispatch({
                type: RESET_PASSWORD_FAILED
            });
        }
    }
}

export function refreshToken() {
    return dispatch => {
        dispatch({
            type: REFRESHING_TOKEN
        });

        jwtAuthService
            .refreshToken(localStorage.getItem("refresh_token"))
            .then(user => {
                dispatch(setUserData(user));

                return dispatch({
                    type: TOKEN_REFRESHED
                });
            })
            .catch(error => {
                dispatch(logoutUser());
            });
    }
}
