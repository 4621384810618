import adsService from "../../services/adsService";
import {error, success} from "./alertActions";

export const GET_ADS_LIST = 'GET_ADS_LIST';
export const GET_ADS_LIST_SUCCESS = 'GET_ADS_LIST_SUCCESS';
export const GET_ADS_LIST_FAILURE = 'GET_ADS_LIST_FAILURE';

export const GET_ADS = 'GET_ADS';
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';
export const GET_ADS_FAILURE = 'GET_ADS_FAILURE';

export const REJECT_ADS = 'REJECT_ADS';
export const REJECT_ADS_SUCCESS = 'REJECT_ADS_SUCCESS';
export const REJECT_ADS_FAILURE = 'REJECT_ADS_FAILURE';

export const APPROVE_ADS = 'APPROVE_ADS';
export const APPROVE_ADS_SUCCESS = 'APPROVE_ADS_SUCCESS';
export const APPROVE_ADS_FAILURE = 'APPROVE_ADS_FAILURE';

export const CREATE_ADS = 'CREATE_ADS';
export const CREATE_ADS_SUCCESS = 'CREATE_ADS_SUCCESS';
export const CREATE_ADS_FAILURE = 'CREATE_ADS_FAILURE';


export function getAds(apartmentId, residentId, state, page, size) {
    return dispatch => {
        dispatch({
            type: GET_ADS_LIST
        });
        adsService
            .getAds(apartmentId, residentId, state, page, size)
            .then(data => {
                dispatch({
                    type: GET_ADS_LIST_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                dispatch({
                    type: GET_ADS_LIST_FAILURE
                });
            });
    };
}

export function getAdsById(adsId) {
    return dispatch => {
        dispatch({
            type: GET_ADS
        });
        adsService
            .getAdsById(adsId)
            .then(data => {
                dispatch({
                    type: GET_ADS_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                dispatch({
                    type: GET_ADS_FAILURE
                });
            });
    };
}

export function rejectAds(adsId) {
    return dispatch => {
        dispatch({
            type: REJECT_ADS
        });
        adsService
            .reject(adsId)
            .then(data => {
                dispatch(success("Delete!", "Your apartment has been deleted."))

                return dispatch({
                    type: REJECT_ADS_SUCCESS
                });
            })
            .catch(e => {
                dispatch(error("Delete!", "Your apartment has been deleted."))
                return dispatch({
                    type: REJECT_ADS_FAILURE
                });
            });
    };
}

export function approveAds(adsId) {
    return dispatch => {
        dispatch({
            type: APPROVE_ADS
        });
        adsService
            .approve(adsId)
            .then(data => {
                dispatch(success("Delete!", "Your apartment has been deleted."))

                return dispatch({
                    type: APPROVE_ADS_SUCCESS
                });
            })
            .catch(e => {
                dispatch(error("Delete!", "Your apartment has been deleted."))
                return dispatch({
                    type: APPROVE_ADS_FAILURE
                });
            });
    };
}

export function createAds(data) {
    return dispatch => {
        dispatch({
            type: CREATE_ADS
        });
        adsService
            .createAds(data)
            .then(data => {
                dispatch(success("Delete!", "Your apartment has been deleted."))

                return dispatch({
                    type: CREATE_ADS_SUCCESS
                });
            })
            .catch(e => {
                dispatch(error("Delete!", "Your apartment has been deleted."))
                return dispatch({
                    type: CREATE_ADS_FAILURE
                });
            });
    };
}