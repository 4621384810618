import {
  ADD_NEW,
  ADD_NEW_FAILURE,
  ADD_NEW_SUCCESS,
  EDIT_NEW,
  EDIT_NEW_FAILURE,
  EDIT_NEW_SUCCESS,
  GET_NEW,
  GET_NEW_FAILURE,
  GET_NEW_SUCCESS,
  GET_NEWS,
  GET_NEWS_FAILURE,
  GET_NEWS_SUCCESS,
    NEW_POST_COMMENT,
    NEW_POST_COMMENT_FAILURE,
    NEW_POST_COMMENT_SUCCESS
} from "../actions/newActions";

const NewReducer = function (state = {}, action) {
    switch (action.type) {
        case GET_NEWS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_NEWS_SUCCESS: {
            return {
                ...state,
                news: action.payload,
                loading: false
            };
        }
        case GET_NEWS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_NEW: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_NEW_SUCCESS: {
            return {
                ...state,
                loading: false,
                newData: action.payload
            };
        }
        case GET_NEW_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case ADD_NEW: {
            return {
                ...state,
                loading: true
            };
        }
        case ADD_NEW_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case ADD_NEW_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case EDIT_NEW: {
            return {
                ...state,
                loading: true
            };
        }
        case EDIT_NEW_SUCCESS: {
            return {
                ...state,
                loading: false,
                news: state.news
            };
        }
        case EDIT_NEW_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case NEW_POST_COMMENT: {
            return {
                ...state,
                loading: true
            };
        }
        case NEW_POST_COMMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                newData: action.payload


            };
        }
        case NEW_POST_COMMENT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        default: {
            return state;
        }
    }
}

export default NewReducer;