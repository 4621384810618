import {
    ASSIGN_WORK_ORDER,
    ASSIGN_WORK_ORDER_FAILURE,
    ASSIGN_WORK_ORDER_SUCCESS,
    CHANGE_PRIORITY,
    CHANGE_PRIORITY_FAILURE,
    CHANGE_PRIORITY_SUCCESS,
    CHANGE_STATUS,
    CHANGE_STATUS_FAILURE,
    CHANGE_STATUS_SUCCESS,
    CLONE_TASK,
    CLONE_TASK_FAILURE,
    CLONE_TASK_SUCCESS,
    GET_WORK_ORDER,
    GET_WORK_ORDER_FAILURE,
    GET_WORK_ORDER_SUCCESS,
    GET_WORK_ORDERS,
    GET_WORK_ORDERS_FAILURE,
    GET_WORK_ORDERS_SUCCESS,
    POST_COMMENT,
    POST_COMMENT_FAILURE,
    POST_COMMENT_SUCCESS
} from "../actions/workOrderActions";

const initialState = {
    workOrders: []
};

const ApartmentReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_WORK_ORDERS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_WORK_ORDERS_SUCCESS: {
            return {
                ...state,
                loading: false,
                workOrders: action.payload
            };
        }
        case GET_WORK_ORDERS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_WORK_ORDER: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_WORK_ORDER_SUCCESS: {
            return {
                ...state,
                loading: false,
                workOrder: action.payload
            };
        }
        case GET_WORK_ORDER_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case CHANGE_STATUS: {
            return {
                ...state,
                loading: true
            };
        }
        case CHANGE_STATUS_SUCCESS: {
            return {
                ...state,
                loading: false,
                workOrder: {
                    ...state.workOrder,
                    status: action.payload.data
                }
            };
        }
        case CHANGE_STATUS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case CHANGE_PRIORITY: {
            return {
                ...state,
                loading: true
            };
        }
        case CHANGE_PRIORITY_SUCCESS: {
            return {
                ...state,
                loading: false,
                workOrder: {
                    ...state.workOrder,
                    priority: action.payload.data
                }
            };
        }
        case CHANGE_PRIORITY_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case ASSIGN_WORK_ORDER: {
            return {
                ...state,
                loading: true
            };
        }
        case ASSIGN_WORK_ORDER_SUCCESS: {
            let data = action.payload;
            return {
                ...state,
                loading: false,
                workOrder: {
                    ...state.workOrder,
                    employee: data.employee
                }
            };
        }
        case ASSIGN_WORK_ORDER_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        case POST_COMMENT: {
            return {
                ...state,
                loading: true
            };
        }
        case POST_COMMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                workOrder: {
                    ...state.workOrder,
                    comments: [
                        ...state.workOrder.comments,
                        action.payload
                    ]
                }
            };
        }
        case POST_COMMENT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        case CLONE_TASK: {
            return {
                ...state,
                loading: true
            };
        }
        case CLONE_TASK_SUCCESS: {
            return {
                ...state,
                loading: false,
                cloneTask: action.payload

            };
        }
        case CLONE_TASK_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        default: {
            return state;
        }
    }
};

export default ApartmentReducer;
