import {
    GET_SURVEY,
    GET_SURVEY_FAILURE,
    GET_SURVEY_REPORT,
    GET_SURVEY_REPORT_FAILURE,
    GET_SURVEY_REPORT_SUCCESS,
    GET_SURVEY_SUCCESS,
    GET_SURVEYS,
    GET_SURVEYS_FAILURE,
    GET_SURVEYS_SUCCESS
} from "../actions/surveyActions";

const SurveyReducer = function (state = {}, action) {
    switch (action.type) {
        case GET_SURVEYS:
            return {
                ...state,
                loading: true
            };
        case GET_SURVEYS_SUCCESS:
            return {
                ...state,
                loading: false,
                surveys: action.payload
            };
        case GET_SURVEYS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_SURVEY_REPORT:
            return {
                ...state,
                loading: true
            };
        case GET_SURVEY_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                surveyReport: action.payload.data
            };
        case GET_SURVEY_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_SURVEY:
            return {
                ...state,
                loading: true
            };
        case GET_SURVEY_SUCCESS:
            return {
                ...state,
                loading: false,
                survey: action.payload
            };
        case GET_SURVEY_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state
    }
};

export default SurveyReducer;