import { PlusOutlined } from '@ant-design/icons'
import { lazy } from 'react'

const organizationModule = {
  pages: {
    OrganizationListing: {
      label: 'breadcrumb.organizationList',
      path: '/d/organizations',
      component: lazy(() => import('./pages/OrganizationListing')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'create',
            label: 'common.button.create',
            style: 'primary',
            icon: <PlusOutlined />,
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}]),
          }
        ]
      })
    }
  }
}

export default organizationModule
