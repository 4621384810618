import parkingService from "../../services/parkingService";
import {error, success} from "./alertActions";

export const GET_PARKING_LOTS = 'GET_PARKING_LOTS';
export const GET_PARKING_LOTS_SUCCESS = 'GET_PARKING_LOTS_SUCCESS';
export const GET_PARKING_LOTS_FAILURE = 'GET_PARKING_LOTS_FAILURE';

export const UPDATE_PARKING_LOTS = 'UPDATE_PARKING_LOTS';
export const UPDATE_PARKING_LOTS_SUCCESS = 'UPDATE_PARKING_LOTS_SUCCESS';
export const UPDATE_PARKING_LOTS_FAILURE = 'UPDATE_PARKING_LOTS_FAILURE';

export const GET_PARKING_LOTS_BY_BLOCK = 'GET_PARKING_LOTS_BY_BLOCK';
export const GET_PARKING_LOTS_BY_BLOCK_FAILURE = 'GET_PARKING_LOTS_BY_BLOCK_FAILURE';
export const GET_PARKING_LOTS_BY_BLOCK_SUCCESS = 'GET_PARKING_LOTS_BY_BLOCK_SUCCESS';

export const GET_PARKING_LOT = 'GET_PARKING_LOT';
export const GET_PARKING_LOT_SUCCESS = 'GET_PARKING_LOT_SUCCESS';
export const GET_PARKING_LOT_FAILURE = 'GET_PARKING_LOT_FAILURE';

export const GET_PARKING_SUBSCRIPTION = 'GET_PARKING_SUBSCRIPTION';
export const GET_PARKING_SUBSCRIPTION_SUCCESS = 'GET_PARKING_SUBSCRIPTION_SUCCESS';
export const GET_PARKING_SUBSCRIPTION_FAILURE = 'GET_PARKING_SUBSCRIPTION_FAILURE';

export const REGISTRY_VEHICLE = 'REGISTRY_VEHICLE';
export const REGISTRY_VEHICLE_SUCCESS = 'REGISTRY_VEHICLE_SUCCESS';
export const REGISTRY_VEHICLE_FAILURE = 'REGISTRY_VEHICLE_FAILURE';

export const CANCEL_VEHICLE = 'CANCEL_VEHICLE';
export const CANCEL_VEHICLE_SUCCESS = 'CANCEL_VEHICLE_SUCCESS';
export const CANCEL_VEHICLE_FAILURE = 'CANCEL_VEHICLE_FAILURE';

export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAILURE = 'UPDATE_VEHICLE_FAILURE';

export const LINK_VEHICLE = 'LINK_VEHICLE';
export const LINK_VEHICLE_SUCCESS = 'LINK_VEHICLE_SUCCESS';
export const LINK_VEHICLE_FAILURE = 'LINK_VEHICLE_FAILURE';

export const CREATE_PARK_COMPONENT = 'CREATE_PARK_COMPONENT';
export const CREATE_PARK_COMPONENT_SUCCESS = 'CREATE_PARK_COMPONENT_SUCCESS';
export const CREATE_PARK_COMPONENT_FAILURE = 'CREATE_PARK_COMPONENT_FAILURE';

export const UPDATE_PARK_COMPONENT = 'UPDATE_PARK_COMPONENT';
export const UPDATE_PARK_COMPONENT_SUCCESS = 'UPDATE_PARK_COMPONENT_SUCCESS';
export const UPDATE_PARK_COMPONENT_FAILURE = 'UPDATE_PARK_COMPONENT_FAILURE';

export const DELETE_PARK_COMPONENT = 'DELETE_PARK_COMPONENT';
export const DELETE_PARK_COMPONENT_SUCCESS = 'DELETE_PARK_COMPONENT_SUCCESS';
export const DELETE_PARK_COMPONENT_FAILURE = 'DELETE_PARK_COMPONENT_FAILURE';

export function getParkingLots(apartmentId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_PARKING_LOTS
            });
            const data = await parkingService.getParkingLots(apartmentId)
            dispatch({
                type: GET_PARKING_LOTS_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: GET_PARKING_LOTS_FAILURE
            });
        }
    }
}

export function getParkingLotsByBlock(blockId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_PARKING_LOTS_BY_BLOCK
            });
            const data = await parkingService.getParkingLotsByBlock(blockId)
            dispatch({
                type: GET_PARKING_LOTS_BY_BLOCK_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: GET_PARKING_LOTS_BY_BLOCK_FAILURE
            });
        }
    }
}

export function updateParkingLot(values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_PARKING_LOTS
            });
            const data = await parkingService.updateParkingLot(values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_PARKING_LOTS_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("", err.response?.data?.message))
            dispatch({
                type: UPDATE_PARKING_LOTS_FAILURE
            });
        }
    }

}

export function getParkingLot(parkingLotId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_PARKING_LOT
            });
            const data = await parkingService.getParkingLot(parkingLotId)
            dispatch({
                type: GET_PARKING_LOT_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: GET_PARKING_LOT_FAILURE
            });
        }
    }
}

export function getParkingSubscription(params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_PARKING_SUBSCRIPTION
            });
            const data = await parkingService.getParkingSubscription(params)
            dispatch({
                type: GET_PARKING_SUBSCRIPTION_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: GET_PARKING_SUBSCRIPTION_FAILURE
            });
        }
    }
}

export function registerVehicle(values) {
    return async dispatch => {
        try {
            dispatch({
                type: REGISTRY_VEHICLE
            });
            const data = await parkingService.registerVehicle(values)
            dispatch(success("", "message.created"))
            dispatch(getParkingSubscription({parkingLotId: values.parkingLotId, type: 'DAY_TIME'}))
            dispatch({
                type: REGISTRY_VEHICLE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("", err.response?.data?.message))
            dispatch({
                type: REGISTRY_VEHICLE_FAILURE
            });
        }
    }
}

export function updateVehicle(values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_VEHICLE
            });
            const data = await parkingService.updateVehicle(values)
            dispatch(success("", "message.updated"))
            dispatch(getParkingSubscription({parkingLotId: values.parkingLotId, type: 'DAY_TIME'}))
            dispatch({
                type: UPDATE_VEHICLE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("", err.response?.data?.message))
            dispatch({
                type: UPDATE_VEHICLE_FAILURE
            });
        }
    }
}

export function cancelVehicle(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: CANCEL_VEHICLE
            });
            await parkingService.cancelVehicle(values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: CANCEL_VEHICLE_SUCCESS,
                payload: id
            });
        } catch (err) {
            dispatch(error("", err.response?.data?.message))
            dispatch({
                type: CANCEL_VEHICLE_FAILURE
            });
        }
    }
}

export function linkVehicleToCard(vehicleId, cardId) {
    return async dispatch => {
        try {
            dispatch({
                type: LINK_VEHICLE
            });
            await parkingService.linkVehicleToCard(vehicleId, cardId)
            dispatch(success("", "message.updated"))
            dispatch({
                type: LINK_VEHICLE_SUCCESS,
                payload: {id: vehicleId, cardId: cardId}
            });
        } catch (err) {
            dispatch(error("", err.response?.data?.message))
            dispatch({
                type: LINK_VEHICLE_FAILURE
            });
        }
    }
}

export function createParkComponent(values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_PARK_COMPONENT
            });
            const data = await parkingService.createParkComponent(values)
            dispatch(success("", "message.created"))
            dispatch({
                type: CREATE_PARK_COMPONENT_SUCCESS,
                payload: {data: data, parkingLotId: values.parkingLotId}
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response?.data?.message))
            dispatch({
                type: CREATE_PARK_COMPONENT_FAILURE
            });
        }
    }
}

export function updateParkComponent(id, values) {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_PARK_COMPONENT
            });
            const data = await parkingService.updateParkComponent(id, values)
            dispatch(success("", "message.updated"))
            dispatch({
                type: UPDATE_PARK_COMPONENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data?.message))
            dispatch({
                type: UPDATE_PARK_COMPONENT_FAILURE
            });
        }
    }
}

export function deleteParkComponent(id) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_PARK_COMPONENT
            });
            const data = await parkingService.deleteParkComponent(id)
            dispatch(success("", "message.updated"))
            dispatch({
                type: DELETE_PARK_COMPONENT_SUCCESS,
                payload: id
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data?.message))
            dispatch({
                type: DELETE_PARK_COMPONENT_FAILURE
            });
        }
    }
}