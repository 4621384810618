import instance from './axiosInstance'
import localStorageService from './localStorageService'
import { hashString } from 'react-hash-string'
import jwt_decode from 'jwt-decode'
import { browserName, deviceType, osName, osVersion } from 'react-device-detect'

class JwtAuthService {
  login = (username, password) => {
    return instance
      .post('/es/auth/login', {
        username: username,
        password: password,
        deviceInfo: {
          deviceId: hashString(username),
          deviceType: deviceType,
          appVersion: '1.0.0',
          deviceModel: browserName,
          language: 'en',
          os: osName,
          osVersion: osVersion,
          notificationToken: ''
        }
      })
      .then(response => {
        let user = jwt_decode(response.data.accessToken)
        this.setSession(response.data)
        this.setUser(user)
        return user
      })
  }

  refreshToken = refreshToken => {
    return instance
      .post('/es/auth/refresh', {
        refreshToken: refreshToken
      })
      .then(response => {
        let user = jwt_decode(response.data.accessToken)
        this.setSession(response.data)
        this.setUser(user)
        return user
      })
  }

  logout = () => {
    let user = localStorageService.getItem('auth_user')
    return instance
      .post('/es/auth/logout', {
        refreshToken: localStorage.getItem('refresh_token'),
        deviceInfo: {
          deviceId: hashString(user.username),
          deviceType: deviceType,
          appVersion: '1.0.0',
          deviceModel: browserName,
          language: 'en',
          os: osName,
          osVersion: osVersion,
          notificationToken: ''
        }
      })
      .then(response => {
        this.removeSession()
        this.removeUser()
      })
  }

  sendOtp = email => {
    return instance
      .post('/es/employees/password/forgot-password', {
        email: email
      })
      .then(({ data }) => {
        return data
      })
  }

  resetPassword = values => {
    return instance
      .post('/es/employees/password/reset', {
        email: values.email,
        otp: values.otp,
        password: values.password,
        confirmPassword: values.confirmPassword
      })
      .then(({ data }) => {
        return data
      })
  }

  changePassword = values => {
    return instance
      .put('/es/employees/password/change', {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword
      })
      .then(({ data }) => {
        return data
      })
  }

  changeAvatar = profileUrl =>
    instance
      .put('/es/employees/avatar', {
        profileUrl
      })
      .then(({ data }) => data)

  me = () => {
    return instance.get('/es/employees/me').then(({ data }) => {
      return data
    })
  }

  createResident = data => {
    return instance
      .post('/rs/residents', {
        username: data.username,
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        password: data.password,
        type: data.type,
        confirmPassword: data.confirmPassword,
        units: data.units
      })
      .then(({ data }) => {
        return data
      })
  }

  setSession = data => {
    localStorage.setItem('jwt_token', data.accessToken)
    localStorage.setItem('refresh_token', data.refreshToken)
  }

  removeSession = () => {
    localStorage.removeItem('jwt_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('apartment')
    localStorage.removeItem('apartment_id')
    localStorage.removeItem('apartment_type')
  }

  setUser = user => {
    localStorageService.setItem('auth_user', user)
  }

  removeUser = () => {
    localStorage.removeItem('auth_user')
  }
}

export default new JwtAuthService()
