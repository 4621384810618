import {lazy} from "react";

const ShopList = lazy(() => import("./list/ShopList"));

const residentRoutes = [
    {
        path: "/shops",
        component: ShopList
    }
];

export default residentRoutes;
