import React from 'react'
import { Redirect } from 'react-router-dom'
import dashboardRoutes from '../views/dashboard/dashboardRoutes'
import sessionsRoutes from '../views/sessions/sessionsRoutes'
import apartmentRoutes from '../views/apartments/apartmentRoutes'
import workOrderRoutes from '../views/work-orders/workOrderRoutes'
import serviceRouters from '../views/services/serviceRouters'
import surveyRouters from '../views/surveys/surveyRouters'
import parkingRoutes from '../views/parking/parkingRoutes'
import adsRoutes from '../views/ads/adsRouters'
import newsRoutes from '../views/news-staff/newsRoutes'

import employeeRoutes from '../views/employees/employeeRoutes'
import managementRoutes from '../views/management/managementRoutes'
import contractRoutes from '../views/contracts/contractRoutes'
import externalContractRoutes from '../views/external-contracts/externalContractRoutes'

import checklistRoutes from '../views/checklists/checkListRoutes'
import assetRoutes from '../views/asset/assetRoutes'
import taskRoutes from '../views/tasks/taskRoutes'
import onboardFormRoutes from '../views/onboard-forms/onboardFormRoutes'
import reportRoutes from '../views/reports/reportRoutes'
import reportRouters from '../views/reports/reportRoutes'
import warehouseRoutes from '../views/warehouse/warehouseRoutes'
import onboardApprovalRoutes from '../views/approval/onboardApprovalRoutes'
import permissionRoutes from '../views/permissions/permissionRoutes'
import roleRoutes from '../views/roles/roleRoutes'
import filesManagementRoutes from '../views/files-management/filesManagementRoutes'
import accountingRoutes from '../views/accounting/accountingRoutes'
import chatRouters from '../views/chat/chatRoutes'
import shopRoutes from '../views/shop/shopRoutes'
import { AuthGuard } from 'context/authContext'
import { ApartmentGuard } from 'context/apartmentContext'
import { GullLayouts } from 'layout'
import { renderRoutes } from 'react-router-config'

const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/dashboard" />
    }
]

const errorRoute = [
    {
        component: () => <Redirect to="/session/404" />
    }
]

const CompMerge = ({ components, ...props }) => {
    
    const renderNestedComponents = (index) => {
        if (index >= components.length) return renderRoutes(props.route.routes)
        const Component = components[index]
        return <Component>{renderNestedComponents(index + 1)}</Component>
    }

    return renderNestedComponents(0)
}

const rootRoutes = [
    ...sessionsRoutes,
    {
        path: '/',
        component: (props) => <CompMerge components={[AuthGuard, ApartmentGuard]} {...props} />,
        routes: [
            {
                component: GullLayouts.layout1,
                routes: [
                    ...dashboardRoutes,
                    ...apartmentRoutes,
                    ...newsRoutes,
                    ...workOrderRoutes,
                    ...serviceRouters,
                    ...surveyRouters,
                    ...parkingRoutes,
                    ...adsRoutes,
                    ...employeeRoutes,
                    ...managementRoutes,
                    ...contractRoutes,
                    ...externalContractRoutes,
                    ...checklistRoutes,
                    ...assetRoutes,
                    ...accountingRoutes,
                    ...taskRoutes,
                    ...onboardFormRoutes,
                    ...warehouseRoutes,
                    ...reportRoutes,
                    ...permissionRoutes,
                    ...roleRoutes,
                    ...redirectRoute,
                    ...onboardApprovalRoutes,
                    ...reportRouters,
                    ...filesManagementRoutes,
                    ...chatRouters,
                    ...shopRoutes,
                    ...errorRoute
                ]
            }
        ]
    }
]

export default rootRoutes
