import React, {Fragment} from "react";
import {FormattedMessage} from "react-intl";

const Breadcrumb = ({routeSegments}) => {
  return (
    <Fragment>
      <div className="breadcrumb">
        {routeSegments ? (
          <Fragment>
            <h1><FormattedMessage id={routeSegments[routeSegments.length - 1]["name"]}/></h1>
          </Fragment>
        ) : null}
      </div>
      <div className="separator-breadcrumb border-top"/>
    </Fragment>
  );
};

export default Breadcrumb;
