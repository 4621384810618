import PageTabsLayout from 'layout/PageTabsLayout'
import accountingModule from './accountingModule'

const accountingRoutes = [
  accountingModule.pages.BillDetail,
  {
    exact: false,
    path: '/a',
    component: PageTabsLayout,
    routes: [
      accountingModule.pages.BillListing,
      // accountingModule.pages.FinancialListing,
      accountingModule.pages.ReceiptPaymentListing
    ]
  }
]

export default accountingRoutes
