import instance from './axiosInstance'
import { getPathsString } from '../@utils'

class NewService {
  getNews = (apartmentId, page, size) => {
    return instance
      .get('/rs/staff/news/staff/apartment/' + apartmentId, {
        params: {
          page: page,
          size: size
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  /**
   * Retrieves a list of news items for a specific apartment.
   * @param {number} apartmentId - The ID of the apartment to retrieve news for.
   * @param {Object} params - The parameters for the news retrieval.
   * @param {number} params.page - The page number to retrieve.
   * @param {number} params.size - The number of items to retrieve per page.
   * @returns {Promise<Object>} - The response data from the server containing the news items.
   */
  // params = {page, size, listCategoryId}
  v2getNews = (apartmentId, params) =>
    instance
      .get('/rs/staff/news/staff/apartment/' + apartmentId, { params })
      .then(({ data }) => data)

  /**
   * Adds a new news item.
   * @param {Object} params - The parameters for the new news item.
   * @param {number} params.apartmentId - The ID of the apartment the news is for.
   * @param {string} params.title - The title of the news item.
   * @param {string} params.content - The content of the news item.
   * @param {string} params.imageTitle - The title of the image for the news item.
   * @param {string[]} params.attachFileUrls - The URLs of any attached files for the news item.
   * @param {boolean} params.isPublic - Whether the news item is public or private.
   * @param {number} [params.categoryId] - The ID of the category the news item belongs to.
   * @returns {Promise<Object>} - The response data from the server.
   */
  // params = {apartmentId, title, content, imageTitle, attachFileUrls, isPublic, categoryId}
  v2postNews = params =>
    instance.post('/rs/staff/news', params).then(({ data }) => data)

  /**
   * Updates an existing news item.
   * @param {number} id - The ID of the news item to update.
   * @param {Object} params - The parameters for the updated news item.
   * @param {number} params.apartmentId - The ID of the apartment the news is for.
   * @param {string} params.title - The updated title of the news item.
   * @param {string} params.content - The updated content of the news item.
   * @param {string} params.imageTitle - The updated title of the image for the news item.
   * @param {string[]} params.attachFileUrls - The updated URLs of any attached files for the news item.
   * @param {boolean} params.isPublic - Whether the news item is public or private.
   * @param {number} [params.categoryId] - The updated ID of the category the news item belongs to.
   * @returns {Promise<Object>} - The response data from the server.
   */
  // params = {apartmentId, title, content, imageTitle, attachFileUrls, isPublic, categoryId}
  v2putNews = (id, params) =>
    instance.put(`/rs/staff/news/${id}`, params).then(({ data }) => data)

  /**
   * Deletes a news item.
   * @param {number} id - The ID of the news item to delete.
   * @returns {Promise<Object>} - The response data from the server.
   */
  v2deleteNews = id =>
    instance.delete(`/rs/staff/news/${id}`).then(({ data }) => data)

  getNewsPrivate = (apartmentId, residentId, page, size) => {
    console.log('vào đây ' + residentId)
    return instance
      .get('/rs/staff/news/staff/apartment/' + apartmentId, {
        params: {
          page: page,
          size: size,
          residentId: residentId,
          isPrivate: true
        }
      })
      .then(({ data }) => {
        return data
      })
  }
  getNew = id => {
    return instance.get('/rs/news/' + id, {}).then(({ data }) => {
      return data
    })
  }
  postComment = values => {
    return instance
      .post('/rs/staff/news/' + values.newsId + '/comment', {
        message: values.message,
        images: getPathsString(values.images)
      })
      .then(({ data }) => {
        return data
      })
  }
  addNew = params => {
    return instance
      .post('/rs/staff/news', {
        apartmentId: params.apartmentId,
        title: params.title,
        content: params.content,
        imageTitle: params.imageTitle,
        attachFileUrls: params.attachFileUrls,
        isPublic: !!params?.isPublic
      })
      .then(({ data }) => {
        return data
      })
  }
  addNewPrivate = params => {
    return instance
      .post('/rs/staff/news', {
        apartmentId: params.apartmentId,
        title: params.title,
        content: params.content,
        imageTitle: params.imageTitle,
        attachFileUrls: params.attachFileUrls,
        residentId: params.residentId
      })
      .then(({ data }) => {
        return data
      })
  }

  editNew = params => {
    return instance
      .put('/rs/staff/news/' + params.id, {
        apartmentId: params.apartmentId,
        title: params.title,
        content: params.content,
        imageTitle: params.imageTitle,
        attachFileUrls: params.attachFileUrls,
        isPublic: !!params?.isPublic
      })
      .then(({ data }) => {
        return data
      })
  }
}

export default new NewService()
