import instance from './axiosInstance'
import { getPathsString } from '../@utils'
import FileSaver from 'file-saver'

class ResidentService {
    getOwnerUnit = (unitId) => {
        return instance
            .get('/rs/residents/get-owner-unit', {
                params: {
                    unitId: unitId
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    getResidentByUnit = (unitId) => {
        return instance
            .get('/rs/residents/get-by-unit', {
                params: {
                    unitId: unitId
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    getResidentDetail = (id) => {
        return instance.get('/rs/residents/' + id + '/detail', {}).then((response) => {
            return response.data
        })
    }

    getResidents = (apartmentId, status, keyword, page, size, showAll) => {
        return instance
            .get('/rs/residents/staff/' + apartmentId, {
                params: {
                    page: page,
                    size: size,
                    status: status,
                    keyword: keyword,
                    showAll: showAll
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    lockResident = (residentId) => {
        return instance.post('/rs/residents/' + residentId + '/lock', {}).then((response) => {
            return response
        })
    }

    unlockResident = (residentId) => {
        return instance.post('/rs/residents/' + residentId + '/unlock', {}).then((response) => {
            return response
        })
    }

    exportResident = (apartmentID) =>
        instance.get(`/rp/report/resident/${apartmentID}`).then((data) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            let blob = new Blob([data], { type: fileType })
            let url = window.URL.createObjectURL(blob)
            window.open(url)
            return blob
        })

    getResidentUnits = (apartmentIds, blockIds, floorIds, unitIds) => {
        return instance
            .get('/rs/residents/get-unit-residents', {
                params: {
                    apartmentIds: apartmentIds + '',
                    blockIds: blockIds + '',
                    floorIds: floorIds + '',
                    unitIds: unitIds + ''
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    getResidentsByApartment = (apartmentId) => {
        return instance
            .get('/rs/residents/get-resident-by-apartment', {
                params: {
                    apartmentId: apartmentId
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    resetPassword = (id, data) => {
        return instance
            .post('/rs/residents/' + id + '/password/reset', {
                newPassword: data.newPassword,
                confirmNewPassword: data.confirmNewPassword
            })
            .then(({ data }) => {
                return data
            })
    }

    getUnitsOfResident = (apartmentId, residentId) => {
        return instance.get('/rs/residents/' + residentId + '/get-unit-of-resident/apartment/' + apartmentId, {}).then(({ data }) => {
            return data
        })
    }

    getVicegerents = (residentId) => {
        return instance.get('/rs/residents/' + residentId + '/vicegerents', {}).then(({ data }) => {
            return data
        })
    }

    createVicegerent = (residentId, values) => {
        return instance
            .post('/rs/residents/' + residentId + '/vicegerents', {
                fullName: values.fullName,
                title: values.title,
                email: values.email,
                phoneNumber: values.phoneNumber,
                idNumber: values.idNumber,
                signature: getPathsString(values.signature)
            })
            .then(({ data }) => {
                return data
            })
    }

    updateVicegerent = (residentId, values) => {
        return instance
            .put('/rs/residents/' + residentId + '/vicegerents/' + values.id, {
                fullName: values.fullName,
                title: values.title,
                email: values.email,
                phoneNumber: values.phoneNumber,
                idNumber: values.idNumber,
                signature: getPathsString(values.signature)
            })
            .then(({ data }) => {
                return data
            })
    }

    deleteVicegerent = (residentId, vicegerentId) => {
        return instance.delete('/rs/residents/' + residentId + '/vicegerents/' + vicegerentId, {}).then(({ data }) => {
            return data
        })
    }

    setDefaultVicegerent = (residentId, vicegerentId) => {
        return instance.post('/rs/residents/' + residentId + '/vicegerents/' + vicegerentId + '/default', {}).then(({ data }) => {
            return data
        })
    }

    setUnDefaultVicegerent = (residentId, vicegerentId) => {
        return instance.post('/rs/residents/' + residentId + '/vicegerents/' + vicegerentId + '/un-default', {}).then(({ data }) => {
            return data
        })
    }

    getResidentHistoryLogin = (residentId, params) => {
        return instance
            .get('/rs/residents/' + residentId + '/history/login', {
                params: {
                    fromDate: params.fromDate.toISOString(),
                    toDate: params.toDate.toISOString(),
                    page: 0,
                    size: 100
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    // Get units by apartmentId,residentId,blockId,floorId
    getUnits = (params) => instance.get('/rs/units', { params }).then(({ data }) => data)

    resident = {
        getResidents: (params) => instance.get(`/rs/residents/filter`, { params }).then(({ data }) => data),
        exportResidents: (params) => instance.post('/rs/residents/export', params, { responseType: 'blob' }).then((response) => FileSaver.saveAs(response.data, 'export_resident.xlsx')),
        getResidentDetail: (id) => instance.get('/rs/residents/' + id + '/detail', {}).then(({ data }) => data?.resident),
        // data: {username,fullName,phoneNumber,email,password,type,confirmPassword,units}
        createResident: (data) => instance.post('/rs/residents', data),
        // data: {fullName,phoneNumber,email,type,units}
        updateResident: (id, data) => instance.put('/rs/residents/' + id, data),
        assignResidentToUnit: ({ unitId, residentId, type }) => instance.post('/rs/residents/toUnit', { unitId, residentId, type }),
        unassignResidentToUnit: (residentUnitId) => instance.delete(`/rs/residents/toUnit/${residentUnitId}`),
        lockResident: (residentId) => instance.post('/rs/residents/' + residentId + '/lock'),
        unlockResident: (residentId) => instance.post('/rs/residents/' + residentId + '/unlock')
    }

    news = {
        // params : { apartmentId, listCategoryId, page, size) {
        getNews: (params) => instance.get('/rs/staff/news/staff/apartment/' + params.apartmentId, { params }).then(({ data }) => data),
        // params = {apartmentId, title, content, imageTitle, attachFileUrls, isPublic, categoryId}
        createNews: (params) => instance.post('/rs/staff/news', params),
        // params = {apartmentId, title, content, imageTitle, attachFileUrls, isPublic, categoryId}
        updateNews: (id, params) => instance.put(`/rs/staff/news/${id}`, params),
        deleteNews: (id) => instance.delete(`/rs/staff/news/${id}`)
    }
}

export default new ResidentService()
