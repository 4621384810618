import UiHeadTabs from 'components/HeadTabs/UiHeadTabs';
import React from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import UiActions from 'components/Actions/UiActions';
import eventEmitter from 'utils/EventEmitter';
import { matchPath } from 'react-router-dom';

/**
 * Renders a layout with tabs at the top of the page, allowing the user to navigate between different sections.
 * The tabs are generated from the `routes` prop, which is an array of route objects. Each route object has a `path`, `label`, and optional `actions` property.
 * The `actions` prop is used to render a set of actions (e.g. buttons, menus) above the tabs.
 * The content for each tab is rendered using the `component` property of the corresponding route object.
 * The current tab is highlighted based on the current URL path.
 *
 * @param {object} props - The component props.
 * @param {object} props.route - The main route object, containing an array of sub-routes.
 * @returns {JSX.Element} - The rendered PageTabsLayout component.
 */
export default function PageTabsLayout(props) {
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()

  const routeByPath = (props.route?.routes ?? [])
    .filter(e => matchPath(location.pathname, e))
  const routeByLocation = (props.route?.routes ?? [])
    .filter(e => (e.tabLink ?? e.path) === location.pathname || (e.tabLink ?? e.path) === `${location.pathname}${location.search}`)

  const routeMatch = [...routeByPath, ...routeByLocation].find(e => e);
  const sections = props.route?.routes?.filter(e => !e.hidden).map(e => ({ ...e, key: e.tabLink ?? e.path, label: intl.formatMessage({ id: e.label }) })) ?? []

  return <div>
    <div className='mb-4'>
      <UiHeadTabs
        items={sections} value={routeMatch?.tabLink ?? routeMatch?.path}
        onTabClick={v => history.push(v)}
        prefix={routeMatch && routeMatch.actions
          ? <UiActions {...routeMatch.actions.call(this, eventEmitter)} />
          : <></>}
      />
    </div>
    <Switch>
      {props.route.routes.map((route, i) => <Route
        key={`${route.path}`} path={route.path} exact={!!route.exact}
        render={p => <route.component route={route} routes={route.routes} {...p} />}
      />)}
    </Switch>
  </div>
}
