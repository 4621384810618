import {
    APPROVE_GOODS_EXPORT,
    APPROVE_GOODS_EXPORT_FAILURE,
    APPROVE_GOODS_EXPORT_SUCCESS,
    APPROVE_GOODS_RECEIPT,
    APPROVE_GOODS_RECEIPT_SUCCESS,
    CREATE_ASSET,
    CREATE_ASSET_FAILURE,
    CREATE_ASSET_SUCCESS,
    CREATE_CHECKLIST_TEMPLATE,
    CREATE_CHECKLIST_TEMPLATE_FAILURE,
    CREATE_CHECKLIST_TEMPLATE_SUCCESS,
    CREATE_ELECTRIC_METER,
    CREATE_ELECTRIC_METER_FAILURE,
    CREATE_ELECTRIC_METER_SUCCESS,
    CREATE_GOODS_EXPORT,
    CREATE_GOODS_EXPORT_FAILURE,
    CREATE_GOODS_EXPORT_SUCCESS,
    CREATE_GOODS_RECEIPT,
    CREATE_GOODS_RECEIPT_FAILURE,
    CREATE_GOODS_RECEIPT_SUCCESS,
    CREATE_WAREHOUSE,
    CREATE_WAREHOUSE_FAILURE,
    CREATE_WAREHOUSE_SUCCESS,
    DELETE_APARTMENT,
    DELETE_APARTMENT_FAILURE,
    DELETE_APARTMENT_SUCCESS,
    EDIT_APARTMENT,
    EDIT_APARTMENT_FAILURE,
    EDIT_APARTMENT_SUCCESS,
    EDIT_UNIT,
    EDIT_UNIT_FAILURE,
    EDIT_UNIT_SUCCESS,
    GET_APARTMENT,
    GET_APARTMENT_FAILURE,
    GET_APARTMENT_SUCCESS,
    GET_APARTMENTS,
    GET_APARTMENTS_FAILURE,
    GET_APARTMENTS_SUCCESS,
    GET_ASSET,
    GET_ASSET_FAILURE,
    GET_ASSET_SUCCESS,
    GET_ASSETS,
    GET_ASSETS_FAILURE,
    GET_ASSETS_SUCCESS,
    GET_BLOCKS,
    GET_BLOCKS_FAILURE,
    GET_BLOCKS_SUCCESS,
    GET_CHECKLIST_TEMPLATE,
    GET_CHECKLIST_TEMPLATE_FAILURE,
    GET_CHECKLIST_TEMPLATE_SUCCESS,
    GET_CHECKLIST_TEMPLATES,
    GET_CHECKLIST_TEMPLATES_FAILURE,
    GET_CHECKLIST_TEMPLATES_SUCCESS,
    GET_CONFIG_ELECTRIC_METER,
    GET_CONFIG_ELECTRIC_METER_FAILURE,
    GET_CONFIG_ELECTRIC_METER_SUCCESS,
    GET_CONFIG_ELECTRIC_METERS,
    GET_CONFIG_ELECTRIC_METERS_FAILURE,
    GET_CONFIG_ELECTRIC_METERS_SUCCESS,
    GET_CONFIG_GOODS,
    GET_CONFIG_GOODS_FAILURE,
    GET_CONFIG_GOODS_SUCCESS,
    GET_CONFIGS_GOODS,
    GET_CONFIGS_GOODS_FAILURE,
    GET_CONFIGS_GOODS_SUCCESS,
    GET_DASHBOARD_FAILURE,
    GET_DASHBOARD_SUCCESS,
    GET_ELECTRIC_METER,
    GET_ELECTRIC_METER_FAILURE,
    GET_ELECTRIC_METER_MONTHLY_REPORT,
    GET_ELECTRIC_METER_MONTHLY_REPORT_FAILURE,
    GET_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS,
    GET_ELECTRIC_METER_MONTHLY_REPORTS,
    GET_ELECTRIC_METER_MONTHLY_REPORTS_FAILURE,
    GET_ELECTRIC_METER_MONTHLY_REPORTS_SUCCESS,
    GET_ELECTRIC_METER_SUCCESS,
    GET_ELECTRIC_METERS,
    GET_ELECTRIC_METERS_BY_UNIT,
    GET_ELECTRIC_METERS_BY_UNIT_FAILURE,
    GET_ELECTRIC_METERS_BY_UNIT_SUCCESS,
    GET_ELECTRIC_METERS_FAILURE,
    GET_ELECTRIC_METERS_SUCCESS,
    GET_EXPENDITURE,
    GET_EXPENDITURE_FAILURE,
    GET_EXPENDITURE_SUCCESS,
    GET_EXPENDITURES,
    GET_EXPENDITURES_FAILURE,
    GET_EXPENDITURES_SUCCESS,
    GET_FLOORS,
    GET_FLOORS_BY_APARTMENT,
    GET_FLOORS_BY_APARTMENT_FAILURE,
    GET_FLOORS_BY_APARTMENT_SUCCESS,
    GET_FLOORS_FAILURE,
    GET_FLOORS_SUCCESS,
    GET_GOODS_EXPORT,
    GET_GOODS_EXPORT_FAILURE,
    GET_GOODS_EXPORT_SUCCESS,
    GET_GOODS_EXPORTS,
    GET_GOODS_EXPORTS_FAILURE,
    GET_GOODS_EXPORTS_SUCCESS,
    GET_GOODS_RECEIPT,
    GET_GOODS_RECEIPT_FAILURE,
    GET_GOODS_RECEIPT_SUCCESS,
    GET_GOODS_RECEIPTS,
    GET_GOODS_RECEIPTS_FAILURE,
    GET_GOODS_RECEIPTS_SUCCESS,
    GET_INSPECTION_CHECKLIST,
    GET_INSPECTION_CHECKLIST_FAILURE,
    GET_INSPECTION_CHECKLIST_SUCCESS,
    GET_INSPECTION_CHECKLISTS,
    GET_INSPECTION_CHECKLISTS_FAILURE,
    GET_INSPECTION_CHECKLISTS_SUCCESS,
    GET_UNIT,
    GET_UNIT_FAILURE,
    GET_UNIT_SUCCESS,
    GET_UNITS,
    GET_UNITS_FAILURE,
    GET_UNITS_SUCCESS,
    GET_WAREHOUSE_ITEMS,
    GET_WAREHOUSE_ITEMS_FAILURE,
    GET_WAREHOUSE_ITEMS_SUCCESS,
    GET_WAREHOUSES,
    GET_WAREHOUSES_FAILURE,
    GET_WAREHOUSES_SUCCESS,
    IMPORT_METER, IMPORT_METER_FAILURE,
    IMPORT_METER_SUCCESS,
    REJECT_GOODS_EXPORT,
    REJECT_GOODS_EXPORT_FAILURE,
    REJECT_GOODS_EXPORT_SUCCESS,
    REJECT_GOODS_RECEIPT,
    REJECT_GOODS_RECEIPT_FAILURE,
    REJECT_GOODS_RECEIPT_SUCCESS,
    UPDATE_ASSET,
    UPDATE_ASSET_FAILURE,
    UPDATE_ASSET_LOCATION,
    UPDATE_ASSET_LOCATION_FAILURE,
    UPDATE_ASSET_LOCATION_SUCCESS,
    UPDATE_ASSET_SUCCESS,
    UPDATE_CHECKLIST_TEMPLATE,
    UPDATE_CHECKLIST_TEMPLATE_FAILURE,
    UPDATE_CHECKLIST_TEMPLATE_SUCCESS,
    UPDATE_CONFIG_ELECTRIC_METER,
    UPDATE_CONFIG_ELECTRIC_METER_FAILURE,
    UPDATE_CONFIG_ELECTRIC_METER_SUCCESS,
    UPDATE_CONFIG_GOOD,
    UPDATE_CONFIG_GOOD_FAILURE,
    UPDATE_CONFIG_GOOD_SUCCESS,
    UPDATE_ELECTRIC_METER,
    UPDATE_ELECTRIC_METER_FAILURE,
    UPDATE_ELECTRIC_METER_MONTHLY_REPORT,
    UPDATE_ELECTRIC_METER_MONTHLY_REPORT_FAILURE,
    UPDATE_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS,
    UPDATE_ELECTRIC_METER_SUCCESS,
    UPDATE_EXPENDITURE,
    UPDATE_EXPENDITURE_FAILURE,
    UPDATE_EXPENDITURE_SUCCESS,
    UPDATE_GOODS_EXPORT,
    UPDATE_GOODS_EXPORT_FAILURE,
    UPDATE_GOODS_EXPORT_SUCCESS,
    UPDATE_GOODS_RECEIPT,
    UPDATE_GOODS_RECEIPT_FAILURE,
    UPDATE_GOODS_RECEIPT_SUCCESS,
    UPDATE_WAREHOUSE,
    UPDATE_WAREHOUSE_FAILURE,
    UPDATE_WAREHOUSE_SUCCESS,
    UPLOAD_PHOTO_APARTMENT,
    UPLOAD_PHOTO_APARTMENT_FAILURE,
    UPLOAD_PHOTO_APARTMENT_SUCCESS
} from "../actions/apartmentActions";


const ApartmentReducer = function (state = {}, action) {
    switch (action.type) {
        case GET_BLOCKS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_BLOCKS_SUCCESS: {
            return {
                ...state,
                loading: false,
                blocks: action.payload
            };
        }
        case GET_BLOCKS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_APARTMENTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_APARTMENTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                apartments: [...action.payload.data],
                apartmentTotalPages: action.payload.totalPages
            };
        }
        case GET_APARTMENTS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        case DELETE_APARTMENT: {
            return {
                ...state,
                loading: true
            };
        }
        case DELETE_APARTMENT_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case DELETE_APARTMENT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case UPLOAD_PHOTO_APARTMENT: {
            return {
                ...state,
                loading: true
            };
        }
        case UPLOAD_PHOTO_APARTMENT_SUCCESS: {
            return {
                ...state,
                apartment: {
                    ...state.apartment,
                    photoUrl: action.payload.data.photoUrl
                },
                loading: false
            }
        }
        case UPLOAD_PHOTO_APARTMENT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_FLOORS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_FLOORS_SUCCESS: {
            return {
                ...state,
                loading: false,
                floors: action.payload
            };
        }
        case GET_FLOORS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_UNIT: {
            return {
                ...state,
                loading: true 
            };
        }
        case GET_UNIT_SUCCESS: {
            return {
                ...state,
                loading: false,
                unit: action.payload.data
            };
        }
        case GET_UNIT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_UNITS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_UNITS_SUCCESS: {
            return {
                ...state,
                loading: false,
                units: action.payload
            };
        }
        case GET_UNITS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case EDIT_UNIT: {
            return {
                ...state,
                loading: true
            };
        }
        case EDIT_UNIT_SUCCESS: {
            return {
                ...state,
                unit: {
                    ...state.unit,
                    code: action.payload.code,
                    name: action.payload.name,
                    area: action.payload.area,
                    numberOfRegisteredWaterUsers: action.payload.numberOfRegisteredWaterUsers,
                    status: action.payload.status
                },
                loading: false
            }
        }
        case EDIT_UNIT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case EDIT_APARTMENT: {
            return {
                ...state,
                loading: true
            };
        }
        case EDIT_APARTMENT_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case EDIT_APARTMENT_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_CHECKLIST_TEMPLATES: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_CHECKLIST_TEMPLATES_SUCCESS: {
            return {
                ...state,
                checklistTemplates: action.payload,
                loading: false
            };
        }
        case GET_CHECKLIST_TEMPLATES_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_INSPECTION_CHECKLISTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_INSPECTION_CHECKLISTS_SUCCESS: {
            return {
                ...state,
                checklists: action.payload,
                loading: false
            };
        }
        case GET_INSPECTION_CHECKLISTS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_INSPECTION_CHECKLIST: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_INSPECTION_CHECKLIST_SUCCESS: {
            return {
                ...state,
                checklist: action.payload,
                loading: false
            };
        }
        case GET_INSPECTION_CHECKLIST_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_CHECKLIST_TEMPLATE: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_CHECKLIST_TEMPLATE_SUCCESS: {
            return {
                ...state,
                checklistTemplate: action.payload,
                loading: false
            };
        }
        case GET_CHECKLIST_TEMPLATE_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case CREATE_CHECKLIST_TEMPLATE: {
            return {
                ...state,
                loading: true,
                error: true
            };
        }
        case CREATE_CHECKLIST_TEMPLATE_SUCCESS: {
            return {
                ...state,
                checklistTemplate: action.payload,
                loading: false,
                error: false
            };
        }
        case CREATE_CHECKLIST_TEMPLATE_FAILURE: {
            return {
                ...state,
                error: true,
                loading: false
            };
        }
        case UPDATE_CHECKLIST_TEMPLATE: {
            return {
                ...state,
                loading: true,
                error: true
            };
        }
        case UPDATE_CHECKLIST_TEMPLATE_SUCCESS: {
            return {
                ...state,
                checklistTemplate: action.payload,
                error: false,
                loading: false
            };
        }
        case UPDATE_CHECKLIST_TEMPLATE_FAILURE: {
            return {
                ...state,
                error: true,
                loading: false
            };
        }
        case GET_ASSETS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_ASSETS_SUCCESS: {
            return {
                ...state,
                assets: action.payload,
                loading: false
            };
        }
        case GET_ASSETS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_ASSET: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_ASSET_SUCCESS: {
            return {
                ...state,
                asset: action.payload,
                loading: false
            };
        }
        case GET_ASSET_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case CREATE_ASSET: {
            return {
                ...state,
                loading: true,
                error: true
            };
        }
        case CREATE_ASSET_SUCCESS: {
            return {
                ...state,
                asset: action.payload,
                error: false,
                loading: false
            };
        }
        case CREATE_ASSET_FAILURE: {
            return {
                ...state,
                error: true,
                loading: false
            };
        }
        case UPDATE_ASSET: {
            return {
                ...state,
                error: true,
                loading: true
            };
        }
        case UPDATE_ASSET_SUCCESS: {
            return {
                ...state,
                asset: action.payload,
                loading: false,
                error: false
            };
        }
        case UPDATE_ASSET_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case UPDATE_ASSET_LOCATION: {
            return {
                ...state,
                loading: true
            };
        }
        case UPDATE_ASSET_LOCATION_SUCCESS: {
            return {
                ...state,
                assets: {
                    ...state.assets,
                    data: state.assets.data.map(
                        (asset, ind) => asset.id === action.payload.id ?
                            {
                                ...asset,
                                currentLocation: action.payload.currentLocation,
                                trackedNote: action.payload.trackedNote
                            }
                            : asset
                    )
                },
                loading: false
            };
        }
        case UPDATE_ASSET_LOCATION_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_WAREHOUSES: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_WAREHOUSES_SUCCESS: {
            return {
                ...state,
                loading: false,
                warehouses: action.payload
            };
        }
        case GET_WAREHOUSES_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case CREATE_WAREHOUSE: {
            return {
                ...state,
                loading: true
            };
        }
        case CREATE_WAREHOUSE_SUCCESS: {
            return {
                ...state,
                loading: false,
                warehouses: {
                    ...state.warehouses,
                    data: [
                        action.payload,
                        ...state.warehouses.data
                    ]
                }
            };
        }
        case CREATE_WAREHOUSE_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case UPDATE_WAREHOUSE: {
            return {
                ...state,
                loading: true
            };
        }
        case UPDATE_WAREHOUSE_SUCCESS: {
            return {
                ...state,
                warehouses: {
                    ...state.warehouses,
                    warehouses: state.warehouses.data.map(
                        (warehouse) => warehouse.id === action.payload.id ?
                            {
                                ...warehouse,
                                code: action.payload.code,
                                location: action.payload.location
                            }
                            : warehouse
                    )
                },
                loading: false
            };
        }
        case UPDATE_WAREHOUSE_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }

        case GET_CONFIG_GOODS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_CONFIG_GOODS_SUCCESS: {
            return {
                ...state,
                loading: false,
                configGoods: action.payload
            };
        }
        case GET_CONFIG_GOODS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_GOODS_RECEIPTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_GOODS_RECEIPTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                goodsReceipts: action.payload
            };
        }
        case GET_GOODS_RECEIPTS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_GOODS_RECEIPT: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_GOODS_RECEIPT_SUCCESS: {
            return {
                ...state,
                loading: false,
                goodsReceipt: action.payload
            };
        }
        case GET_GOODS_RECEIPT_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case CREATE_GOODS_RECEIPT: {
            return {
                ...state,
                error: true,
                loading: true
            };
        }
        case CREATE_GOODS_RECEIPT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                goodsReceipt: action.payload
            };
        }
        case CREATE_GOODS_RECEIPT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_WAREHOUSE_ITEMS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_WAREHOUSE_ITEMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                warehouseItems: action.payload
            };
        }
        case GET_WAREHOUSE_ITEMS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_GOODS_EXPORTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_GOODS_EXPORTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                goodsExports: action.payload
            };
        }
        case GET_GOODS_EXPORTS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }


        case GET_GOODS_EXPORT: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_GOODS_EXPORT_SUCCESS: {
            return {
                ...state,
                loading: false,
                goodsExport: action.payload
            };
        }
        case GET_GOODS_EXPORT_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case CREATE_GOODS_EXPORT: {
            return {
                ...state,
                error: true,
                loading: true
            };
        }
        case CREATE_GOODS_EXPORT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                goodsExport: action.payload
            };
        }
        case CREATE_GOODS_EXPORT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case UPDATE_GOODS_EXPORT: {
            return {
                ...state,
                loading: true,
                error: true
            };
        }
        case UPDATE_GOODS_EXPORT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                goodsExport: action.payload
            };
        }
        case UPDATE_GOODS_EXPORT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case APPROVE_GOODS_EXPORT: {
            return {
                ...state,
                loading: true
            };
        }
        case APPROVE_GOODS_EXPORT_SUCCESS: {
            return {
                ...state,
                loading: false,
                goodsExports: {
                    ...state.goodsExports,
                    data: state.goodsExports?.data.map(
                        (goodsExport, ind) => goodsExport.id === action.payload.id ?
                            {
                                ...goodsExport,
                                status: 'APPROVED',
                                exportDate: action.payload.exportDate,
                                employeeApproveExportInfo: action.payload.employeeApproveExportInfo
                            }
                            : goodsExport
                    )
                },
            };
        }
        case APPROVE_GOODS_EXPORT_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case APPROVE_GOODS_RECEIPT: {
            return {
                ...state,
                loading: true
            };
        }
        case APPROVE_GOODS_RECEIPT_SUCCESS: {
            return {
                ...state,
                loading: false,
                goodsReceipts: {
                    ...state.goodsReceipts,
                    data: state.goodsReceipts?.data.map(
                        (goodsReceipt, ind) => goodsReceipt.id === action.payload.id ?
                            {
                                ...goodsReceipt,
                                status: 'APPROVED',
                                receiptDate: action.payload.receiptDate,
                                employeeApproveInfo: action.payload.employeeApproveInfo
                            }
                            : goodsReceipt
                    )
                },
            };
        }
        case REJECT_GOODS_RECEIPT: {
            return {
                ...state,
                loading: true
            };
        }
        case REJECT_GOODS_RECEIPT_FAILURE: {
            return {
                ...state,
                loading: true,
                error: true
            };
        }
        case REJECT_GOODS_RECEIPT_SUCCESS: {
            return {
                ...state,
                loading: false,
                goodsReceipts: {
                    ...state.goodsReceipts,
                    data: state.goodsReceipts?.data.map(
                        (goodsReceipt, ind) => goodsReceipt.id === action.payload.id ?
                            {
                                ...goodsReceipt,
                                status: 'REJECT',
                                receiptDate: action.payload.receiptDate,
                                employeeRejectInfo: action.payload.employeeRejectInfo
                            }
                            : goodsReceipt
                    )
                },
            };
        }
        case REJECT_GOODS_EXPORT: {
            return {
                ...state,
                loading: true
            };
        }
        case REJECT_GOODS_EXPORT_FAILURE: {
            return {
                ...state,
                loading: true,
                error: true
            };
        }
        case REJECT_GOODS_EXPORT_SUCCESS: {
            return {
                ...state,
                loading: false,
                goodsExports: {
                    ...state.goodsExports,
                    data: state.goodsExports?.data.map(
                        (goodsExport, ind) => goodsExport.id === action.payload.id ?
                            {
                                ...goodsExport,
                                status: 'REJECT',
                                exportDate: action.payload.exportDate,
                                employeeRejectExportInfo: action.payload.employeeRejectExportInfo
                            }
                            : goodsExport
                    )
                },
            };
        }
        case GET_ELECTRIC_METER: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_ELECTRIC_METER_SUCCESS: {
            return {
                ...state,
                loading: false,
                meter: action.payload
            };
        }
        case GET_ELECTRIC_METER_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_ELECTRIC_METERS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_ELECTRIC_METERS_SUCCESS: {
            return {
                ...state,
                loading: false,
                meters: action.payload
            };
        }
        case GET_ELECTRIC_METERS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_ELECTRIC_METERS_BY_UNIT: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_ELECTRIC_METERS_BY_UNIT_SUCCESS: {
            return {
                ...state,
                loading: false,
                electricMetersByUnit: action.payload
            };
        }
        case GET_ELECTRIC_METERS_BY_UNIT_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case CREATE_ELECTRIC_METER: {
            return {
                ...state,
                loading: true
            };
        }
        case CREATE_ELECTRIC_METER_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case CREATE_ELECTRIC_METER_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case UPDATE_ELECTRIC_METER: {
            return {
                ...state,
                loading: true
            };
        }
        case UPDATE_ELECTRIC_METER_SUCCESS: {
            return {
                ...state,
                loading: false,
                meters: {
                    ...state.meters,
                    data: state.meters.data.map(
                        (electricMeter, ind) => electricMeter.id === action.payload.id ?
                            {
                                ...electricMeter,
                                serialNo: action.payload.serialNo,
                                brand: action.payload.brand,
                                remark: action.payload.remark,
                                location: action.payload.location,
                                type: action.payload.type
                            }
                            : electricMeter
                    )
                }
            };
        }
        case UPDATE_ELECTRIC_METER_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }

        case GET_ELECTRIC_METER_MONTHLY_REPORTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_ELECTRIC_METER_MONTHLY_REPORTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                monthlyReports: action.payload
            };
        }
        case GET_ELECTRIC_METER_MONTHLY_REPORTS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_ELECTRIC_METER_MONTHLY_REPORT: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS: {
            return {
                ...state,
                loading: false,
                monthlyReport: action.payload
            };
        }
        case GET_ELECTRIC_METER_MONTHLY_REPORT_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case UPDATE_ELECTRIC_METER_MONTHLY_REPORT: {
            return {
                ...state,
                loading: true
            };
        }
        case UPDATE_ELECTRIC_METER_MONTHLY_REPORT_SUCCESS: {
            return {
                ...state,
                loading: false,
                monthlyReports: {
                    ...state.monthlyReports,
                    data: state.monthlyReports.data.map(
                        (configUnit, ind) => configUnit.id === action.payload.id ?
                            {
                                ...configUnit,
                                newNumber: action.payload.newNumber,
                                reportImages: action.payload.reportImages
                            }
                            : configUnit
                    )
                }
            };
        }
        case UPDATE_ELECTRIC_METER_MONTHLY_REPORT_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }

        case GET_CONFIG_ELECTRIC_METER: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_CONFIG_ELECTRIC_METER_SUCCESS: {
            return {
                ...state,
                loading: false,
                configUnit: action.payload
            };
        }
        case GET_CONFIG_ELECTRIC_METER_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_CONFIG_ELECTRIC_METERS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_CONFIG_ELECTRIC_METERS_SUCCESS: {
            return {
                ...state,
                loading: false,
                configUnits: action.payload
            };
        }
        case GET_CONFIG_ELECTRIC_METERS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case UPDATE_CONFIG_ELECTRIC_METER: {
            return {
                ...state,
                loading: true
            };
        }
        case UPDATE_CONFIG_ELECTRIC_METER_SUCCESS: {
            return {
                ...state,
                loading: false,
                configUnit: action.payload
            };
        }
        case UPDATE_CONFIG_ELECTRIC_METER_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        case GET_APARTMENT: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_APARTMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                apartment: action.payload
            };
        }
        case GET_APARTMENT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_DASHBOARD_SUCCESS: {
            return {
                ...state,
                loading: false,
                dashboard: action.payload
            };
        }
        case  GET_DASHBOARD_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_FLOORS_BY_APARTMENT: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_FLOORS_BY_APARTMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                floorsByApartment: action.payload
            };
        }
        case GET_FLOORS_BY_APARTMENT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_CONFIGS_GOODS: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_CONFIGS_GOODS_SUCCESS: {
            return {
                ...state,
                loading: false,
                configsGoods: action.payload
            };
        }
        case GET_CONFIGS_GOODS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case UPDATE_CONFIG_GOOD: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case UPDATE_CONFIG_GOOD_SUCCESS: {
            return {
                ...state,
                loading: false,
                configsGoods: {
                    ...state.configsGoods,
                    data: state.configsGoods.data.map(x => x.id === action.payload.id ?
                        {
                            ...x,
                            code: action.payload.code,
                            name: action.payload.name,
                            brand: action.payload.brand,
                            origin: action.payload.origin,
                            unit: action.payload.unit
                        }
                        :
                        x
                    )
                }
            };
        }
        case UPDATE_CONFIG_GOOD_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case UPDATE_GOODS_RECEIPT: {
            return {
                ...state,
                loading: true,
                error: true
            };
        }
        case UPDATE_GOODS_RECEIPT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                goodsReceipt: action.payload
            };
        }
        case UPDATE_GOODS_RECEIPT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case GET_EXPENDITURES: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_EXPENDITURES_SUCCESS: {
            return {
                ...state,
                loading: false,
                expenditures: action.payload
            };
        }
        case GET_EXPENDITURES_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }
        case GET_EXPENDITURE: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_EXPENDITURE_SUCCESS: {
            return {
                ...state,
                loading: false,
                expenditure: action.payload
            };
        }
        case GET_EXPENDITURE_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case UPDATE_EXPENDITURE: {
            return {
                ...state,
                loading: true
            };
        }
        case UPDATE_EXPENDITURE_SUCCESS: {
            return {
                ...state,
                loading: false,
                expenditures: {
                    ...state.expenditures,
                    data: state.expenditures.data.map(
                        (expenditure, ind) => expenditure.id === action.payload.id ?
                            {
                                ...expenditure,
                                amount: action.payload.amount,
                                type: action.payload.type,
                                goodsReceiptId: action.payload.goodsReceiptId,
                                diffNumber: action.payload.diffNumber
                            }
                            : expenditure
                    )
                }
            };
        }
        case UPDATE_EXPENDITURE_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }
        case IMPORT_METER: {
            return {
                ...state,
                loading: true
            };
        }
        case IMPORT_METER_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case IMPORT_METER_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        default: {
            return state;
        }
    }
};

export default ApartmentReducer;
