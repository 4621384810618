import {lazy} from "react";

const TaskList = lazy(() => import("./list/TaskList"));
const TaskScheduleList = lazy(() => import("./schedules/TaskScheduleList"));
const TaskDetail = lazy(() => import("./detail/TaskDetail"));

const taskRoutes = [

    {
        path: "/tasks/:id",
        component: TaskDetail
    },
    {
        path: "/tasks",
        component: TaskList
    },
    {
        path: "/task-schedules",
        component: TaskScheduleList
    }
];

export default taskRoutes;
