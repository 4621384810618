import {
    DOWNLOAD_FILE,
    DOWNLOAD_FILE_FAILURE,
    DOWNLOAD_FILE_SUCCESS,
    UPLOAD_FILES,
    UPLOAD_FILES_FAILURE,
    UPLOAD_FILES_SUCCESS,
    VIEW_FILE,
    VIEW_FILE_FAILURE,
    VIEW_FILE_SUCCESS
} from "../actions/uploadAction";

const ContractReducer = function(state = {error: false, loading: false}, action) {
    switch (action.type) {
        case UPLOAD_FILES: {
            return {
                ...state,
                loading: true
            };
        }
        case UPLOAD_FILES_SUCCESS: {
            return {
                ...state,
                loading: false,
                files: action.payload
            };
        }
        case UPLOAD_FILES_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case DOWNLOAD_FILE: {
            return {
                ...state,
                loading: true
            };
        }
        case DOWNLOAD_FILE_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case DOWNLOAD_FILE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case VIEW_FILE: {
            return {
                ...state,
                loading: true,
                url:action.url
            };
        }
        case VIEW_FILE_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case VIEW_FILE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        default: {
            return state;
        }
    }
};

export default ContractReducer;
