import { MIME_TYPES } from 'constants/Constants'
import instance from './axiosInstance'
import _ from 'lodash'
import exportUtils from 'utils/exportUtils'

class SubscriptionService {
    getGroupGoodsServiceByApartment = (apartmentId) => {
        return instance
            .get('/ss/goods_service/staff/group', {
                params: {
                    apartmentId: apartmentId
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    createGroupGoodsService = (data) => {
        return instance
            .post('/ss/goods_service/group', {
                galleries: data.galleries,
                imageUrl: data.imageUrl.uri ? data.imageUrl.uri : data.imageUrl,
                serviceType: data.serviceType,
                description: data.description,
                apartmentId: data.apartmentId,
                displayAmount: data.displayAmount
            })
            .then(({ data }) => {
                return data
            })
    }

    updateGroupGoodsService = (data) => {
        return instance
            .put('/ss/goods_service/staff/group/' + data.id, {
                galleries: data.galleries,
                imageUrl: data.imageUrl.uri ? data.imageUrl.uri : data.imageUrl,
                description: data.description,
                displayAmount: data.displayAmount
            })
            .then(({ data }) => {
                return data
            })
    }

    getGroupGoodServiceById = (id) => {
        return instance.get('/ss/goods_service/staff/group/' + id, {}).then(({ data }) => {
            return data
        })
    }

    createGoodService = (data) => {
        return instance
            .post('/ss/goods_service', {
                name: data.name,
                fee: data.fee,
                type: data.type,
                quantity: data.quantity,
                description: data.description,
                imageUrl: data.imageUrl?.uri,
                location: data.location,
                isPayFirst: data.isPayFirst,
                priority: data.priority,
                // isDefaultService: data.isDefaultService,
                isRequireApprove: data.isRequireApprove,
                apartmentId: data.apartmentId,
                groupGoodsServiceId: data.groupGoodsServiceId
            })
            .then(({ data }) => {
                return data
            })
    }

    updateGoodService = (data) => {
        return instance
            .put('/ss/goods_service/' + data.id, {
                name: data.name,
                fee: data.fee,
                type: data.type,
                quantity: data.quantity,
                description: data.description,
                imageUrl: data.imageUrl?.uri ? data.imageUrl?.uri : data.imageUrl,
                location: data.location,
                isPayFirst: data.isPayFirst,
                priority: data.priority,
                // isDefaultService: data.isDefaultService,
                isRequireApprove: data.isRequireApprove
            })
            .then(({ data }) => {
                return data
            })
    }

    getGoodServiceById = (id) => {
        return instance.get('/ss/goods_service/staff/' + id, {}).then(({ data }) => {
            return data
        })
    }

    getGoodServices = (apartmentId) => instance.get('/ss/goods_service/apartment/' + apartmentId, {}).then(({ data }) => data)

    getServiceResources = (goodServiceId) => {
        return instance.get('/ss/service-resource/get-by-goods-service/' + goodServiceId, {}).then(({ data }) => {
            return data
        })
    }

    createServiceResource = (values) => {
        return instance
            .post('/ss/service-resource', {
                name: values.name,
                imageUrl: values.imageUrl,
                goodsServiceId: values.goodsServiceId
            })
            .then(({ data }) => {
                return data
            })
    }

    getSubscriptionsByResident = (residentId) => {
        return instance.get('/ss/subscription/staff/get-resident-subscription/' + residentId, {}).then(({ data }) => {
            return data
        })
    }

    getSubscriptions = (apartmentId, residentId, unitId, state, page, size) => {
        return instance
            .get('/ss/subscription/staff/apartment/' + apartmentId, {
                params: {
                    residentId: residentId,
                    unitId: unitId,
                    state: state,
                    page: page,
                    size: size
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    v2getSubscriptions = ({ apartmentId, ...params }) => instance.get(`/ss/subscription/staff/apartment/${apartmentId}`, { params }).then(({ data }) => data)

    approveSubscription = (subscriptionId) => {
        return instance.put('/ss/subscription/approve/' + subscriptionId, {}).then(({ data }) => {
            return data
        })
    }

    rejectSubscription = (subscriptionId) => {
        return instance.put('/ss/subscription/reject/' + subscriptionId, {}).then(({ data }) => {
            return data
        })
    }

    // registerSubscription = (values) => {
    //     return instance
    //         .post("/ss/subscription/staff", {
    //             residentId: values.residentId,
    //             unitId: values.unitId,
    //             goodsServiceId: values.goodsServiceId,
    //             description: values.description,
    //             isAutoRenew: values.isAutoRenew,
    //             paymentMethod: values.paymentMethod,
    //             payload: values.payload
    //         })
    //         .then(({data}) => {
    //             return data;
    //         });
    // } old func

    /**
     * Registers a new subscription with the provided values.
     *
     * @param {Object} values - The subscription values to register.
     * @param {string} values.residentId - The ID of the resident.
     * @param {string} values.unitId - The ID of the unit.
     * @param {string} values.goodsServiceId - The ID of the goods/service.
     * @param {string} values.description - The description of the subscription.
     * @param {boolean} values.isAutoRenew - Whether the subscription is set to auto-renew.
     * @param {string} values.paymentMethod - The payment method for the subscription.
     * @param {string} values.fromDate - The fromDate for the subscription.
     * @param {string} values.toDate - The toDate for the subscription.
     * @param {any} values.payload - The payload data for the subscription.
     * @returns {Promise<any>} - A promise that resolves to the response data.
     */
    v2registerSubscription = (values) => instance.post('/ss/subscription/staff', values).then(({ data }) => data)

    registerSubscription = (values, serviceType) => {
        const payloadDynamic = {
            BBQ: values.bbq,
            PARKING: values.parking
        }
        return instance
            .post('/ss/subscription/staff', {
                residentId: values.residentId,
                unitId: values.unitId,
                paymentMethod: values.paymentMethod,
                autoRenew: values.autoRenew,
                description: values.description,
                goodsServiceId: values.goodsServiceId,
                payload: payloadDynamic[serviceType]
            })
            .then(({ data }) => {
                return data
            })
    }

    getBillsMonthly = (apartmentId, params) => {
        return instance
            .get('/ss/bill-monthly/apartment/' + apartmentId + '/v2', {
                params: {
                    apartmentId: apartmentId,
                    fromDate: params.month.startOf('month').toISOString(),
                    toDate: params.month.endOf('month').toISOString(),
                    residentId: params.residentId,
                    status: params.status,
                    page: params.page,
                    size: params.size
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    v2getBillsMonthly = (apartmentId, params) =>
        instance
            .get('/ss/bill-monthly/apartment/' + apartmentId + '/v2', {
                params: { apartmentId: apartmentId, ...params }
            })
            .then(({ data }) => data)

    paidBill = (billId, amount) => {
        return instance.put('/ss/bill-monthly/paid/' + billId + '?amount=' + amount, {}).then(({ data }) => {
            return data
        })
    }

    createBillsMonthly = (apartmentId, values) => {
        return instance
            .post('/ss/bill-monthly/apartment/' + apartmentId + '/resident', {
                residents: values.residents
            })
            .then(({ data }) => {
                return data
            })
    }

    downloadBill = (id) => {
        return instance
            .get('/ss/bill-monthly/' + id + '/export', {
                responseType: 'arraybuffer'
            })
            .then(({ data }) => {
                return data
            })
    }

    getBillMonthly = (residentMonthlyBillId) => instance.get(`/ss/bill-monthly/resident/bill/${residentMonthlyBillId}`).then(({ data }) => data)

    getBillMonthlyTemplate = (monthlyBillId) => instance.get(`/ss/bill-monthly/${monthlyBillId}/export/hashmap`).then(({ data }) => data)

    getBillQr = (id) => {
        return instance.get(`https://api-uat.prohomepmc.com.vn/ss/bill-monthly/resident/bill/${id}/qr`, {}).then(({ data }) => {
            return data
        })
    }

    remindBill = (ids) => {
        return instance.post('/ss/bill-monthly/remind', { ids }).then(({ data }) => data)
    }
    getListOfBillsMonthly = (params) =>
        instance
            .get('/ss/bill-monthly/filter', {
                params: {
                    apartmentId: params?.apartmentId,
                    unitId: params?.unitId,
                    page: params.page,
                    size: params.size
                }
            })
            .then(({ data }) => data)

    createBillsMonthlyByResidents = (apartmentId, residents) => instance.post(`/ss/bill-monthly/apartment/${apartmentId}/resident`, { residents }).then(({ data }) => data)

    createBillsMonthlyByUnits = ({ units, monthDate }) => instance.post('/ss/bill-monthly/monthly-date/units', { units, monthDate }).then(({ data }) => data)

    //////////////////////////////////////////////////////////////
    getSubscription = (subscriptionId) => instance.get(`/ss/subscription/staff/${subscriptionId}`, {}).then(({ data }) => data)
    patchSubscription = (subscriptionId, data) => instance.patch(`/ss/subscription/${subscriptionId}`, data).then(({ data }) => data)

    subscription = {
        getSubscriptions: ({ apartmentId, ...params }) => instance.get(`/ss/subscription/staff/apartment/${apartmentId}`, { params }).then(({ data }) => data),
        getSubscription: (subscriptionId) => instance.get(`/ss/subscription/staff/${subscriptionId}`, {}).then(({ data }) => data),
        // createSubscription data: {residentId, unitId, goodsServiceId, description, isAutoRenew, paymentMethod, fromDate, toDate, payload}
        createSubscription: (data) => instance.post('/ss/subscription/staff', data),
        updateSubscription: (subscriptionId, data) => instance.patch(`/ss/subscription/${subscriptionId}`, data),
        approveSubscription: (subscriptionId) => instance.put(`/ss/subscription/approve/${subscriptionId}`),
        rejectSubscription: (subscriptionId) => instance.put(`/ss/subscription/reject/${subscriptionId}`)
    }

    incomeExpense = {
        getIncomeExpenses: (params) => instance.get('/ss/income-expense/filter', { params }).then(({ data }) => data),
        createIncomeExpense: (data) => instance.post('/ss/income-expense', data),
        updateIncomeExpense: (id, data) => instance.put(`/ss/income-expense/${id}`, data),
        cancelIncomeExpenses: (id) => instance.post(`/ss/income-expense/cancel/${id}`),
        approveIncomeExpenses: (id) => instance.post(`/ss/income-expense/approve/${id}`),
        downloadIncomeExpenses: (id) =>
            instance.get(`/ss/income-expense/export/${id}`, { responseType: 'arraybuffer' }).then(({ data }) => {
                const blob = new Blob([data], { type: 'application/pdf' })
                const url = window.URL.createObjectURL(blob)
                window.open(url)
            }),
        // incomeExpense for subscription
        getSubscriptionIncomeExpenses: (subscriptionId, params) => instance.get(`/ss/subscription/${subscriptionId}/income-expense`, { params }).then(({ data }) => data),
        createSubscriptionIncomeExpense: (subscriptionId, data) => instance.post(`/ss/subscription/${subscriptionId}/income-expense`, data)
    }

    parkingSubscription = {
        getParkingSubscriptions: (params) =>
            instance
                .get('/ss/parking-subscription', {
                    params: _.omit(
                        {
                            ...params,
                            ...(params?.registerDate ? { toFromDate: params.registerDate?.[1], fromFromDate: params.registerDate?.[0] } : {}),
                            ...(params?.expiredDate ? { toToDate: params.expiredDate?.[1], fromToDate: params.expiredDate?.[0] } : {})
                        },
                        ['dateArrive', 'dateLeave', 'registerDate', 'expiredDate']
                    )
                })
                .then(({ data }) => data),
        exportParkingSubscriptions: (params) =>
            instance.post('/ss/parking-subscription/export', params, { responseType: 'blob' }).then((response) => exportUtils.saveFile('export_parking-subscription.xlsx', response.data, { type: MIME_TYPES.XLSX })),
        // createParkingSubscription data: {unitId, parkingLotId, residentId, requestType, licensePlate, remark, fromDate, brand, idNumber, owner, type, toDate }
        createParkingSubscription: (data) => instance.post('/ss/parking-subscription/staff/subscribe', data),
        // updateParkingSubscription data: {unitId, parkingLotId, residentId, requestType, licensePlate, remark, fromDate, brand, idNumber, owner, type, toDate }
        updateParkingSubscription: ([id, data]) => instance.put(`/ss/parking-subscription/staff/${id}`, data),
        cancelParkingSubscription: ([id, toDate]) => instance.post(`/ss/parking-subscription/staff/cancel/${id}`, { toDate }),
        changeRequestType: (parkingLotId, requestType) => instance.patch(`/ss/parking-subscription/${parkingLotId}`, { requestType }),
        changeCardMagnetUuid: (parkingLotId, cardMagnetUuid) => instance.patch(`/ss/parking-subscription/${parkingLotId}`, { cardMagnetUuid })
    }

    monthlyBill = {
        getUnitForBillCreate: (params) => instance.get('/ss/bill-monthly/resident-no-bill', { params }).then(({ data }) => data),
        getBills: (params) => instance.get('/ss/bill-monthly/n/filter', { params }).then(({ data }) => data),
        getBillDetail: (monthlyBillId) => instance.get(`/ss/bill-monthly/n/filter`, { params: { id: monthlyBillId } }).then(({ data }) => data?.data?.[0]),
        getBillTemplate: (monthlyBillId) => instance.get(`/ss/bill-monthly/${monthlyBillId}/export/hashmap`).then(({ data }) => data),
        createBills: ({ units, monthDate }) => instance.post('/ss/bill-monthly/monthly-date/units', { units, monthDate }),
        deleteBills: (ids) => instance.delete(`/ss/bill-monthly/delete`, { data: { ids } }),
        remindBills: (ids) => instance.post(`/ss/bill-monthly/remind`, { ids }),
        remindBillAll: (filter) => instance.post(`/ss/bill-monthly/remind/all`, { filter }),
        approveBills: (ids) => instance.post('/ss/bill-monthly/approve', { ids }),
        approveBillAll: (filter) => instance.post('/ss/bill-monthly/approve/all', { filter }),
        downloadBill: (id, language) => instance.get('/ss/bill-monthly/' + id + '/export', { responseType: 'blob', params: { language: language ?? 'vi' } }).then((response) => exportUtils.exportFile(`bill_${id}_${language ?? 'vi'}.pdf`, response.data, { type: MIME_TYPES.PDF })),
        printBill: (params) => instance.post('/ss/bill-monthly/export/multiple', params, { responseType: 'blob' }).then((response) => exportUtils.exportFile(`print_bill.pdf`, response.data, { type: MIME_TYPES.PDF })),
    }

    apartmentConfig = {
        getConfigFees: (apartmentId) =>
            instance.get('/ss/apartment-fee-config/apartment/' + apartmentId, {}).then((res) => ({
                ...res.data,
                data: Object.entries(_.groupBy(res.data?.data ?? [], 'categoryFee')).map(([label, children]) => {
                    return {
                        id: label,
                        serviceName: label,
                        label: `config.field.serviceName.${label}`,
                        root: 0,
                        children: Object.entries(_.groupBy(children, 'unitType')).map(([unitType, unitChildren]) => ({
                            id: label + unitType,
                            serviceName: unitType,
                            label: `config.field.serviceName.${label}.${unitType}`,
                            root: 1,
                            children: unitChildren.map((i) => ({
                                ...i,
                                label: `config.field.serviceName.${label}.${i.serviceName}`,
                                root: 2
                            }))
                        }))
                    }
                })
            })),
        getConfigPath: (apartmentId) =>
            instance
                .get('/ss/apartment-fee-config/apartment/' + apartmentId, {})
                .then((res) => (res.data?.data ?? []).reduce((t, a) => ({ ...t, [(a.categoryFee?.concat('.') ?? '') + (a.unitType?.concat('.') ?? '') + a.serviceName]: a }), {})),
        createConfig: (values) => instance.post(`/ss/apartment-fee-config`, values).then(({ data }) => data),
        updateConfig: (id, values) => instance.put(`/ss/apartment-fee-config/${id}`, values).then(({ data }) => data)
    }
}

export default new SubscriptionService()
