import React, { useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import authService from 'services/authService'

const AuthContext = React.createContext({})

export const AuthProvider = ({ children }) => {
    const [loaded, setLoaded] = useState(false)
    const [user, setUser] = useState(null)

    useEffect(() => {
        const loggedUser = localStorage.getItem('authUser')
        const accessToken = localStorage.getItem('accessToken')
        
        const processRefreshAuthToken = async () => {
            await refreshAuthToken()
            setLoaded(true)
        }

        if (loggedUser && accessToken) {
            const decodedToken = jwtDecode(accessToken)
            if (decodedToken.exp * 1000 < Date.now()) {
                processRefreshAuthToken()
            } else {
                setUser(JSON.parse(loggedUser))
                setLoaded(true)
            }
        } else {
            setLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const signin = (tokens) => {
        const decodedToken = jwtDecode(tokens.accessToken)
        const userData = decodedToken

        localStorage.setItem('authUser', JSON.stringify(userData))
        localStorage.setItem('accessToken', tokens.accessToken)
        localStorage.setItem('refreshToken', tokens.refreshToken)
        setUser(userData)
    }

    const signout = () => {
        localStorage.removeItem('authUser')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        setUser(null)
    }

    const refreshAuthToken = async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken')

            if (!refreshToken) {
                signout()
                return
            }

            const data = await authService.refreshToken(refreshToken)
            if (data.accessToken && data.refreshToken) {
                signin(data)
            } else {
                signout()
            }
        } catch (error) {
            console.error('Failed to refresh token', error)
            signout()
        }
    }

    if (!loaded) {
        return <></>
    }

    return <AuthContext.Provider value={{ user, signin, signout, refreshAuthToken, isAuthenticated: !!user }}>{children}</AuthContext.Provider>
}

export const AuthGuard = withRouter(({ children }) => {
    const { isAuthenticated } = useAuth()

    if (isAuthenticated) {
        return children
    }

    return <Redirect to="/session/signin" />
})

export const useAuth = () => React.useContext(AuthContext)

export default AuthContext
