import {GET_FORMS, GET_FORMS_FAILURE, GET_FORMS_SUCCESS} from "../actions/onboardFormActions";

const OnboardFormReducer = function(state = {}, action) {
    switch (action.type) {
        case GET_FORMS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_FORMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                forms: action.payload
            };
        }
        case GET_FORMS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        default: {
            return state;
        }
    }
};

export default OnboardFormReducer;
