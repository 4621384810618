import jwt_decode from 'jwt-decode'
import moment from 'moment'
import localStorageService from 'services/localStorageService'
import queryString from 'query-string'
import _ from 'lodash'
import { MIME_TYPES } from 'constants/Constants'

export function getParams(search) {
    return JSON.parse(queryString.parse(search)?.s ?? '{}')
}
export function setUrlSearch(search, moreKeys) {
    return JSON.stringify(
        _.omit(
            _.omitBy(search, v => v === null || v === undefined || v === ''),
            moreKeys
        )
    )
}
export function debounce(func, wait, immediate) {
    var timeout
    return function () {
        var context = this,
            args = arguments
        clearTimeout(timeout)
        timeout = setTimeout(function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }, wait)
        if (immediate && !timeout) func.apply(context, args)
    }
}

export function isMobile() {
    if (window) {
        return window.matchMedia(`(max-width: 767px)`).matches
    }
    return false
}

export function isMdScreen() {
    if (window) {
        return window.matchMedia(`(max-width: 1199px)`).matches
    }
    return false
}

function currentYPosition() {
    if (!window) {
        return
    }
    // Firefox, Chrome, Opera, Safari
    if (window.pageYOffset) return window.pageYOffset
    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop) return document.documentElement.scrollTop
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) return document.body.scrollTop
    return 0
}

function elmYPosition(elm) {
    var y = elm.offsetTop
    var node = elm
    while (node.offsetParent && node.offsetParent !== document.body) {
        node = node.offsetParent
        y += node.offsetTop
    }
    return y
}

export function scrollTo(scrollableElement, elmID) {
    var elm = document.getElementById(elmID)
    if (!elmID || !elm) {
        return
    }
    var startY = currentYPosition()
    var stopY = elmYPosition(elm)
    var distance = stopY > startY ? stopY - startY : startY - stopY
    if (distance < 100) {
        scrollTo(0, stopY)
        return
    }
    var speed = Math.round(distance / 50)
    if (speed >= 20) speed = 20
    var step = Math.round(distance / 25)
    var leapY = stopY > startY ? startY + step : startY - step
    var timer = 0
    if (stopY > startY) {
        for (var i = startY; i < stopY; i += step) {
            setTimeout(
                (function (leapY) {
                    return () => {
                        scrollableElement.scrollTo(0, leapY)
                    }
                })(leapY),
                timer * speed
            )
            leapY += step
            if (leapY > stopY) leapY = stopY
            timer++
        }
        return
    }
    for (let i = startY; i > stopY; i -= step) {
        setTimeout(
            (function (leapY) {
                return () => {
                    scrollableElement.scrollTo(0, leapY)
                }
            })(leapY),
            timer * speed
        )
        leapY -= step
        if (leapY < stopY) leapY = stopY
        timer++
    }
    return false
}

export function getTimeDifference(date) {
    let difference = moment(new Date()).diff(moment(date)) / 1000

    if (difference < 60) return `${Math.floor(difference)} seconds ago`
    else if (difference < 3600) return `${Math.floor(difference / 60)} minutes ago`
    else if (difference < 86400) return `${Math.floor(difference / 3660)} hours ago`
    else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} days ago`
    else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} months ago`
    else return `${(difference / 86400 / 30 / 12).toFixed(1)} years ago`
}

export function generateRandomId() {
    let tempId = Math.random().toString()
    let uid = tempId.substr(2, tempId.length - 1)
    return uid
}

export function getQueryParam(prop) {
    var params = {}
    var search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1))
    var definitions = search.split('&')
    definitions.forEach(function (val, key) {
        var parts = val.split('=', 2)
        params[parts[0]] = parts[1]
    })
    return prop && prop in params ? params[prop] : params
}

export function classList(classes) {
    return Object.entries(classes)
        .filter(entry => entry[1])
        .map(entry => entry[0])
        .join(' ')
}

export function initCodeViewer() {
    if (!document) return
    const pre = document.getElementsByTagName('pre')
    if (!pre.length) return
    Array.prototype.map.call(pre, p => {
        // console.log(p);
        p.classList.add('collapsed')
        p.addEventListener('click', e => {
            console.log(e.target)
            e.target.classList.remove('collapsed')
        })
    })
}

export const isExpired = token => {
    let jwt = jwt_decode(token)
    if (moment(Date.now()) / 1000 > jwt.exp - 5) {
        return true
    }

    return false
}

export const getEmployeeId = () => {
    let user = localStorageService.getItem('auth_user')
    if (!user) {
        return null
    }
    return user.sub
}

export const getOrgId = () => {
    let user = localStorageService.getItem('auth_user')
    if (!user) {
        return null
    }
    return user.organizationId
}

export const isToday = date => {
    const today = new Date()
    let cDate = moment(date)
    return cDate.date() === today.getDate() && cDate.month() === today.getMonth() && cDate.year() === today.getFullYear()
}

export const getFromToDate = rangeDate => {
    switch (rangeDate) {
        case 'today':
            return {
                fromDate: moment().startOf('day'),
                toDate: moment().endOf('day')
            }
        case 'yesterday':
            return {
                fromDate: moment().subtract(1, 'day').startOf('day'),
                toDate: moment().subtract(1, 'day').endOf('day')
            }

        case 'this_week':
            return {
                fromDate: moment().startOf('week'),
                toDate: moment().endOf('week')
            }
        case 'this_month':
            return {
                fromDate: moment().startOf('month'),
                toDate: moment().endOf('month')
            }
        default:
            return null
    }
}

export const hasAnyAuthorities = authorities => {
    // let user = localStorageService.getItem('auth_user')

    let user = {
        roles: ['APARTMENT_MANAGEMENT', 'RESIDENT_MANAGEMENT', 'SERVICE_MANAGEMENT', 'TECHNICAL_MANAGEMENT', 'FINANCE_MANAGEMENT', 'EMPLOYEE_MANAGEMENT', 'SYSTEM_MANAGEMENT', 'REPORT_MANAGEMENT']
    }

    if (!user) {
        return false
    }
    return authorities.some(r => user.roles.includes(r))
}

export const hasAnySystemRole = () => {
    let user = localStorageService.getItem('auth_user')
    if (!user) {
        return false
    }
    return ['ROLE_VBMS', 'ROLE_BQL'].some(r => user.roles.includes(r))
}

export const isVbms = () => {
    let user = localStorageService.getItem('auth_user')
    if (!user) {
        return false
    }
    return user.roles.includes('ROLE_VBMS')
}

export const isHidePermission = permission => {
    if (permission.resource === 'PERMISSION' || permission.resource === 'BQL') {
        if (!isVbms()) {
            switch (permission.slug) {
                case 'EDIT_PERMISSION':
                    return true
                case 'CREATE_PERMISSION':
                    return true
                case 'DELETE_PERMISSION':
                    return true
                case 'CREATE_ORG':
                    return true
                default:
                    return false
            }
        }
    }
    return false
}

export const getDepartmentId = () => {
    if (hasAnySystemRole()) {
        return null
    }
    let user = localStorageService.getItem('auth_user')
    return user?.departmentId
}

export const getFileName = path => {
    return path.substr(path.lastIndexOf('/') + 1)
}

export const buildPicturesWall = paths => {
    if (paths === '' || paths === null) {
        return []
    }
    return paths.split(',').map((item, ind) => ({
        uid: ind,
        name: getFileName(item),
        status: 'done',
        url: item,
        response: {
            name: getFileName(item),
            uri: item
        }
    }))
}

export const getPathsString = fileList => {
    let paths = []
    fileList.forEach((file, ind) => {
        if (file.status === 'done') {
            if (file.response) {
                paths.push(file.response.uri)
            }
        }
    })
    return paths.join(',')
}

export const getApartmentId = () => {
    return localStorage.getItem('apartment_id')
}

export const openBlob = (blob, fileName) => {
    const fileExtension = fileName.split('.').pop().toUpperCase()
    const url = window.URL.createObjectURL(new Blob([blob], { type: MIME_TYPES[fileExtension] ?? MIME_TYPES.BIN }))

    // Mã hóa tên file để tránh các ký tự đặc biệt
    const encodedFileName = encodeURIComponent(fileName)

    // Thêm tên file vào URL như một tham số truy vấn
    const urlWithFileName = `${url}#${encodedFileName}`

    // Mở URL trong một tab mới
    window.open(urlWithFileName, '_blank')

    // Giải phóng URL object để tránh rò rỉ bộ nhớ
    setTimeout(() => window.URL.revokeObjectURL(url), 1000)
}
