import roleModule from './roleModule'
import PageTabsLayout from 'layout/PageTabsLayout'

const roleRoutes = [
  {
    exact: false,
    path: '/r',
    component: PageTabsLayout,
    routes: [roleModule.pages.RoleListing]
  }
]

export default roleRoutes
