import {lazy} from "react";

const Chat = lazy(() => import("./chat/chat"))

const chatRoutes = [
    {
        path: "/chat",
        component: Chat
    },
];

export default chatRoutes;
