import {lazy} from "react";

const WorkOrderDetail = lazy(() => import("./detail/WorkOrderDetail"));

const workOrderRouters = [
    {
        path: "/wo/maintenances/:id",
        component: WorkOrderDetail
    },
    // {
    //     path: "/wo/maintenances",
    //     component: lazy(() => import("./list/WorkOrderList"))
    // },
    {
        path: "/wo/feedbacks/:id",
        component: WorkOrderDetail
    },
    // {
    //     path: "/wo/feedbacks",
    //     component: lazy(() => import("./list/WorkOrderList"))
    // }
];

export default workOrderRouters;
