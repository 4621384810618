import { PlusOutlined } from '@ant-design/icons'
import { setUrlSearch } from '@utils'
import { lazy } from 'react'

const residentModule = {
    pages: {
        ResidentListing: {
            label: 'resident.head',
            path: '/residents',
            getLink: (filter) => `/residents/?s=${setUrlSearch(filter)}`,
            component: lazy(() => import('./pages/ResidentList')),
            actions: (eventEmitter) => ({
                actions: [
                    {
                        key: 'add',
                        label: 'common.button.add',
                        style: 'primary',
                        icon: <PlusOutlined />,
                        onClick: () => eventEmitter.emit('layout', ['onCreate', {}])
                    }
                ]
            })
        },
        ResidentNewsDetail: {
            path: '/residents/news/:id',
            getLink: ({ id }) => `/residents/news/${id}`,
            component: lazy(() => import('./detail/NewsDetail'))
        },
        ResidentDetail: {
            path: '/residents/:id',
            getLink: ({ id }) => `/residents/${id}`,
            component: lazy(() => import('./detail/ResidentDetail'))
        }
    }
}

export default residentModule
