import instance from './axiosInstance'
import moment from 'moment/moment'

class ReportService {
  getWarehouseReports = params => {
    return instance
      .get('/rp/warehouse', {
        params: {
          warehouseId: params.warehouseId,
          fromDate: params.fromDate && params.fromDate.toISOString(),
          toDate: params.toDate && params.toDate.toISOString(),
          goodsId: params.goodsId,
          type: params.type,
          page: params.page,
          size: params.size
        }
      })
      .then(({ data }) => {
        return data
      })
  }
  getConfigReports = reportId => {
    return instance.get('/rp/config/ops/' + reportId, {}).then(({ data }) => {
      return data
    })
  }

  exportWarehouseReports = params => {
    return instance
      .get('/rp/warehouse/export', {
        params: {
          warehouseId: params?.warehouseId,
          fromDate: params?.fromDate && params.fromDate.toISOString(),
          toDate: params?.toDate && params.toDate.toISOString(),
          goodsId: params?.goodsId,
          type: params?.type
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportReports = (reportId, params) => {
    return instance
      .get('/rp/report/' + reportId, {
        params: params,
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  v2exportResidents = apartmentID => {
    return instance.get(`/rp/report/resident/${apartmentID}`).then(data => {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      let blob = new Blob([data], { type: fileType })
      let url = window.URL.createObjectURL(blob)
      window.open(url)
      return blob
    })
  }

  getResidentIndexReports = (apartmentId, params) => {
    if (params.residentId !== null && params.residentId !== undefined) {
      return instance
        .get('/as/electric-meter/apartment/' + apartmentId + '/resident/' + params.residentId + '/v2', {
          params: {
            type: params.type,
            from: params.fromDate && params.fromDate.toISOString(),
            to: params.toDate && params.toDate.toISOString()
          }
        })
        .then(({ data }) => {
          return data
        })
    } else {
      return instance
        .get('/as/electric-meter/summary/apartment/' + apartmentId + '/v2', {
          params: {
            type: params.type,
            from: params.fromDate && params.fromDate.toISOString(),
            to: params.toDate && params.toDate.toISOString()
          }
        })
        .then(({ data }) => {
          return data
        })
    }
  }

  exportSummaryWarehouseReports = (apartmentId, month) => {
    return instance
      .get('/rp/report/warehouse/summary/' + apartmentId, {
        params: {
          month: month.month() + 1,
          year: month.year()
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportSummaryWarehouseReportsByQuarter = (apartmentId, quarter, year) => {
    return instance
      .get('/rp/report/warehouse/summary/' + apartmentId + '/quarter/' + quarter, {
        params: {
          year: year.year()
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportSummaryParkingSubscriptionReport = apartmentId => {
    return instance
      .get('/rp/report/parking/' + apartmentId, {
        params: {},
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportParkingSubscriptionRequestReport = (apartmentId, params) => {
    return instance
      .get('/rp/report/parking/' + apartmentId + '/request', {
        params: {
          residentId: params?.residentId,
          fromDate: params?.fromDate && params.fromDate.toISOString(),
          toDate: params?.toDate && params.toDate.toISOString(),
          requestType: params?.requestType
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportSummaryContractReport = (apartmentId, params) => {
    console.log(params)
    return instance
      .get('/rp/contract/export', {
        params: {
          apartmentId: apartmentId,
          quarter: params?.quarter.quarter(),
          year: params?.quarter.year(),
          size: 10000
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportConstructionOvertimeReport = (apartmentId, params) => {
    return instance
      .get('/rp/report/construction/overtime/' + apartmentId, {
        params: {
          residentId: params.residentId,
          fromDate: params?.fromDate && params.fromDate.toISOString(),
          toDate: params?.toDate && params.toDate.toISOString()
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportPCCCOvertimeReport = (apartmentId, params) => {
    return instance
      .get('/rp/report/construction/pccc/' + apartmentId, {
        params: {
          residentId: params.residentId,
          fromDate: params?.fromDate && params.fromDate.toISOString(),
          toDate: params?.toDate && params.toDate.toISOString()
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportWatchmanActivityReport = (apartmentId, params) => {
    return instance
      .get('/rp/report/watchman_activities', {
        params: {
          apartmentId: apartmentId,
          fromDate: params?.date.startOf('day').toISOString(),
          toDate: params?.date.endOf('day').toISOString()
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportSanitationReport = (apartmentId, params) => {
    return instance
      .get('/rp/report/sanitation', {
        params: {
          apartmentId: apartmentId,
          fromDate: params?.date.startOf('day').toISOString(),
          toDate: params?.date.endOf('day').toISOString()
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportChecklist = (apartmentId, params) => {
    return instance
      .get('/rp/report/checklist/' + apartmentId, {
        params: {
          fromDate: params?.fromDate.toISOString(),
          toDate: params?.toDate.toISOString(),
          code: params.checklistTemplateId,
          floorId: params.floorId,
          departmentId: params.departmentId
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportWorkingOvertimeReport = (apartmentId, params) => {
    return instance
      .get('/rp/report/working/overtime/' + apartmentId, {
        params: {
          residentId: params.residentId,
          fromDate: params?.fromDate && params.fromDate.toISOString(),
          toDate: params?.toDate && params.toDate.toISOString()
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportProfitAndLossReport = (apartmentId, params) => {
    return instance
      .get('/rp/profit_loss/' + apartmentId, {
        params: {
          fromDate: params?.fromDate && params.fromDate.toISOString(),
          toDate: params?.toDate && params.toDate.toISOString()
        },
        responseType: 'blob'
      })
      .then(res => {
        return res
      })
  }

  exportIncomeReport = (apartmentId, params) => {
    return instance
      .get('/rp/report/income/apartment/' + apartmentId, {
        params: {
          entryDateFrom: params.entryDateFrom ? moment(params.entryDateFrom).format('YYYY-MM-DD') : null,
          entryDateTo: params.entryDateTo ? moment(params.entryDateTo).format('YYYY-MM-DD') : null,
          month: params.month,
          year: params.year
        },
        responseType: 'blob'
      })
      .then(res => {
        return res.data
      })
  }

  exportOutcomeReport = (apartmentId, params) => {
    return instance
      .get('/rp/report/expense/apartment/' + apartmentId, {
        params: {
          entryDateFrom: params.entryDateFrom ? moment(params.entryDateFrom).format('YYYY-MM-DD') : null,
          entryDateTo: params.entryDateTo ? moment(params.entryDateTo).format('YYYY-MM-DD') : null,
          month: params.month,
          year: params.year
        },
        responseType: 'blob'
      })
      .then(res => {
        return res.data
      })
  }

  exportDebtReport = (apartmentId, params) => {
    return instance.get('/rp/report/debt/' + apartmentId, { params }).then(res => res.data)
  }

  exportCashReport = (apartmentId, params) => {
    return instance.get('/rp/report/treasury/' + apartmentId, { params, responseType: 'blob' }).then(res => res.data)
  }

  exportReport = (apiEndpoint, params) => {
    return instance.post(apiEndpoint, params, { responseType: 'blob' }).then(res => res.data)
  }
}

export default new ReportService()
