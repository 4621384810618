import {lazy} from "react";

const Signin = lazy(() => import("./Signin"));

const ForgotPassword = lazy(() => import("./ForgotPassword"));

const ChangePassword = lazy(() => import("./ChangePassword"))

const Error404 = lazy(() => import("./Error"));

const ChooseBuilding = lazy(() => import("./ChooseBuilding"))

const sessionsRoutes = [
  {
    path: "/session/signin",
    component: Signin,
  },
  {
    path: "/session/forgot-password",
    component: ForgotPassword
  },
  {
    path: "/session/change-password",
    component: ChangePassword
  },
  {
    path: "/session/404",
    component: Error404
  },
  {
    path: "/session/choose-building",
    component: ChooseBuilding
  }
];

export default sessionsRoutes;
