import newService from "../../services/newService";
import {error, success} from "./alertActions";

export const GET_NEWS = 'GET_NEWS';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILURE = 'GET_NEWS_FAILURE';

export const GET_NEW = 'GET_NEW';
export const GET_NEW_SUCCESS = 'GET_NEW_SUCCESS';
export const GET_NEW_FAILURE = 'GET_NEW_FAILURE';

export const ADD_NEW = 'ADD_NEW';
export const ADD_NEW_SUCCESS = 'ADD_NEW_SUCCESS';
export const ADD_NEW_FAILURE = 'ADD_NEW_FAILURE';

export const EDIT_NEW = 'EDIT_NEW';
export const EDIT_NEW_SUCCESS = 'EDIT_NEW_SUCCESS';
export const EDIT_NEW_FAILURE = 'EDIT_NEW_FAILURE';

export const  NEW_POST_COMMENT='NEW_POST_COMMENT';
export const  NEW_POST_COMMENT_FAILURE='NEW_POST_COMMENT_FAILURE';
export const  NEW_POST_COMMENT_SUCCESS='NEW_POST_COMMENT_SUCCESS';

export function getNews(apartmentId, page, size) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_NEWS
            });
            const data = await newService.getNews(apartmentId, page, size)
            dispatch({
                type: GET_NEWS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_NEWS_FAILURE
            });
        }
    }
}
export function getNewsPrivate(apartmentId, residentId, page, size) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_NEWS
            });
            const data = await newService.getNewsPrivate(apartmentId,residentId, page, size)
            dispatch({
                type: GET_NEWS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_NEWS_FAILURE
            });
        }
    }
}

export function getNew(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_NEW
            });
            const data = await newService.getNew(id)
            dispatch({
                type: GET_NEW_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_NEW_FAILURE
            });
        }
    }
}
export function postComment(values) {

    return async dispatch => {
        try {
            dispatch({
                type:NEW_POST_COMMENT
            });
            const data = await newService.postComment(values)
            dispatch(success("", "message.created"))
            dispatch({
                type: NEW_POST_COMMENT_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: NEW_POST_COMMENT_FAILURE
            });
        }
    }
}

export function addNew(params) {
    return async dispatch => {
        try {
            dispatch({
                type: ADD_NEW
            });
            const data = await newService.addNew(params)
            dispatch(success("Thông báo!", "Đã tạo thành công."))
            dispatch(getNews(localStorage.getItem("apartment_id"), 0, 10));
            dispatch({
                type: ADD_NEW_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: ADD_NEW_FAILURE
            });
        }
    }
}
export function addNewPrivate(params) {
    return async dispatch => {
        try {
            dispatch({
                type: ADD_NEW
            });
            if(params.residentId){
                const data = await newService.addNewPrivate(params)
                dispatch(success("Thông báo!", "Đã tạo thành công."))
                dispatch(getNews(localStorage.getItem("apartment_id"), 0, 10));
                dispatch({
                    type: ADD_NEW_SUCCESS,
                    payload: data
                });
                return data;
            }else{
                dispatch(error("Thông báo!","Không tìm thấy thông tin khách hàng để gửi thông báo"))
                dispatch({
                    type: ADD_NEW_FAILURE
                });
            }
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: ADD_NEW_FAILURE
            });
        }
    }
}

export function editNew(params) {
    return async dispatch => {
        try {
            dispatch({
                type: EDIT_NEW
            });
            const data = await newService.editNew(params)
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch({
                type: EDIT_NEW_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: EDIT_NEW_FAILURE
            });
        }
    }
}
