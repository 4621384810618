import subscriptionService from "../../services/subscriptionService";
import {error, success} from "./alertActions";

export const GET_GROUP_GOODS_SERVICE = 'GET_GROUP_GOODS_SERVICE';
export const GET_GROUP_GOODS_SERVICE_SUCCESS = 'GET_GROUP_GOODS_SERVICE_SUCCESS';
export const GET_GROUP_GOODS_SERVICE_FAILURE = 'GET_GROUP_GOODS_SERVICE_FAILURE';

export const CREATE_GROUP_GOODS_SERVICE = 'CREATE_GROUP_GOODS_SERVICE';
export const CREATE_GROUP_GOODS_SERVICE_SUCCESS = 'CREATE_GROUP_GOODS_SERVICE_SUCCESS';
export const CREATE_GROUP_GOODS_SERVICE_FAILURE = 'CREATE_GROUP_GOODS_SERVICE_FAILURE';

export const UPDATE_GROUP_GOODS_SERVICE = 'UPDATE_GROUP_GOODS_SERVICE';
export const UPDATE_GROUP_GOODS_SERVICE_SUCCESS = 'UPDATE_GROUP_GOODS_SERVICE_SUCCESS';
export const UPDATE_GROUP_GOODS_SERVICE_FAILURE = 'UPDATE_GROUP_GOODS_SERVICE_FAILURE';

export const GET_GROUP_GOOD_SERVICE = 'GET_GROUP_GOOD_SERVICE';
export const GET_GROUP_GOOD_SERVICE_SUCCESS = 'GET_GROUP_GOOD_SERVICE_SUCCESS';
export const GET_GROUP_GOOD_SERVICE_FAILURE = 'GET_GROUP_GOOD_SERVICE_FAILURE';

export const CREATE_GOOD_SERVICE = 'CREATE_GOOD_SERVICE';
export const CREATE_GOOD_SERVICE_SUCCESS = 'CREATE_GOOD_SERVICE_SUCCESS';
export const CREATE_GOOD_SERVICE_FAILURE = 'CREATE_GOOD_SERVICE_FAILURE';

export const UPDATE_GOOD_SERVICE = 'UPDATE_GOOD_SERVICE';
export const UPDATE_GOOD_SERVICE_SUCCESS = 'UPDATE_GOOD_SERVICE_SUCCESS';
export const UPDATE_GOOD_SERVICE_FAILURE = 'UPDATE_GOOD_SERVICE_FAILURE';

export const GET_GOOD_SERVICE = 'GET_GOOD_SERVICE';
export const GET_GOOD_SERVICE_SUCCESS = 'GET_GOOD_SERVICE_SUCCESS';
export const GET_GOOD_SERVICE_FAILURE = 'GET_GOOD_SERVICE_FAILURE';

export const GET_GOOD_SERVICES = 'GET_GOOD_SERVICES';
export const GET_GOOD_SERVICES_SUCCESS = 'GET_GOOD_SERVICES_SUCCESS';
export const GET_GOOD_SERVICES_FAILURE = 'GET_GOOD_SERVICES_FAILURE';

export const GET_RESOURCE_SERVICES = 'GET_RESOURCE_SERVICES';
export const GET_RESOURCE_SERVICES_SUCCESS = 'GET_RESOURCE_SERVICES_SUCCESS';
export const GET_RESOURCE_SERVICES_FAILURE = 'GET_RESOURCE_SERVICES_FAILURE';

export const CREATE_RESOURCE_SERVICE = 'CREATE_RESOURCE_SERVICE';
export const CREATE_RESOURCE_SERVICE_SUCCESS = 'CREATE_RESOURCE_SERVICE_SUCCESS';
export const CREATE_RESOURCE_SERVICE_FAILURE = 'CREATE_RESOURCE_SERVICE_FAILURE';

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_FAILURE = 'GET_SUBSCRIPTIONS_FAILURE';

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';

export const GET_REJECT_SUBSCRIPTION = 'GET_REJECT_SUBSCRIPTION';
export const GET_REJECT_SUBSCRIPTION_SUCCESS = 'GET_REJECT_SUBSCRIPTION_SUCCESS';
export const GET_REJECT_SUBSCRIPTION_FAILURE = 'GET_REJECT_SUBSCRIPTION_FAILURE';

export const GET_APPROVE_SUBSCRIPTION = 'GET_APPROVE_SUBSCRIPTION';
export const GET_APPROVE_SUBSCRIPTION_SUCCESS = 'GET_APPROVE_SUBSCRIPTION_SUCCESS';
export const GET_APPROVE_SUBSCRIPTION_FAILURE = 'GET_APPROVE_SUBSCRIPTION_FAILURE';

export const GET_BILLS_MONTHLY = 'GET_BILLS_MONTHLY';
export const GET_BILLS_MONTHLY_SUCCESS = 'GET_BILLS_MONTHLY_SUCCESS';
export const GET_BILLS_MONTHLY_FAILURE = 'GET_BILLS_MONTHLY_FAILURE';

export const PAID_BILL = 'PAID_BILL';
export const PAID_BILL_SUCCESS = 'PAID_BILL_SUCCESS';
export const PAID_BILL_FAILURE = 'PAID_BILL_FAILURE';

export const APPROVE_BILL = 'APPROVE_BILL';
export const APPROVE_BILL_SUCCESS = 'APPROVE_BILL_SUCCESS';
export const APPROVE_BILL_FAILURE = 'APPROVE_BILL_FAILURE';

export const CREATE_BILL = 'CREATE_BILL';
export const CREATE_BILL_SUCCESS = 'CREATE_BILL_SUCCESS';
export const CREATE_BILL_FAILURE = 'CREATE_BILL_FAILURE';

export const GET_BILL_MONTHLY = 'GET_BILL_MONTHLY';
export const GET_BILL_MONTHLY_SUCCESS = 'GET_BILL_MONTHLY_SUCCESS';
export const GET_BILL_MONTHLY_FAILURE = 'GET_BILL_MONTHLY_FAILURE';

export const DOWNLOAD_BILL_MONTHLY = 'DOWNLOAD_BILL_MONTHLY';
export const DOWNLOAD_BILL_MONTHLY_SUCCESS = 'DOWNLOAD_BILL_MONTHLY_SUCCESS';
export const DOWNLOAD_BILL_MONTHLY_FAILURE = 'DOWNLOAD_BILL_MONTHLY_FAILURE';

export const REMIND_BILL = 'REMIND_BILL';
export const REMIND_BILL_SUCCESS = 'REMIND_BILL_SUCCESS';
export const REMIND_BILL_FAILURE = 'REMIND_BILL_FAILURE';

export function getGroupGoodsServiceByApartment(apartmentId) {
    return dispatch => {
        dispatch({
            type: GET_GROUP_GOODS_SERVICE
        });
        subscriptionService
            .getGroupGoodsServiceByApartment(apartmentId)
            .then(data => {
                return dispatch({
                    type: GET_GROUP_GOODS_SERVICE_SUCCESS,
                    payload: data.data
                });
            })
            .catch(error => {
                return dispatch({
                    type: GET_GROUP_GOODS_SERVICE_FAILURE
                });
            });
    };
}

export function createGroupGoodsService(values) {
    return async (dispatch) => {
        dispatch({
            type: CREATE_GROUP_GOODS_SERVICE
        });
        try {
            const data = await subscriptionService.createGroupGoodsService(values);
            dispatch(success("Thông báo!", "Đã tạo thành công."))
            dispatch({
                type: CREATE_GROUP_GOODS_SERVICE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: CREATE_GROUP_GOODS_SERVICE_FAILURE
            });
        }
    }
}


export function updateGroupGoodsService(values) {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_GROUP_GOODS_SERVICE
        });
        try {
            const data = await subscriptionService.updateGroupGoodsService(values);
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch({
                type: UPDATE_GROUP_GOODS_SERVICE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: UPDATE_GROUP_GOODS_SERVICE_FAILURE
            });
        }
    }
}

export function getGroupGoodServiceById(id) {
    return async (dispatch) => {
        dispatch({
            type: GET_GROUP_GOOD_SERVICE
        });
        try {
            const data = await subscriptionService.getGroupGoodServiceById(id);
            dispatch({
                type: GET_GROUP_GOOD_SERVICE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: GET_GROUP_GOOD_SERVICE_FAILURE
            });
        }
    }
}

export function createGoodService(values) {
    return async (dispatch) => {
        dispatch({
            type: CREATE_GOOD_SERVICE
        });
        try {
            const data = await subscriptionService.createGoodService(values);
            dispatch(success("Thông báo!", "Đã tạo thành công."))
            dispatch({
                type: CREATE_GOOD_SERVICE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: CREATE_GOOD_SERVICE_FAILURE
            });
        }
    }
}

export function updateGoodService(values) {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_GOOD_SERVICE
        });
        try {
            const data = await subscriptionService.updateGoodService(values);
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch({
                type: UPDATE_GOOD_SERVICE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: UPDATE_GOOD_SERVICE_FAILURE
            });
        }
    }
}

export function getGoodService(id) {
    return dispatch => {
        dispatch({
            type: GET_GOOD_SERVICE
        });
        subscriptionService
            .getGoodServiceById(id)
            .then(data => {
                return dispatch({
                    type: GET_GOOD_SERVICE_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                return dispatch({
                    type: GET_GOOD_SERVICE_FAILURE
                });
            });
    };
}


export function createResourceService(values) {
    return async (dispatch) => {
        dispatch({
            type: CREATE_RESOURCE_SERVICE
        });
        try {
            const data = await subscriptionService.createServiceResource(values);
            dispatch(success("Thông báo!", "Đã tạo thành công."))
            dispatch({
                type: CREATE_RESOURCE_SERVICE_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: CREATE_RESOURCE_SERVICE_FAILURE
            });
        }
    }
}

export function getResourceServices(goodServiceId) {
    return dispatch => {
        dispatch({
            type: GET_RESOURCE_SERVICES
        });
        subscriptionService
            .getServiceResources(goodServiceId)
            .then(data => {
                return dispatch({
                    type: GET_RESOURCE_SERVICES_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                return dispatch({
                    type: GET_RESOURCE_SERVICES_FAILURE
                });
            });
    };
}

export function getSubscriptions(apartmentId, residentId, unitId, state, page, size) {
    return async (dispatch) => {
        dispatch({
            type: GET_SUBSCRIPTIONS
        });
        try {
            
            const data = await subscriptionService.getSubscriptions(apartmentId, residentId, unitId, state, page, size);
            console.log(data)
            dispatch({
                type: GET_SUBSCRIPTIONS_SUCCESS,
                payload: data
            });
        } catch (err) {
            dispatch({
                type: GET_SUBSCRIPTIONS_FAILURE
            });
        }
    }
}

export function getSubscriptionByResident(residentId) {
    return dispatch => {
        dispatch({
            type: GET_SUBSCRIPTIONS
        });
        subscriptionService
            .getSubscriptionsByResident(residentId)
            .then(data => {
                return dispatch({
                    type: GET_SUBSCRIPTIONS_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                return dispatch({
                    type: GET_SUBSCRIPTIONS_FAILURE
                });
            });
    };
}

export function approveSubscription(subscriptionId) {
    return dispatch => {
        dispatch({
            type: GET_APPROVE_SUBSCRIPTION
        });
        subscriptionService
            .approveSubscription(subscriptionId)
            .then(data => {
                dispatch(success("", "message.updated"))
                return dispatch({
                    type: GET_APPROVE_SUBSCRIPTION_SUCCESS,
                    payload: data
                });
            })
            .catch(err => {
                dispatch(error("", err.response.data.message))
                return dispatch({
                    type: GET_APPROVE_SUBSCRIPTION_FAILURE
                });
            });
    };
}

export function rejectSubscription(subscriptionId) {
    return dispatch => {
        dispatch({
            type: GET_REJECT_SUBSCRIPTION
        });
        subscriptionService
            .rejectSubscription(subscriptionId)
            .then(data => {
                dispatch(success("", "message.updated"))
                return dispatch({
                    type: GET_REJECT_SUBSCRIPTION_SUCCESS,
                    payload: data
                });
            })
            .catch(err => {
                dispatch(error("", err.response.data.message))
                return dispatch({
                    type: GET_REJECT_SUBSCRIPTION_FAILURE
                });
            });
    };
}

export function registerSubscription(values, serviceType) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_SUBSCRIPTION
            });
            const data = await subscriptionService.registerSubscription(values, serviceType)
            dispatch({
                type: CREATE_SUBSCRIPTION_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: CREATE_SUBSCRIPTION_FAILURE
            });
        }
    }
}

export function getGoodServices(apartmentId) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_GOOD_SERVICES
            });
            const data = await subscriptionService.getGoodServices(apartmentId)
            dispatch({
                type: GET_GOOD_SERVICES_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_GOOD_SERVICES_FAILURE
            });
        }
    }
}

export function getBillsMonthly(apartmentId, params) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_BILLS_MONTHLY
            });
            const data = await subscriptionService.getBillsMonthly(apartmentId, params)
            dispatch({
                type: GET_BILLS_MONTHLY_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_BILLS_MONTHLY_FAILURE
            });
        }
    }
}

export function getBillMonthly(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_BILL_MONTHLY
            });
            const data = await subscriptionService.getBillMonthly(id)
            dispatch({
                type: GET_BILL_MONTHLY_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_BILL_MONTHLY_FAILURE
            });
        }
    }
}

export function downloadBill(id) {
    return async dispatch => {
        try {
            dispatch({
                type: DOWNLOAD_BILL_MONTHLY
            });
            const data = await subscriptionService.downloadBill(id)
            dispatch({
                type: DOWNLOAD_BILL_MONTHLY_SUCCESS,
            });
            let blob = new Blob([data], { type: 'application/pdf' }), url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: DOWNLOAD_BILL_MONTHLY_FAILURE
            });
        }
    }
}


export function paidBill(billId, amount) {
    return async dispatch => {
        try {
            dispatch({
                type: PAID_BILL
            });
            const data = await subscriptionService.paidBill(billId, amount)
            dispatch(success("", "message.updated"))
            dispatch({
                type: PAID_BILL_SUCCESS,
                payload: {bill: data, billId: billId}
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: PAID_BILL_FAILURE
            });
        }
    }
}

export function approveBill(billId) {
    return async dispatch => {
        try {
            dispatch({
                type: APPROVE_BILL
            });
            const data = await subscriptionService.approveBill(billId)
            dispatch(success("", "message.updated"))
            dispatch({
                type: APPROVE_BILL_SUCCESS,
                payload: billId
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: APPROVE_BILL_FAILURE
            });
        }
    }
}

export function createBills(apartmentId, values) {
    return async dispatch => {
        try {
            dispatch({
                type: CREATE_BILL
            });
            const data = await subscriptionService.createBillsMonthly(apartmentId, values)
            dispatch(success("", "Tạo hoá đơn thành công."))
            dispatch({
                type: CREATE_BILL_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("", err.response.data.message))
            dispatch({
                type: CREATE_BILL_FAILURE
            });
        }
    }
}

export function remindBill(id) {
    return async dispatch => {
        try {
            dispatch({
                type: REMIND_BILL
            });
            const data = await subscriptionService.remindBill(id)
            dispatch({
                type: REMIND_BILL_SUCCESS,
                payload: id
            });
            return data;
        } catch (err) {
            dispatch({
                type: CREATE_BILL_FAILURE
            });
        }
    }
}