import {lazy} from "react";

const NewsList = lazy(() => import("./list/NewsList"));

const newsRoutes = [
  {
    path: "/staffNews",
    component: NewsList
  }
];

export default newsRoutes;
