import {lazy} from "react";

const onboardFormList = lazy(() => import("./list/OnboardFormList"));
const OnboardFormView = lazy(() => import("./view/OnboardFormView"));

const onboardFormRoutes = [
    {
        path: "/onboard-forms/:id",
        component: OnboardFormView
    },
    {
        path: "/onboard-forms",
        component: onboardFormList
    }
];

export default onboardFormRoutes;
