import {error, success} from "./alertActions";
import approvalService from "../../services/onboardApprovalService";
import onboardApprovalService from "../../services/onboardApprovalService";

export const GET_REQUESTS = 'GET_REQUESTS';
export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS';
export const GET_REQUESTS_FAILURE = 'GET_REQUESTS_FAILURE';

export const SEND_APPROVAL = 'SEND_APPROVAL';
export const SEND_APPROVAL_SUCCESS = 'SEND_APPROVAL_SUCCESS';
export const SEND_APPROVAL_FAILURE = 'SEND_APPROVAL_FAILURE';


export const REJECT = 'REJECT';
export const REJECT_SUCCESS = 'REJECT_SUCCESS';
export const REJECT_FAILURE = 'REJECT_FAILURE';

export const PUTBACK = 'PUTBACK';
export const PUTBACK_SUCCESS = 'PUTBACK_SUCCESS';
export const PUTBACK_FAILURE = 'PUTBACK_FAILURE';

export const APPROVE = 'APPROVE';
export const APPROVE_SUCCESS = 'APPROVE_SUCCESS';
export const APPROVE_FAILURE = 'APPROVE_FAILURE';

export const GET_BY_ID = 'GET_BY_ID';
export const GET_BY_ID_SUCCESS = 'GET_BY_ID_SUCCESS';
export const GET_BY_ID_FAILURE = 'GET_BY_ID_FAILURE';

export function getRequestsByType(apartmentId, params) {
    return async (dispatch) => {
        dispatch({
            type: GET_REQUESTS
        });
        try {
            const data = await onboardApprovalService.getRequests(apartmentId, params);
            dispatch({
                type: GET_REQUESTS_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: GET_REQUESTS_FAILURE
            });
        }
    }
}

export function getRequestById(id) {
    return async (dispatch) => {
        dispatch({
            type: GET_BY_ID
        });
        try {
            const data = await approvalService.getRequestById(id);
            dispatch({
                type: GET_BY_ID_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: GET_BY_ID_FAILURE
            });
        }
    }
}


export function changeState(id, approveState, parkingLotId, reason) {
    return async dispatch => {
        try {
            dispatch({
                type: SEND_APPROVAL
            })
            switch (approveState) {
                case "APPROVED":
                    await approvalService.approve(id, parkingLotId)
                    break;
                case "REJECTED":
                    await approvalService.rejectOnboard(id, reason)
                    break;
                default:
                    return
            }
            dispatch(success("Thông báo!", "Chuyển trạng thái thành công"))
            dispatch({
                type: SEND_APPROVAL_SUCCESS,
                payload: {id: id, status: approveState}
            });

        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: SEND_APPROVAL_FAILURE
            });
        }
    }
}



