import PageTabsLayout from 'layout/PageTabsLayout'
import departmentModule from './department/departmentModule'
import employeeModule from './employees/employeeModule'
import organizationModule from './organization/organizationModule'
import configRoutes from './config/configRoutes'
import residentRoutes from './residents/residentRoutes'
import subscriptionRoutes from './subscriptions/subscriptionRoutes'
import visitorRoutes from './visitor/visitorRoutes'
import meterRoutes from './meters/meterRoutes'
import workOrderRoutes from './work-order/workOrderRoutes'
import roleRoutes from './role/roleRoutes'
import newRoutes from './news/newRoutes'

const managementRoutes = [
  ...newRoutes,
  ...configRoutes,
  ...residentRoutes,
  ...subscriptionRoutes,
  ...visitorRoutes,
  ...meterRoutes,
  ...workOrderRoutes,
  ...roleRoutes,
  /// Departments and employees, organizations routes
  {
    exact: false,
    path: '/d',
    component: PageTabsLayout,
    routes: [organizationModule.pages.OrganizationListing, departmentModule.pages.DepartmentListing, employeeModule.pages.EmployeeListing]
  }
]

export default managementRoutes
