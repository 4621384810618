import employeeService from '../../services/employeeService'
import { error, success } from './alertActions'
import history from '../../@history'
import { DEFAULT_PARAMS } from '../../constants/Constants'

export const GET_EMPLOYEE_OPTIONS = 'GET_EMPLOYEE_OPTIONS'
export const GET_EMPLOYEE_OPTIONS_SUCCESS = 'GET_EMPLOYEE_OPTIONS_SUCCESS'
export const GET_EMPLOYEE_OPTIONS_FAILURE = 'GET_EMPLOYEE_OPTIONS_FAILURE'

export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS'
export const GET_EMPLOYEES_FAILURE = 'GET_EMPLOYEES_FAILURE'

export const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS'
export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE'

export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS'
export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE'

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS'
export const UPDATE_EMPLOYEE_FAILURE = 'UPDATE_EMPLOYEE_FAILURE'

export const ACTIVE_EMPLOYEE = 'ACTIVE_EMPLOYEE'
export const ACTIVE_EMPLOYEE_SUCCESS = 'ACTIVE_EMPLOYEE_SUCCESS'
export const ACTIVE_EMPLOYEE_FAILURE = 'ACTIVE_EMPLOYEE_FAILURE'

export const DEACTIVE_EMPLOYEE = 'DEACTIVE_EMPLOYEE'
export const DEACTIVE_EMPLOYEE_SUCCESS = 'DEACTIVE_EMPLOYEE_SUCCESS'
export const DEACTIVE_EMPLOYEE_FAILURE = 'DEACTIVE_EMPLOYEE_FAILURE'

export const UPLOAD_FILES = 'UPLOAD_FILES'
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS'
export const UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE'

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS'
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS'
export const GET_ORGANIZATIONS_FAILURE = 'GET_ORGANIZATIONS_FAILURE'

export const GET_ORGANIZATION = 'GET_ORGANIZATION'
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS'
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE'

export const UPDATE_ORG = 'UPDATE_ORG'
export const UPDATE_ORG_SUCCESS = 'UPDATE_ORG_SUCCESS'
export const UPDATE_ORG_FAILURE = 'UPDATE_ORG_FAILURE'

export const CREATE_ORG = 'CREATE_ORG'
export const CREATE_ORG_SUCCESS = 'CREATE_ORG_SUCCESS'
export const CREATE_ORG_FAILURE = 'CREATE_ORG_FAILURE'

export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS'
export const GET_DEPARTMENTS_FAILURE = 'GET_DEPARTMENTS_FAILURE'

export const GET_DEPARTMENT = 'GET_DEPARTMENT'
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS'
export const GET_DEPARTMENT_FAILURE = 'GET_DEPARTMENT_FAILURE'

export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT'
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS'
export const UPDATE_DEPARTMENT_FAILURE = 'UPDATE_DEPARTMENT_FAILURE'

export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT'
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS'
export const CREATE_DEPARTMENT_FAILURE = 'CREATE_DEPARTMENT_FAILURE'

export const EDIT_ROLE_DEPARTMENT = 'EDIT_ROLE_DEPARTMENT'
export const EDIT_ROLE_DEPARTMENT_SUCCESS = 'EDIT_ROLE_DEPARTMENT_SUCCESS'
export const EDIT_ROLE_DEPARTMENT_FAILURE = 'EDIT_ROLE_DEPARTMENT_FAILURE'

export const GET_ROLES = 'GET_ROLES'
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE'

export const GET_ROLE = 'GET_ROLE'
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS'
export const GET_ROLE_FAILURE = 'GET_ROLE_FAILURE'

export const CREATE_ROLE = 'CREATE_ROLE'
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS'
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE'

export const UPDATE_ROLE = 'UPDATE_ROLE'
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE'

export const DELETE_ROLE = 'DELETE_ROLE'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE'

export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS'
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE'

export const GET_PERMISSION = 'GET_PERMISSION'
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS'
export const GET_PERMISSION_FAILURE = 'GET_PERMISSION_FAILURE'

export const CREATE_PERMISSION = 'CREATE_PERMISSION'
export const CREATE_PERMISSION_SUCCESS = 'CREATE_PERMISSION_SUCCESS'
export const CREATE_PERMISSION_FAILURE = 'CREATE_PERMISSION_FAILURE'

export const UPDATE_PERMISSION = 'UPDATE_PERMISSION'
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS'
export const UPDATE_PERMISSION_FAILURE = 'UPDATE_PERMISSION_FAILURE'

export const DELETE_PERMISSION = 'DELETE_PERMISSION'
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS'
export const DELETE_PERMISSION_FAILURE = 'DELETE_PERMISSION_FAILURE'

export const GET_MY_SCHEDULES = 'GET_MY_SCHEDULES'
export const GET_MY_SCHEDULES_SUCCESS = 'GET_MY_SCHEDULES_SUCCESS'
export const GET_MY_SCHEDULES_FAILURE = 'GET_MY_SCHEDULES_FAILURE'

export function getEmployeeOptions(keyword) {
  return async dispatch => {
    try {
      const data = await employeeService.getEmployeeOptions(keyword)
      dispatch({
        type: GET_EMPLOYEE_OPTIONS_SUCCESS
      })
      return data
    } catch (err) {
      dispatch({
        type: GET_EMPLOYEE_OPTIONS_FAILURE
      })
    }
  }
}

export function uploadFiles(files) {
  return dispatch => {
    dispatch({
      type: UPLOAD_FILES
    })
    return employeeService
      .uploadFiles(files)
      .then(data => {
        dispatch({
          type: UPLOAD_FILES_SUCCESS
        })
        return data
      })
      .catch(error => {
        dispatch({
          type: UPLOAD_FILES_FAILURE
        })
      })
  }
}

export function uploadMultipleFiles(files) {
  return dispatch => {
    dispatch({
      type: UPLOAD_FILES
    })
    return employeeService
      .uploadMultipleFiles(files)
      .then(data => {
        dispatch({
          type: UPLOAD_FILES_SUCCESS
        })
        return data
      })
      .catch(error => {
        dispatch({
          type: UPLOAD_FILES_FAILURE
        })
      })
  }
}

export function getEmployees(departmentId, keyword, page, size) {
  return async dispatch => {
    try {
      dispatch({
        type: GET_EMPLOYEES
      })
      const data = await employeeService.getEmployees(departmentId, keyword, page, size)
      dispatch({
        type: GET_EMPLOYEES_SUCCESS,
        payload: data
      })
    } catch (err) {
      dispatch({
        type: GET_EMPLOYEES_FAILURE
      })
    }
  }
}

export function getOrganizations(params) {
  return dispatch => {
    dispatch({
      type: GET_ORGANIZATIONS
    })
    employeeService
      .getOrganizations(params)
      .then(data => {
        return dispatch({
          type: GET_ORGANIZATIONS_SUCCESS,
          payload: data
        })
      })
      .catch(err => {
        return dispatch({
          type: GET_ORGANIZATIONS_FAILURE
        })
      })
  }
}

export function getOrganization(id) {
  return dispatch => {
    dispatch({
      type: GET_ORGANIZATION
    })
    employeeService
      .getOrganization(id)
      .then(data => {
        return dispatch({
          type: GET_ORGANIZATION_SUCCESS,
          payload: data
        })
      })
      .catch(error => {
        return dispatch({
          type: GET_ORGANIZATION_FAILURE
        })
      })
  }
}

export function updateOrganization(id, values) {
  return async dispatch => {
    try {
      dispatch({
        type: UPDATE_ORG
      })
      const data = await employeeService.updateOrganization(id, values)
      dispatch(success('', 'message.updated'))
      dispatch({
        type: UPDATE_ORG_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch(error('', err.response.data.message))
      dispatch({
        type: UPDATE_ORG
      })
    }
  }
}

export function createOrganization(values) {
  return async dispatch => {
    try {
      dispatch({
        type: CREATE_ORG
      })
      const data = await employeeService.createOrganization(values)
      dispatch(success('', 'message.created'))
      dispatch(getOrganizations({ DEFAULT_PARAMS }))
      dispatch({
        type: CREATE_ORG_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch(error('', err.response.data.message))
      dispatch({
        type: CREATE_ORG_FAILURE
      })
    }
  }
}

export function getDepartments() {
  return dispatch => {
    dispatch({
      type: GET_DEPARTMENTS
    })
    employeeService
      .getDepartments({ page: 0, size: 100 })
      .then(data => {
        return dispatch({
          type: GET_DEPARTMENTS_SUCCESS,
          payload: data
        })
      })
      .catch(error => {
        return dispatch({
          type: GET_DEPARTMENTS_FAILURE
        })
      })
  }
}

export function getDepartment(id) {
  return async dispatch => {
    try {
      dispatch({
        type: GET_DEPARTMENT
      })
      const data = await employeeService.getDepartment(id)
      dispatch({
        type: GET_DEPARTMENT_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch({
        type: GET_DEPARTMENT_FAILURE
      })
    }
  }
}

export function createDepartment(values) {
  return async dispatch => {
    try {
      dispatch({
        type: CREATE_DEPARTMENT
      })
      const data = await employeeService.createDepartment(values)
      dispatch(success('', 'message.created'))
      dispatch(getDepartments())
      dispatch({
        type: CREATE_DEPARTMENT_SUCCESS,
        payload: values
      })
      return data
    } catch (err) {
      dispatch(error('', err.response.data.message))
      dispatch({
        type: CREATE_DEPARTMENT_FAILURE
      })
      return err
    }
  }
}

export function updateDepartment(id, values) {
  return async dispatch => {
    try {
      dispatch({
        type: UPDATE_DEPARTMENT
      })
      const data = await employeeService.updateDepartment(id, values)
      dispatch(success('', 'message.updated'))
      dispatch({
        type: UPDATE_DEPARTMENT_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch(error('', err.response.data.message))
      dispatch({
        type: UPDATE_DEPARTMENT_FAILURE
      })
      return err
    }
  }
}

export function getRoles() {
  return async dispatch => {
    try {
      dispatch({
        type: GET_ROLES
      })
      const data = await employeeService.getRoles()
      dispatch({
        type: GET_ROLES_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch({
        type: GET_ROLES_FAILURE
      })
    }
  }
}

export function getPermissions(params) {
  return async dispatch => {
    try {
      dispatch({
        type: GET_PERMISSIONS
      })
      const data = await employeeService.getPermissions(params)
      dispatch({
        type: GET_PERMISSIONS_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch({
        type: GET_PERMISSIONS_FAILURE
      })
    }
  }
}

export function getPermission(permissionId) {
  return async dispatch => {
    try {
      dispatch({
        type: GET_PERMISSION
      })
      const data = await employeeService.getPermissionById(permissionId)
      dispatch({
        type: GET_PERMISSION_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch({
        type: GET_PERMISSION_FAILURE
      })
    }
  }
}

export function createPermission(values) {
  return async dispatch => {
    try {
      dispatch({
        type: CREATE_PERMISSION
      })
      const data = await employeeService.createPermission(values)
      dispatch(success('', 'message.created'))
      dispatch({
        type: CREATE_PERMISSION_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch(error('', err.response.data.message))
      dispatch({
        type: CREATE_PERMISSION_FAILURE
      })
    }
  }
}

export function updatePermission(permissionId, values) {
  return async dispatch => {
    try {
      dispatch({
        type: UPDATE_PERMISSION
      })
      const data = await employeeService.updatePermission(permissionId, values)
      dispatch(success('', 'message.updated'))
      dispatch({
        type: UPDATE_PERMISSION_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch(error('', err.response.data.message))
      dispatch({
        type: UPDATE_PERMISSION_FAILURE
      })
    }
  }
}

export function deletePermission(permissionId) {
  return async dispatch => {
    try {
      dispatch({
        type: DELETE_PERMISSION
      })
      const data = await employeeService.deletePermission(permissionId)
      dispatch(success('', 'message.deleted'))
      dispatch({
        type: DELETE_PERMISSION_SUCCESS,
        payload: permissionId
      })
      return data
    } catch (err) {
      dispatch(error('', err.response.data.message))
      dispatch({
        type: DELETE_PERMISSION_FAILURE
      })
    }
  }
}

export function getRole(roleId) {
  return async dispatch => {
    try {
      dispatch({
        type: GET_ROLE
      })
      const data = await employeeService.getRoleById(roleId)
      dispatch({
        type: GET_ROLE_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch({
        type: GET_ROLE_FAILURE
      })
    }
  }
}

export function createRole(values) {
  return async dispatch => {
    try {
      dispatch({
        type: CREATE_ROLE
      })
      const data = await employeeService.createRole(values)
      dispatch(success('', 'message.created'))
      dispatch({
        type: CREATE_ROLE_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch(error('', err.response.data.message))
      dispatch({
        type: CREATE_ROLE_FAILURE
      })
    }
  }
}

export function updateRole(roleId, values) {
  return async dispatch => {
    try {
      dispatch({
        type: UPDATE_ROLE
      })
      const data = await employeeService.updateRole(roleId, values)
      dispatch(success('', 'message.updated'))
      dispatch({
        type: UPDATE_ROLE_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch(error('', err.response.data.message))
      dispatch({
        type: UPDATE_ROLE_FAILURE
      })
    }
  }
}

export function deleteRole(roleId) {
  return async dispatch => {
    try {
      dispatch({
        type: DELETE_ROLE
      })
      const data = await employeeService.deleteRole(roleId)
      dispatch(success('', 'message.deleted'))
      dispatch({
        type: DELETE_ROLE_SUCCESS,
        payload: roleId
      })
      return data
    } catch (err) {
      dispatch(error('', err.response.data.message))
      dispatch({
        type: DELETE_ROLE_FAILURE
      })
    }
  }
}

export function createEmployee(values) {
  return async dispatch => {
    try {
      dispatch({
        type: CREATE_EMPLOYEE
      })
      const data = await employeeService.createEmployee(values)
      dispatch(success('Thông báo!', 'Đã tạo thành công.'))
      history.push({ pathname: '/employees' })
      dispatch({
        type: CREATE_EMPLOYEE_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch(error('Thông báo!', err.response.data.message))
      dispatch({
        type: CREATE_EMPLOYEE_FAILURE
      })
    }
  }
}

export function updateEmployee(employeeId, values) {
  return async dispatch => {
    try {
      dispatch({
        type: UPDATE_EMPLOYEE
      })
      const data = await employeeService.updateEmployee(employeeId, values)
      dispatch(success('Thông báo!', 'Đã cập nhật thành công.'))
      dispatch({
        type: UPDATE_EMPLOYEE_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch(error('Thông báo!', err.response.data.message))
      dispatch({
        type: UPDATE_EMPLOYEE_FAILURE
      })
    }
  }
}

export function activeEmployee(employeeId) {
  return async dispatch => {
    try {
      dispatch({
        type: ACTIVE_EMPLOYEE
      })
      const data = await employeeService.activeEmployee(employeeId)
      dispatch(success('Thông báo!', 'Đã mở khoá thành công.'))
      dispatch({
        type: ACTIVE_EMPLOYEE_SUCCESS,
        payload: employeeId
      })
      return data
    } catch (err) {
      dispatch(error('Thông báo!', err.response.data.message))
      dispatch({
        type: ACTIVE_EMPLOYEE_FAILURE
      })
    }
  }
}

export function deactiveEmployee(employeeId) {
  return async dispatch => {
    try {
      dispatch({
        type: DEACTIVE_EMPLOYEE
      })
      const data = await employeeService.deactiveEmployee(employeeId)
      dispatch(success('Thông báo!', 'Đã khoá thành công.'))
      dispatch({
        type: DEACTIVE_EMPLOYEE_SUCCESS,
        payload: employeeId
      })
      return data
    } catch (err) {
      dispatch(error('Thông báo!', err.response.data.message))
      dispatch({
        type: DEACTIVE_EMPLOYEE_FAILURE
      })
    }
  }
}

export function getEmployee(employeeId) {
  return async dispatch => {
    try {
      dispatch({
        type: GET_EMPLOYEE
      })
      const data = await employeeService.getEmployee(employeeId)
      dispatch({
        type: GET_EMPLOYEE_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch({
        type: GET_EMPLOYEE_FAILURE
      })
    }
  }
}

export function getMySchedules(apartmentId, fromDate, toDate) {
  return async dispatch => {
    try {
      dispatch({
        type: GET_MY_SCHEDULES
      })
      const data = await employeeService.getMySchedules(apartmentId, fromDate, toDate)
      dispatch({
        type: GET_MY_SCHEDULES_SUCCESS,
        payload: data
      })
      return data
    } catch (err) {
      dispatch({
        type: GET_MY_SCHEDULES_FAILURE
      })
    }
  }
}
