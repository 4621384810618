import {
    CANCEL_VEHICLE,
    CANCEL_VEHICLE_FAILURE,
    CANCEL_VEHICLE_SUCCESS,
    CREATE_PARK_COMPONENT,
    CREATE_PARK_COMPONENT_FAILURE,
    CREATE_PARK_COMPONENT_SUCCESS,
    DELETE_PARK_COMPONENT,
    DELETE_PARK_COMPONENT_FAILURE,
    DELETE_PARK_COMPONENT_SUCCESS,
    GET_PARKING_LOT,
    GET_PARKING_LOT_FAILURE,
    GET_PARKING_LOT_SUCCESS,
    GET_PARKING_LOTS,
    GET_PARKING_LOTS_BY_BLOCK,
    GET_PARKING_LOTS_BY_BLOCK_FAILURE,
    GET_PARKING_LOTS_BY_BLOCK_SUCCESS,
    GET_PARKING_LOTS_FAILURE,
    GET_PARKING_LOTS_SUCCESS,
    GET_PARKING_SUBSCRIPTION,
    GET_PARKING_SUBSCRIPTION_FAILURE,
    GET_PARKING_SUBSCRIPTION_SUCCESS,
    LINK_VEHICLE,
    LINK_VEHICLE_FAILURE,
    LINK_VEHICLE_SUCCESS,
    UPDATE_PARK_COMPONENT,
    UPDATE_PARK_COMPONENT_FAILURE,
    UPDATE_PARK_COMPONENT_SUCCESS,
    UPDATE_PARKING_LOTS,
    UPDATE_PARKING_LOTS_FAILURE,
    UPDATE_PARKING_LOTS_SUCCESS,
    UPDATE_VEHICLE,
    UPDATE_VEHICLE_FAILURE,
    UPDATE_VEHICLE_SUCCESS
} from "../actions/parkingActions";

const ParkingReducer = function (state = {}, action) {
    switch (action.type) {
        case GET_PARKING_LOTS:
            return {
                ...state,
                loading: true
            };
        case GET_PARKING_LOTS_SUCCESS:
            return {
                ...state,
                loading: false,
                parkingLots: action.payload.data
            };
        case GET_PARKING_LOTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_PARKING_LOTS_BY_BLOCK:
            return {
                ...state,
                loading: true
            };
        case GET_PARKING_LOTS_BY_BLOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                parkingLots: action.payload.data
            };
        case GET_PARKING_LOTS_BY_BLOCK_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case UPDATE_PARKING_LOTS:
            return {
                ...state,
                loading: true
            };
        case UPDATE_PARKING_LOTS_SUCCESS:
            let parkingLot = action.payload;
            return {
                ...state,
                loading: false,
                parkingLots: state.parkingLots.map(p => p.id === parkingLot.id
                    ?
                    {...p, name: parkingLot.name, description: parkingLot.description, parkingLotComponents: parkingLot.parkingLotComponents}
                    : p
                )
            };
        case UPDATE_PARKING_LOTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case GET_PARKING_LOT:
            return {
                ...state
            };
        case GET_PARKING_LOT_SUCCESS:
            return {
                ...state,
                parkingLot: action.payload
            };
        case GET_PARKING_LOT_FAILURE:
            return {
                ...state,
                error: true
            };
        case GET_PARKING_SUBSCRIPTION:
            return {
                ...state,
                loading: true
            };
        case GET_PARKING_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                parkingSubscriptions: action.payload
            };
        case GET_PARKING_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case UPDATE_VEHICLE:
            return {
                ...state,
                loading: true
            };
        case UPDATE_VEHICLE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case UPDATE_VEHICLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CANCEL_VEHICLE:
            return {
                ...state,
                loading: true
            };
        case CANCEL_VEHICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                parkingSubscriptions: {
                    ...state.parkingSubscriptions,
                    data: state.parkingSubscriptions.data.map(p => p.id === action.payload
                        ?
                        {...p, status: 'INACTIVE'}
                        : p
                    )
                }
            };
        case CANCEL_VEHICLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case LINK_VEHICLE:
            return {
                ...state,
                loading: true
            };
        case LINK_VEHICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                parkingSubscriptions: {
                    ...state.parkingSubscriptions,
                    data: state.parkingSubscriptions.data.map(p => p.id === action.payload.id
                        ?
                        {...p, cardMagnetUuid: action.payload.cardId}
                        : p
                    )
                }
            };
        case LINK_VEHICLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        case CREATE_PARK_COMPONENT:
            return {
                ...state
            };
        case CREATE_PARK_COMPONENT_SUCCESS:
            return {
                ...state,
                parkingLots: state.parkingLots.map(p => p.id === action.payload.parkingLotId
                    ?
                    {
                        ...p,
                        parkingLotComponents: [
                            action.payload.data,
                            ...p.parkingLotComponents
                        ]
                    }
                    : p
                )
            };
        case CREATE_PARK_COMPONENT_FAILURE:
            return {
                ...state,
                error: true
            };
        case UPDATE_PARK_COMPONENT:
            return {
                ...state
            };
        case UPDATE_PARK_COMPONENT_SUCCESS:
            console.log(action.payload)
            return {
                ...state,
                parkingLot: {
                    ...state.parkingLot,
                    parkingLotComponents: state.parkingLot.parkingLotComponents.map(p => p.id === action.payload.id
                        ?
                        {
                            ...p,
                            fee: action.payload.fee,
                            vehicleType: action.payload.vehicleType,
                            totalSlots: action.payload.totalSlots,
                            overnightFee: action.payload.overnightFee
                        }
                        : p
                    )
                }
            };
        case UPDATE_PARK_COMPONENT_FAILURE:
            return {
                ...state,
                error: true
            };
        case DELETE_PARK_COMPONENT:
            return {
                ...state,
            };
        case DELETE_PARK_COMPONENT_SUCCESS:
            return {
                ...state,
                parkingLot: {
                    ...state.parkingLot,
                    parkingLotComponents: state.parkingLot.parkingLotComponents.filter(p => p.id !== action.payload)
                }
            };
        case DELETE_PARK_COMPONENT_FAILURE:
            return {
                ...state,
                error: true
            };

        default:
            return state
    }
};

export default ParkingReducer;