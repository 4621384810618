export const reports = [
    {
        label: "[KHO] Báo cáo nhập/xuất kho",
        value: "BC-NXK",
        params: [
            {
                id:"fromDate",
                name: "fromDate",
                type: "date",
                picker: "date"
            },
            {
                id:"toDate",
                name: "toDate",
                type: "date",
                picker: "date"
            },
            {
                id: "warehouseId",
                name: "warehouse",
                type: "combobox",
                options: "warehouses"
            },
            {
                id: "goodsId",
                name: "goods",
                type: "combobox",
                options: "configsGoods"
            },
            {
                id: "type",
                name: "type",
                type: "combobox",
                options: "warehouseTypes"
            }
        ]
    },
    {
        label: "[KHO] Báo cáo nhập/xuất tồn hàng tháng",
        value: "BC-NXK-TON",
        params: [
            {
                id: "warehouseId",
                name: "warehouse",
                type: "combobox",
                options: "warehouses"
            },
            {
                id: "month",
                name: "month",
                type: "date",
                picker: "month"
            }
        ]
    },
    {
        label: "[KHO] Báo cáo nhập/xuất tồn theo quý",
        value: "BC-NXK-TON-QUARTER",
        params: [
            {
                id: "warehouseId",
                name: "warehouse",
                type: "combobox",
                options: "warehouses"
            },
            {
                id: "year",
                name: "year",
                type: "date",
                picker: "year"
            },
            {
                id:"quarter",
                name: "quarter",
                type: "combobox",
                options:"quarter"
            }
        ]
    },
    {
        label: "[PARKING] Báo cáo doanh thu bãi xe",
        value: "BC-PARKING",
        params: [
        ]
    },
    {
        label: "[PARKING] Báo cáo đăng ký xe khách hàng",
        value: "BC-PARKING-REQUEST",
        params: [
            {
                id: "residentId",
                name: "resident",
                type: "combobox",
                options: "residents",
                require: true
            },
            {
                id: "requestType",
                name: "requestType",
                type: "combobox",
                options: "requestTypes"
            },
            {
                id:"fromDate",
                name: "fromDate",
                type: "date"
            },
            {
                id:"toDate",
                name: "toDate",
                type: "date"
            }
        ]
    },
    {
        label: "[KHÁCH HÀNG] Báo cáo tiền thuê và phí quản lý hằng quý",
        value: "BC_RENTAL_FEE",
        params: [
            {
                id:"quarter",
                name: "quarter",
                type: "date",
                picker: "quarter"
            }
        ]
    },
    {
        label: "[KHÁCH HÀNG] Báo cáo chi phí đăng ký thi công ngoài giờ",
        value: "BC_CONSTRUCTION",
        params: [
            {
                name: "fromDate",
                id:"fromDate",
                type: "date"
            },
            {
                name: "toDate",
                id:"toDate",
                type: "date"
            },
            {
                id: "residentId",
                name: "resident",
                type: "combobox",
                options: "residents",
                require: true
            }
        ]
    },
    {
        label: "[KHÁCH HÀNG] Báo cáo chi phí bơm nước và hệ thống PCCC",
        value: "BC_PCCC",
        params: [
            {
                name: "fromDate",
                id:"fromDate",
                type: "date"
            },
            {
                name: "toDate",
                id:"toDate",
                type: "date"
            },
            {
                id: "residentId",
                name: "resident",
                type: "combobox",
                options: "residents",
                require: true
            }
        ]
    },
    {
        label: "[KHÁCH HÀNG] Báo cáo làm việc ngoài giờ",
        value: "BC_WORKING_OVERTIME",
        params: [
            {
                name: "fromDate",
                id:"fromDate",
                type: "date"
            },
            {
                name: "toDate",
                id:"toDate",
                type: "date"
            },
            {
                id: "residentId",
                name: "resident",
                type: "combobox",
                options: "residents",
                require: true
            }
        ]
    },
    {
        label: "[TÀI CHÍNH] Báo cáo doanh thu và chi phí",
        value: "BC_DT_CP",
        params: [
            {
                name: "fromDate",
                id:"fromDate",
                type: "date"
            },
            {
                name: "toDate",
                id:"toDate",
                type: "date"
            }
        ]
    },
    {
        label: "[BẢO VỆ] Báo cáo hàng ngày của bảo vệ",
        value: "BC_BAOVE",
        params: [
            {
                name: "date",
                id: "date",
                type: "date",
                picker: "date"
            }
        ]
    },
    {
        label: "[VỆ SINH] Báo cáo kiểm tra vệ sinh",
        value: "BC_KT_VS",
        params: [
            {
                name: "date",
                id: "date",
                type: "date",
                picker: "date"
            }
        ]
    },
]


