import PageTabsLayout from 'layout/PageTabsLayout'
import meterModule from './meterModule'

const meterRoutes = [
  meterModule.pages.ImportMeter,
  {
    exact: false,
    path: '/e',
    component: PageTabsLayout,
    routes: [meterModule.pages.ElectricMeters, meterModule.pages.ElectricReport]
  },
  {
    exact: false,
    path: '/w',
    component: PageTabsLayout,
    routes: [meterModule.pages.WaterMeters, meterModule.pages.WaterReport]
  }
]

export default meterRoutes
