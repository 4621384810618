import {GET_ME, GET_ME_FAILURE, GET_ME_SUCCESS, SET_USER_DATA, USER_LOGGED_OUT} from "../actions/UserActions";

const initialState = {
    loading: false,
    error: false
};

const userReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_USER_DATA: {
            return {
                ...state,
                ...action.data
            };
        }
        case USER_LOGGED_OUT: {
            return {};
        }
        case GET_ME: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_ME_SUCCESS: {
            return {
                ...state,
                loading: false,
                me: action.payload
            }
        }
        case GET_ME_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            }
        }
        default: {
            return state;
        }
    }
};

export default userReducer;
