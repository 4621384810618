/* eslint-disable no-eval */
import instance from './axiosInstance'
import { getPathsString } from '../@utils'

class ApartmentService {
    /**
     * Searches for apartment units based on the provided parameters.
     *
     * @param {Object} params - The search parameters.
     * @param {string} params.code - The code to search for.
     * @returns {Promise<Object>} The search results.
     */
    searchUnits = async (params) => instance.get('/as/units', { params }).then(({ data }) => data)

    getDashboard = (apartmentId) => {
        return instance.get('/es/dashboard/new/' + apartmentId, {}).then(({ data }) => {
            return data
        })
    }

    getApartments = (page, size) => {
        return instance
            .get('/as/apartments/staff', {
                params: {
                    page: page,
                    size: size
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    deleteApartment = (apartmentId) => {
        return instance.delete('/as/apartments/' + apartmentId, {}).then(({ data }) => {
            return data
        })
    }

    editApartment = (apartmentId, values) => {
        return instance
            .put('/as/apartments/' + apartmentId, {
                name: values.name,
                email: values.email,
                phoneNumber: values.phoneNumber,
                address: values.address,
                state: values.state,
                district: values.district,
                city: values.city,
                country: values.country,
                description: values.description,
                blocks: values.blocks
            })
            .then(({ data }) => {
                return data
            })
    }

    uploadPhotoApartment = (apartmentId, file) => {
        let formData = new FormData()
        formData.append('avatar', file)

        return instance
            .post('/as/apartments/' + apartmentId + '/avatar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(({ data }) => {
                return { data }
            })
    }

    getUnitDetail = (unitId) => {
        return instance.get('/as/units/' + unitId, {}).then((response) => {
            return response
        })
    }

    getUnitsByCode = (apartmentId, keyword) => {
        return instance
            .get('/as/apartments/' + apartmentId + '/get-unit-by-code', {
                params: {
                    keyword: keyword
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    getChecklists = (apartmentId, params) => {
        return instance
            .get('/as/inspection-checklist/apartment/' + apartmentId, {
                params: {
                    code: params.checklistTemplateId,
                    fromDate: params.fromDate && params.fromDate.toISOString(),
                    toDate: params.toDate && params.toDate.toISOString(),
                    page: params.page,
                    size: params.size
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    getChecklist = (id) => {
        return instance.get('/as/inspection-checklist/' + id, {}).then(({ data }) => {
            return data
        })
    }

    getChecklistTemplates = (apartmentId, params) => {
        return instance
            .get('/as/inspection-checklist/template/apartment/' + apartmentId, {
                params: {
                    floorId: params.floorId,
                    departmentId: params.departmentId,
                    name: params.name,
                    code: params.code,
                    page: params.page,
                    size: params.size
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    deleteChecklistTemplate = (id) => {
        return instance.delete('/as/inspection-checklist/template/' + id, {}).then(({ data }) => {
            return data
        })
    }

    getChecklistTemplate = (checklistTemplateId) => {
        console.log('getChecklistTemplate')
        return instance.get('/as/inspection-checklist/template/' + checklistTemplateId, {}).then(({ data }) => {
            return data
        })
    }

    createChecklistTemplate = (values) => {
        return instance
            .post('/as/inspection-checklist/template', {
                floorId: values.floorId,
                apartmentId: values.apartmentId,
                name: values.name,
                code: values.code,
                types: values.types,
                departmentId: values.departmentId
            })
            .then(({ data }) => {
                return data
            })
    }

    updateChecklistTemplate = (templateId, values) => {
        return instance
            .put('/as/inspection-checklist/template/' + templateId, {
                name: values.name,
                code: values.code,
                types: values.types,
                floorId: values.floorId,
                departmentId: values.departmentId
            })
            .then(({ data }) => {
                return data
            })
    }

    getFloorsByApartmentId = (apartmentId) => {
        return instance.get('/as/apartments/' + apartmentId + '/floor', {}).then(({ data }) => {
            return data
        })
    }

    getAssets = (apartmentId, params) => {
        return instance
            .get('/as/assets/apartment/' + apartmentId, {
                params: {
                    serial: params.serial,
                    code: params.code,
                    name: params.name,
                    page: params.page,
                    size: params.size
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    createAsset = (values) => {
        return instance
            .post('/as/assets', {
                name: values.name,
                apartmentId: values.apartmentId,
                currentDepartmentId: values.currentDepartmentId,
                price: values.price,
                description: values.description,
                currentLocation: values.currentLocation,
                code: values.code,
                type: values.type,
                serialNumber: values.serialNumber,
                boughtDate: values.boughtDate,
                guaranteeDate: values.guaranteeDate,
                supplier: values.supplier,
                depreciationValue: values.depreciationValue,
                status: values.status
            })
            .then(({ data }) => {
                return data
            })
    }

    updateAsset = (id, values) => {
        return instance
            .put('/as/assets/' + id, {
                name: values.name,
                price: values.price,
                currentDepartmentId: values.currentDepartmentId,
                description: values.description,
                currentLocation: values.currentLocation,
                code: values.code,
                type: values.type,
                serialNumber: values.serialNumber,
                boughtDate: values.boughtDate,
                guaranteeDate: values.guaranteeDate,
                supplier: values.supplier,
                depreciationValue: values.depreciationValue,
                status: values.status
            })
            .then(({ data }) => {
                return data
            })
    }

    deleteAsset = (id) => {
        return instance.delete('/as/assets/' + id, {}).then(({ data }) => {
            return data
        })
    }

    getAssetById = (assetId) => {
        return instance.get('/as/assets/' + assetId, {}).then(({ data }) => {
            return data
        })
    }

    updateLocation = (assetId, values) => {
        return instance
            .put('/as/assets/' + assetId + '/updateLocation', {
                location: values.location,
                changeLocationNote: values.changeLocationNote
            })
            .then(({ data }) => {
                return data
            })
    }

    deleteUnit = (unitId) => {
        return instance.delete('/as/units/' + unitId, {}).then((response) => {
            return response
        })
    }

    getWarehouses = (apartmentId) => {
        return instance.get('/as/warehouse/apartment/' + apartmentId, {}).then(({ data }) => {
            return data
        })
    }

    getWarehouse = (warehouseId) => {
        return instance.get('/as/warehouse/' + warehouseId, {}).then(({ data }) => {
            return data
        })
    }

    createWarehouse = (values) => {
        return instance
            .post('/as/warehouse', {
                apartmentId: values.apartmentId,
                code: values.code,
                location: values.location
            })
            .then(({ data }) => {
                return data
            })
    }

    updateWarehouse = (id, values) => {
        return instance
            .put('/as/warehouse/' + id, {
                code: values.code,
                location: values.location
            })
            .then(({ data }) => {
                return data
            })
    }

    deleteWarehouse = (id) => {
        return instance.delete('/as/warehouse/' + id, {}).then(({ data }) => {
            return data
        })
    }

    getGoods = (params) => {
        return instance
            .get('/as/warehouse/goods', {
                params: {
                    code: params.code,
                    name: params.name,
                    page: params.page,
                    size: params.size
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    getGood = (goodsId) => {
        return instance.get('/as/warehouse/goods/' + goodsId, {}).then(({ data }) => {
            return data
        })
    }

    createGoods = (values) => {
        return instance
            .post('/as/warehouse/goods', {
                code: values.code,
                name: values.name,
                brand: values.brand,
                origin: values.origin,
                unit: values.unit
            })
            .then(({ data }) => {
                return data
            })
    }

    updateGoods = (goodsId, values) => {
        return instance
            .put('/as/warehouse/goods/' + goodsId, {
                code: values.code,
                name: values.name,
                brand: values.brand,
                origin: values.origin,
                unit: values.unit
            })
            .then(({ data }) => {
                return data
            })
    }

    deleteGoods = (goodsId) => {
        return instance.delete('/as/warehouse/goods/' + goodsId, {}).then(({ data }) => {
            return data
        })
    }

    getGoodsReceipts = (apartmentId, params) => {
        return instance
            .get('/as/warehouse/apartment/' + apartmentId + '/goods-receipt', {
                params: {
                    fromDateReceipt: params.fromDateReceipt && params.fromDateReceipt.toISOString(),
                    toDateReceipt: params.toDateReceipt && params.toDateReceipt.toISOString(),
                    page: params.page,
                    size: params.size,
                    supplier: params.supplier,
                    employeeRequestInfo: params.createdName,
                    employeeApproveInfo: params.approvedName,
                    status: params.status,
                    warehouseId: params.warehouseId
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    getGoodsReceiptsByApartment = (apartmentId) => {
        return instance.get('/as/warehouse/apartment/' + apartmentId + '/goods-receipt', {}).then(({ data }) => {
            return data
        })
    }

    getGoodsReceipt = (goodsReceiptId) => {
        return instance.get('/as/warehouse/goods-receipt/' + goodsReceiptId, {}).then(({ data }) => {
            return data
        })
    }

    createGoodReceipt = (values) => {
        return instance
            .post('/as/warehouse/goods-receipt', {
                requestDate: values.requestDate,
                supplierName: values.supplierName,
                supplierPhoneNumber: values.supplierPhoneNumber,
                supplierAddress: values.supplierAddress,
                components: values.components,
                warehouseId: values.warehouseId,
                attachments: getPathsString(values.attachments),
                receivedBy: values.receivedBy && JSON.stringify(values.receivedBy)
            })
            .then(({ data }) => {
                return data
            })
    }

    updateGoodReceipt = (id, values) => {
        return instance
            .put('/as/warehouse/goods-receipt/' + id, {
                requestDate: values.requestDate,
                supplierName: values.supplierName,
                supplierPhoneNumber: values.supplierPhoneNumber,
                supplierAddress: values.supplierAddress,
                components: values.components,
                attachments: getPathsString(values.attachments),
                receivedBy: values.receivedBy && JSON.stringify(values.receivedBy)
            })
            .then(({ data }) => {
                return data
            })
    }

    approveGoodsReceipt = (goodReceiptId) => {
        return instance.post('/as/warehouse/goods-receipt/' + goodReceiptId + '/approve', {}).then(({ data }) => {
            return data
        })
    }

    rejectGoodsReceipt = (goodReceiptId) => {
        return instance.post('/as/warehouse/goods-receipt/' + goodReceiptId + '/reject', {}).then(({ data }) => {
            return data
        })
    }

    exportGoodsReceipt = (goodReceiptId) => {
        return instance
            .get('/as/warehouse/goods-receipt/' + goodReceiptId + '/export', {
                responseType: 'blob'
            })
            .then((res) => {
                return res
            })
    }

    getWarehouseItems = (warehouseId, params) => {
        return instance
            .get('/as/warehouse/' + warehouseId + '/item', {
                params: {
                    code: params.code,
                    name: params.name,
                    page: params.page,
                    size: params.size
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    getWarehouseItemByGoodsId = (warehouseId, goodsId) => {
        return instance.get('/as/warehouse/' + warehouseId + '/item/' + goodsId, {}).then(({ data }) => {
            return data
        })
    }

    getGoodsExports = (apartmentId, params) => {
        return instance
            .get('/as/warehouse/apartment/' + apartmentId + '/goods-export', {
                params: {
                    fromDateExport: params.fromDateExport && params.fromDateExport.toISOString(),
                    toDateExport: params.toDateExport && params.toDateExport.toISOString(),
                    page: params.page,
                    size: params.size,
                    departmentId: params.departmentId,
                    employeeRequestInfo: params.createdName,
                    employeeApproveInfo: params.approvedName,
                    status: params.status,
                    warehouseId: params.warehouseId
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    getGoodsExport = (id) => {
        return instance.get('/as/warehouse/goods-export/' + id, {}).then(({ data }) => {
            return data
        })
    }

    createGoodsExport = (values) => {
        return instance
            .post('/as/warehouse/goods-export', {
                requestDate: values.requestDate,
                components: values.components,
                warehouseId: values.warehouseId,
                departmentId: values.departmentId,
                departmentName: values.departmentName,
                note: values.note,
                workOrderId: values.workOrderId,
                receivedBy: values.receivedBy && JSON.stringify(values.receivedBy),
                deliveryBy: values.deliveryBy && JSON.stringify(values.deliveryBy),
                attachments: getPathsString(values.attachments)
            })
            .then(({ data }) => {
                return data
            })
    }

    updateGoodsExport = (id, values) => {
        return instance
            .put('/as/warehouse/goods-export/' + id, {
                requestDate: values.requestDate,
                components: values.components,
                warehouseId: values.warehouseId,
                departmentId: values.departmentId,
                note: values.note,
                workOrderId: values.workOrderId,
                departmentName: values.departmentName,
                receivedBy: values.receivedBy && JSON.stringify(values.receivedBy),
                deliveryBy: values.deliveryBy && JSON.stringify(values.deliveryBy),
                attachments: getPathsString(values.attachments)
            })
            .then(({ data }) => {
                return data
            })
    }

    approveGoodsExport = (id) => {
        return instance.post('/as/warehouse/goods-export/' + id + '/approve', {}).then(({ data }) => {
            return data
        })
    }

    rejectGoodsExport = (id) => {
        return instance.post('/as/warehouse/goods-export/' + id + '/reject', {}).then(({ data }) => {
            return data
        })
    }

    getMeters = (apartmentId, params) => {
        return instance
            .get('/as/electric-meter/apartment/' + apartmentId, {
                params: {
                    ...params,
                    apartmentId: apartmentId,
                    serialNo: params.serialNo,
                    location: params.location,
                    type: params.type,
                    page: params.page,
                    size: params.size
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    getElectricMetersByUnit = (unitId) => {
        return instance.get('/as/electric-meter/unit-electric-meter/unit/' + unitId, {}).then(({ data }) => {
            return data
        })
    }

    getMeter = (electricMeterId) => {
        return instance.get('/as/electric-meter/' + electricMeterId, {}).then(({ data }) => {
            return data
        })
    }

    createMeter = (values) => {
        return instance
            .post('/as/electric-meter', {
                apartmentId: values.apartmentId,
                location: values.location,
                brand: values.brand,
                type: values.type,
                serialNo: values.serialNo,
                remark: values.remark,
                initValue: values.initValue
            })
            .then(({ data }) => {
                return data
            })
    }

    updateMeter = (electricMeterId, values) => {
        return instance
            .put('/as/electric-meter/' + electricMeterId, {
                location: values.location,
                brand: values.brand,
                serialNo: values.serialNo,
                type: values.type,
                remark: values.remark,
                initValue: values.initValue
            })
            .then(({ data }) => {
                return data
            })
    }

    deleteElectricMeter = (electricMeterId) => {
        return instance.delete('/as/electric-meter/' + electricMeterId, {}).then(({ data }) => {
            return data
        })
    }

    createMonthlyReport = (values) => {
        return instance
            .post('/as/electric-meter/monthly-report', {
                electricMeterId: values.meterId,
                monthReport: values.monthReport,
                newNumber: values.newNumber,
                reportImages: getPathsString(values.reportImages)
            })
            .then(({ data }) => {
                return data
            })
    }

    updateMonthlyReport = (id, values) => {
        return instance
            .put('/as/electric-meter/monthly-report/' + id, {
                newNumber: values.newNumber,
                reportImages: getPathsString(values.reportImages)
            })
            .then(({ data }) => {
                return data
            })
    }

    deleteMonthlyReport = (id) => {
        return instance.delete('/as/electric-meter/monthly-report/' + id, {}).then(({ data }) => {
            return data
        })
    }

    getMonthlyReports = (apartmentId, params) => {
        return instance
            .get('/as/electric-meter/monthly-report/apartment/' + apartmentId, {
                params: {
                    electricMeterId: params.meterId,
                    fromDate: params.fromDate && params.fromDate.toISOString(),
                    toDate: params.toDate && params.toDate.toISOString(),
                    type: params.type,
                    page: params.page,
                    size: params.size
                }
            })
            .then(({ data }) => {
                return data
            })
    }

    getMonthlyReport = (id) => {
        return instance.get('/as/electric-meter/monthly-report/' + id, {}).then(({ data }) => {
            return data
        })
    }

    getConfigMeterUnits = (unitId) => {
        return instance.get('/as/electric-meter/unit-electric-meter/unit/' + unitId, {}).then(({ data }) => {
            return data
        })
    }

    getConfigMeterUnit = (id) => {
        return instance.get('/as/electric-meter/unit-electric-meter/' + id, {}).then(({ data }) => {
            return data
        })
    }

    createConfigMetricUnit = (values) => {
        return instance
            .post('/as/electric-meter/unit-electric-meter', {
                electricMeterId: values.meterId,
                type: values.type,
                note: values.note,
                description: values.description,
                unitId: values.unitId,
                function: values.function
            })
            .then(({ data }) => {
                return data
            })
    }

    createAdjConfigUnit = (configId, values) => {
        return instance
            .post('/as/electric-meter/unit-electric-meter/' + configId + '/adjustment', {
                fromDate: values.fromDate.format('YYYY-MM-DD'),
                toDate: values.toDate.format('YYYY-MM-DD'),
                note: values.note,
                description: values.description,
                function: eval(values.function)
            })
            .then(({ data }) => {
                return data
            })
    }

    updateAdjConfigUnit = (configId, values) => {
        return instance
            .put('/as/electric-meter/unit-electric-meter/' + configId + '/adjustment/' + values.id, {
                fromDate: values.fromDate.format('YYYY-MM-DD'),
                toDate: values.toDate.format('YYYY-MM-DD'),
                note: values.note,
                description: values.description,
                function: eval(values.function)
            })
            .then(({ data }) => {
                return data
            })
    }

    deleteAdjConfigUnit = (configId, adjId) => {
        return instance.delete('/as/electric-meter/unit-electric-meter/' + configId + '/adjustment/' + adjId, {}).then(({ data }) => {
            return data
        })
    }

    updateConfigMetricUnit = (id, values) => {
        return instance
            .put('/as/electric-meter/unit-electric-meter/' + id, {
                function: values.function,
                note: values.note,
                description: values.description
            })
            .then(({ data }) => {
                return data
            })
    }

    deleteConfigMetricUnit = (id) => {
        return instance.delete('/as/electric-meter/unit-electric-meter/' + id, {}).then(({ data }) => {
            return data
        })
    }

    getExpenditures = (apartmentId, params) => {
        return instance
            .get('/as/expenditure-transaction/apartment/' + apartmentId, {
                type: params.type,
                fromDate: params.fromDate,
                toDate: params.toDate,
                page: params.page,
                size: params.size
            })
            .then(({ data }) => {
                return data
            })
    }

    getExpenditure = (id) => {
        return instance.get('/as/expenditure-transaction/' + id, {}).then(({ data }) => {
            return data
        })
    }

    createExpenditure = (values) => {
        return instance
            .post('/as/expenditure-transaction', {
                apartmentId: values.apartmentId,
                amount: values.amount,
                type: values.type,
                goodsReceiptId: values.goodsReceiptId,
                transactionDate: values.transactionDate,
                diffNumber: values.diffNumber
            })
            .then(({ data }) => {
                return data
            })
    }

    updateExpenditure = (id, values) => {
        return instance
            .put('/as/expenditure-transaction/' + id, {
                amount: values.amount,
                type: values.type,
                goodsReceiptId: values.goodsReceiptId,
                diffNumber: values.diffNumber
            })
            .then(({ data }) => {
                return data
            })
    }

    createChecklist = (values) => {
        return instance
            .post('/as/inspection-checklist', {
                floorId: values.floorId,
                checkDate: values.checkDate.toISOString(),
                code: values.code,
                signatureImage: values.signatureImage,
                note: values.note,
                images: getPathsString(values.images),
                components: values.components
            })
            .then(({ data }) => {
                return data
            })
    }

    verifyChecklist = (checklistId, values) => {
        return instance
            .put('/as/inspection-checklist/sign/' + checklistId + '/verify', {
                signatureImage: values.signatureImage,
                counterNote: values.counterNote,
                counterImage: getPathsString(values.counterImage)
            })
            .then(({ data }) => {
                return data
            })
    }

    importMeter = (apartmentId, formData) => {
        return instance.post('/as/electric-meter/apartment/' + apartmentId + '/import', formData).then(({ data }) => {
            return data
        })
    }

    /*

      29/08/2024: Những code sau ổn có sử dụng

  */

    getUnitsByApartment = (apartmentId, params) =>
        instance.get('/as/apartments/' + apartmentId + '/units', { params }).then(({ data }) => {
            return data
        })

    // Get apartment detail by apartment id
    getApartmentDetail = (apartmentId, params) =>
        instance
            .get('/as/apartments/get-detail', {
                params: {
                    apartmentId: apartmentId,
                    blockId: params?.blockId,
                    floorId: params?.floorId,
                    type: params?.type,
                    status: params?.status
                }
            })
            .then(({ data }) => data)

    // Get blocks by apartment id
    getBlocksByApartment = (apartmentId) => instance.get('/as/apartments/' + apartmentId + '/blocks', {}).then(({ data }) => data)

    // Get floors by apartment id, block id
    getFloorsByBlock = (apartmentId, blockId) => instance.get('/as/apartments/' + apartmentId + '/blocks/' + blockId + '/floors', {}).then(({ data }) => data)

    // Tạo căn hộ
    createUnit = (values) => instance.post('/as/units', values).then(({ data }) => data)
    editUnit = (unitId, data) => instance.put('/as/units/' + unitId, data).then(({ data }) => data)

    // Liệt kê tất cả electric-meter
    getMeterUnits = (params) => {
        return instance
            .get('/as/electric-meter/byUnit', {
                params: {
                    apartmentId: params?.apartmentId,
                    unitId: params?.unitId,
                    type: params?.type,
                    keyword: params?.keyword,
                    historyType: params?.historyType,
                    page: params?.page ?? 0,
                    size: params?.size ?? 10
                }
            })
            .then(({ data }) => data)
    }
    assignElectricMeter = (values) => instance.post('/as/electric-meter/unit-electric-meter', values).then(({ data }) => data)
    unassignElectricMeter = (unitElectricMeterId) => instance.delete(`/as/electric-meter/unit-electric-meter/${unitElectricMeterId}`).then(({ data }) => data)

    meters = {
        getReports: (params) => instance.get(`/ss/meter-report/staff/apartment/${params.apartmentId}`, { params }).then(({ data }) => data),
        getMeterUnits: (params) => instance.get('/as/electric-meter/byUnit', { params }).then(({ data }) => data),
        //data: {electricMeterId, function, type, note, description}
        assignMeterToUnit: (data) => instance.post('/as/electric-meter/unit-electric-meter', data).then(({ data }) => data),
        unassignMeterToUnit: (unitElectricMeterId) => instance.delete(`/as/electric-meter/unit-electric-meter/${unitElectricMeterId}`).then(({ data }) => data)
    }

    unit = {
        createUnit: (values) => instance.post('/as/units', values).then(({ data }) => data),
        updateUnit: (unitId, data) => instance.put('/as/units/' + unitId, data).then(({ data }) => data),
        // params: {apartmentId: '', keyword: '', residentUnits.residentId: '', page: 0, size: 10}
        getUnits: (params) => instance.get('/as/units/n/list', { params }).then(({ data }) => data),
        getUnit: (unitId) => instance.get('/as/units/' + unitId, {}).then(({ data }) => data),
        getUnitMeters: (unitId, params) => instance.get('/as/electric-meter/byUnit', { params: { ...params, unitId } }).then(({ data }) => data)
    }

    apartment = {
        getApartments: (params) => instance.get('/as/apartments/staff', { params }).then(({ data }) => data),
        getApartment: (apartmentId, params) => instance.get('/as/apartments/get-detail', { params: { ...params, apartmentId: apartmentId } }).then(({ data }) => data),
        getApartmentsStaff: (params) => instance.get('/as/apartments/staff', { params }).then(({ data }) => data),
        getBlocks: (apartmentId) => instance.get('/as/apartments/' + apartmentId + '/blocks', {}).then(({ data }) => data),
        getFloors: (apartmentId, blockId) => instance.get('/as/apartments/' + apartmentId + '/blocks/' + blockId + '/floors', {}).then(({ data }) => data),
        getBlockWithFoorAndUnits: (apartmentId, blockId, params) =>
            instance.get('/as/apartments/get-detail', { params: { ...params, apartmentId: apartmentId, blockId: blockId } }).then(({ data }) => data?.blocks?.[0]),
        updateApartment: (apartmentId, data) => instance.put(`/as/apartments/${apartmentId}`, data).then(({ data }) => data)
    }
}

export default new ApartmentService()
