import PageTabsLayout from 'layout/PageTabsLayout'
import apartmentModule from './apartmentModule'

const apartmentRoutes = [
    {
        exact: true,
        path: '/apartments',
        component: PageTabsLayout,
        routes: [apartmentModule.pages.ApartmentDetail]
    },
    {
        exact: false,
        path: '/apartments/units',
        component: PageTabsLayout,
        routes: [apartmentModule.pages.UnitDetail]
    }
]

export default apartmentRoutes
