import _ from 'lodash'
import instance from './axiosInstance'

class ParkingService {
  getParkingLots = apartmentId => {
    return instance.get('/ss/parking-lots/apartment/' + apartmentId, {}).then(({ data }) => {
      return data
    })
  }

  getParkingLotsByBlock = blockId => {
    return instance.get('/ss/parking-lots/get-by-block/' + blockId, {}).then(({ data }) => {
      return data
    })
  }

  updateParkingLot = values => {
    return instance
      .put('/ss/parking-lots', {
        parkingLotId: values.id,
        name: values.name,
        description: values.description,
        parkingLotComponents: values.parkingLotComponents
      })
      .then(({ data }) => {
        return data
      })
  }

  getParkingLot = parkingLotId => {
    return instance.get('/ss/parking-lots/' + parkingLotId, {}).then(({ data }) => {
      return data
    })
  }

  createParkingLotComponent = data => {
    return instance
      .post('/ss/parking-lots/component', {
        parkingLotId: data.parkingLotId,
        vehicleType: data.vehicleType,
        totalSlots: data.totalSlots
      })
      .then(({ data }) => {
        return data
      })
  }

  getParkingSubscription = params => {
    return instance
      .get('/ss/parking-subscription', {
        params: {
          ...params,
          parkingLotId: params.parkingLotId,
          residentId: params.residentId,
          licensePlate: params.licensePlate,
          idNumber: params.idNumber && params.idNumber,
          requestType: params.type,
          status: params.status,
          // toFromDate: params.toFromDate && params.toFromDate.toISOString(),
          // fromFromDate: params.fromFromDate && params.fromFromDate.toISOString(),
          // toToDate: params.toToDate && params.toToDate.toISOString(),
          // fromToDate: params.fromToDate && params.fromToDate.toISOString(),
          cardMagnetUuid: params.cardMagnetUuid,
          owner: params.owner,
          page: params.page,
          size: params.size
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  linkVehicleToCard = (vehicleId, cardId) => {
    return instance
      .put('/ss/parking-subscription/' + vehicleId + '/assign-magnet-card/' + cardId, {})
      .then(({ data }) => {
        return data
      })
  }

  registerVehicle = values => {
    return instance
      .post('/ss/parking-subscription/staff/subscribe', {
        unitId: values.unitId,
        parkingLotId: values.parkingLotId,
        residentId: values.residentId,
        parkingRequestType: values.parkingRequestType,
        licensePlate: values.licensePlate,
        remark: values.remark,
        fromDate: values.fromDate,
        toDate: values.toDate,
        type: values.type,
        brand: values.brand,
        idNumber: values.idNumber,
        owner: values.owner
      })
      .then(({ data }) => {
        return data
      })
  }

  cancelVehicle = values => {
    return instance
      .post('/ss/parking-subscription/staff/cancel', {
        parkingLotId: values.parkingLotId,
        residentId: values.residentId,
        cancelLicensePlate: values.cancelLicensePlate,
        parkingTransactionType: values.type,
        parkingRequestType: values.parkingRequestType
      })
      .then(({ data }) => {
        return data
      })
  }

  patchVehicle = (parkingLotId, values) => {
    return instance.patch(`/ss/parking-subscription/${parkingLotId}`, values).then(({ data }) => {
      return data
    })
  }

  updateVehicle = values => {
    return instance
      .post('/ss/parking-subscription/staff/update', {
        unitId: values.unitId,
        parkingLotId: values.parkingLotId,
        residentId: values.residentId,
        brand: values.brand,
        licensePlate: values.licensePlate,
        oldLicensePlate: values.oldLicensePlate,
        idNumber: values.idNumber,
        owner: values.owner,
        remark: values.remark,
        parkingTransactionType: values.type,
        parkingRequestType: values.parkingRequestType,
        fromDate: values.fromDate
        //  && values.fromDate?.toISOString()
      })
      .then(({ data }) => {
        return data
      })
  }

  createParkComponent = values => {
    return instance
      .post('/ss/parking-lots/component', {
        parkingLotId: values.parkingLotId,
        vehicleType: values.type,
        totalSlots: values.totalSlots,
        fee: values.fee,
        overnightFee: values.overNightFee
      })
      .then(({ data }) => {
        return data
      })
  }

  updateParkComponent = (id, values) => {
    return instance
      .put('/ss/parking-lots/component/' + id, {
        vehicleType: values.type,
        totalSlots: values.totalSlots,
        fee: values.fee,
        overnightFee: values.overNightFee
      })
      .then(({ data }) => {
        return data
      })
  }

  deleteParkComponent = id => {
    return instance.delete('/ss/parking-lots/component/' + id, {}).then(({ data }) => {
      return data
    })
  }

  v2getParkingSubscription = params =>
    instance
      .get('/ss/parking-subscription', {
        params: {
          ..._.omit(params, ['dateArrive', 'dateLeave']),
          ...(params?.registerDate
            ? { toFromDate: params.registerDate?.[1], fromFromDate: params.registerDate?.[0] }
            : {}),
          ...(params?.expiredDate ? { toToDate: params.expiredDate?.[1], fromToDate: params.expiredDate?.[0] } : {})
        }
      })
      .then(({ data }) => data)
}

export default new ParkingService()
