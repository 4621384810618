import staffNewsService from "../../services/staffNewsService";
import {error, success} from "./alertActions";

export const GET_STAFF_NEWS = 'GET_STAFF_NEWS';
export const GET_STAFF_NEWS_SUCCESS = 'GET_STAFF_NEWS_SUCCESS';
export const GET_STAFF_NEWS_FAILURE = 'GET_STAFF_NEWS_FAILURE';

export const GET_STAFF_NEWS_ID = 'GET_STAFF_NEWS_ID';
export const GET_STAFF_NEWS_ID_SUCCESS = 'GET_STAFF_NEWS_ID_SUCCESS';
export const GET_STAFF_NEWS_ID_FAILURE = 'GET_STAFF_NEWS_ID_FAILURE';

export const ADD_STAFF_NEWS = 'ADD_STAFF_NEWS';
export const ADD_STAFF_NEWS_SUCCESS = 'ADD_STAFF_NEWS_SUCCESS';
export const ADD_STAFF_NEWS_FAILURE = 'ADD_STAFF_NEWS_FAILURE';

export const EDIT_STAFF_NEWS = 'EDIT_STAFF_NEWS';
export const EDIT_STAFF_NEWS_SUCCESS = 'EDIT_STAFF_NEWS_SUCCESS';
export const EDIT_STAFF_NEWS_FAILURE = 'EDIT_STAFF_NEWS_FAILURE';


export function getStaffNews(apartmentId, page, size) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_STAFF_NEWS
            });
            const data = await staffNewsService.getStaffNews(apartmentId, page, size)
            dispatch({
                type: GET_STAFF_NEWS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_STAFF_NEWS_FAILURE
            });
        }
    }
}

export function getStaffNewsById(id) {
    return async dispatch => {
        try {
            dispatch({
                type: GET_STAFF_NEWS_ID
            });
            const data = await staffNewsService.getStaffNewsById(id)
            dispatch({
                type: GET_STAFF_NEWS_ID_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch({
                type: GET_STAFF_NEWS_ID_FAILURE
            });
        }
    }
}

export function addStaffNews(params) {
    return async dispatch => {
        try {
            dispatch({
                type: ADD_STAFF_NEWS
            });
            const data = await staffNewsService.addStaffNews(params)
            dispatch(success("Thông báo!", "Đã tạo thành công."))
            dispatch(getStaffNews(localStorage.getItem("apartment_id"), 0, 10));
            dispatch({
                type: ADD_STAFF_NEWS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: ADD_STAFF_NEWS_FAILURE
            });
        }
    }
}

export function editStaffNews(params) {
    return async dispatch => {
        try {
            dispatch({
                type: EDIT_STAFF_NEWS
            });
            const data = await staffNewsService.editStaffNews(params)
            dispatch(success("Thông báo!", "Đã cập nhật thành công."))
            dispatch({
                type: EDIT_STAFF_NEWS_SUCCESS,
                payload: data
            });
            return data;
        } catch (err) {
            dispatch(error("Thông báo!", err.response.data.message))
            dispatch({
                type: EDIT_STAFF_NEWS_FAILURE
            });
        }
    }
}
