import React, { Component } from "react";
import { Link } from "react-router-dom";
import { classList } from "@utils";
import DropDownMenuItem from "./DropDownMenuItem";
import { FormattedMessage } from "react-intl";
import { hasAnyAuthorities } from "../../@utils";

class DropDownMenu extends Component {
    state = {
        open: false
    };

    onItemClick = e => {
        e.preventDefault();
        this.setState({ open: !this.state.open });
    };

    renderLevels = items => items.filter(e => hasAnyAuthorities(e.auth) || !e?.auth?.length).map((item, i) => {
        const no = i + 1;
        if (item.sub) {
            return (
                <DropDownMenuItem key={i} item={item} parentNo={no}>
                    {this.renderLevels(item.sub)}
                </DropDownMenuItem>
            );
        } else {
            return (
                <li key={i} className={classList({ "nav-item": true, open: this.state.open })} onClick={this.props.closeSecSidenav} >
                    <Link to={item.path} className='d-flex align-items-center'>
                        <i className={`nav-icon ${item.icon}`} style={{ minWidth: 18, fontSize: 16 }}></i>
                        <div className="item-name text-truncate">
                            {this.props.parentNo ? `${this.props.parentNo}.${no} ` : `${no}. `}
                            <FormattedMessage id={item.name} />
                        </div>
                    </Link>
                </li>
            );
        }
    })

    render() {
        return <ul className="childNav">{this.renderLevels(this.props.menu)}</ul>;
    }
}

export default DropDownMenu;
