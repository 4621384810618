import PageTabsLayout from "layout/PageTabsLayout";
import {lazy} from "react";

const dashboardRoutes = [
  {
    exact: false,
    path: '/dashboard',
    component: PageTabsLayout,
    routes: [
      {
        label: 'Overview',
        path: '/dashboard',
        component: lazy(() => import('./pages/Dashboard'))
      }
    ]
  }
];

export default dashboardRoutes;
