import PageTabsLayout from 'layout/PageTabsLayout'
import visitorModule from './visitorModule'

const visitorRoutes = [
  {
    exact: false,
    path: '/visitors',
    component: PageTabsLayout,
    routes: [visitorModule.pages.VisitorListing]
  }
]

export default visitorRoutes
