import {
    EXPORT_WAREHOUSE_REPORT,
    EXPORT_WAREHOUSE_REPORT_FAILURE,
    EXPORT_WAREHOUSE_REPORT_SUCCESS,
    GET_RESIDENT_INDEX_REPORTS,
    GET_RESIDENT_INDEX_REPORTS_FAILURE,
    GET_RESIDENT_INDEX_REPORTS_SUCCESS,
    GET_WAREHOUSE_REPORTS,
    GET_WAREHOUSE_REPORTS_FAILURE,
    GET_WAREHOUSE_REPORTS_SUCCESS,
    PREVIEW_REPORTS ,
    PREVIEW_REPORTS_SUCCESS,
    PREVIEW_REPORTS_FAILURE,
    GET_REPORTS,
    GET_REPORTS_SUCCESS,
    GET_REPORTS_FAILURE

} from "../actions/reportActions";


const ReportReducer = function(state = {}, action) {

    switch (action.type) {

        case GET_REPORTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_REPORTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                reports: action.payload
            };
        }
        case GET_REPORTS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }


        case GET_WAREHOUSE_REPORTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_WAREHOUSE_REPORTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                warehouseReports: action.payload
            };
        }
        case GET_WAREHOUSE_REPORTS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
        case EXPORT_WAREHOUSE_REPORT: {
            return {
                ...state,
                loading: true
            };
        }
        case EXPORT_WAREHOUSE_REPORT_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case EXPORT_WAREHOUSE_REPORT_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }


        case PREVIEW_REPORTS: {
            return {
                ...state,
                loading: true
            };
        }
        case PREVIEW_REPORTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                previewData: action.payload
            };
        }
        case PREVIEW_REPORTS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        case GET_RESIDENT_INDEX_REPORTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_RESIDENT_INDEX_REPORTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                residentIndexReports: action.payload
            };
        }
        case GET_RESIDENT_INDEX_REPORTS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }


        default: {
            return state;
        }
    }
};

export default ReportReducer;
