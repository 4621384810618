import instance from './axiosInstance'
import { getPathsString } from '../@utils'
import { error } from '../../src/redux/actions/alertActions'
import { useDispatch } from 'react-redux'

class WorkOrderService {
  getWorkOrders = (apartmentId, params) => {
    return instance
      .get('/wo/wos/staff/' + apartmentId, {
        params: {
          departmentId: params.departmentId,
          employeeId: params.employeeId,
          status: params.status,
          priority: params.priority,
          type: params.type,
          assetId: params.assetId,
          fromDate: params.fromDate && params.fromDate.toISOString(),
          toDate: params.toDate && params.toDate.toISOString(),
          page: params.page,
          size: params.size
        }
      })
      .then(({ data }) => {
        return data
      })
  }

  getWorkOrderDetail = id => {
    return instance.get('/wo/wos/' + id + '/detail').then(({ data }) => {
      return data
    })
  }

  assignWorkOrder = (id, employeeId) => {
    return instance
      .post('/wo/wos/' + id + '/assign', {
        employeeId: employeeId
      })
      .then(({ data }) => {
        return data
      })
  }

  changeStatus = (id, status) => {
    return instance
      .post('/wo/wos/' + id + '/change-status', {
        status: status
      })
      .then(({ data }) => {
        return data
      })
  }

  changePriority = (id, priority) => {
    return instance
      .post('/wo/wos/' + id + '/change-priority', {
        priority: priority
      })
      .then(({ data }) => {
        return priority
      })
  }

  postComment = values => {
    return instance
      .post('/wo/comments', {
        workOrderId: values.workOrderId,
        message: values.message,
        images: getPathsString(values.images)
      })
      .then(({ data }) => {
        return data
      })
  }

  createSingleTask = values => {
    try {
      return instance
        .post('/wo/wos/employee', {
          apartmentId: values.apartmentId,
          title: values.title,
          type: values.type,
          detail: values.detail,
          location: values.location,
          priority: values.priority,
          assignedTo: values.assignedTo,
          startDate: values.startDate,
          finishDate: values.finishDate,
          assetId: values?.assetId,
          departmentId: values.departmentId,
          unitId: values.unitId,
          inspectionChecklistTemplateId: values.checklistTemplateId,
          tagPayload: values?.tagPayload
            ? JSON.stringify(
                values?.tagPayload.map(x => ({
                  'text': x.text,
                  'color': '#a9a9a9'
                }))
              )
            : '',
          followerIds: values?.followerIds ? values?.followerIds.map(x => x.value).join(',') : ''
        })
        .then(({ data }) => {
          console.log(data)
          return data
        })
    } catch (err) {
      console.log(err)
      useDispatch(error('Thông báo!', err.response.data.message))
      // dispatch({
      //     type: CHANGE_STATUS_FAILURE
      // });
    }
  }

  createScheduleTask = values => {
    console.log(values)
    return instance
      .post('/wo/wos/employee/bulk', {
        apartmentId: values.apartmentId,
        title: values.title,
        type: values.type,
        detail: values.detail,
        location: values.location,
        priority: values.priority,
        assignedTo: values.assignedTo,
        startDate: values.startDate,
        finishDate: values.finishDate,
        assetId: values?.assetId,
        departmentId: values.departmentId,
        unitId: values.unitId,
        inspectionChecklistTemplateId: values?.checklistTemplateId,
        period: values.period,
        periodString: values.periodString?.join(','),
        endLoop: values.endLoop,
        loopQuantity: values.loopQuantity,
        remindBeforeDate: values.remindBeforeDate,
        tagPayload: values?.tagPayload
          ? JSON.stringify(
              values?.tagPayload.map(x => ({
                'text': x.text,
                'color': '#a9a9a9'
              }))
            )
          : '',
        followerIds: values?.followerIds ? values?.followerIds.map(x => x.value).join(',') : ''
      })
      .then(({ data }) => {
        return data
      })
  }

  cloneTask = (workOrderId, time) => {
    return instance
      .post('/wo/wos/employee/bulk/' + workOrderId + '?time=' + time?.toISOString(), {})
      .then(({ data }) => {
        return data
      })
  }

  deleteLoop = loopId => {
    return instance.delete('/wo/wos/employee/bulk/' + loopId, {}).then(({ data }) => {
      return data
    })
  }
  uploadImage = (woId, files) => {
    return instance.post('/wo/wos/' + woId + '/upload', { data: files }).then(({ data }) => {
      return data
    })
  }
  updateImage = (woId, files) => {
    return instance.post('/wo/wos/' + woId + '/images', { images: files }).then(({ data }) => {
      return data
    })
  }

  v2getWorkOrders = params => instance.get('/wo/wos/staff/' + params.apartmentId, { params }).then(({ data }) => data)

  v2changePriority = ([priority, workOrderIds]) =>
    instance.patch('/wo/wos/lists/change-priority', { priority, workOrderIds }).then(({ data }) => data)

  v2changeStatus = ([status, workOrderIds]) =>
    instance.patch('/wo/wos/lists/change-status', { status, workOrderIds }).then(({ data }) => data)
}

export default new WorkOrderService()
