import { PlusOutlined } from '@ant-design/icons'
import { lazy } from 'react'

const parkingModule = {
  pages: {
    ParkingLotList: {
      label: 'breadcrumb.parkingLotList',
      path: '/p/parking-lots',
      component: lazy(() => import('./list/ParkingLotList'))
    },
    ParkingSubscriptionList: {
      label: 'nav.parkingSubscription',
      path: '/p/parking-subscriptions',
      component: lazy(() => import('./pages/ParkingSubscriptionList')),
      actions: eventEmitter => ({
        actions: [
          {
            key: 'register',
            label: 'common.button.register',
            style: 'primary',
            icon: <PlusOutlined />,
            onClick: () => eventEmitter.emit('layout', ['onCreate', {}])
          }
        ]
      })
    }
  }
}

export default parkingModule
