class EventEmitter {
  constructor() {
    this.events = {}
  }

  on(event, listener) {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(listener)
    return () => this.removeListener(event, listener)
  }

  emit(event, data) {
    if (this.events[event]) {
      this.events[event].forEach(listener => listener(data))
    }
  }

  removeListener(event, listenerToRemove) {
    if (this.events[event]) {
      this.events[event] = this.events[event].filter(listener => listener !== listenerToRemove)
    }
  }
}
const eventEmitter = new EventEmitter()

export default eventEmitter
